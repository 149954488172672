import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { configs } from '../../../assets/Config';
import { useLogin } from '../../../services/contexts/AdminLoginContext';
import { useRMContext } from '../../../services/contexts/RMContext';
import LeftBarPage from '../../includes/dashboard/LeftBarPage';
import TopBarPage from '../../includes/dashboard/TopBarPage';
import ToastPage from '../../includes/ToastPage';
import CreateRolePage from './CreateRolePage';
import EditFunctionPage from './EditFunctionPage';
import RoleListPage from './RoleListPage';
import UserDetailPage from './UserDetailPage';
import '../../../assets/styles/RM.css'
import DeletedRolePage from './DeletedRolePage';


export default function RMMainPage() {

    const { getFunctionList, getAllRoleUsersList, showDeletedRoles, setShowDeletedRoles } = useRMContext();
    const { setCurrModule, allowedFunc } = useLogin()

    const history = useHistory()

    useEffect(() => {
        (async () => {
            try {
                if (allowedFunc.includes(configs.functionList['role_management:role_management'])) {
                    setCurrModule(configs.modules.rm)
                    getFunctionList();
                    getAllRoleUsersList()
                    setShowDeletedRoles(false)
                } else {
                    return history.goBack()
                }
            } catch (error) {

            }
        })()
    }, [])

    // role management main ui code
    return (
        <>
            <TopBarPage />
            <div style={{ display: "flex" }}>
                <LeftBarPage />
                {showDeletedRoles ? <div id='rmContainer' className="rm-container"><DeletedRolePage /></div> :
                    <div id='rmContainer' className="rm-container">
                        <RoleListPage />
                        <CreateRolePage />
                        <UserDetailPage />
                        <EditFunctionPage />
                        <ToastPage />
                    </div>
                }
            </div>
        </>
    )
}