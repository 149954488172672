import { DataGrid, GridToolbar } from '@material-ui/data-grid';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { configs } from '../../../assets/Config';
import '../../../assets/styles/RM.css'
import { useLogin } from '../../../services/contexts/AdminLoginContext';
import { useRMContext } from '../../../services/contexts/RMContext';
import DeleteModalPage from '../../includes/DeleteModalPage';
import CreateAdminPage from './CreateAdminPage';

export default function RoleListPage() {

    const { roleUserList, setRoleUserList, editRole, setEditRole, deleteRole, arFunction, setarFunction, userDetail, showUserDetail, setCreateAdminRoleId, setUpdateAdmin, setEditAdminDetail, showDeletedRoles, setShowDeletedRoles, setDeletedRoles } = useRMContext();

    const history = useHistory()
    const { setShowDelete } = useLogin()
    const [deleteId, setDeleteId] = useState({})
    const [tempRoles, setTempRoles] = useState(roleUserList)

    const columns = [{ field: "id", headerName: "User ID", minWidth: 100, flex: 0.5, hide: true, filterable: false },
    { field: "email", headerName: "Email", minWidth: 100, flex: 0.5, cellClassName: "break-text" },
    { field: "name", headerName: "Name", minWidth: 100, flex: 0.4, cellClassName: "break-text" },
    { field: "account_created_on", headerName: "Account Created On", minWidth: 100, flex: 0.5, cellClassName: "break-text" },
    {
        field: "edit", headerName: " ", minWidth: 40, flex: 0.1, cellClassName: "break-text", cellClassName: "text-primary", renderCell: (params) => {
            return <a href="#" className="custom-link text-primary" onClick={(e) => {
                e.preventDefault()
                setCreateAdminRoleId(params.row.role_id)
                setUpdateAdmin(true)
                setEditAdminDetail({
                    "edit": true,
                    "data": params.row
                })
            }}>Edit</a>
        }
    },
    {
        field: "delete", headerName: " ", minWidth: 65, flex: 0.1, cellClassName: "break-text", cellClassName: "text-danger", renderCell: (params) => {
            return <a href="#" className="custom-link text-danger" onClick={(e) => {
                e.preventDefault()
                setDeleteId({
                    "category": configs.modules.am,
                    "type": "Admin",
                    "name": params.row.name,
                    "id": params.row.id
                })
                setShowDelete(true)
            }}>Delete</a>
        }
    }]


    // search roles function
    function roleSearch(e) {
        try {
            if (e.target.value === "" || e.target.value === null) {
                setTempRoles(roleUserList);
            } else {
                let data = [];
                roleUserList.map((element, key) => {
                    if (element.name.toLowerCase().includes(e.target.value.toLowerCase())) {
                        data.push(element)
                    }
                })
                setTempRoles(data)
            }
        } catch (error) {

        }
    }


    useEffect(() => {
        try {
            setTempRoles(roleUserList)
        } catch (error) {

        }
    }, [roleUserList])

    // role list page ui code
    return (
        <div id="roleListPage" className='d-md-block' style={{ width: "100%" }}>
            <div className="row d-flex align-items-center mt-2 ml-3 mb-1 mr-2">
                <div className="col-lg-6 col-7 m-0 p-0">
                    <p className="h4 text-custom-grey">Role Management</p>
                </div>
                <div className="col-lg-6 col-5 text-right m-0 p-0">
                    <a href="#" className="custom-link" onClick={(e) => {
                        e.preventDefault()
                        setDeletedRoles("")
                        setShowDeletedRoles(!showDeletedRoles)
                    }}><i className={showDeletedRoles ? "fas fa-user mr-2" : "fas fa-user-slash mr-2"}></i>{showDeletedRoles ? "Active Roles" : "Deleted Roles"}</a>
                </div>
                <div className="col-12 row m-0 p-0 mt-3 mb-0 pb-0">
                    <div className='col-6 m-0 p-0'>
                        <div className='d-md-none d-block'>
                            <a href='#' className='custom-link' onClick={(e) => {
                                e.preventDefault()
                                document.getElementById("rmCreateRole").classList.toggle('d-none')
                                document.getElementById("roleListPage").classList.toggle('d-none')
                            }}><p className='m-0 p-0'>Create New Role</p></a>
                        </div>
                    </div>
                    <div className='col-6 m-0 p-0 text-right'>
                        <input type="text" className="form-control float-right mb-0 top-search f-10" placeholder="Search Role" onKeyUp={roleSearch} />
                    </div>
                </div>
            </div>
            <div id="rmRoleList" className="rm-main">
                {tempRoles ? tempRoles.map((element, key) => {
                    return (<div key={`roleTemp` + key} className="role-card mb-4">
                        <div className="row">
                            <div className="col-lg-6">
                                <p className="h6 text-custom-greyDark text-lg-left text-center">{element.name}</p>
                            </div>
                            <div className="col-lg-6 text-lg-right text-center">
                                <a href="#" className="btn btn-warning mr-2 f-10" onClick={(e) => {
                                    e.preventDefault();
                                    setUpdateAdmin(true)
                                    setCreateAdminRoleId(element.id)
                                    // history.push('/adminManagement')
                                }}><i className="fas fa-user mr-2 f-10" ></i>Add Admin</a>
                                <a href="#" className="btn btn-primary mr-2 f-10" onClick={(e) => {
                                    e.preventDefault()
                                    let edit = editRole
                                    edit.edit = true
                                    edit.data = element
                                    setarFunction(element.function_ids)
                                    setEditRole(editRole)
                                    setCreateAdminRoleId(element.id)
                                    document.getElementById("rmCreateRole").classList.toggle('d-none')
                                    document.getElementById("roleListPage").classList.toggle('d-none')
                                }}><i className="far fa-edit mr-2 f-10" ></i>Edit</a>
                                <a href="#" data-toggle="modal" data-target="#deleteRole" className="btn btn-danger f-10" onClick={(e) => {
                                    e.preventDefault()
                                    setDeleteId({
                                        "category": configs.modules.rm,
                                        "type": "Role",
                                        "name": element.name,
                                        "id": element.id
                                    })
                                    setShowDelete(true)
                                }}><i className="fas fa-trash mr-2 f-10"></i>Delete</a>
                            </div>
                            <p className="text-custom-greyDark role-decsription f-12">{element.description}</p>
                        </div>
                        <p className="mt-1 text-custom-grey f-12">Enrolled Users</p>
                        {element.users ?
                            <div className="role-table">
                                <DataGrid
                                    rows={element.users}
                                    headerHeight={30}
                                    rowHeight={40}
                                    columns={columns}
                                    resizable={true}
                                    autoPageSize
                                    pagination
                                    components={{
                                        Toolbar: GridToolbar,
                                    }}

                                // onSelectionModelChange={onRowSelected}
                                />
                            </div>
                            : <div className="loader"></div>}
                    </div>
                    )
                }) : <div className="loader"></div>}
            </div>
            <CreateAdminPage />
            <DeleteModalPage {...deleteId} />
        </div>
    )
}