import React, { useEffect, useState } from 'react';
import { configs } from '../../../assets/Config';
import { useLogin } from '../../../services/contexts/AdminLoginContext';
import '../../../assets/styles/BuyOrder.css'
import TopBarPage from '../../includes/dashboard/TopBarPage';
import LeftBarPage from '../../includes/dashboard/LeftBarPage';
import { DataGrid, GridToolbar, GridToolbarContainer, GridToolbarExport } from '@material-ui/data-grid';
import { Link } from 'react-router-dom';
import { useKYCContext } from '../../../services/contexts/KYCContext';
import { useHistory } from 'react-router-dom';


export default function CustomerStatPage() {

    const { setKycRequesterUserDetail, getCustomerStats, activeStatus, setActiveStatus } = useKYCContext()
    const { setCurrModule, allowedFunc, showDelete, setShowDelete, showConfirm, setShowConfirm } = useLogin()

    var [count, setCount] = useState(0)
    const [tableData, setTableData] = useState([])

    const history = useHistory()

    useEffect(() => {
        if (allowedFunc.includes(configs.functionList['user_management:load_user_details'])) {
            setCurrModule(configs.modules.custStat)
        } else {
            return history.goBack()
        }
    }, [])

    useEffect(() => {
        (async () => {
            if (count != 0) {
                await getStats()
            }
        })()
    }, [activeStatus])

    // function getOrders
    async function getStats() {
        try {
            let response = await getCustomerStats(configs.customerStatistics[activeStatus].api)
            if (response.success && response.success === "success") {
                setTableData(response.data)
            } else {

            }
        } catch (error) {

        }
    }


    // columns for various data tables
    const tableColumns = [
        { field: "id", headerName: "ID", minWidth: 50, flex: 0.4, hide: true },
        {
            field: "name", headerName: "Name", minWidth: 150, flex: 0.4, cellClassName: "break-text f-12"
        },
        {
            field: "email", headerName: "E-mail", minWidth: 150, flex: 0.4, cellClassName: "break-text f-12", renderCell: (params) => {
                if (allowedFunc.includes(configs.functionList['user_management:load_user_details']) && (activeStatus !== configs.customerStatistics['Without Account'].name)) {
                    return <Link to={`/customerProfile/${params.row.id}`} onClick={() => {
                        setKycRequesterUserDetail(params.row)
                    }}>{params.row.email}</Link>
                } else {
                    return params.row.email
                }
            }, valueGetter: (value) => value.row.email
        },
        // { field: "mobile", headerName: "Mobile", minWidth: 150, flex: 0.4, cellClassName: "break-text f-12" }
        {
            field: "mobile", headerName: "Phone No.", minWidth: 100, flex: 0.3, cellClassName: "break-text f-12", renderCell: (params) => {
                return <a href={"https://wa.me/" + params.row.mobile} target="_blank">{params.row.mobile ? `+${params.row.mobile}` : params.row.mobile}</a>
            }, valueGetter: (value) => value.row.mobile ? `+${value.row.mobile}` : value.row.mobile
        },
        {
            field: "timestamp", headerName: "Timestamp", minWidth: 150, flex: 0.3, cellClassName: "break-text f-12"
        }
    ]


    // react data table export option
    function CustomToolbar() {
        return (
            <GridToolbarContainer>
                <GridToolbarExport />
            </GridToolbarContainer>
        );
    }

    // buy order ui code
    return (
        <>
            <TopBarPage />
            <div style={{ display: "flex" }}>
                <LeftBarPage />
                <div id='custStatContainer' className="smbo-container">
                    <div style={{ width: "100%" }}>
                        <div className="row m-md-4 m-2 p-0">
                            <div className="col-md-6 col-7 m-0 p-0">
                                <p className="h5 text-custom-grey">{activeStatus}</p>
                            </div>
                            <div className="col-md-6 col-5 row m-0 p-0 align-items-center justify-content-end">
                                {/* <div className='col-' */}
                                <span className="text-primary mr-2">Status</span>
                                <div className='col-md-6 col-12 m-0 p-0 pr-lg-3'>
                                    <select name="" id="" className="w-100 py-1 px-2 border rounded" value={activeStatus ? activeStatus : ""} onChange={(e) => {
                                        setTableData("")
                                        setActiveStatus(e.target.value)
                                    }}>
                                        {Object.keys(configs.customerStatistics).map((element, key) => {
                                            if (count === 0) {
                                                setActiveStatus(configs.customerStatistics[element].name)
                                                setCount(count += 1)
                                            }
                                            return (
                                                <option key={`custStatKey` + key} value={configs.customerStatistics[element].name}>{configs.customerStatistics[element].name}</option>
                                            )
                                        })}
                                    </select>
                                </div>
                            </div>
                        </div>
                        <div className="kyc-table">
                            {tableData ? <DataGrid
                                rows={tableData}
                                columns={tableColumns}
                                resizable={true}
                                autoPageSize
                                pagination
                                headerHeight={30}
                                rowHeight={40}
                                // onSelectionModelChange={onRowSelected}
                                components={{
                                    Toolbar: GridToolbar,
                                }}
                            />
                                : <div className="loader"></div>}
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}