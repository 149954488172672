import { DataGrid, GridToolbar } from '@material-ui/data-grid';
import React, { useEffect } from 'react';
import { configs } from '../../../assets/Config';
import { useLogin } from '../../../services/contexts/AdminLoginContext';
import LeftBarPage from '../../includes/dashboard/LeftBarPage';
import TopBarPage from '../../includes/dashboard/TopBarPage';
import '../../../assets/styles/NM.css'
import { useNMContext } from '../../../services/contexts/NMContext';
import ToastPage from '../../includes/ToastPage';
import { Link } from 'react-router-dom';
import { useHistory } from 'react-router';

export default function NMPage() {

    const { nmFunction, getNotificationFunction, updateNotificationFunction, setNMFunction } = useNMContext()
    const { setCurrModule, setShowSuccess, allowedFunc } = useLogin()

    const history = useHistory()

    useEffect(() => {
        (async () => {
            try {
                setCurrModule(configs.modules.mn)
                if (allowedFunc.includes(configs.functionList['notification_management:notification_management'])) {
                    getNotificationFunction()
                } else {
                    return history.goBack()
                }
            } catch (error) {

            }
        })()
    }, [])

    // async function update notification
    async function updateNotificationSetting(functionId, status, type) {
        try {
            setNMFunction("");
            let value = 0
            status ? value = 1 : value = 0
            let response = await updateNotificationFunction(functionId, value, type)
            if (response === "success") {
                setShowSuccess(true)
            }
        } catch (error) {

        }
    }

    const columns = [{ field: "id", headerName: "Function ID", minWidth: 100, flex: 0.5, hide: true, filterable: false },
    { field: "name", headerName: "Function", minWidth: 100, flex: 0.3, cellClassName: "break-text" },
    { field: "receiver", headerName: "Reciever", minWidth: 100, flex: 0.3, cellClassName: "break-text" },
    { field: "description", headerName: "description", minWidth: 100, flex: 0.5, cellClassName: "break-text" },
    {
        field: "mobile", headerName: "Mobile", minWidth: 30, flex: 0.2, cellClassName: "break-text text-primary justify-content-center", renderCell: (cellValues) => {
            return <input type="checkbox" defaultChecked={cellValues.row.mobile === 1 ? true : false} className="form-check-input" onChange={(e) => {
                updateNotificationSetting(cellValues.row.id, e.target.checked, "mobile")
            }} />
        }
    },
    {
        field: "email", headerName: "Email", minWidth: 30, flex: 0.2, cellClassName: "break-text text-danger justify-content-center", renderCell: (cellValues) => {
            return <input type="checkbox" defaultChecked={cellValues.row.email === 1 ? true : false} className="form-check-input" onChange={(e) => {
                updateNotificationSetting(cellValues.row.id, e.target.checked, "email")
            }} />
        }
    },
    {
        field: "whatsapp", headerName: "WhatsApp", minWidth: 30, flex: 0.2, cellClassName: "break-text text-danger justify-content-center", renderCell: (cellValues) => {
            return <input type="checkbox" defaultChecked={cellValues.row.whatsapp === 1 ? true : false} className="form-check-input" onChange={(e) => {
                updateNotificationSetting(cellValues.row.id, e.target.checked, "whatsapp")
            }} />
        }
    }
    ]


    // notification management main ui code
    return (
        <>
            <TopBarPage />
            <div style={{ display: "flex" }}>
                <LeftBarPage />
                <div id='nmContainer' className="nm-container">
                    <div style={{ width: "100%" }}>
                        <div className="row d-flex align-items-center mt-2 ml-2 mb-4 mr-3">
                            <div className="col-md-6 text-left">
                                <p className="h4 text-custom-grey">Notification Management</p>
                            </div>
                            <div className="col-md-6 text-left text-md-right">
                                <Link to="/notificationTemplateManagement"><i className="fas fa-id-card-alt mr-2"></i>Manage Templates</Link>
                            </div>
                        </div>
                        <div className=" nm-role-table">
                            {nmFunction ? <DataGrid
                                rows={nmFunction}
                                headerHeight={40}
                                rowHeight={30}
                                columns={columns}
                                resizable={true}
                                autoPageSize
                                pagination
                                components={{
                                    Toolbar: GridToolbar,
                                }} />
                                : <div className="loader"></div>}
                        </div>
                    </div>
                </div>
                <ToastPage />
            </div>
        </>
    )
}