import axios from "axios";
import { configs } from '../../../assets/Config';

// get all functions
export function getAllFunctions(token) {
    return new Promise(async (resolve, reject) => {
        try {
            var config = {
                method: 'get',
                url: `${process.env.REACT_APP_BASEURL}/admin/rolemanagement/functions`,
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            };

            let res = await axios(config)
            resolve(res)
        } catch (err) {
            resolve(err?.response)
        }
    })
}


// edit function details
export function editFunctionDetail(token, functionId, extraName, description) {
    return new Promise(async (resolve, reject) => {
        try {
            var data = {
                "function_id": functionId,
                "extra_name": extraName,
                "description": description
            }
            var config = {
                method: 'patch',
                url: `${process.env.REACT_APP_BASEURL}/admin/rolemanagement/editFunction`,
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json'
                },
                data: data
            };

            let res = await axios(config)
            resolve(res)
        } catch (err) {
            resolve(err?.response)
        }
    })
}