import React, { useEffect, useState } from 'react';
import { configs } from '../../../assets/Config';
import { useLogin } from '../../../services/contexts/AdminLoginContext';
import '../../../assets/styles/BuyOrder.css'
import TopBarPage from '../../includes/dashboard/TopBarPage';
import LeftBarPage from '../../includes/dashboard/LeftBarPage';
import { DataGrid, GridToolbar, GridToolbarContainer, GridToolbarExport } from '@material-ui/data-grid';
import { Link } from 'react-router-dom';
import { useDistributorContext } from '../../../services/contexts/DistributorContext';
import { useHistory } from 'react-router-dom';

export default function DistributorStatsPage() {

    const { setKycRequesterUserDetail, getCustomerStats, activeStatus, setActiveStatus } = useDistributorContext()
    const { setCurrModule, allowedFunc, showDelete, setShowDelete, showConfirm, setShowConfirm } = useLogin()

    var [count, setCount] = useState(0)
    const [tableData, setTableData] = useState([])

    const history = useHistory()


    useEffect(() => {
        if (allowedFunc.includes(configs.functionList['distributor_management:load_distributor_details'])) {
            (async () => {
                setCurrModule(configs.modules.distStat)
                let response = await getCustomerStats()
                if (response.success && response.success === "success") {
                    setTableData(response.data)
                } else {

                }

            })()
        } else {
            return history.goBack()
        }
    }, [])


    // columns for various data tables
    const tableColumns = [
        { field: "id", headerName: "ID", minWidth: 50, flex: 0.4, hide: true },
        {
            field: "name", headerName: "Name", minWidth: 150, flex: 0.4, cellClassName: "break-text f-12"
        },
        {
            field: "email", headerName: "E-mail", minWidth: 150, flex: 0.4, cellClassName: "break-text f-12", renderCell: (params) => {
                return params.row.email
            }, valueGetter: (value) => value.row.email
        },
        // { field: "mobile", headerName: "Mobile", minWidth: 150, flex: 0.4, cellClassName: "break-text f-12" }
        {
            field: "mobile", headerName: "Phone No.", minWidth: 100, flex: 0.3, cellClassName: "break-text f-12", renderCell: (params) => {
                return <a href={"https://wa.me/" + params.row.mobile} target="_blank">{params.row.mobile ? `+${params.row.mobile}` : params.row.mobile}</a>
            }, valueGetter: (value) => value.row.mobile ? `+${value.row.mobile}` : value.row.mobile
        },
        {
            field: "timestamp", headerName: "Timestamp", minWidth: 150, flex: 0.3, cellClassName: "break-text f-12"
        }
    ]

    // react data table export option
    function CustomToolbar() {
        return (
            <GridToolbarContainer>
                <GridToolbarExport />
            </GridToolbarContainer>
        );
    }

    // buy order ui code
    return (
        <>
            <TopBarPage />
            <div style={{ display: "flex" }}>
                <LeftBarPage />
                <div id='custStatContainer' className="smbo-container">
                    <div style={{ width: "100%" }}>
                        <section className="container pt-2">
                            <div className="row">
                                <div className="col-6 mb-3">
                                    <p className="h5 text-custom-grey">Distributor Drop Off</p>
                                </div>
                                {/* <div className="col-lg-6 text-lg-right text-md-right text-center mt-lg-0 mt-2">
                                    <span className="text-primary mr-2">Status</span>
                                    <select name="" id="" className="w-50 py-1 px-2 border rounded" value={activeStatus ? activeStatus : ""} onChange={(e) => {
                                        setActiveStatus(e.target.value)
                                    }}>
                                        {Object.keys(configs.customerStatistics).map((element, key) => {
                                            if (count === 0) {
                                                setActiveStatus(configs.customerStatistics[element].name)
                                                setCount(count += 1)
                                            }
                                            return (
                                                <option key={`custStatKey` + key} value={configs.customerStatistics[element].name}>{configs.customerStatistics[element].name}</option>
                                            )
                                        })}
                                    </select>
                                </div> */}
                            </div>
                        </section>
                        <div className="kyc-table">
                            {tableData ? <DataGrid
                                rows={tableData}
                                columns={tableColumns}
                                resizable={true}
                                autoPageSize
                                pagination
                                headerHeight={30}
                                rowHeight={40}
                                // onSelectionModelChange={onRowSelected}
                                components={{
                                    Toolbar: GridToolbar,
                                }}
                            />
                                : <div className="loader"></div>}
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}