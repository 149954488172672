import { Modal, TextField } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { configs } from '../../../../assets/Config';
import '../../../../assets/styles/RM.css'
import { useLogin } from '../../../../services/contexts/AdminLoginContext';
import { useKYCContext } from '../../../../services/contexts/KYCContext';
import { useCRMContext } from '../../../../services/contexts/CRMContext';
import { useRMContext } from '../../../../services/contexts/RMContext';

export default function CreateEntryPage() {

    const { setShowSuccess, token, logout, setLoginError } = useLogin()
    const { editRemark, setEditRemark, editRemarkData, setEditRemarkData, createEditRemarkNonUser, getAllRemarkList, getAllRemarkListByUser, remarkList, remarkListType, setRemarkListType, customer, setCustomer } = useCRMContext()
    const { kycRequestersList } = useKYCContext()
    const { adminList } = useRMContext()

    const [relationshipManager, setRelationshipManager] = useState({})
    const [lastCallDate, setLastCallDate] = useState("")
    const [remark, setRemark] = useState("")
    const [investable_surplus, setInvestable_surplus] = useState("")
    const [interested_in, setInterested_in] = useState("0")

    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [mobile, setMobile] = useState("");
    const [error, setError] = useState("")

    const history = useHistory()

    useEffect(() => {
        try {
            if (editRemarkData) {
                setRelationshipManager(editRemarkData.relationship_manager && editRemarkData.relationship_manager.name ? editRemarkData.relationship_manager : { id: "", name: "" })
                setInvestable_surplus(editRemarkData.investable_surplus)
                setInterested_in(editRemarkData.interested_in)
                setError("")
            }

        } catch (error) {
            console.error(error)

        }
    }, [editRemarkData])


    const handleRMChange = (e, value) => {
        try {
            setRelationshipManager(value)
        } catch (error) {

        }
    }


    // add company function
    async function addRemark() {
        try {

            document.getElementById("newsBtn").classList.toggle("d-none")
            document.getElementById("newsBtnLoader").classList.toggle("d-none")

            if (!name) {
                setError("Please provide a valid customer name.")
            } else if (email && !configs.emailRegex.test(email)) {
                setError("Please provide a valid customer email.")
            } else if (mobile && !configs.phoneRegex.test(mobile)) {
                setError("Please provide a valid customer mobile")
            } else {
                let response = await createEditRemarkNonUser(name, email, mobile, new Date().toISOString().split('T')[0], remark, investable_surplus, interested_in, relationshipManager.id)

                if (response && response === "success") {
                    resetValues()
                    setShowSuccess(true)
                    getAllRemarkList("non-user")
                } else {
                    setError(response)
                }
            }
            document.getElementById("newsBtn").classList.toggle("d-none")
            document.getElementById("newsBtnLoader").classList.toggle("d-none")
        } catch (error) {
        }
    }

    // reset all values
    function resetValues() {
        try {
            setCustomer({ id: "", name: "" })
            setRelationshipManager({ id: "", name: "" })
            setLastCallDate("")
            setRemark("")
            setInvestable_surplus("")
            setInterested_in("0")
            setError("")
            setEditRemarkData({})
            setName("")
            setEmail("")
            setMobile("")
        } catch (error) {

        }
    }

    // create role ui code
    return (
        <div id="rmCreateNews" className="col-lg-3 col-md-4 d-md-block d-none pt-3 main-rmPanel">
            <p className="h6 text-custom-grey">{editRemark ? "Edit Remark Information" : "Add a Remark or Manager"}</p>
            <form id="createRoleForm" action="" className='mr-1'>
                <div className="form-group">
                    <label className="f-12">Customer Name</label>
                    <input type="text" id="roleName" className="form-control f-10" placeholder="Customer Name" value={name} required onChange={(e) => {
                        setName(e.target.value)
                    }} />
                </div>
                <div className="form-group">
                    <label className="f-12">Customer E-mail</label>
                    <input type="text" id="roleName" className="form-control f-10" placeholder="Customr E-mail" value={email} required onChange={(e) => {
                        setEmail(e.target.value)
                    }} />
                </div>
                <div className="form-group">
                    <label className="f-12">Customer Mobile</label>
                    <input type="text" id="roleName" className="form-control f-10" placeholder="Customer Mobile" value={mobile} required onChange={(e) => {
                        setMobile(e.target.value)
                    }} />
                </div>
                <div className="form-group">
                    <label className="f-12">Relationship Manager</label>
                    {adminList ? <Autocomplete
                        className="f-12"
                        id="companyIP"
                        value={relationshipManager ? relationshipManager : ""}
                        placeholder="Manager Name"
                        getOptionLabel={option => option.name}
                        onChange={handleRMChange}
                        options={adminList}
                        renderInput={(params) => <TextField {...params} placeholder="Manager Name" className="f-12" />}
                    /> : null}
                </div>
                <div className="form-group">
                    <label className="f-12">Call Remarks</label>
                    <textarea id="roleName" className="form-control f-10" placeholder="Call Remarks" value={remark} required onChange={(e) => {
                        setRemark(e.target.value)
                    }} />
                </div><div className="form-group">
                    <label className="f-12">Investable Surplus</label>
                    <input type="text" id="roleName" className="form-control f-10" placeholder="Investable Surplus" value={investable_surplus} required onChange={(e) => {
                        setInvestable_surplus(e.target.value)
                    }} />
                </div>

                <div className="form-group">
                    <label className="f-12">Interested in</label>
                    <select id="roleName" className="form-control f-10" placeholder="Interested in" value={interested_in} onChange={(e) => {
                        setInterested_in(e.target.value)
                    }} >
                        <option value="0">---Select an option---</option>
                        <option value="Startups">Startups</option>
                        <option value="Large Cap">Large Cap</option>
                        <option value="Fixed Income"> Fixed Income</option>
                    </select>
                </div>
            </form>
            <div className="form-check p-0">
                <p className="text-danger" id="createRoleError">{error}</p>
            </div>
            <div className='row m-0 p-0'>
                <div className='col-9 m-0 p-0'>
                    <button id="newsBtn" className="btn btn-primary f-12" onClick={(e) => {
                        e.preventDefault()
                        addRemark()
                    }}>{editRemark ? "Update Remark" : "Add Remark"}</button>
                    <div id="newsBtnLoader" className='animBtn d-none'><div className='gSignIn-Anim'></div></div>
                </div>
                <div className='col-3 m-0 p-0'>
                    <button className="btn btn-primary f-12" onClick={(e) => {
                        e.preventDefault()
                        resetValues()
                    }}>Reset</button>
                </div>
            </div>
            <div className='row m-0 p-0'>
                <button className="btn btn-primary mt-3 d-md-none" onClick={(e) => {
                    e.preventDefault()
                    document.getElementById("rmCreateNews").classList.toggle('d-none')
                    document.getElementById("newsListPage").classList.toggle('d-none')
                    history.push('/nonuserinsight')
                }}>View Non User Remarks</button>
            </div>
        </div>
    )
}