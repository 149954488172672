import Modal from "@material-ui/core/Modal";
import '../../assets/styles/TopBar.css'
import { useLogin } from "../../services/contexts/AdminLoginContext";
import { useHistory } from 'react-router-dom';

export default function ProfileModalPage() {
    const { profileModal, setProfileModal, logout } = useLogin();

    const history = useHistory();

    const handleClose = () => {
        setProfileModal(false);
    };
    return (<Modal open={profileModal} onClose={handleClose}>
        <div className="navbar-profile-modal-container shadow bg-white rounded">
            {/* <div className="d-flex justify-content-between my-1">
                <div className="navbar-profile-modal-heading">Terms and conditions</div>
                <div className="navbar-profile-modal-icon">
                    <i className="fab fa-instagram"></i>
                </div>
            </div>
            <div className="d-flex justify-content-between my-2">
                <div className="navbar-profile-modal-heading">Privacy Policy</div>
                <div className="navbar-profile-modal-icon">
                    <i className="fab fa-instagram"></i>
                </div>
            </div>
            <hr /> */}
            {/* <div className="d-flex justify-content-between my-2">
                <div className="navbar-profile-modal-heading">My Account</div>
                <div className="navbar-profile-modal-icon">
                    <i className="fab fa-instagram"></i>
                </div>
            </div>
            <div className="d-flex justify-content-between my-2">
                <div className="navbar-profile-modal-heading">Refer and earn</div>
                <div className="navbar-profile-modal-icon">
                    <i className="fab fa-instagram"></i>
                </div>
            </div>
            <hr />
            <div className="d-flex justify-content-between my-2">
                <div className="navbar-profile-modal-heading">Raise a ticker</div>
            </div> */}
            <div className="d-flex justify-content-between my-2 curPoint" onClick={(e) => {
                e.preventDefault();
                setProfileModal(false);
                history.push('resetpassword');
            }}>
                <div className="navbar-profile-modal-heading">Reset Password</div>
                <div className="navbar-profile-modal-icon">
                    <i className="fa fa-user"></i>
                </div>
            </div>
            <div className="d-flex justify-content-between my-2 text-danger curPoint" onClick={(e) => {
                setProfileModal(false);
                logout();
            }}>
                <div className="navbar-profile-modal-heading" >
                    Logout
                </div>
                <div className="navbar-profile-modal-icon">
                    <i className="fas fa-sign-out-alt"></i>
                </div>
            </div>
        </div>
    </Modal>)
}