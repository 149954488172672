import { Modal, TextField } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { configs } from '../../../../assets/Config';
import '../../../../assets/styles/RM.css'
import { useLogin } from '../../../../services/contexts/AdminLoginContext';
import { useKYCContext } from '../../../../services/contexts/KYCContext';
import { useCRMContext } from '../../../../services/contexts/CRMContext';
import { useRMContext } from '../../../../services/contexts/RMContext';
import { useCMContext } from '../../../../services/contexts/companyManagement/CMContext';

export default function CreateEntryPage() {

    const { setShowSuccess, token, logout, setLoginError } = useLogin()
    const { editRemark, setEditRemark, editRemarkData, setEditRemarkData, createOrderLead, getAllRemarkList, getAllRemarkListByUser, remarkList, remarkListType, setRemarkListType, customer, setCustomer, editOrderLead } = useCRMContext()
    const { kycRequestersList } = useKYCContext()
    const { companyList } = useCMContext()

    const [CompanyName, setCompanyName] = useState({})
    const [lastCallDate, setLastCallDate] = useState("")
    const [remark, setRemark] = useState("")
    const [investable_surplus, setInvestable_surplus] = useState("")
    const [rate, setRate] = useState("")
    const [interested_in, setInterested_in] = useState("0")

    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [mobile, setMobile] = useState("");
    const [status, setStatus] = useState("");
    const [error, setError] = useState("")

    const history = useHistory()

    useEffect(() => {
        try {
            if (editRemarkData) {
                setRemark(editRemarkData.remark)
                setStatus(editRemarkData.status)
                setError("")
            }

        } catch (error) {
            console.error(error)
        }
    }, [editRemarkData])


    const handleRMChange = (e, value) => {
        try {
            setCompanyName(value)
        } catch (error) {

        }
    }

    const handleCustomerChange = (e, value) => {
        try {
            setCustomer(value)
            getAllRemarkListByUser(value.id, true)
        } catch (error) {

        }
    }


    // add company function
    async function addRemark() {
        try {

            document.getElementById("newsBtn").classList.toggle("d-none")
            document.getElementById("newsBtnLoader").classList.toggle("d-none")

            if (!customer.id && !name) {
                setError("Please provide a valid customer name.")
            } else if (email && !configs.emailRegex.test(email)) {
                setError("Please provide a valid customer email.")
            } else if (mobile && !configs.phoneRegex.test(mobile)) {
                setError("Please provide a valid customer mobile")
            } else {
                let response = await createOrderLead(customer.id, name, email, mobile, CompanyName.name, investable_surplus, rate, interested_in, remark)

                if (response && response === "success") {
                    resetValues()
                    setShowSuccess(true)
                    getAllRemarkList("order-insight")
                    setRemarkListType("all")
                } else {
                    setError(response)
                }
            }
            document.getElementById("newsBtn").classList.toggle("d-none")
            document.getElementById("newsBtnLoader").classList.toggle("d-none")
        } catch (error) {
        }
    }

    // add company function
    async function editRemarks() {
        try {

            document.getElementById("newsBtnEdit").classList.toggle("d-none")
            document.getElementById("newsBtnEditLoader").classList.toggle("d-none")

            if (!status) {
                setError("Please provide a valid status.")
            } else {
                let response = await editOrderLead(editRemarkData.id, status, remark)

                if (response && response === "success") {
                    resetValues()
                    setShowSuccess(true)
                    getAllRemarkList("order-insight")
                    setRemarkListType("all")
                } else {
                    setError(response)
                }
            }
            document.getElementById("newsBtnEdit").classList.toggle("d-none")
            document.getElementById("newsBtnEditLoader").classList.toggle("d-none")
        } catch (error) {
        }
    }

    // reset all values
    function resetValues() {
        try {
            setCustomer({ id: "", name: "" })
            setCompanyName({ id: "", name: "" })
            setLastCallDate("")
            setRemark("")
            setInvestable_surplus("")
            setInterested_in("0")
            setError("")
            setEditRemark(false);
            setEditRemarkData({})
            setName("")
            setEmail("")
            setMobile("")
            setRate("")
        } catch (error) {

        }
    }

    // create role ui code
    return (
        <div id="rmCreateNews" className="col-lg-3 col-md-4 d-md-block d-none pt-3 main-rmPanel">
            <p className="h6 text-custom-grey">{editRemark ? "Edit Lead Status" : "Add a Order Lead"}</p>
            {!editRemark ?
                <form id="createRoleForm" action="" className='mr-1'>
                    <div className="form-group">
                        <label className="f-12">Customer</label>
                        {kycRequestersList ? <Autocomplete
                            className="f-12"
                            id="companyIP"
                            value={customer}
                            placeholder="Customer Name"
                            getOptionLabel={option => option && option.email ? `${option.name} - ${option.email}` : ""}
                            onChange={handleCustomerChange}
                            options={kycRequestersList}
                            renderInput={(params) => <TextField {...params} placeholder="Customer Name" className="f-12" />}
                        /> : null}
                    </div>
                    <p className='text-center m-2'>OR</p>
                    <div className="form-group">
                        <label className="f-12">Customer Name</label>
                        <input type="text" id="roleName" className="form-control f-10" placeholder="Customer Name" value={name} required onChange={(e) => {
                            setName(e.target.value)
                        }} />
                    </div>
                    <div className="form-group">
                        <label className="f-12">Customer E-mail</label>
                        <input type="text" id="roleName" className="form-control f-10" placeholder="Customr E-mail" value={email} required onChange={(e) => {
                            setEmail(e.target.value)
                        }} />
                    </div>
                    <div className="form-group">
                        <label className="f-12">Customer Mobile</label>
                        <input type="text" id="roleName" className="form-control f-10" placeholder="Customer Mobile" value={mobile} required onChange={(e) => {
                            setMobile(e.target.value)
                        }} />
                    </div>
                    <div className="form-group">
                        <label className="f-12">Select Company</label>
                        {companyList ? <Autocomplete
                            className="f-12"
                            id="companyIP"
                            value={CompanyName ? CompanyName : ""}
                            placeholder="Manager Name"
                            getOptionLabel={option => option.name}
                            onChange={handleRMChange}
                            options={companyList}
                            renderInput={(params) => <TextField {...params} placeholder="Company Name" className="f-12" />}
                        /> : null}
                    </div>
                    <div className="form-group">
                        <label className="f-12">Quantity</label>
                        <input type="number" id="roleName" className="form-control f-10" placeholder="Quantity" value={investable_surplus} required onChange={(e) => {
                            setInvestable_surplus(e.target.value)
                        }} />
                    </div><div className="form-group">
                        <label className="f-12">Rate/Share</label>
                        <input type="number" id="roleName" className="form-control f-10" placeholder="Rate/Share" value={rate} required onChange={(e) => {
                            setRate(e.target.value)
                        }} />
                    </div>
                    <div className="form-group">
                        <label className="f-12">Order Type</label>
                        <select id="roleName" className="form-control f-10" placeholder="Interested in" value={interested_in} onChange={(e) => {
                            setInterested_in(e.target.value)
                        }} >
                            <option value="0">---Select an option---</option>
                            <option value="BUY">Buy</option>
                            <option value="SELL">Sell</option>
                        </select>
                    </div>
                    <div className="form-group">
                        <label className="f-12">Call Remarks</label>
                        <textarea id="roleName" className="form-control f-10" placeholder="Call Remarks" value={remark} required onChange={(e) => {
                            setRemark(e.target.value)
                        }} />
                    </div>

                    <div className="form-check p-0">
                        <p className="text-danger" id="createRoleError">{error}</p>
                    </div>
                    <div className='row m-0 p-0'>
                        <div className='col-9 m-0 p-0'>
                            <button id="newsBtn" className="btn btn-primary f-12" onClick={(e) => {
                                e.preventDefault()
                                addRemark()
                            }}>{editRemark ? "Update Lead" : "Add Lead"}</button>
                            <div id="newsBtnLoader" className='animBtn d-none'><div className='gSignIn-Anim'></div></div>
                        </div>
                        <div className='col-3 m-0 p-0'>
                            <button className="btn btn-primary f-12" onClick={(e) => {
                                e.preventDefault()
                                resetValues()
                            }}>Reset</button>
                        </div>
                    </div>
                    <div className='row m-0 p-0'>
                        <button className="btn btn-primary mt-3 d-md-none" onClick={(e) => {
                            e.preventDefault()
                            document.getElementById("rmCreateNews").classList.toggle('d-none')
                            document.getElementById("newsListPage").classList.toggle('d-none')
                            history.push('/orderinsight')
                        }}>View All Order Leads</button>
                    </div>
                </form>
                :
                <form id="createRoleForm" action="" className='mr-1'>
                    <div className="form-group">
                        <label className="f-12">Update Status</label>
                        <select className='' id="selectRemarkData" value={status} onChange={(e) => {
                            e.preventDefault()
                            setStatus(e.target.value)
                        }}>
                            <option value="OPEN">Open</option>
                            <option value="PROCESSED">Processed</option>
                            <option value="REJECTED_BY_ALTIUS">Rejected by Altius</option>
                            <option value="REJECTED_BY_USER">Rejected by Investor</option>
                        </select>
                    </div>
                    <div className="form-group">
                        <label className="f-12">Call Remarks</label>
                        <textarea id="roleName" className="form-control f-10" placeholder="Call Remarks" value={remark} required onChange={(e) => {
                            setRemark(e.target.value)
                        }} />
                    </div>
                    <div className="form-check p-0">
                        <p className="text-danger" id="createRoleError">{error}</p>
                    </div>
                    <div className='row m-0 p-0'>
                        <div className='col-9 m-0 p-0'>
                            <button id="newsBtnEdit" className="btn btn-primary f-12" onClick={(e) => {
                                e.preventDefault()
                                editRemarks()
                            }}>{editRemark ? "Update Lead" : "Add Lead"}</button>
                            <div id="newsBtnEditLoader" className='animBtn d-none'><div className='gSignIn-Anim'></div></div>
                        </div>
                        <div className='col-3 m-0 p-0'>
                            <button className="btn btn-primary f-12" onClick={(e) => {
                                e.preventDefault()
                                resetValues()
                            }}>Reset</button>
                        </div>
                    </div>
                    <div className='row m-0 p-0'>
                        <button className="btn btn-primary mt-3 d-md-none" onClick={(e) => {
                            e.preventDefault()
                            document.getElementById("rmCreateNews").classList.toggle('d-none')
                            document.getElementById("newsListPage").classList.toggle('d-none')
                            history.push('/orderinsight')
                        }}>View All Order Leads</button>
                    </div>
                </form>
            }
        </div>
    )
}