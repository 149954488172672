import Modal from "@material-ui/core/Modal";
import { useState } from "react";
import { configs } from "../../assets/Config";
import '../../assets/styles/TopBar.css'
import { useLogin } from "../../services/contexts/AdminLoginContext";
import { useBondContext } from "../../services/contexts/assetManagement/BondContext";
import { useBucketContext } from "../../services/contexts/assetManagement/BucketContext";
import { useGSecContext } from "../../services/contexts/assetManagement/GSecContext";
import { useMixBucketContext } from "../../services/contexts/assetManagement/MixBucketContext";
import { useMLDContext } from "../../services/contexts/assetManagement/MLDContext";
import { useStartupContext } from "../../services/contexts/assetManagement/StartupContext";
import { useBuyOrderContext } from "../../services/contexts/BuyOrdersContext";
import { useCMContext } from "../../services/contexts/companyManagement/CMContext";
import { useNewsContext } from "../../services/contexts/companyManagement/NewsContext";
import { useCouponContext } from "../../services/contexts/couponManagement/CouponContext";
import { useRMContext } from "../../services/contexts/RMContext";
import { useSellOrderContext } from "../../services/contexts/SellOrdersContext";
import ToastPage from "./ToastPage";


export default function ConfirmModalPage(restoreData) {
    const { restoreRole, restoreAdmin } = useRMContext()
    const { restoreCompanyById, getAllCompaniesList } = useCMContext()
    const { restoreMLDById, getAllMLDList } = useMLDContext()
    const { restoreStartupById, getAllStartupList } = useStartupContext()
    const { restoreGSecById, getAllGSecList } = useGSecContext()
    const { restoreBondById, getAllBondList } = useBondContext()
    const { restoreNewsById, getAllNewsList } = useNewsContext()
    const { restoreOrderById, getOrdersByType, activeStatus } = useBuyOrderContext()
    const { restoreSellOrderById, getSellOrder, activeStatusSell } = useSellOrderContext()
    const { restorecouponById, getAllcouponList } = useCouponContext()
    const { restoreBucketById, getAllBucketList } = useBucketContext()
    const { restoreMixBucketById, getAllMixBucketList } = useMixBucketContext()


    const { showConfirm, setShowConfirm, setShowSuccess } = useLogin()

    const [error, setError] = useState("")

    const handleClose = () => {
        setShowConfirm(false);
    };

    async function restoreDataById() {
        try {
            document.getElementById("confirmBtn").classList.toggle("d-none")
            document.getElementById("confirmBtnLoader").classList.toggle("d-none")

            if (restoreData.category === configs.modules.rm) {
                let response = await restoreRole(restoreData.id)
                handleClose()
            } else if (restoreData.category === configs.modules.am) {
                let response = await restoreAdmin(restoreData.id)
                handleClose()
            } else if (restoreData.category === configs.modules.cm) {
                let response = await restoreCompanyById(restoreData.id)
                if (response === "success") {
                    setError("")
                    handleClose()
                    setShowSuccess(true)
                    getAllCompaniesList(true)
                } else {
                    setError(response)
                }
            } else if (restoreData.category === configs.modules.mldMgmt) {
                let response = await restoreMLDById(restoreData.id)
                if (response === "success") {
                    setError("")
                    handleClose()
                    setShowSuccess(true)
                    getAllMLDList(true)
                } else {
                    setError(response)
                }
            } else if (restoreData.category === configs.modules.startupMgmt) {
                let response = await restoreStartupById(restoreData.id)
                if (response === "success") {
                    setError("")
                    handleClose()
                    setShowSuccess(true)
                    getAllStartupList(true)
                } else {
                    setError(response)
                }
            } else if (restoreData.category === configs.modules.gsecMgmt) {
                let response = await restoreGSecById(restoreData.id)
                if (response === "success") {
                    setError("")
                    handleClose()
                    setShowSuccess(true)
                    getAllGSecList(true)
                } else {
                    setError(response)
                }
            } else if (restoreData.category === configs.modules.bondMgmt) {
                let response = await restoreBondById(restoreData.id)
                if (response === "success") {
                    setError("")
                    handleClose()
                    setShowSuccess(true)
                    getAllBondList(true)
                } else {
                    setError(response)
                }
            } else if (restoreData.category === configs.modules.newsManage) {
                let response = await restoreNewsById(restoreData.id)
                if (response === "success") {
                    setError("")
                    handleClose()
                    setShowSuccess(true)
                    getAllNewsList(true)
                } else {
                    setError(response)
                }
            } else if (restoreData.category === configs.modules.smBuyOrder) {
                let response = await restoreOrderById(restoreData.id)
                if (response === "success") {
                    setError("")
                    handleClose()
                    setShowSuccess(true)
                    getOrdersByType(configs.shareStatusTypes[activeStatus].api)

                } else {
                    setError(response)
                }
            } else if (restoreData.category === configs.modules.smSellOrder) {
                let response = await restoreSellOrderById(restoreData.id)
                if (response === "success") {
                    setError("")
                    handleClose()
                    setShowSuccess(true)
                    getSellOrder(configs.sellOrderStatusTypes[activeStatusSell].api)

                } else {
                    setError(response)
                }
            } else if (restoreData.category === configs.modules.couponMgmt) {
                let response = await restorecouponById(restoreData.id)
                if (response === "success") {
                    setError("")
                    handleClose()
                    setShowSuccess(true)
                    getAllcouponList(true)
                } else {
                    setError(response)
                }
            } else if (restoreData.category === configs.modules.bucketMgmt) {
                let response = await restoreBucketById(restoreData.id)
                if (response === "success") {
                    setError("")
                    handleClose()
                    setShowSuccess(true)
                    getAllBucketList(true)
                } else {
                    setError(response)
                }
            } else if (restoreData.category === configs.modules.mixBucketMgmt) {
                let response = await restoreMixBucketById(restoreData.id)
                if (response === "success") {
                    setError("")
                    handleClose()
                    setShowSuccess(true)
                    getAllMixBucketList(true)
                } else {
                    setError(response)
                }
            }

            document.getElementById("confirmBtn").classList.toggle("d-none")
            document.getElementById("confirmBtnLoader").classList.toggle("d-none")
        } catch (error) {

        }
    }

    return (<Modal open={showConfirm} onClose={handleClose}>
        <div className="modal-fade" id="KYCSubmitModal" tabIndex="-1" role="dialog" aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered" role="document">
                <div className="modal-content">
                    <div className="modal-header border-0">
                        <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={handleClose}>
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div className="modal-body text-center py-3">
                        <p className="text-center h1 font-weight-normal text-warning">
                            <i className="fas fa-question-circle mr-1"></i>
                            Restore
                        </p>
                        <p className="mt-3 px-3">
                            Are you sure that you want to restore <b>{restoreData.type}: {restoreData.name}</b>?
                        </p>
                        {error ? <p className="mt-3 px-3 text-danger">
                            {error}
                        </p> : null}
                        <button id="confirmBtn" type="button" data-dismiss="modal" className="btn btn-primary text-center mt-3" onClick={(e) => {
                            e.preventDefault()
                            restoreDataById()
                        }}>Restore</button>
                        <div id="confirmBtnLoader" className='animBtn d-none w-100'><div className='gSignIn-Anim'></div></div>
                    </div>
                </div>
            </div>
        </div>
    </Modal>)
}