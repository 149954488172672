import { Modal, TextField } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { configs } from '../../../assets/Config';
import '../../../assets/styles/RM.css'
import { uploadCompanyDataDoc } from '../../../services/apis/companyManagement/CMApi';
import { useLogin } from '../../../services/contexts/AdminLoginContext';
import { useCouponContext } from '../../../services/contexts/couponManagement/CouponContext';
import { useCMContext } from '../../../services/contexts/companyManagement/CMContext';
import RichTextEditorPage from '../../includes/RichTextEditorPage';
import { useKYCContext } from '../../../services/contexts/KYCContext';
import { useBondContext } from '../../../services/contexts/assetManagement/BondContext';
import { useMLDContext } from '../../../services/contexts/assetManagement/MLDContext';
import { useGSecContext } from '../../../services/contexts/assetManagement/GSecContext';
import { useStartupContext } from '../../../services/contexts/assetManagement/StartupContext';
import { Autocomplete } from '@material-ui/lab';
import { useBucketContext } from '../../../services/contexts/assetManagement/BucketContext';
import { useMixBucketContext } from '../../../services/contexts/assetManagement/MixBucketContext';

export default function CreateCouponPage() {

    const { setShowSuccess, token, logout, setLoginError, allowedFunc } = useLogin()
    const { editcoupon, setEditcoupon, editcouponData, setEditcouponData, createEditcoupon, getAllcouponList, setcouponsnapshot, setcouponkeyHigh, setcoupondeal, setcouponterms, setcouponfaq, setcoupondoc } = useCouponContext()
    const { setShowRTPage, showRTPage } = useCouponContext()

    const { utmList, getAllUTMList } = useKYCContext()
    const { getAllBondList } = useBondContext()
    const { getAllMLDList } = useMLDContext()
    const { getAllCompaniesList } = useCMContext()
    const { getAllGSecList } = useGSecContext()
    const { getAllStartupList } = useStartupContext()
    const { getAllBucketList } = useBucketContext()
    const { getAllMixBucketList } = useMixBucketContext()

    const statusValues = ["DRHP Not Filed", "DRHP Filed", "DRHP Approved", "Delisted", "Listed but not traded", "IPO in Process", "Now Listed"]

    const [logoFile, setLogoFile] = useState("")
    const [code, setcode] = useState(editcouponData.code)
    const [discount, setdiscount] = useState(editcouponData.discount)
    const [maxDiscount, setmaxDiscount] = useState(editcouponData.max_discount)
    const [expiryDate, setexpiryDate] = useState(editcouponData.expiry_date)
    const [utm, setutm] = useState(editcouponData.utm)
    const [assetId, setassetId] = useState(editcouponData.company_id)
    const [assetClass, setassetClass] = useState(editcouponData.asset_class)
    const [firstOrder, setfirstOrder] = useState(editcouponData.first_order)
    const [assetList, setAssetList] = useState([])
    const [error, setError] = useState("")
    const [rtPageType, setRTPageType] = useState("")

    const [file, setFile] = useState("")
    const [fileError, setFileError] = useState("")
    const [errorDetail, setErrorDetail] = useState("")
    const [showErrorModal, setShowErrorModal] = useState(false)

    const history = useHistory()

    useEffect(() => {
        (async () => {
            await getAllUTMList();
        })()
    }, [])

    useEffect(() => {
        (async () => {
            if (assetClass === configs.assetList[0]) {
                var response = await getAllBondList()
            }
            else if (assetClass === configs.assetList[1]) {
                response = await getAllMLDList()
            }
            else if (assetClass === configs.assetList[2]) {
                response = await getAllCompaniesList()
            }
            else if (assetClass === configs.assetList[3]) {
                response = await getAllGSecList()
            }
            else if (assetClass === configs.assetList[4]) {
                response = await getAllStartupList()
            }
            else if (assetClass === configs.assetList[5]) {
                response = await getAllBucketList()
            }
            else if (assetClass === configs.assetList[6]) {
                response = await getAllMixBucketList()
            }

            if (response && response.success) {
                setAssetList(response.data)
            }
        })()
    }, [assetClass])

    useEffect(() => {
        try {
            if (editcouponData) {
                setcode(editcouponData.code)
                setdiscount(editcouponData.discount)
                setmaxDiscount(editcouponData.max_discount)
                setexpiryDate(editcouponData.expiry_date)
                setutm(editcouponData.utm)
                setassetId(editcouponData.company_id)
                setassetClass(editcouponData.asset_class)
                setfirstOrder(editcouponData.first_order)
                setError("")
            }
        } catch (error) {

        }
    }, [editcouponData])


    // add company function
    async function addcoupon() {
        try {

            document.getElementById("couponBtn").classList.toggle("d-none")
            document.getElementById("couponBtnLoader").classList.toggle("d-none")

            const red = /^[0-9]\d*(\.\d{1,2})?$/;
            // .toString().match(red)
            if (editcoupon && !editcouponData.id) {
                setError("Please select a valid coupon to edit.")
            } else if (!code) {
                setError("Please provide a valid coupon code.")
            } else if (parseInt(discount) <= 0 || parseInt(discount) > 100) {
                setError("Please provide a valid discount.")
            } else if (parseInt(maxDiscount) <= 0) {
                setError("Please provide a valid Max Discount.")
            } else if (!expiryDate) {
                setError("Please enter a valid Expiry Date.")
            } else {
                let response = await createEditcoupon(code, discount, maxDiscount, expiryDate, utm ? utm.utm : "", assetId ? assetId.id : "", assetClass ? assetClass : "", firstOrder ? firstOrder : "", editcoupon, editcouponData.id)

                if (response && response === "success") {
                    resetValues()
                    setShowSuccess(true)
                    getAllcouponList()
                } else {
                    setError(response)
                }
            }

            document.getElementById("couponBtn").classList.toggle("d-none")
            document.getElementById("couponBtnLoader").classList.toggle("d-none")

        } catch (error) {
            console.error(error)
        }

    }


    const handleUtmChange = (e, value) => {
        try {
            setutm(value);
        } catch (error) {

        }
    }

    const handleAssetChange = (e, value) => {
        try {
            setassetId(value);
        } catch (error) {

        }
    }

    // reset all values
    function resetValues() {
        try {
            setcode("")
            setdiscount("")
            setmaxDiscount("")
            setexpiryDate("")
            setutm("")
            setassetId("")
            setassetClass("")
            setfirstOrder("")
            setEditcoupon(false)
            setError("")
        } catch (error) {

        }
    }

    // get file contents through file chooser
    function uploadFile(file, type) {
        try {
            if (type === "Logo" && (file.type === "image/jpg" || file.type === "image/jpeg" || file.type === "image/png")) {
                setLogoFile(file)
            } else if (type !== "Logo" && (file.type === ".xls" || file.type === "xlsx" || file.type === "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" || file.type === "application/vnd.ms-excel")) {
                setFile(file)
                setFileError("")
            } else {
                type === "Logo" ? alert("Only jpg or png files are allowed.") : alert("Only csv files are allowed.")
            }
        } catch (error) {
            console.error(error)
        }
    }

    // create role ui code
    return (
        <div id="rmCreateCompany" className="col-lg-3 col-md-4 d-md-block d-none pt-3 main-rmPanel">
            {((allowedFunc.includes(configs.functionList["coupon_management:coupon_management"]) && editcoupon) || allowedFunc.includes(configs.functionList["coupon_management:coupon_management"])) ?
                <>
                    <p className="h6 text-custom-grey">{editcoupon ? "Edit coupon Information" : "Add a coupon"}</p>

                    <div className="function-container-asset">
                        <form id="createRoleForm" action="" className='mr-1'>
                            <div className="form-group">
                                <label className="f-12">Coupon Code</label>
                                <input type="text" id="roleName" className="form-control f-10" placeholder="Coupon Code" value={code} required onChange={(e) => {
                                    setcode(e.target.value)
                                }} />
                            </div>
                            <div className="form-group">
                                <label className="f-12">Discount</label>
                                <input type="number" id="roleName" className="form-control f-10" placeholder="Discount" value={discount} required onChange={(e) => {
                                    setdiscount(e.target.value)
                                }} />
                            </div>
                            <div className="form-group">
                                <label className="f-12">Maximum Discount</label>
                                <input type="number" id="roleName" className="form-control f-10" placeholder="Maximum Discount" value={maxDiscount} required onChange={(e) => {
                                    setmaxDiscount(e.target.value)
                                }} />
                            </div>
                            <div className="form-group">
                                <label className="f-12">Expiry Date</label>
                                <input type="datetime-local" id="roleName" className="form-control f-10" placeholder="Expiry Date" value={expiryDate} required onChange={(e) => {
                                    setexpiryDate(e.target.value)
                                }} />
                            </div>
                            <div className="form-group">
                                <label className="f-12">UTM</label>
                                {utmList && utmList.length > 0 ? <Autocomplete
                                    value={utm ? utm : null}
                                    disableCloseOnSelect={false}
                                    filterSelectedOptions
                                    className="f-12"
                                    id="utmip"
                                    placeholder="Select UTM"
                                    getOptionLabel={option => `${option.distributor} - ${option.utm}`}
                                    onChange={handleUtmChange}
                                    options={utmList}
                                    renderInput={(params) => <TextField {...params} placeholder="Select UTM" className="f-12" />}
                                /> : null}
                            </div>
                            <div className="form-group">
                                <label className="f-12">Asset Class</label>
                                <select name="" id="" className="col-12 py-1 px-2 border rounded f-12" value={assetClass != null ? assetClass : ""} onChange={(e) => {
                                    setassetClass(e.target.value)
                                }}>
                                    <option className='f-12' value=""> Select Asset Class </option>
                                    {configs.assetList.map((asset, key) => {
                                        return <option className='f-12' key={`asset${key}`} value={asset}> {asset} </option>
                                    })}
                                </select>
                            </div>
                            <div className="form-group">
                                {assetList && assetList.length > 0 ?
                                    <>
                                        <label className="f-12">Select Asset</label>
                                        < Autocomplete
                                            value={assetId ? assetId : null}
                                            disableCloseOnSelect={false}
                                            filterSelectedOptions
                                            className="f-12"
                                            id="utmip"
                                            placeholder="Select Asset"
                                            getOptionLabel={option => `${option.name}`}
                                            onChange={handleAssetChange}
                                            options={assetList}
                                            renderInput={(params) => <TextField {...params} placeholder="Select Asset" className="f-12" />}
                                        />
                                    </>
                                    : null}
                            </div>
                            <div className="form-group">
                                <label className="f-12">First Order</label>
                                <select name="" id="" className="col-12 py-1 px-2 border rounded f-12" value={firstOrder != null ? firstOrder : "default"} onChange={(e) => {
                                    setfirstOrder(e.target.value)
                                }}>
                                    <option className='f-12' value="default"> ---- </option>
                                    <option className='f-12' value="0"> No </option>
                                    <option className='f-12' value="1"> Yes </option>
                                </select>
                            </div>
                        </form>
                    </div>
                    <div className="form-check p-0">
                        <p className="text-danger" id="createRoleError">{error}</p>
                    </div>
                    <div className='row m-0 p-0'>
                        <div className='col-9 m-0 p-0'>
                            <button id="couponBtn" className="btn btn-primary f-12" onClick={(e) => {
                                e.preventDefault()
                                addcoupon()
                            }}>{editcoupon ? "Update coupon" : "Add coupon"}</button>
                            <div id="couponBtnLoader" className='animBtn d-none'><div className='gSignIn-Anim'></div></div>
                        </div>
                        <div className='col-3 m-0 p-0'>
                            <button className="btn btn-primary f-12" onClick={(e) => {
                                e.preventDefault()
                                resetValues()
                            }}>Reset</button>
                        </div>
                    </div>
                    <div className='row m-0 p-0'>
                        <button className="btn btn-primary mt-3 d-md-none" onClick={(e) => {
                            e.preventDefault()
                            document.getElementById("rmCreateCompany").classList.toggle('d-none')
                            document.getElementById("companyListPage").classList.toggle('d-none')
                            history.push('/assetManagement/coupon')
                        }}>View All coupon</button>
                    </div>
                </>
                : null}
            {/* --------------------------------create compnay overview--------------------------------- */}
            {showRTPage ? <RichTextEditorPage {...{ type: rtPageType }} /> : null}
        </div>
    )
}