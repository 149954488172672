import Modal from "@material-ui/core/Modal";
import '../../assets/styles/TopBar.css'
import { useKYCContext } from "../../services/contexts/KYCContext";


export default function ErrorModalPage(errorMessage) {
    const { showError, setShowError } = useKYCContext()

    const handleClose = () => {
        setShowError(false);
    };
    return (<Modal open={showError} onClose={handleClose}>
        <div className="modal-fade" id="KYCSubmitModal" tabIndex="-1" role="dialog" aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered" role="document">
                <div className="modal-content">
                    <div className="modal-header border-0">
                        <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={handleClose}>
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div className="modal-body text-center py-3">
                        <p className="text-center h1 font-weight-normal text-danger">
                            <i className="far fa-times-circle mr-1"></i>
                            Failure
                        </p>
                        <p className="mt-3 px-3">
                            Unable to process request, Please try again!
                        </p>
                        {errorMessage && errorMessage.error ?
                            <p className="mt-3 px-3">
                                Message - {errorMessage.error}
                            </p>
                            : null}
                        <button type="button" data-dismiss="modal" className="btn btn-primary text-center mt-3" onClick={handleClose}>Done</button>
                    </div>
                </div>
            </div>
        </div>
    </Modal>)
}