import React from 'react';
import { createContext, useContext, useState } from 'react';
import { configs } from '../../../assets/Config';
import { createUpdateGSec, deleteGSec, getAllGSecs, getGSecById, restoreGSec } from '../../apis/assetManagement/GSecManagementApi';
import { useLogin } from '../AdminLoginContext';

// create global GSec management context
export const GSecContext = createContext();

export function GSecProvider({ children }) {

    const { token, logout, setLoginError } = useLogin();

    const [GSecList, setGSecList] = useState("")
    const [editGSec, setEditGSec] = useState(false)
    const [editGSecData, setEditGSecData] = useState({})
    const [showDeletedGSec, setShowDeletedGSec] = useState(false)
    const [showRTPage, setShowRTPage] = useState(false)
    const [GSecsnapshot, setGSecsnapshot] = useState("")
    const [GSeckeyHigh, setGSeckeyHigh] = useState("")
    const [GSecdeal, setGSecdeal] = useState("")
    const [GSecterms, setGSecterms] = useState("")
    const [GSecfaq, setGSecfaq] = useState("")
    const [GSecdoc, setGSecdoc] = useState("")

    // get all GSec
    async function getAllGSecList(getDeleted) {
        try {
            let response = await getAllGSecs(token, getDeleted);
            if (response && response.status === 200) {
                setGSecList(response.data)
                return {
                    success: "success",
                    data: response.data
                }
            } else if (response && response.status === 401) {
                logout()
                setLoginError(configs.unauthorizedErrorMessage)
            } else {
                setGSecList("")
            }
        } catch (error) {

        }
    }

    // get GSec data by id
    async function getGSecDataById(GSecId) {
        try {
            let response = await getGSecById(token, GSecId);
            if (response && response.status === 200) {
                let data = response.data
                setEditGSecData({
                    ...editGSecData,
                    ...data
                })
            } else if (response && response.status === 401) {
                logout()
                setLoginError(configs.unauthorizedErrorMessage)
            } else { }
        } catch (error) {

        }
    }

    // create or update GSec
    async function createEditGSec(name, isin, rate_of_return, maturity_period, disabletrading, description, key_1, value_1, key_2, value_2, logo, editGSec, GSecId) {
        try {
            let response = await createUpdateGSec(token, name, isin, rate_of_return, maturity_period, disabletrading, description, GSecsnapshot, GSecfaq, key_1, value_1, key_2, value_2, logo, editGSec, GSecId);
            if (response && response.status === 200) {
                return "success"
            } else if (response && response.status === 401) {
                logout()
                setLoginError(configs.unauthorizedErrorMessage)
            } else {
                return response.data.error
            }
        } catch (error) {

        }
    }

    // delete GSec
    async function deleteGSecById(GSecId) {
        try {
            let response = await deleteGSec(token, GSecId);
            if (response && response.status === 200) {
                return "success"
            } else if (response && response.status === 401) {
                logout()
                setLoginError(configs.unauthorizedErrorMessage)
            } else {
                return response.data.error
            }
        } catch (error) {

        }
    }

    // restore GSec
    async function restoreGSecById(GSecId) {
        try {
            let response = await restoreGSec(token, GSecId);
            if (response && response.status === 200) {
                return "success"
            } else if (response && response.status === 401) {
                logout()
                setLoginError(configs.unauthorizedErrorMessage)
            } else {
                return response.data.error
            }
        } catch (error) {

        }
    }

    // export global context
    return (
        <GSecContext.Provider
            value={{
                GSecList,
                setGSecList,
                getAllGSecList,
                deleteGSecById,
                restoreGSecById,
                editGSec,
                setEditGSec,
                editGSecData,
                setEditGSecData,
                getGSecDataById,
                createEditGSec,
                showDeletedGSec,
                setShowDeletedGSec,
                showRTPage,
                setShowRTPage,
                GSecsnapshot,
                setGSecsnapshot,
                GSeckeyHigh,
                setGSeckeyHigh,
                GSecdeal,
                setGSecdeal,
                GSecterms,
                setGSecterms,
                GSecfaq,
                setGSecfaq,
                GSecdoc,
                setGSecdoc
            }}
        >
            {children}
        </GSecContext.Provider>
    );
}

// return GSec management context
export function useGSecContext() {
    return useContext(GSecContext);
}