import { useState } from "react"
import { useLogin } from "../../services/contexts/AdminLoginContext";
import ToastPage from "./ToastPage";
import TopBarPage from "./dashboard/TopBarPage";
import LeftBarPage from "./dashboard/LeftBarPage";

export default function ResetPasswordPage() {

    const { logout, adminChangePassword, setShowSuccess } = useLogin();

    const [currentPass, setCurrentPass] = useState("");
    const [newPass, setNewPass] = useState("");
    const [cnfPass, setCnfPass] = useState("");
    const [error, setError] = useState("");

    async function updatePassword() {
        try {
            if (!currentPass) {
                setError("Kindly enter the current password.")
            } else if (!newPass) {
                setError("Kindly enter the new password.")
            } else if (!cnfPass) {
                setError("Kindly enter the confirm password.")
            } else if (newPass !== cnfPass) {
                setError("New and confirm passwords do not match")
            } else {
                let response = await adminChangePassword(currentPass, newPass, cnfPass);
                if (response && response.success) {
                    setCurrentPass("");
                    setNewPass("");
                    setCnfPass("");
                    setError("");
                    setShowSuccess(true);
                    logout();
                } else {
                    setError(response)
                }
            }
        } catch (error) {
            console.error(error);
        }
    }

    return (
        <>
            <TopBarPage />
            <div style={{ display: "flex" }}>
                <LeftBarPage />
                <div className="resetPass">
                    <h1>Reset Password</h1>
                    <form>
                        <div className="form-group">
                            <label className="f-12">Current Password</label>
                            <input type="password" id="curPass" className="form-control f-10" placeholder="Current Password" value={currentPass} required onChange={(e) => {
                                setCurrentPass(e.target.value)
                            }} />
                        </div>
                        <div className="form-group">
                            <label className="f-12">New Password</label>
                            <input type="password" id="newPass" className="form-control f-10" placeholder="New Password" value={newPass} required onChange={(e) => {
                                setNewPass(e.target.value)
                            }} />
                        </div>
                        <div className="form-group">
                            <label className="f-12">Confirm Password</label>
                            <input type="password" id="cnfPass" className="form-control f-10" placeholder="Confirm Password" value={cnfPass} required onChange={(e) => {
                                setCnfPass(e.target.value)
                            }} />
                        </div>
                        <div className="form-check p-0">
                            <p className="text-danger" id="createRoleError">{error}</p>
                        </div>
                        <div className='col-9 m-0 p-0'>
                            <button id="passBtn" className="btn btn-primary f-12" onClick={(e) => {
                                e.preventDefault()
                                updatePassword()
                            }}>Update Password</button>
                            <div id="passBtnLoader" className='animBtn d-none'><div className='gSignIn-Anim'></div></div>
                        </div>

                    </form>
                    <ToastPage />

                </div>
            </div>
        </>
    )
}