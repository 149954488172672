import axios from "axios";
import { configs } from "../../../assets/Config";
import FormData from 'form-data';

// get all news list
export function getAllNews(token, getDeleted) {
    return new Promise(async (resolve, reject) => {
        try {
            let url = 'news'
            if (getDeleted) {
                url = 'deletedNews'
            }

            var config = {
                method: 'get',
                url: `${process.env.REACT_APP_BASEURL}/admin/companymanagement/${url}`,
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            };

            let res = await axios(config)
            resolve(res)
        } catch (err) {
            resolve(err?.response)
        }
    })
}


// create or update news
export function createUpdateNews(token, companyId, newsId, news_link, news_heading, news_description, news_image, news_date, editNews) {
    return new Promise(async (resolve, reject) => {
        try {

            let data = {
                news_link: news_link,
                news_heading: news_heading,
                news_description: news_description,
                news_image: news_image,
                news_date: news_date
            }

            let method = 'POST'

            if (editNews) {
                method = 'PATCH'
                data['news_id'] = newsId
            } else {
                data['company_id'] = companyId
            }

            var config = {
                method: method,
                url: `${process.env.REACT_APP_BASEURL}/admin/companymanagement/news`,
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json'
                },
                data: data
            };

            let res = await axios(config)
            resolve(res)
        } catch (err) {
            resolve(err?.response)
        }
    })
}

// delete news
export function deleteNews(token, newsId) {
    return new Promise(async (resolve, reject) => {
        try {

            var data = {
                news_id: newsId
            }
            var config = {
                method: 'DELETE',
                url: `${process.env.REACT_APP_BASEURL}/admin/companymanagement/news`,
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json'
                },
                data: data
            };

            let res = await axios(config)
            resolve(res)
        } catch (err) {
            resolve(err?.response)
        }
    })
}

// restore news
export function restoreNews(token, newsId) {
    return new Promise(async (resolve, reject) => {
        try {

            var data = {
                news_id: newsId
            }
            var config = {
                method: 'PUT',
                url: `${process.env.REACT_APP_BASEURL}/admin/companymanagement/news`,
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json'
                },
                data: data
            };

            let res = await axios(config)
            resolve(res)
        } catch (err) {
            resolve(err?.response)
        }
    })
}