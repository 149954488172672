import axios from 'axios';
import { configs } from '../../assets/Config';

// get pro leads
export function customerProLeads(token) {
    return new Promise(async (resolve, reject) => {
        try {

            var config = {
                method: 'get',
                url: `${process.env.REACT_APP_BASEURL}/admin/userPro/formLeads`,
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            };

            let res = await axios(config)
            resolve(res)
        } catch (err) {
            resolve(err?.response)
        }
    })
}

// get pro payment detais
export function customerProPayment(token) {
    return new Promise(async (resolve, reject) => {
        try {

            var config = {
                method: 'get',
                url: `${process.env.REACT_APP_BASEURL}/admin/userPro/payments`,
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            };

            let res = await axios(config)
            resolve(res)
        } catch (err) {
            resolve(err?.response)
        }
    })
}

// get xclusive leads
export function customerXclusiveLeads(token) {
    return new Promise(async (resolve, reject) => {
        try {

            var config = {
                method: 'get',
                url: `${process.env.REACT_APP_BASEURL}/admin/xclusive/formLeads`,
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            };

            let res = await axios(config)
            resolve(res)
        } catch (err) {
            resolve(err?.response)
        }
    })
}

// get xclusive payment detais
export function customerXclusivePayment(token) {
    return new Promise(async (resolve, reject) => {
        try {

            var config = {
                method: 'get',
                url: `${process.env.REACT_APP_BASEURL}/admin/xclusive/payments`,
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            };

            let res = await axios(config)
            resolve(res)
        } catch (err) {
            resolve(err?.response)
        }
    })
}

// get pro user report
export function customerProUserReport(token) {
    return new Promise(async (resolve, reject) => {
        try {

            var config = {
                method: 'get',
                url: `${process.env.REACT_APP_BASEURL}/admin/userPro`,
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            };

            let res = await axios(config)
            resolve(res)
        } catch (err) {
            resolve(err?.response)
        }
    })
}

// get pro plans
export function getProPlans(token) {
    return new Promise(async (resolve, reject) => {
        try {

            var config = {
                method: 'get',
                url: `${process.env.REACT_APP_BASEURL}/admin/userPro/pricing`,
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            };

            let res = await axios(config)
            resolve(res)
        } catch (err) {
            resolve(err?.response)
        }
    })
}

// update pro plans
export function updateProPlans(token, plOp, plSp, plDesc, ppOp, ppSp, ppDesc, pmOp, pmSp, pmDesc) {
    return new Promise(async (resolve, reject) => {
        try {

            var config = {
                method: 'POST',
                url: `${process.env.REACT_APP_BASEURL}/admin/userPro/pricing`,
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json'
                },
                data: JSON.stringify({
                    pro_lite: {
                        original_price: plOp,
                        selling_price: plSp,
                        description: plDesc
                    },
                    pro_plus: {
                        original_price: ppOp,
                        selling_price: ppSp,
                        description: ppDesc
                    },
                    pro_max: {
                        original_price: pmOp,
                        selling_price: pmSp,
                        description: pmDesc
                    }
                })
            };

            let res = await axios(config)
            resolve(res)
        } catch (err) {
            resolve(err?.response)
        }
    })
}

// get kyc requested by customers list
export function kycRequesters(token) {
    return new Promise(async (resolve, reject) => {
        try {

            var config = {
                method: 'get',
                url: `${process.env.REACT_APP_BASEURL}/admin/users`,
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            };

            let res = await axios(config)
            resolve(res)
        } catch (err) {
            resolve(err?.response)
        }
    })
}

// get kyc verified customers list
export function kycVerified(token) {
    return new Promise(async (resolve, reject) => {
        try {

            var config = {
                method: 'get',
                url: `${process.env.REACT_APP_BASEURL}/admin/users/status/verified`,
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            };

            let res = await axios(config)
            resolve(res)
        } catch (err) {
            resolve(err?.response)
        }
    })
}

// get complete kyc info of customer
export function customerCompleteKYC(token, customerID) {
    return new Promise(async (resolve, reject) => {
        try {
            var config = {
                method: 'get',
                url: `${process.env.REACT_APP_BASEURL}/admin/users/${customerID}`,
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            };

            let res = await axios(config)
            resolve(res)
        } catch (err) {
            resolve(err?.response)
        }
    })
}

// get customer statistics
export function getCustomerStatistics(token, type) {
    return new Promise(async (resolve, reject) => {
        try {

            var config = {
                method: 'get',
                url: `${process.env.REACT_APP_BASEURL}/admin/user/${type}`,
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            };

            let res = await axios(config)
            resolve(res)
        } catch (err) {
            resolve(err?.response)
        }
    })
}

// get customer kyc document
export function getCustomerDocuments(token, docType, customerID) {
    return new Promise(async (resolve, reject) => {
        try {
            var config = {
                method: 'get',
                url: `${process.env.REACT_APP_BASEURL}/admin/users/document/${customerID}/${docType}`,
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            };

            let res = await axios(config)
            resolve(res)
        } catch (err) {
            resolve(err?.response)
        }
    })
}

// update customer kyc basic detail status
export function updateCustomerBasicStatus(token, customerID, field, status) {
    return new Promise(async (resolve, reject) => {
        try {
            let data = JSON.stringify({
                "status": status,
                "field": field,
                "user_id": customerID
            })
            var config = {
                method: 'post',
                url: `${process.env.REACT_APP_BASEURL}/admin/kyc/updateBasicDetailsStatus`,
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json'
                },
                data: data
            };

            let res = await axios(config)
            resolve(res)
        } catch (err) {
            resolve(err?.response)
        }
    })
}

// update customer kyc bank detail status
export function updateCustomerBankStatus(token, customerID, status) {
    return new Promise(async (resolve, reject) => {
        try {
            let data = JSON.stringify({
                "status": status,
                "user_id": customerID
            })
            var config = {
                method: 'post',
                url: `${process.env.REACT_APP_BASEURL}/admin/kyc/updateBankDetailsStatus`,
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json'
                },
                data: data
            };

            let res = await axios(config)
            resolve(res)
        } catch (err) {
            resolve(err?.response)
        }
    })
}

// update customer kyc basic detail status
export function updateCustomerDematStatus(token, customerID, status) {
    return new Promise(async (resolve, reject) => {
        try {
            let data = JSON.stringify({
                "status": status,
                "user_id": customerID
            })
            var config = {
                method: 'post',
                url: `${process.env.REACT_APP_BASEURL}/admin/kyc/updateDematDetailsStatus`,
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json'
                },
                data: data
            };

            let res = await axios(config)
            resolve(res)
        } catch (err) {
            resolve(err?.response)
        }
    })
}


// update customer kyc basic detail status
export function updateCustomerRemark(token, customerID, remark, adminRemark) {
    return new Promise(async (resolve, reject) => {
        try {
            let data = JSON.stringify({
                "user_remark": remark,
                "user_id": customerID,
                "internal_remark": adminRemark
            })
            var config = {
                method: 'post',
                url: `${process.env.REACT_APP_BASEURL}/admin/kyc/remark`,
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json'
                },
                data: data
            };

            let res = await axios(config)
            resolve(res)
        } catch (err) {
            resolve(err?.response)
        }
    })
}

// update customer utm
export function updateCustomerUTM(token, customerID, UTM) {
    return new Promise(async (resolve, reject) => {
        try {
            let data = JSON.stringify({
                "utm": UTM
            })
            var config = {
                method: 'patch',
                url: `${process.env.REACT_APP_BASEURL}/admin/user/utm/${customerID}`,
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json'
                },
                data: data
            };

            let res = await axios(config)
            resolve(res)
        } catch (err) {
            resolve(err?.response)
        }
    })
}

// get utm list
export function getUTMList(token) {
    return new Promise(async (resolve, reject) => {
        try {

            var config = {
                method: 'get',
                url: `${process.env.REACT_APP_BASEURL}/admin/distributor`,
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            };

            let res = await axios(config)
            resolve(res)
        } catch (err) {
            resolve(err?.response)
        }
    })
}

// update customer type
export function updateCustomerType(token, userId, userType, userParentId) {
    return new Promise(async (resolve, reject) => {
        try {
            let data = JSON.stringify({
                "user_id": userId,
                "account_type": userType,
                "pro_user_id": userParentId
            })
            var config = {
                method: 'patch',
                url: `${process.env.REACT_APP_BASEURL}/admin/user/accountType`,
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json'
                },
                data: data
            };

            let res = await axios(config)
            resolve(res)
        } catch (err) {
            resolve(err?.response)
        }
    })
}

// update customer beneficiary status
export function updateBeneficiaryStatus(token, customerID, status) {
    return new Promise(async (resolve, reject) => {
        try {
            let data = JSON.stringify({
                "beneficiary_status": status,
                "user_id": customerID
            })
            var config = {
                method: 'post',
                url: `${process.env.REACT_APP_BASEURL}/admin/kyc/beneficiary`,
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json'
                },
                data: data
            };

            let res = await axios(config)
            resolve(res)
        } catch (err) {
            resolve(err?.response)
        }
    })
}

// update customer turnover status
export function updateTurnOverStatus(token, customerID, status) {
    return new Promise(async (resolve, reject) => {
        try {
            let data = JSON.stringify({
                "is_turn_over_above_10_cr": status,
                "user_id": customerID
            })
            var config = {
                method: 'post',
                url: `${process.env.REACT_APP_BASEURL}/admin/kyc/turnover-status`,
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json'
                },
                data: data
            };

            let res = await axios(config)
            resolve(res)
        } catch (err) {
            resolve(err?.response)
        }
    })
}

// get user actions
export function getAllUserAction(token) {
    return new Promise(async (resolve, reject) => {
        try {
            
            var config = {
                method: 'get',
                url: `${process.env.REACT_APP_BASEURL}/admin/user/actions`,
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            };

            let res = await axios(config)
            resolve(res)
        } catch (err) {
            resolve(err?.response)
        }
    })
}