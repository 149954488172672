import axios from 'axios';
import { configs } from '../../assets/Config';

// backend api for customer credential login
export function credentialLogin(username, password) {
    return new Promise(async (resolve, reject) => {
        try {
            var data = JSON.stringify({
                "email": username,
                "password": password
            });

            var config = {
                method: 'post',
                url: `${process.env.REACT_APP_BASEURL}/admin/login`,
                headers: {
                    'Authorization': 'Bearer asd',
                    'Content-Type': 'application/json'
                },
                data: data
            };

            let res = await axios(config)
            resolve(res)
        } catch (err) {
            resolve(err?.response)
        }
    })
}

// logout customer api
export function adminLogout(token) {
    return new Promise(async (resolve, reject) => {
        try {
            var config = {
                method: 'post',
                url: `${process.env.REACT_APP_BASEURL}/admin/logout`,
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            };

            let res = await axios(config)
            resolve(res)
        } catch (err) {
            resolve(err?.response)
        }
    })
}

// get all funcitons of admin
export function getAdminFunctions(token) {
    return new Promise(async (resolve, reject) => {
        try {
            var config = {
                method: 'get',
                url: `${process.env.REACT_APP_BASEURL}/admin/login/loadFunctions`,
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            };

            let res = await axios(config)
            resolve(res)
        } catch (err) {
            resolve(err?.response)
        }
    })
}

// get all master remark comments
export function getRemarkComment(token, type) {
    return new Promise(async (resolve, reject) => {
        try {
            var config = {
                method: 'get',
                url: `${process.env.REACT_APP_BASEURL}/admin/remark/loadRemarkByTemplate/${type}`,
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            };

            let res = await axios(config)
            resolve(res)
        } catch (err) {
            resolve(err?.response)
        }
    })
}

// get all master remark comments template
export function getRemarkCommentTemlate(token) {
    return new Promise(async (resolve, reject) => {
        try {
            var config = {
                method: 'get',
                url: `${process.env.REACT_APP_BASEURL}/admin/remark/loadRemarkTemplate`,
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            };

            let res = await axios(config)
            resolve(res)
        } catch (err) {
            resolve(err?.response)
        }
    })
}

// add master remark
export function addMasterRemark(token, templateId, remark) {
    return new Promise(async (resolve, reject) => {
        try {
            var data = {
                template_id: templateId,
                template: remark
            }
            var config = {
                method: 'POST',
                url: `${process.env.REACT_APP_BASEURL}/admin/remark/addRemarkTemplate`,
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json'
                },
                data: data
            };

            let res = await axios(config)
            resolve(res)
        } catch (err) {
            resolve(err?.response)
        }
    })
}

// edit master remark
export function editMasterRemark(token, templateId, remark) {
    return new Promise(async (resolve, reject) => {
        try {
            var data = {
                template_data_id: templateId,
                template: remark
            }
            var config = {
                method: 'PUT',
                url: `${process.env.REACT_APP_BASEURL}/admin/remark/updateRemarkTemplate`,
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json'
                },
                data: data
            };

            let res = await axios(config)
            resolve(res)
        } catch (err) {
            resolve(err?.response)
        }
    })
}

// delete master remark
export function deleteMasterRemark(token, templateId) {
    return new Promise(async (resolve, reject) => {
        try {
            var data = {
                template_data_id: templateId
            }
            var config = {
                method: 'DELETE',
                url: `${process.env.REACT_APP_BASEURL}/admin/remark/deleteRemarkTemplate`,
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json'
                },
                data: data
            };

            let res = await axios(config)
            resolve(res)
        } catch (err) {
            resolve(err?.response)
        }
    })
}

// get all referral details
export function getReferralDetails(token) {
    return new Promise(async (resolve, reject) => {
        try {
            var config = {
                method: 'get',
                url: `${process.env.REACT_APP_BASEURL}/admin/referral/user/referralLedger`,
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            };

            let res = await axios(config)
            resolve(res)
        } catch (err) {
            resolve(err?.response)
        }
    })
}

// get distributor all referral details
export function getDistributorReferralDetails(token) {
    return new Promise(async (resolve, reject) => {
        try {
            var config = {
                method: 'get',
                url: `${process.env.REACT_APP_BASEURL}/admin/referral/distributor/referralLedger`,
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            };

            let res = await axios(config)
            resolve(res)
        } catch (err) {
            resolve(err?.response)
        }
    })
}


// reset admin password
export function resetAdminPassword(currentPass, newPass, cnfPass, token) {
    return new Promise(async (resolve, reject) => {
        try {
            var data = JSON.stringify({
                "password": newPass,
                "current_password": currentPass,
                "confirm_password": cnfPass
            });

            var config = {
                method: 'post',
                url: `${process.env.REACT_APP_BASEURL}/admin/updatePassword`,
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json'
                },
                data: data
            };

            let res = await axios(config)
            resolve(res)
        } catch (err) {
            resolve(err?.response)
        }
    })
}
