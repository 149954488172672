import React, { useEffect } from 'react';
import { configs } from '../../../assets/Config';
import { useLogin } from '../../../services/contexts/AdminLoginContext';
import { useRMContext } from '../../../services/contexts/RMContext';
import LeftBarPage from '../../includes/dashboard/LeftBarPage';
import TopBarPage from '../../includes/dashboard/TopBarPage';
import AMListPage from './AMListPage';
import CreateAdminPage from './CreateAdminPage';
import ToastPage from '../../includes/ToastPage';
import { useHistory } from 'react-router';

export default function AMMainPage() {

    const { getAllRoleUsersList, setShowDeletedAdmins } = useRMContext();
    const { setCurrModule, allowedFunc } = useLogin()

    const history = useHistory()

    useEffect(() => {
        (async () => {
            try {
                if (allowedFunc.includes(configs.functionList['role_management:role_management'])) {
                    setCurrModule(configs.modules.am)
                    getAllRoleUsersList()
                    setShowDeletedAdmins(false)
                } else {
                    return history.goBack()
                }
            } catch (error) {

            }
        })()
    }, [])

    // role management main ui code
    return (
        <>
            <TopBarPage />
            <div style={{ display: "flex" }}>
                <LeftBarPage />
                <div id='amContainer' className="am-container">
                    <AMListPage />
                    <ToastPage />
                    <CreateAdminPage />
                </div>
            </div>
        </>
    )
}