import { Modal } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { configs } from '../../../assets/Config';
import '../../../assets/styles/RM.css'
import { uploadCompanyDataDoc } from '../../../services/apis/companyManagement/CMApi';
import { useLogin } from '../../../services/contexts/AdminLoginContext';
import { useCMContext } from '../../../services/contexts/companyManagement/CMContext';
import RichTextEditorPage from '../../includes/RichTextEditorPage';

export default function CreateCompanyPage() {

    const { setShowSuccess, token, logout, setLoginError, allowedFunc } = useLogin()
    const { editCompany, setEditCompany, editCompanyData, setEditCompanyData, createEditCompany, getAllCompaniesList, setShowRTPage, showRTPage, setOverview, setInsight, setAnnualReport } = useCMContext()

    const statusValues = ["DRHP Not Filed", "DRHP Filed", "DRHP Approved", "Delisted", "Listed but not traded", "IPO in Process", "Now Listed"]

    const [logoFile, setLogoFile] = useState("")
    const [name, setName] = useState(editCompanyData.name)
    const [secondaryName, setSecondaryName] = useState(editCompanyData.secondary_name)
    const [faqTitle, setFaqTitle] = useState(editCompanyData.faq_title)
    const [industry, setIndustry] = useState(editCompanyData.industry)
    const [sector, setSector] = useState(editCompanyData.sector)
    const [unique_title, setUnique_title] = useState(editCompanyData.uniquetitle)
    const [isin, setISIN] = useState(editCompanyData.isin)
    const [status, setStatus] = useState(editCompanyData.status)
    const [statusMessage, setStatusMessage] = useState(editCompanyData.listed_on)
    const [nsdl, setNSDL] = useState(editCompanyData.nsdl)
    const [cdsl, setCDSL] = useState(editCompanyData.cdsl)
    const [display_on_home, setDisplay_on_home] = useState(editCompanyData.display_on_home)
    const [featured, setFeatured] = useState(editCompanyData.featured)
    const [tagId, setTagId] = useState(editCompanyData.blog_tag_id)
    const [disabletrading, setDisableTrading] = useState(editCompanyData.disabletrading)
    const [metaData, setMetaData] = useState(editCompanyData.metadata)
    const [error, setError] = useState("")
    const [rtPageType, setRTPageType] = useState("")

    const [file, setFile] = useState("")
    const [fileError, setFileError] = useState("")
    const [errorDetail, setErrorDetail] = useState("")
    const [showErrorModal, setShowErrorModal] = useState(false)

    const history = useHistory()

    const handleClose = () => {
        setShowErrorModal(false);
    };


    useEffect(() => {
        try {
            if (editCompanyData) {
                setName(editCompanyData.name)
                setSecondaryName(editCompanyData.secondary_name)
                setFaqTitle(editCompanyData.faq_title)
                setIndustry(editCompanyData.industry)
                setSector(editCompanyData.sector)
                setUnique_title(editCompanyData.uniquetitle)
                setISIN(editCompanyData.isin)
                setStatus(editCompanyData.status)
                setStatusMessage(editCompanyData.listed_on)
                setNSDL(editCompanyData.nsdl.toString())
                setCDSL(editCompanyData.cdsl.toString())
                setDisplay_on_home(editCompanyData.display_on_home.toString())
                setFeatured(editCompanyData.featured.toString())
                setTagId(editCompanyData.blog_tag_id)
                setDisableTrading(editCompanyData.disabletrading.toString())
                setMetaData(editCompanyData.metadata)
                setOverview(editCompanyData.overview)
                setInsight(editCompanyData.insight)
                setAnnualReport(editCompanyData.annual_report)
                setError("")
            }
        } catch (error) {

        }
    }, [editCompanyData])


    // add company function
    async function addCompany() {
        try {

            document.getElementById("companyBtn").classList.toggle("d-none")
            document.getElementById("companyBtnLoader").classList.toggle("d-none")

            if (editCompany && !editCompanyData.id) {
                setError("Please select a valid company to edit.")
            } else if (!name || (name && name.length > 200)) {
                setError("Please provide a valid company name.")
            } else if (!secondaryName || (secondaryName && secondaryName.length > 200)) {
                setError("Please provide a valid secondary name.")
            } else if (!faqTitle || (faqTitle && faqTitle.length > 200)) {
                setError("Please provide a valid FAQ Title.")
            } else if (!industry || (industry && (industry.length < 3 || industry.length > 150))) {
                setError("Please provide a valid industry.")
            } else if (!sector || (sector && (sector.length < 3 || sector.length > 150))) {
                setError("Please provide a valid sector.")
            } else if (!unique_title || (unique_title && (unique_title.length < 10 || unique_title.length > 160))) {
                setError("Please provide a valid unique title.")
            } else if (!isin || (isin && !isin.match(/^[a-zA-Z0-9]{12}$/))) {
                setError("Please provide a valid ISIN.")
            } else if (!status || !statusValues.includes(status)) {
                setError("Please select a valid status.")
            } else if (!nsdl || (nsdl != null && (nsdl != 0 && nsdl != 1))) {
                setError("Please select valid NSDL value.")
            } else if (!cdsl || (cdsl != null && (cdsl != 0 && cdsl != 1))) {
                setError("Please select valid CDSL value.")
            } else if (!display_on_home || (display_on_home != null && (display_on_home != 0 && display_on_home != 1))) {
                setError("Please select valid Display on Home value.")
            } else if (!featured || (featured != null && (featured != 0 && featured != 1))) {
                setError("Please select valid featured value.")
            } else if (!disabletrading || (disabletrading != null && (disabletrading != 0 && disabletrading != 1))) {
                setError("Please select valid Disable trading value.")
            } else if (!editCompany && !logoFile) {
                setError("Please select a valid logo file.")
            } else if (editCompany && !metaData) {
                setError("Please provide valid meta data.")
            } else {
                let response = await createEditCompany(name, secondaryName, faqTitle, industry, sector, unique_title, isin, status, statusMessage, nsdl, cdsl, display_on_home, featured, tagId, disabletrading, logoFile, metaData, editCompany, editCompanyData.id)

                if (response && response === "success") {
                    resetValues()
                    setShowSuccess(true)
                    getAllCompaniesList()
                } else {
                    setError(response)
                }
            }

            document.getElementById("companyBtn").classList.toggle("d-none")
            document.getElementById("companyBtnLoader").classList.toggle("d-none")
        } catch (error) {
        }
    }

    // reset all values
    function resetValues() {
        try {
            setName("")
            setSecondaryName("")
            setFaqTitle("")
            setIndustry("")
            setSector("")
            setUnique_title("")
            setISIN("")
            setStatus("")
            setStatusMessage("")
            setNSDL("")
            setCDSL("")
            setDisplay_on_home("")
            setFeatured("")
            setTagId("")
            setDisableTrading("")
            setMetaData("")
            setLogoFile("")
            setEditCompany(false)
            setError("")
            setOverview("")
            setInsight("")
            setAnnualReport("")
        } catch (error) {

        }
    }



    // get file contents through file chooser
    function uploadFile(file, type) {
        try {
            if (type === "Logo" && (file.type === "image/jpg" || file.type === "image/jpeg" || file.type === "image/png")) {
                setLogoFile(file)
            } else if (type !== "Logo" && (file.type === ".xls" || file.type === "xlsx" || file.type === "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" || file.type === "application/vnd.ms-excel")) {
                setFile(file)
                setFileError("")
            } else {
                type === "Logo" ? alert("Only jpg or png files are allowed.") : alert("Only csv files are allowed.")
            }
        } catch (error) {
            console.error(error)
        }
    }

    // upload csv to server
    async function uploadFileToServer() {
        try {
            if (file) {
                document.getElementById("fileBtn").classList.toggle("d-none")
                document.getElementById("fileBtnLoader").classList.toggle("d-none")

                let response = await uploadCompanyDataDoc(token, file);
                if (response && response.status === 200) {
                    setFileError("")
                    setFile("")
                    setErrorDetail("")
                    setShowSuccess(true)
                } else if (response && response.status === 401) {
                    logout();
                    setLoginError(configs.unauthorizedErrorMessage);
                }
                else if (response && response.status === 406) {
                    setFileError("Click to view error details.");
                    setErrorDetail(response.data.error);
                }
                else {
                    setFileError(response.data.error)
                }

                document.getElementById("fileBtn").classList.toggle("d-none")
                document.getElementById("fileBtnLoader").classList.toggle("d-none")
            } else {
                setFileError("Please select a file to upload.")
            }
        } catch (error) {

        }
    }



    // create role ui code
    return (
        <div id="rmCreateCompany" className="col-lg-3 col-md-4 d-md-block d-none pt-3 main-rmPanel">
            {((allowedFunc.includes(configs.functionList["company_management:update_company"]) && editCompany) || allowedFunc.includes(configs.functionList["company_management:create_company"])) ?
                <>
                    <p className="h6 text-custom-grey">{editCompany ? "Edit Company Information" : "Add a Company"}</p>

                    <div className="function-container">
                        <form id="createRoleForm" action="" className='mr-1'>
                            <div className="form-group">
                                <label className="f-12">Company Name</label>
                                <input type="text" id="roleName" className="form-control f-10" placeholder="Company Name" value={name} required onChange={(e) => {
                                    setName(e.target.value)
                                }} />
                            </div>
                            <div className="form-group">
                                <label className="f-12">Secondary Name</label>
                                <input type="text" id="roleName" className="form-control f-10" placeholder="Secondary Name" value={secondaryName} required onChange={(e) => {
                                    setSecondaryName(e.target.value)
                                }} />
                            </div>
                            <div className="form-group">
                                <label className="f-12">FAQ Title</label>
                                <input type="text" id="roleName" className="form-control f-10" placeholder="FAQ Title" value={faqTitle} required onChange={(e) => {
                                    setFaqTitle(e.target.value)
                                }} />
                            </div>
                            <div className="form-group">
                                <label className="f-12">Industry</label>
                                <input type="text" id="roleName" className="form-control f-10" placeholder="Industry" value={industry} required onChange={(e) => {
                                    setIndustry(e.target.value)
                                }} />
                            </div><div className="form-group">
                                <label className="f-12">Sector</label>
                                <input type="text" id="roleName" className="form-control f-10" placeholder="Sector" value={sector} required onChange={(e) => {
                                    setSector(e.target.value)
                                }} />
                            </div><div className="form-group">
                                <label className="f-12">Unique Title</label>
                                <input type="text" id="roleName" className="form-control f-10" placeholder="Unique Title" value={unique_title} required onChange={(e) => {
                                    setUnique_title(e.target.value)
                                }} />
                            </div><div className="form-group">
                                <label className="f-12">ISIN</label>
                                <input type="text" id="roleName" className="form-control f-10" placeholder="ISIN" value={isin} required onChange={(e) => {
                                    setISIN(e.target.value)
                                }} />
                            </div><div className="form-group">
                                <label className="f-12">Status</label>
                                <select name="" id="" className="col-12 py-1 px-2 border rounded" value={status ? status : "default"} onChange={(e) => {
                                    setStatus(e.target.value)
                                }}>
                                    <option value="default"> -- Select a Status -- </option>
                                    {statusValues.map((element, key) => {
                                        return <option value={element} key={`stat` + key}>{element} </option>
                                    })}

                                </select>
                            </div>
                            <div className="form-group">
                                <label className="f-12">Listing Status Message</label>
                                <input type="text" id="roleName" className="form-control f-10" placeholder="Listing Status Message" value={statusMessage} required onChange={(e) => {
                                    setStatusMessage(e.target.value)
                                }} />
                            </div>
                            <div className="form-group">
                                <label className="f-12">NSDL</label>
                                <select name="" id="" className="col-12 py-1 px-2 border rounded" value={nsdl != null ? nsdl : "default"} onChange={(e) => {
                                    setNSDL(e.target.value)
                                }}>
                                    <option value="default"> ---- </option>
                                    <option value="0"> No </option>
                                    <option value="1"> Yes </option>
                                </select>
                            </div><div className="form-group">
                                <label className="f-12">CDSL</label>
                                <select name="" id="" className="col-12 py-1 px-2 border rounded" value={cdsl != null ? cdsl : "default"} onChange={(e) => {
                                    setCDSL(e.target.value)
                                }}>
                                    <option value="default"> ---- </option>
                                    <option value="0"> No </option>
                                    <option value="1"> Yes </option>
                                </select>
                            </div><div className="form-group">
                                <label className="f-12">Display on Home</label>
                                <select name="" id="" className="col-12 py-1 px-2 border rounded" value={display_on_home != null ? display_on_home : "default"} onChange={(e) => {
                                    setDisplay_on_home(e.target.value)
                                }}>
                                    <option value="default"> ---- </option>
                                    <option value="0"> No </option>
                                    <option value="1"> Yes </option>
                                </select>
                            </div>
                            <div className="form-group">
                                <label className="f-12">Featured</label>
                                <select name="" id="" className="col-12 py-1 px-2 border rounded" value={featured != null ? featured : "default"} onChange={(e) => {
                                    setFeatured(e.target.value)
                                }}>
                                    <option value="default"> ---- </option>
                                    <option value="0"> No </option>
                                    <option value="1"> Yes </option>
                                </select>
                            </div>
                            <div className="form-group">
                                <label className="f-12">Blog Tag Id</label>
                                <input type="text" className="form-control f-10" placeholder="Blog Tag Id" value={tagId} onChange={(e) => {
                                    setTagId(e.target.value)
                                }} />
                            </div>
                            <div className="form-group">
                                <label className="f-12">Disable Trading</label>
                                <select name="" id="" className="col-12 py-1 px-2 border rounded" value={disabletrading != null ? disabletrading : "default"} onChange={(e) => {
                                    setDisableTrading(e.target.value)
                                }}>
                                    <option value="default"> ---- </option>
                                    <option value="0"> No </option>
                                    <option value="1"> Yes </option>
                                </select>
                            </div>
                            {editCompany ? <div className="form-group">
                                <label className="f-12">Meta Data</label>
                                <textarea id="roleName" className="form-control f-10" placeholder="Meta Data" value={metaData} required onChange={(e) => {
                                    setMetaData(e.target.value)
                                }} />
                            </div> : null}
                            <div className="form-group">
                                <a className="f-12" href='#' onClick={(e) => {
                                    e.preventDefault()
                                    setShowRTPage(true)
                                    setRTPageType("overview")
                                }}>Add Overview</a>

                            </div>
                            <div className="form-group">
                                <a className="f-12" href='#' onClick={(e) => {
                                    e.preventDefault()
                                    setShowRTPage(true)
                                    setRTPageType("insight")
                                }}>Add Insight</a>

                            </div>
                            <div className="form-group">
                                <a className="f-12" href='#' onClick={(e) => {
                                    e.preventDefault()
                                    setShowRTPage(true)
                                    setRTPageType("annualReport")
                                }}>Add Annual Report</a>

                            </div>
                            <div className="form-group">
                                <label className="f-12">Logo</label>
                                <p className="pb-0 mb-0 f-12 ">
                                    <input type="file" id="logo" required hidden accept="image/jpg, image/jpeg, image/png" onChange={(e) => {
                                        uploadFile(e.target.files[0], "Logo")
                                    }} />
                                    <label htmlFor="logo" className="mb-0 p-1  curPoint btn-primary">Choose Logo File</label>
                                    <br /> File to be uploaded: <span className="text-theme">{logoFile.name}</span>
                                </p>
                            </div>
                        </form>
                    </div>
                    <div className="form-check p-0">
                        <p className="text-danger" id="createRoleError">{error}</p>
                    </div>
                    <div className='row m-0 p-0'>
                        <div className='col-9 m-0 p-0'>
                            <button id="companyBtn" className="btn btn-primary f-12" onClick={(e) => {
                                e.preventDefault()
                                addCompany()
                            }}>{editCompany ? "Update Company" : "Add Company"}</button>
                            <div id="companyBtnLoader" className='animBtn d-none'><div className='gSignIn-Anim'></div></div>
                        </div>
                        <div className='col-3 m-0 p-0'>
                            <button className="btn btn-primary f-12" onClick={(e) => {
                                e.preventDefault()
                                resetValues()
                            }}>Reset</button>
                        </div>
                    </div>
                    <div className='row m-0 p-0'>
                        <button className="btn btn-primary mt-3 d-md-none" onClick={(e) => {
                            e.preventDefault()
                            document.getElementById("rmCreateCompany").classList.toggle('d-none')
                            document.getElementById("companyListPage").classList.toggle('d-none')
                            history.push('/companyManagement/companyDetail')
                        }}>View All Companies</button>
                    </div>
                </>
                : null}
            {allowedFunc.includes(configs.functionList["company_parser:update_company_data"]) ?
                <form id="fileForm">
                    <div className="form-group mt-5">
                        <p className="h6 text-custom-grey">Upload Company Information</p>
                        <div className="file-border rounded py-4" onDragOver={(e) => {
                            e.preventDefault();
                        }} onDrop={(e) => {
                            uploadFile(e.dataTransfer.files[0])
                            e.preventDefault();
                        }}>
                            <i className="fas fa-file-upload f-22"></i>
                            <p className="pb-0 mb-0 f-12">
                                Drag your file or &nbsp;
                                <input type="file" id="upload_userPAN" required hidden accept=".xls, xlsx, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel" onChange={(e) => {
                                    uploadFile(e.target.files[0], "CSV")
                                }} />
                                <label htmlFor="upload_userPAN" className="mb-0 pb-0">Click here</label>
                                &nbsp; to choose <br /> Upload: <span className="text-theme">{file.name}</span>
                            </p>
                        </div>
                    </div>
                    <div className="form-check p-0">
                        {errorDetail ? <p className="text-danger curPoint" id="inventoryError" onClick={(e) => {
                            e.preventDefault()
                            setShowErrorModal(true)
                        }}>{fileError}</p> : <p className="text-danger" id="inventoryError">{fileError}</p>}
                    </div>
                    <div className="text-center">
                        <a id="fileBtn" href="#" className="btn btn-primary f-12" onClick={(e) => {
                            e.preventDefault()
                            uploadFileToServer()
                            document.getElementById("fileForm").reset();
                        }}><i className="fas fa-upload mr-2"></i>Upload Company Data</a>
                        <div id="fileBtnLoader" className='animBtn d-none'><div className='gSignIn-Anim'></div></div>
                    </div>
                </form>
                : null}


            {/* --------------------------------------------Error Detail Modal-------------------------------------------------------------------- */}
            <Modal open={showErrorModal} onClose={handleClose}>
                <div className="modal-fade" id="KYCSubmitModal" tabIndex="-1" role="dialog" aria-hidden="true">
                    <div className="modal-dialog modal-dialog-centered" role="document">
                        <div className="modal-content">
                            <div className="modal-header border-0">
                                <h5>Error Detail</h5>
                                <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={handleClose}>
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div className="modal-body py-3" style={{ overflowY: "auto", height: "500px" }}>
                                <p className="text-danger">Following errors were found while trying to upload data:</p>
                                <ol>
                                    {Object.keys(errorDetail).map((element, key) => {
                                        if (Array.isArray(errorDetail[element]) && errorDetail[element].length > 0) {
                                            return <li key={`out` + key}>{element}
                                                <ol type='i'>
                                                    {errorDetail[element].map((inner, key) => {
                                                        return <li key={`inner` + key}>{inner}</li>
                                                    })}
                                                </ol>
                                            </li>
                                        } else if (!Array.isArray(errorDetail[element])) {
                                            return <li key={`out` + key}>{element}
                                                <ol type='i'>
                                                    {Object.keys(errorDetail[element]).map((inner, key) => {
                                                        return <li key={`inner` + key}>{inner}
                                                            <ol type='a'>
                                                                {errorDetail[element][inner].map((error, key) => {
                                                                    return <li key={`err` + key}>{error}</li>
                                                                })}
                                                            </ol>
                                                        </li>
                                                    })}
                                                </ol>
                                            </li>
                                        }

                                    })}
                                </ol>
                            </div>
                        </div>
                    </div>
                </div>
            </Modal>

            {/* --------------------------------create compnay overview--------------------------------- */}
            {showRTPage ? <RichTextEditorPage {...{ type: rtPageType }} /> : null}
        </div>
    )
}