import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { configs } from '../../../assets/Config';
import { useLogin } from '../../../services/contexts/AdminLoginContext';
import LeftBarPage from '../../includes/dashboard/LeftBarPage';
import TopBarPage from '../../includes/dashboard/TopBarPage';
import ToastPage from '../../includes/ToastPage';
import '../../../assets/styles/RM.css'
import CreateCompanyPage from './CreateCompanyPage';
import ListCompanyPage from './ListCompanyPage';
import { useCMContext } from '../../../services/contexts/companyManagement/CMContext';
import DeletedCompanyPage from './DeletedCompanyPage';


export default function CMMainPage() {

    const { companyList, getAllCompaniesList, showDeletedCompanies } = useCMContext()
    const { setCurrModule, allowedFunc } = useLogin()

    const history = useHistory()

    useEffect(() => {
        (async () => {
            try {
                if (allowedFunc.includes(configs.functionList['company_management:view_company'])) {
                    setCurrModule(configs.modules.cm)
                    getAllCompaniesList()
                } else {
                    return history.goBack()
                }
            } catch (error) {

            }
        })()
    }, [])

    // role management main ui code
    return (
        <>
            <TopBarPage />
            <div style={{ display: "flex" }}>
                <LeftBarPage />
                {showDeletedCompanies ? <div id='rmContainer' className="rm-container"><DeletedCompanyPage /></div> :
                    <div id='rmContainer' className="rm-container">
                        <ListCompanyPage />
                        {allowedFunc.includes(configs.functionList['company_management:create_company']) || allowedFunc.includes(configs.functionList['company_management:update_company']) || allowedFunc.includes(configs.functionList['company_parser:update_company_data']) ?
                            <CreateCompanyPage />
                            : null}
                        <ToastPage />
                    </div>
                }
            </div>
        </>
    )
}