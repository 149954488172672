import { Modal } from '@material-ui/core';
import { DataGrid, GridToolbar } from '@material-ui/data-grid';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { configs, downloadDoc, downloadImage } from '../../../assets/Config';
import '../../../assets/styles/RM.css'
import { downloadCompanyDataDoc } from '../../../services/apis/companyManagement/CMApi';
import { useLogin } from '../../../services/contexts/AdminLoginContext';
import { useCMContext } from '../../../services/contexts/companyManagement/CMContext';
import DeleteModalPage from '../../includes/DeleteModalPage';
import CompanyHighlightMgmt from '../miscellaneous/StatusManagement/CompanyHighlightMgmt';


export default function ListCompanyPage() {

    const { companyList, setCompanyList, getAllCompaniesList, downloadFile, setEditCompany, setEditCompanyData, editCompanyData, getCompanyDataById, setShowDeletedCompanies, showDeletedCompanies, addCompanyStock, getCompanyStock } = useCMContext()
    const { setShowDelete, setShowSuccess, allowedFunc, setHighlightManagement } = useLogin()

    const history = useHistory()
    const [deleteId, setDeleteId] = useState({})

    const [companyData, setCompanyData] = useState("")

    const [stockSumModal, setStockSumModal] = useState(false)
    const [faceValue, setFaceValue] = useState("")
    const [bookValue, setBookValue] = useState("")
    const [shares, setShares] = useState("")
    const [EPS, setEPS] = useState("")
    const [sales, setSales] = useState("")
    const [PE, setPE] = useState("")
    const [Equity, setEquity] = useState("")
    const [PAT, setPAT] = useState("")
    const [dividend, setDividend] = useState("")
    const [dYield, setDYield] = useState("")
    const [message, setMessage] = useState("")
    const [error, setError] = useState("")
    const [addStock, setAddStock] = useState("")

    const handleClose = () => {
        setStockSumModal(false);
        setFaceValue("")
        setBookValue("")
        setShares("")
        setEPS("")
        setSales("")
        setPE("")
        setPAT("")
        setEquity("")
        setDividend("")
        setDYield("")
        setMessage("")
        setError("")
    };

    // add company stock summary
    async function addStockData() {
        try {

            document.getElementById("addStockBtn").classList.toggle("d-none")
            document.getElementById("addStockBtnLoader").classList.toggle("d-none")

            const reg = /^[0-9]\d*(\.\d{1,2})?$/;
            const regM = /^[-]?[0-9]\d*(\.\d{1,2})?$/;
            if (!faceValue.toString().match(reg)) {
                setError("Only numbers and decimals upto 2 places are allowed for Face Value.")
            } else if (!bookValue.toString().match(regM)) {
                setError("Only numbers and decimals upto 2 places are allowed for Book Value.")
            } else if (!shares.toString().match(reg)) {
                setError("Only numbers and decimals upto 2 places are allowed for No of shares.")
            } else if (!EPS.toString().match(regM)) {
                setError("Only numbers and decimals upto 2 places are allowed for EPS.")
            } else if (!sales.toString().match(reg)) {
                setError("Only numbers and decimals upto 2 places are allowed for Sales.")
            } else if (!PE.toString().match(regM)) {
                setError("Only numbers and decimals upto 2 places are allowed for Industry PE.")
            } else if (!Equity.toString().match(regM)) {
                setError("Only numbers and decimals upto 2 places are allowed for Equity.")
            } else if (!PAT.toString().match(regM)) {
                setError("Only numbers and decimals upto 2 places are allowed for PAT.")
            } else if (!dividend.toString().match(reg)) {
                setError("Only numbers and decimals upto 2 places are allowed for Dividend.")
            } else {
                let response = await addCompanyStock(companyData.id, faceValue, bookValue, shares, EPS, sales, PE, dividend, Equity, PAT, message)
                if (response && response === 'success') {
                    handleClose()
                    setShowSuccess(true)
                } else {
                    setError(response)
                }
            }

            document.getElementById("addStockBtn").classList.toggle("d-none")
            document.getElementById("addStockBtnLoader").classList.toggle("d-none")
        } catch (error) {
            console.error(error)
        }
    }

    // get company stock summary data
    async function getStockData(companyId) {
        try {
            setAddStock(true)
            let response = await getCompanyStock(companyId)
            if (response && response.success) {
                setAddStock(false)
                setFaceValue(response.data.face_value)
                setBookValue(response.data.book_value)
                setShares(response.data.no_of_shares)
                setEPS(response.data.eps)
                setSales(response.data.sales)
                setPE(response.data.industry_pe)
                setEquity(response.data.equity)
                setPAT(response.data.pat)
                setDividend(response.data.dividend)
                setDYield(response.data.dividend_yield)
                setMessage(response.data.message)
            }
        } catch (error) {

        }
    }

    const columns = [{ field: "id", headerName: "Company ID", minWidth: 100, flex: 0.5, hide: true, filterable: false },
    { field: "name", headerName: "Name", minWidth: 300, flex: 0.5, cellClassName: "break-text" },
    { field: "secondary_name", headerName: "Secondary Name", minWidth: 300, flex: 0.5, cellClassName: "break-text", hide: true },
    { field: "faq_title", headerName: "FAQ Title", minWidth: 300, flex: 0.5, cellClassName: "break-text", hide: true },
    {
        field: "display_on_home", headerName: "Display on Home", minWidth: 100, flex: 0.5, cellClassName: "break-text", renderCell: (params) => {
            return params.row.display_on_home === 1 ? "Yes" : "No"
        }, valueGetter: (params) => params.row.display_on_home === 1 ? "Yes" : "No"
    },
    {
        field: "featured", headerName: "Featured", minWidth: 100, flex: 0.5, cellClassName: "break-text", renderCell: (params) => {
            return params.row.featured === 1 ? "Yes" : "No"
        }, valueGetter: (params) => params.row.featured === 1 ? "Yes" : "No"
    },
    { field: "isin", headerName: "ISIN", minWidth: 150, flex: 0.5, cellClassName: "break-text" },
    { field: "industry", headerName: "Industry", minWidth: 150, flex: 0.5, cellClassName: "break-text" },
    { field: "sector", headerName: "Sector", minWidth: 200, flex: 0.5, cellClassName: "break-text" },
    { field: "unique_title", headerName: "Unique Title", minWidth: 400, flex: 0.5, cellClassName: "break-text" },
    {
        field: "cdsl", headerName: "CDSL", minWidth: 120, flex: 0.1, cellClassName: "break-text", renderCell: (params) => {
            return params.row.cdsl === 1 ? "Yes" : "No"
        }
    },
    {
        field: "nsdl", headerName: "NSDL", minWidth: 120, flex: 0.1, cellClassName: "break-text", renderCell: (params) => {
            return params.row.nsdl === 1 ? "Yes" : "No"
        }
    },
    {
        field: "disabletrading", headerName: "Disable Trading", minWidth: 130, flex: 0.1, cellClassName: "break-text", renderCell: (params) => {
            return params.row.disabletrading === 1 ? "Yes" : "No"
        }, valueGetter: (params) => params.row.disabletrading === 1 ? "Yes" : "No"
    },
    { field: "status", headerName: "Status", minWidth: 120, flex: 0.5, cellClassName: "break-text" },
    { field: "listed_on", headerName: "Status Message", minWidth: 120, flex: 0.5, cellClassName: "break-text" },
    { field: "blog_tag_id", headerName: "Blog Tag ID", minWidth: 80, flex: 0.5, cellClassName: "break-text" },
    {
        field: "logo", headerName: "Logo", minWidth: 110, flex: 0.1, cellClassName: "break-text", cellClassName: "text-primary", renderCell: (params) => {
            return <div className='row pl-2' id={`comp` + params.row.id}>
                <a href={`${process.env.REACT_APP_BASEURL}/all/companyLogo/${params.row.logo}`} target="_blank">
                    <i className="fa fa-external-link kyc-openDoc-prof curPoint"></i></a>
                <i className="fa fa-download kyc-openDoc-prof curPoint" onClick={(e) => {
                    e.preventDefault()
                    downloadImage(`${process.env.REACT_APP_BASEURL}/all/companyLogo/${params.row.logo}`, params.row.id)
                }}></i>
            </div >
        }
    },
    {
        field: "stock", headerName: " ", minWidth: 90, flex: 0.1, cellClassName: "break-text", cellClassName: "text-primary", hide: !allowedFunc.includes(configs.functionList["company_management:update_stocksummary"]) ? true : false, renderCell: (params) => {
            return <a href="#" className="custom-link" onClick={(e) => {
                e.preventDefault()
                setCompanyData(params.row)
                getStockData(params.row.id)
                setStockSumModal(true)
            }}>Add Stock</a>
        }
    },
    {
        field: "highlight", headerName: " ", minWidth: 100, flex: 0.1, cellClassName: "break-text", cellClassName: "text-primary", hide: !allowedFunc.includes(configs.functionList["company_management:update_company"]) ? true : false, renderCell: (params) => {
            return <a href="#" className="custom-link text-primary" onClick={(e) => {
                e.preventDefault()
                setHighlightManagement({
                    asset: params.row,
                    showPage: true
                })
            }}>Highlights</a>
        }
    },
    {
        field: "download", headerName: " ", minWidth: 90, flex: 0.1, cellClassName: "break-text", cellClassName: "text-primary", hide: !allowedFunc.includes(configs.functionList["company_parser:export_company_data"]) ? true : false, renderCell: (params) => {
            return <a href="#" className="custom-link" onClick={(e) => {
                e.preventDefault()
                downloadFile(params.row.id)
            }}>CSV Download</a>
        }
    },
    {
        field: "edit", headerName: " ", minWidth: 40, flex: 0.1, cellClassName: "break-text", cellClassName: "text-primary", hide: !allowedFunc.includes(configs.functionList["company_management:update_company"]) ? true : false, renderCell: (params) => {
            return <a href="#" className="custom-link text-primary" onClick={(e) => {
                e.preventDefault()
                setEditCompany(true)
                getCompanyDataById(params.row.id)
                document.getElementById("rmCreateCompany").classList.toggle('d-none')
                document.getElementById("companyListPage").classList.toggle('d-none')
            }}>Edit</a>
        }
    },
    {
        field: "delete", headerName: " ", minWidth: 65, flex: 0.1, cellClassName: "break-text", cellClassName: "text-danger", hide: !allowedFunc.includes(configs.functionList["company_management:delete_or_restore_company"]) ? true : false, renderCell: (params) => {
            return <a href="#" className="custom-link text-danger" onClick={(e) => {
                e.preventDefault()
                setDeleteId({
                    "category": configs.modules.cm,
                    "type": "Company",
                    "name": params.row.name,
                    "id": params.row.id
                })
                setShowDelete(true)
            }}>Delete</a>
        }
    }]

    useEffect(() => {
        (async () => {
            try {
                await getAllCompaniesList()
            } catch (error) {

            }
        })()
    }, [])


    // role list page ui code
    return (
        <div id="companyListPage" className='d-md-block' style={{ width: "100%" }}>
            <div className="row d-flex align-items-center mt-2 ml-3 mb-1 mr-2">
                <div className="col-lg-6 col-8 m-0 p-0">
                    <p className="h4 text-custom-grey">Company Detail Management</p>
                </div>
                <div className="col-lg-6 col-4 text-right m-0 p-0">
                    {allowedFunc.includes(configs.functionList["company_management:delete_or_restore_company"]) ?
                        <a href="#" className="custom-link" onClick={(e) => {
                            e.preventDefault()
                            setCompanyList("")
                            setShowDeletedCompanies(!showDeletedCompanies)
                        }}><i className={showDeletedCompanies ? "fas fa-user mr-2" : "fas fa-user-slash mr-2"}></i>{showDeletedCompanies ? "Active Companies" : "Deleted Companies"}</a>
                        : null}
                </div>
            </div>
            {allowedFunc.includes(configs.functionList['company_management:create_company']) || allowedFunc.includes(configs.functionList['company_parser:update_company_data']) ?
                <div className='row m-0 mx-3 p-0 d-md-none d-block'>
                    <a href='#' className='custom-link' onClick={(e) => {
                        e.preventDefault()
                        document.getElementById("rmCreateCompany").classList.toggle('d-none')
                        document.getElementById("companyListPage").classList.toggle('d-none')
                    }}><p className='m-0 p-0'>Create New Company</p></a>
                </div>
                : null}
            {companyList ?

                <div className="nm-role-table m-2">
                    <DataGrid
                        rows={companyList}
                        headerHeight={30}
                        rowHeight={40}
                        columns={columns}
                        resizable={true}
                        autoPageSize
                        pagination
                        components={{
                            Toolbar: GridToolbar,
                        }}

                    // onSelectionModelChange={onRowSelected}
                    />
                </div>
                : <div className="loader"></div>}
            <DeleteModalPage {...deleteId} />

            {/* --------------------------------------------Stock Summary Modal-------------------------------------------------------------------- */}
            <Modal open={stockSumModal} onClose={handleClose}>
                <div className="modal-fade" id="KYCSubmitModal" tabIndex="-1" role="dialog" aria-hidden="true">
                    <div className="modal-dialog modal-dialog-centered" role="document">
                        <div className="modal-content">
                            <div className="modal-header border-0">
                                <h5>Update Stock Summary</h5>
                                <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={handleClose}>
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div className="modal-body py-3" style={{ overflowY: "auto", height: "500px" }}>
                                {!addStock ? <div>
                                    <form id="createRoleForm" action="" className='mr-1'>
                                        <div className="form-group">
                                            <label className="f-12">Company Name</label>
                                            <input type="text" id="roleName" className="form-control f-10" disabled placeholder="Company Name" value={companyData.name} />
                                        </div>
                                        <div className="form-group">
                                            <label className="f-12">Message (Data as Per)</label>
                                            <input type="text" id="roleName" className="form-control f-10" placeholder="Message" value={message ? message : ""} required onChange={(e) => {
                                                setMessage(e.target.value)
                                            }} />
                                        </div>
                                        <div className="form-group">
                                            <label className="f-12">Face Value</label>
                                            <input type="text" id="roleName" className="form-control f-10" placeholder="Face Value" value={faceValue ? faceValue : ""} required onChange={(e) => {
                                                setFaceValue(e.target.value)
                                            }} />
                                        </div><div className="form-group">
                                            <label className="f-12">Book Value/share</label>
                                            <input type="text" id="roleName" className="form-control f-10" placeholder="Book Value/share" value={bookValue ? bookValue : ""} required onChange={(e) => {
                                                setBookValue(e.target.value)
                                            }} />
                                        </div><div className="form-group">
                                            <label className="f-12">No. of Shares</label>
                                            <input type="text" id="roleName" className="form-control f-10" placeholder="No. of shares" value={shares ? shares : ""} required onChange={(e) => {
                                                setShares(e.target.value)
                                            }} />
                                        </div><div className="form-group">
                                            <label className="f-12">EPS</label>
                                            <input type="text" id="roleName" className="form-control f-10" placeholder="EPS" value={EPS ? EPS : ""} required onChange={(e) => {
                                                setEPS(e.target.value)
                                            }} />
                                        </div><div className="form-group">
                                            <label className="f-12">Sales (in Cr)</label>
                                            <input type="text" id="roleName" className="form-control f-10" placeholder="Sales/share" value={sales ? sales : ""} required onChange={(e) => {
                                                setSales(e.target.value)
                                            }} />
                                        </div><div className="form-group">
                                            <label className="f-12">Industry PE</label>
                                            <input type="text" id="roleName" className="form-control f-10" placeholder="Industry PE" value={PE ? PE : ""} required onChange={(e) => {
                                                setPE(e.target.value)
                                            }} />
                                        </div><div className="form-group">
                                            <label className="f-12">Share Capital</label>
                                            <input type="text" id="roleName" className="form-control f-10" placeholder="Equity" value={Equity ? Equity : ""} required onChange={(e) => {
                                                setEquity(e.target.value)
                                            }} />
                                        </div><div className="form-group">
                                            <label className="f-12">PAT</label>
                                            <input type="text" id="roleName" className="form-control f-10" placeholder="PAT" value={PAT ? PAT : ""} required onChange={(e) => {
                                                setPAT(e.target.value)
                                            }} />
                                        </div><div className="form-group">
                                            <label className="f-12">Dividend</label>
                                            <input type="text" id="roleName" className="form-control f-10" placeholder="Dividend" value={dividend ? dividend : ""} required onChange={(e) => {
                                                setDividend(e.target.value)
                                            }} />
                                        </div>
                                    </form>
                                    <div className="form-check p-0">
                                        <p className="text-danger" id="createRoleError">{error}</p>
                                    </div>
                                    <button id="addStockBtn" className="btn btn-primary f-12" onClick={(e) => {
                                        e.preventDefault()
                                        addStockData()
                                    }}>Update Stock Summary</button>
                                    <div id="addStockBtnLoader" className='animBtn d-none'><div className='gSignIn-Anim'></div></div>
                                </div>
                                    : <div className="loader"></div>}
                            </div>
                        </div>
                    </div>
                </div>
            </Modal>
            <CompanyHighlightMgmt />
        </div>
    )
}