import { DataGrid, GridToolbar } from '@material-ui/data-grid';
import React, { useEffect, useState } from 'react';
import { configs, formatCurrency } from '../../../assets/Config';
import { useLogin } from '../../../services/contexts/AdminLoginContext';
import LeftBarPage from '../../includes/dashboard/LeftBarPage';
import TopBarPage from '../../includes/dashboard/TopBarPage';
import '../../../assets/styles/NM.css'
import { useNMContext } from '../../../services/contexts/NMContext';
import ToastPage from '../../includes/ToastPage';
import { Link } from 'react-router-dom';
import { useHistory } from 'react-router';
import { Modal } from '@material-ui/core';
import { useKYCContext } from '../../../services/contexts/KYCContext';

export default function ReferralMasterPage() {

    const { setCurrModule, allowedFunc, getAllReferralDetails } = useLogin()
    const { setKycRequesterUserDetail } = useKYCContext()

    const [tableData, setTableData] = useState("")

    const history = useHistory()


    useEffect(() => {
        (async () => {
            try {
                setCurrModule(configs.modules.referralMaster)
                if (allowedFunc.includes(configs.functionList['referral_management:referral_management'])) {
                    let res = await getAllReferralDetails()
                    if (res && res.success) {
                        setTableData(res.data)
                    }
                } else {
                    return history.goBack()
                }
            } catch (error) {

            }
        })()
    }, [])

    const columns = [{ field: "id", headerName: "ID", minWidth: 100, flex: 0.2, hide: true, filterable: false },
    {
        field: "name", headerName: "Name", minWidth: 150, flex: 0.5, cellClassName: "break-text f-12", valueGetter: (params) => {
            return params.row.user.name
        }
    },
    {
        field: "mobile", headerName: "Phone No.", minWidth: 120, flex: 0.3, cellClassName: "break-text f-12", renderCell: (params) => {
            return <a href={"https://wa.me/" + params.row.user.mobile} target="_blank">{params.row.user.mobile ? `+${params.row.user.mobile}` : params.row.user.mobile}</a>
        }, valueGetter: (value) => value.row.user.mobile ? `+${value.row.user.mobile}` : value.row.user.mobile
    },
    {
        field: "email", headerName: "E-mail", minWidth: 150, flex: 0.4, cellClassName: "break-text f-12", renderCell: (params) => {
            if (allowedFunc.includes(configs.functionList['user_management:load_user_details'])) {
                return <Link to={`/customerProfile/${params.row.user.id}`} onClick={() => {
                    setKycRequesterUserDetail(params.row.user)
                }}>{params.row.user.email}</Link>
            } else {
                return params.row.user.email
            }
        }, valueGetter: (value) => value.row.user.email
    },
    {
        field: "date", headerName: "Date", minWidth: 150, flex: 0.5, cellClassName: "break-text f-12", valueGetter: (value) => value.row.date
    },
    {
        field: "description", headerName: "Description", minWidth: 400, flex: 0.5, cellClassName: "break-text f-12", valueGetter: (value) => value.row.description
    },
    {
        field: "credit", headerName: "Credit", minWidth: 100, flex: 0.3, cellClassName: "break-text f-12", renderCell: (params) => {
            return formatCurrency(parseFloat(params.row.credit))
        }, valueGetter: (value) => value.row.credit
    },
    {
        field: "debit", headerName: "Debit", minWidth: 100, flex: 0.3, cellClassName: "break-text f-12", renderCell: (params) => {
            return formatCurrency(parseFloat(params.row.debit))
        }, valueGetter: (value) => value.row.debit
    },
    {
        field: "total_amount", headerName: "Total", minWidth: 100, flex: 0.3, cellClassName: "break-text f-12", renderCell: (params) => {
            return formatCurrency(parseFloat(params.row.total_amount))
        }, valueGetter: (value) => value.row.total_amount
    }
    ]


    // notification management main ui code
    return (
        <>
            <TopBarPage />
            <div style={{ display: "flex" }}>
                <LeftBarPage />
                <div id='nmContainer' className="nm-container">
                    <div style={{ width: "100%" }}>
                        <div className="row d-flex align-items-center mt-2 ml-2 mb-4 mr-3">
                            <div className="col-lg-6 text-left">
                                <p className="h4 text-custom-grey">Customer Referral Master</p>
                            </div>
                        </div>
                        <div className="nm-role-table m-2">
                            {tableData ? <DataGrid
                                rows={tableData}
                                headerHeight={40}
                                rowHeight={30}
                                columns={columns}
                                resizable={true}
                                autoPageSize
                                pagination
                                components={{
                                    Toolbar: GridToolbar,
                                }} />
                                : <div className="loader"></div>}
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}