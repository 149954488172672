import { Modal } from "@material-ui/core"
import { useEffect, useState } from "react"
import { configs, formatCurrency } from "../../../assets/Config"
import { useLogin } from "../../../services/contexts/AdminLoginContext"
import { useSellOrderContext } from "../../../services/contexts/SellOrdersContext"
import '../../../assets/styles/BuyOrder.css'
import ToastPage from "../../includes/ToastPage"
import { acknowSellOrder } from "../../../services/apis/shareManagement/SellOrdersApi"


export default function SellOrderModalPage() {

    const { getSellOrder, approvePrice, setApprovePrice, approveQuantity, setApproveQuantity, approveInstrument, setApproveInstrument, editModal, setEditModal, editSellOrderById, orderId, rejectModal, setRejectModal, rejectSellOrderById, updateRemark, setUpdateRemark, dematList, demat, setDemat, approveSellOrderById, completeSellOrderById, activeStatusSell, ackModal, setAckModal, acknowSellOrderById, confirmSellOrderShareTransferId, commentModal, setCommentModal, updateSellOrderComment, isDealerOrder, setIsDealerOrder } = useSellOrderContext()

    const { setShowSuccess, remarkComments } = useLogin()

    const [remark, setRemark] = useState("")
    const [dealerUTR, setDealerUTR] = useState("")
    const [status, setStatus] = useState("share_transfer_pending")
    const [error, setError] = useState("")

    // sell order update remark
    async function orderRemarkupdate(btnId) {
        try {
            document.getElementById(btnId).classList.toggle("d-none")
            document.getElementById("orderRemarkBtnLoader").classList.toggle("d-none")

            if (!remark.trim()) {
                setError("Please enter a valid remark to update status.")
            } else if (isDealerOrder && updateRemark === configs.functionList['sell_module:mark_order_as_completed'] && !dealerUTR) {
                setError("Please provide dealer UTR detail.")
            } else {
                let response;
                if (updateRemark === configs.functionList["sell_module:reject_sell_order"]) {
                    response = await rejectSellOrderById(orderId, remark)
                } else if (updateRemark === configs.functionList['sell_module:mark_order_as_completed']) {
                    response = await completeSellOrderById(orderId, remark, dealerUTR)
                } else if (updateRemark === configs.functionList["sell_module:transfer_share_on_users_behalf"]) {
                    response = await confirmSellOrderShareTransferId(orderId, remark)
                }
                if (response === "success") {
                    setError("")
                    setRemark("")
                    setUpdateRemark("")
                    setRejectModal(false)
                    setShowSuccess(true)
                    getSellOrder(configs.sellOrderStatusTypes[activeStatusSell].api)
                } else {
                    setError(response)
                }
            }

            document.getElementById(btnId).classList.toggle("d-none")
            document.getElementById("orderRemarkBtnLoader").classList.toggle("d-none")
        } catch (error) {

        }
    }

    // sell order approve
    async function sellOrderApprove(btnId) {
        try {

            document.getElementById(btnId).classList.toggle("d-none")
            document.getElementById("orderRemarkBtnLoader").classList.toggle("d-none")

            if (!demat) {
                setError("Please select a valid demat account.")
            } else {
                let response = await approveSellOrderById(orderId, demat)
                if (response === "success") {
                    setError("")
                    setRemark("")
                    setUpdateRemark("")
                    setRejectModal(false)
                    setShowSuccess(true)
                    setDemat("")
                    getSellOrder(configs.sellOrderStatusTypes[activeStatusSell].api)
                } else {
                    setError(response)
                }
            }

            document.getElementById(btnId).classList.toggle("d-none")
            document.getElementById("orderRemarkBtnLoader").classList.toggle("d-none")
        } catch (error) {
            console.error(error)
        }
    }

    // sell order update
    async function updateSellOrder() {
        try {
            document.getElementById("updateOrderBtn").classList.toggle("d-none")
            document.getElementById("updateOrderBtnLoader").classList.toggle("d-none")

            const re = /^[0-9]+$/;
            const red = /^[0-9]\d*(\.\d{1,2})?$/;
            if (!approveQuantity.toString().match(re)) {
                setError("Please enter a valid qunatity to update status.")
            } else if (!approvePrice.toString().match(red)) {
                setError("Please enter a valid price to update status.")
            } else {
                let response = await editSellOrderById(orderId, approveQuantity, approvePrice)
                if (response === "success") {
                    setError("")
                    setApproveQuantity("")
                    setApprovePrice("")
                    setEditModal(false)
                    setShowSuccess(true)
                    getSellOrder(configs.sellOrderStatusTypes[activeStatusSell].api)
                } else {
                    setError(response)
                }
            }

            document.getElementById("updateOrderBtn").classList.toggle("d-none")
            document.getElementById("updateOrderBtnLoader").classList.toggle("d-none")
        } catch (error) {
        }
    }

    // sell order acknowledge share transfer
    async function ackSellOrder() {
        try {
            document.getElementById("ackOrderBtn").classList.toggle("d-none")
            document.getElementById("ackOrderBtnLoader").classList.toggle("d-none")

            if (!remark.trim()) {
                setError("Please enter a valid remark to update status.")
            } else if (!status) {
                setError("Please select a valid acknowledgement status.")
            } else {
                let response = await acknowSellOrderById(orderId, remark, status)
                if (response === "success") {
                    setError("")
                    setRemark("")
                    setStatus("share_transfer_pending")
                    setApproveQuantity("")
                    setApprovePrice("")
                    setAckModal(false)
                    setShowSuccess(true)
                    getSellOrder(configs.sellOrderStatusTypes[activeStatusSell].api)
                } else {
                    setError(response)
                }
            }

            document.getElementById("ackOrderBtn").classList.toggle("d-none")
            document.getElementById("ackOrderBtnLoader").classList.toggle("d-none")
        } catch (error) {
        }
    }

    // sell order update comment
    async function updateComment() {
        try {
            document.getElementById("updateCommentBtn").classList.toggle("d-none")
            document.getElementById("updateCommentBtnLoader").classList.toggle("d-none")

            if (!remark.trim()) {
                setError("Please enter a valid comment to update.")
            } else {
                let response = await updateSellOrderComment(orderId, remark)
                if (response === "success") {
                    setError("")
                    setRemark("")
                    setCommentModal(false)
                    setShowSuccess(true)
                    getSellOrder(configs.sellOrderStatusTypes[activeStatusSell].api)
                } else {
                    setError(response)
                }
            }

            document.getElementById("updateCommentBtn").classList.toggle("d-none")
            document.getElementById("updateCommentBtnLoader").classList.toggle("d-none")
        } catch (error) {
            console.error(error);
        }
    }

    const handleClose = () => {
        setRejectModal(false)
        setEditModal(false)
        setAckModal(false)
        setCommentModal(false)
        setRemark("")
        setStatus("share_transfer_pending")
        setApprovePrice("")
        setApproveQuantity("")
        setError("")
        setUpdateRemark("")
        setDemat("")
    }

    return (
        <>

            {/* // ------------------------sell order reject modal------------------------------- */}
            <Modal open={rejectModal} onClose={handleClose} style={{ overflow: "scroll" }}>
                <div className="modal-fade" id="exampleModal" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div className="modal-dialog" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="text-custom-grey h6" id="exampleModalLabel">{updateRemark === configs.functionList["sell_module:approve_order_and_set_status_to_share_transfer_pending"] ? "Approve" : updateRemark === configs.functionList['sell_module:mark_order_as_completed'] ? "Complete" : "Reject"} Order - {orderId}</h5>
                                <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={(e) => {
                                    e.preventDefault()
                                    handleClose()
                                }}>
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div className="modal-body">
                                <form action="">
                                    <div className="form-group">
                                        <label>Instrument - <span className="text-custom-grey">{approveInstrument}</span></label>
                                    </div>
                                </form>
                                {updateRemark !== configs.functionList["sell_module:approve_order_and_set_status_to_share_transfer_pending"] ?
                                    <form action="">
                                        <div className="form-group my-1">
                                            <label>Remark</label>
                                            <select name="" id="" className="col-12 py-1 px-2 border rounded" value={remark ? remark : "0"} onChange={(e) => {
                                                setRemark(e.target.value)
                                            }}>
                                                <option value="0"> -- Select a Remark -- </option>
                                                {remarkComments && remarkComments.templates ? remarkComments.templates.map((element, key) => {
                                                    return (
                                                        <option key={`sellorderrejectremark` + key} value={element.template}>{element.template}</option>
                                                    )
                                                }) : null}
                                            </select>
                                        </div>
                                        <div className="form-group">
                                            <textarea className="form-control" placeholder="Please write a remark or choose from above" value={remark ? remark : ""} onChange={(e) => {
                                                setRemark(e.target.value)
                                            }} />
                                        </div>
                                        {isDealerOrder && updateRemark === configs.functionList['sell_module:mark_order_as_completed'] ?
                                            <div className="form-group">
                                                <input type="text" className="form-control" placeholder="Dealer Transaction UTR" value={dealerUTR ? dealerUTR : ""} onChange={(e) => {
                                                    setDealerUTR(e.target.value)
                                                }} />
                                            </div> : null}
                                    </form>
                                    :
                                    <form action="">
                                        <div className="form-group">
                                            <label>Demat Account</label>
                                            <select className="form-control" placeholder="Select Demat Account" value={demat ? demat : ""} onChange={(e) => {
                                                setDemat(e.target.value)
                                            }} >
                                                {dematList ? dematList.map((element, key) => {
                                                    return <option key={element.id} value={element.id}>{element.depository_service} - {element.client_id} - {element.dp_id}</option>

                                                }) : null}
                                            </select>
                                        </div>
                                    </form>
                                }
                                <div className="form-check p-0">
                                    <p className="text-danger" id="remarkmodalError">{error}</p>
                                </div>
                            </div>
                            <div className="modal-footer justify-content-center">
                                {updateRemark === configs.functionList["sell_module:approve_order_and_set_status_to_share_transfer_pending"] ?
                                    <button id="orderRemarkBtnapprove" type="button" className="btn btn-primary" onClick={(e) => {
                                        e.preventDefault()
                                        sellOrderApprove("orderRemarkBtnapprove")
                                    }}>Approve</button>
                                    : updateRemark === configs.functionList["sell_module:mark_order_as_completed"] ?
                                        <button id="orderRemarkBtncomplete" type="button" className="btn btn-success" onClick={(e) => {
                                            e.preventDefault()
                                            orderRemarkupdate("orderRemarkBtncomplete")
                                        }}>Complete</button>
                                        : updateRemark === configs.functionList["sell_module:transfer_share_on_users_behalf"] ?
                                            <button id="orderRemarkBtntransfer" type="button" className="btn btn-primary" onClick={(e) => {
                                                e.preventDefault()
                                                orderRemarkupdate("orderRemarkBtntransfer")
                                            }}>Confirm Share Transfer</button>
                                            :
                                            <button id="orderRemarkBtnreject" type="button" className="btn btn-danger" onClick={(e) => {
                                                e.preventDefault()
                                                orderRemarkupdate("orderRemarkBtnreject")
                                            }}>Reject</button>

                                }
                                <div id="orderRemarkBtnLoader" className='animBtn d-none'><div className='gSignIn-Anim'></div></div>
                            </div>
                        </div>
                    </div>
                </div>
            </Modal>

            {/* // ------------------------sell order approve modal------------------------------- */}
            <Modal open={editModal} onClose={handleClose} style={{ overflow: "scroll" }}>
                <div className="modal-fade" id="exampleModal" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div className="modal-dialog" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="text-custom-grey h6" id="exampleModalLabel">Review Order - {orderId}</h5>
                                <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={(e) => {
                                    e.preventDefault()
                                    handleClose()
                                }}>
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div className="modal-body">
                                <form action="">
                                    <div className="form-group">
                                        <label>Instrument - <span className="text-custom-grey">{approveInstrument}</span></label>
                                    </div>
                                </form>
                                <form action="">
                                    <div className="form-group">
                                        <label>Quantity</label>
                                        <input className="form-control" placeholder="Quantity" value={approveQuantity} onChange={(e) => {
                                            setApproveQuantity(e.target.value)
                                        }} />
                                    </div>
                                    <div className="form-group">
                                        <label>Price/Share</label>
                                        <input className="form-control" placeholder="Price/Share" value={approvePrice} onChange={(e) => {
                                            setApprovePrice(e.target.value)
                                        }} />
                                    </div>
                                </form>
                                <div className="form-check p-0">
                                    <p className="text-danger" id="approveSellOrderError">{error}</p>
                                </div>
                            </div>
                            <div className="modal-footer justify-content-center">
                                <button id="updateOrderBtn" type="button" className="btn btn-primary" onClick={(e) => {
                                    e.preventDefault()
                                    updateSellOrder()
                                }}>Submit</button>
                                <div id="updateOrderBtnLoader" className='animBtn d-none'><div className='gSignIn-Anim'></div></div>
                            </div>
                        </div>
                    </div>
                </div>
            </Modal>

            {/* // ------------------------sell order acknowledge modal------------------------------- */}
            <Modal open={ackModal} onClose={handleClose} style={{ overflow: "scroll" }}>
                <div className="modal-fade" id="exampleModal" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div className="modal-dialog" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="text-custom-grey h6" id="exampleModalLabel">Acknowledge Order - {orderId}</h5>
                                <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={(e) => {
                                    e.preventDefault()
                                    handleClose()
                                }}>
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div className="modal-body">
                                <form action="">
                                    <div className="form-group">
                                        <label>Instrument - <span className="text-custom-grey">{approveInstrument}</span></label>
                                    </div>
                                </form>

                                <form action="">
                                    <div className="form-group">
                                        <label>Remark</label>
                                        <textarea className="form-control" placeholder="Remark" value={remark ? remark : ""} onChange={(e) => {
                                            setRemark(e.target.value)
                                        }} />
                                    </div>
                                </form>

                                <form action="">
                                    <div className="form-group">
                                        <label>Status</label>
                                        <select className="form-control" placeholder="Select Status" value={status ? status : ""} onChange={(e) => {
                                            setStatus(e.target.value)
                                        }} >
                                            <option value="share_transfer_pending">Share Transfer Pending</option>
                                            <option value="share_transfer_acknowledged">Share Transfer Acknowledged</option>
                                        </select>
                                    </div>
                                </form>

                                <div className="form-check p-0">
                                    <p className="text-danger" id="remarkmodalError">{error}</p>
                                </div>
                            </div>
                            <div className="modal-footer justify-content-center">
                                <button id="ackOrderBtn" type="button" className="btn btn-primary" onClick={(e) => {
                                    e.preventDefault()
                                    ackSellOrder()
                                }}>Acknowledge</button>
                                <div id="ackOrderBtnLoader" className='animBtn d-none'><div className='gSignIn-Anim'></div></div>
                            </div>
                        </div>
                    </div>
                </div>
            </Modal>

            {/* // ------------------------sell order update comment modal------------------------------- */}
            <Modal open={commentModal} onClose={handleClose} style={{ overflow: "scroll" }}>
                <div className="modal-fade" id="exampleModal" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div className="modal-dialog" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="text-custom-grey h6" id="exampleModalLabel">Update Comment - {orderId}</h5>
                                <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={(e) => {
                                    e.preventDefault()
                                    handleClose()
                                }}>
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div className="modal-body">

                                <form action="">
                                    <div className="form-group">
                                        <label>Comment</label>
                                        <textarea className="form-control" placeholder="Comment" value={remark ? remark : ""} onChange={(e) => {
                                            setRemark(e.target.value)
                                        }} />
                                    </div>
                                </form>

                                <div className="form-check p-0">
                                    <p className="text-danger" id="remarkmodalError">{error}</p>
                                </div>
                            </div>
                            <div className="modal-footer justify-content-center">
                                <button id="updateCommentBtn" type="button" className="btn btn-primary" onClick={(e) => {
                                    e.preventDefault()
                                    updateComment()
                                }}>Update Comment</button>
                                <div id="updateCommentBtnLoader" className='animBtn d-none'><div className='gSignIn-Anim'></div></div>
                            </div>
                        </div>
                    </div>
                </div>
            </Modal>

            <ToastPage />
        </>
    )
}