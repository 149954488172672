import { Modal, TextField } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { configs } from '../../../assets/Config';
import '../../../assets/styles/RM.css'
import { useLogin } from '../../../services/contexts/AdminLoginContext';
import { useCMContext } from '../../../services/contexts/companyManagement/CMContext';
import { useNewsContext } from '../../../services/contexts/companyManagement/NewsContext';

export default function CreateNewsPage() {

    const { setShowSuccess, token, logout, setLoginError } = useLogin()
    const { editNews, setEditNews, editNewsData, setEditNewsData, createEditNews, getAllNewsList } = useNewsContext()
    const { companyList } = useCMContext()

    const [company, setCompany] = useState({})
    const [heading, setHeading] = useState("")
    const [description, setDescription] = useState("")
    const [link, setLink] = useState("")
    const [date, setDate] = useState("")
    const [image, setImage] = useState("")
    const [error, setError] = useState("")

    const history = useHistory()

    useEffect(() => {
        try {
            if (editNewsData) {
                setHeading(editNewsData.news_heading)
                setDescription(editNewsData.news_description)
                setLink(editNewsData.news_link)
                setDate(editNewsData.news_date)
                setImage(editNewsData.news_image)
                setError("")
            }

        } catch (error) {
            console.error(error)

        }
    }, [editNewsData])


    const handleCompanyChange = (e, value) => {
        try {
            setCompany(value)
        } catch (error) {

        }
    }


    // add company function
    async function addNews() {
        try {

            document.getElementById("newsBtn").classList.toggle("d-none")
            document.getElementById("newsBtnLoader").classList.toggle("d-none")

            if (!editNews && !company) {
                setError("Please select a valid company.")
            } else if (!heading || (heading && heading.length > 500)) {
                setError("Please provide a valid heading.")
            } else if (!description) {
                setError("Please provide a valid description.")
            } else if (!link) {
                setError("Please provide a valid link.")
            } else if (!date) {
                setError("Please provide a valid date.")
            } else if (!image) {
                setError("Please provide a valid image URL.")
            } else {
                let response = await createEditNews(company.id, editNewsData.id, link, heading, description, image, date)

                if (response && response === "success") {
                    resetValues()
                    setShowSuccess(true)
                    getAllNewsList()
                } else {
                    setError(response)
                }
            }

            document.getElementById("newsBtn").classList.toggle("d-none")
            document.getElementById("newsBtnLoader").classList.toggle("d-none")
        } catch (error) {
        }
    }

    // reset all values
    function resetValues() {
        try {
            setCompany({})
            setHeading("")
            setDescription("")
            setLink("")
            setDate("")
            setImage("")
            setEditNews(false)
            setError("")
            setEditNewsData({})
        } catch (error) {

        }
    }

    // create role ui code
    return (
        <div id="rmCreateNews" className="col-lg-3 col-md-4 d-md-block d-none pt-3 main-rmPanel">
            <p className="h6 text-custom-grey">{editNews ? "Edit News Information" : "Add a News"}</p>
            <form id="createRoleForm" action="" className='mr-1'>
                {!editNews ? <div className="form-group">
                    <label className="f-12">Company</label>
                    {companyList ? <Autocomplete
                        className="f-12"
                        id="companyIP"
                        value={company}
                        placeholder="Compnay Name"
                        getOptionLabel={option => option.name}
                        onChange={handleCompanyChange}
                        options={companyList}
                        renderInput={(params) => <TextField {...params} placeholder="Company Name" className="f-12" />}
                    /> : null}
                </div> : null}
                <div className="form-group">
                    <label className="f-12">News Heading</label>
                    <input type="text" id="roleName" className="form-control f-10" placeholder="News Heading" value={heading} required onChange={(e) => {
                        setHeading(e.target.value)
                    }} />
                </div>
                <div className="form-group">
                    <label className="f-12">Description</label>
                    <textarea id="roleName" className="form-control f-10" placeholder="Description" value={description} required onChange={(e) => {
                        setDescription(e.target.value)
                    }} />
                </div><div className="form-group">
                    <label className="f-12">Link</label>
                    <input type="text" id="roleName" className="form-control f-10" placeholder="Link" value={link} required onChange={(e) => {
                        setLink(e.target.value)
                    }} />
                </div><div className="form-group">

                    <label className="f-12">Date</label>
                    <input type="date" id="newsDate" className="form-control f-10" placeholder="Date" value={date} required onChange={(e) => {
                        if (new Date(e.target.value).getTime() <= new Date().getTime())
                            setDate(e.target.value)
                        else
                            e.preventDefault()
                    }} />
                </div><div className="form-group">
                    <label className="f-12">Image</label>
                    <input type="text" id="roleName" className="form-control f-10" placeholder="Image" value={image} required onChange={(e) => {
                        setImage(e.target.value)
                    }} />
                    {image ? <img src={image} style={{ height: "200px", width: "200px" }} /> : null}
                </div>
            </form>
            <div className="form-check p-0">
                <p className="text-danger" id="createRoleError">{error}</p>
            </div>
            <div className='row m-0 p-0'>
                <div className='col-9 m-0 p-0'>
                    <button id="newsBtn" className="btn btn-primary f-12" onClick={(e) => {
                        e.preventDefault()
                        addNews()
                    }}>{editNews ? "Update News" : "Add News"}</button>
                    <div id="newsBtnLoader" className='animBtn d-none'><div className='gSignIn-Anim'></div></div>
                </div>
                <div className='col-3 m-0 p-0'>
                    <button className="btn btn-primary f-12" onClick={(e) => {
                        e.preventDefault()
                        resetValues()
                    }}>Reset</button>
                </div>
            </div>
            <div className='row m-0 p-0'>
                <button className="btn btn-primary mt-3 d-md-none" onClick={(e) => {
                    e.preventDefault()
                    document.getElementById("rmCreateNews").classList.toggle('d-none')
                    document.getElementById("newsListPage").classList.toggle('d-none')
                    history.push('/companyManagement/newsDetail')
                }}>View All News</button>
            </div>
        </div>
    )
}