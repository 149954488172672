import React from 'react';
import { createContext, useContext, useState } from 'react';
import { configs } from '../../../assets/Config';
import { createUpdatecoupon, deletecoupon, getAllcoupons, getcouponById, restorecoupon } from '../../apis/couponManagement/CouponManagementApi';
import { useLogin } from '../AdminLoginContext';

// create global coupon management context
export const CouponContext = createContext();

export function CouponProvider({ children }) {

    const { token, logout, setLoginError } = useLogin();

    const [couponList, setcouponList] = useState("")
    const [editcoupon, setEditcoupon] = useState(false)
    const [editcouponData, setEditcouponData] = useState({})
    const [showDeletedcoupon, setShowDeletedcoupon] = useState(false)
    const [showRTPage, setShowRTPage] = useState(false)
    const [couponsnapshot, setcouponsnapshot] = useState("")
    const [couponkeyHigh, setcouponkeyHigh] = useState("")
    const [coupondeal, setcoupondeal] = useState("")
    const [couponterms, setcouponterms] = useState("")
    const [couponfaq, setcouponfaq] = useState("")
    const [coupondoc, setcoupondoc] = useState("")

    // get all coupon
    async function getAllcouponList(getDeleted) {
        try {
            let response = await getAllcoupons(token, getDeleted);
            if (response && response.status === 200) {
                setcouponList(response.data)
            } else if (response && response.status === 401) {
                logout()
                setLoginError(configs.unauthorizedErrorMessage)
            } else {
                setcouponList("")
            }
        } catch (error) {

        }
    }

    // get coupon data by id
    async function getcouponDataById(couponId) {
        try {
            let response = await getcouponById(token, couponId);
            if (response && response.status === 200) {
                let data = response.data
                setEditcouponData({
                    ...editcouponData,
                    ...data
                })
            } else if (response && response.status === 401) {
                logout()
                setLoginError(configs.unauthorizedErrorMessage)
            } else { }
        } catch (error) {

        }
    }

    // create or update coupon
    async function createEditcoupon(code, discount, max_discount, expiry_date, utm, company_id, asset_class, first_order, editcoupon, couponId) {
        try {
            let response = await createUpdatecoupon(token, code, discount, max_discount, expiry_date, utm, company_id, asset_class, first_order, editcoupon, couponId);
            if (response && response.status === 200) {
                return "success"
            } else if (response && response.status === 401) {
                logout()
                setLoginError(configs.unauthorizedErrorMessage)
            } else {
                return response.data.error
            }
        } catch (error) {
            console.error(error)
        }
    }

    // delete coupon
    async function deletecouponById(couponId) {
        try {
            let response = await deletecoupon(token, couponId);
            if (response && response.status === 200) {
                return "success"
            } else if (response && response.status === 401) {
                logout()
                setLoginError(configs.unauthorizedErrorMessage)
            } else {
                return response.data.error
            }
        } catch (error) {

        }
    }

    // restore coupon
    async function restorecouponById(couponId) {
        try {
            let response = await restorecoupon(token, couponId);
            if (response && response.status === 200) {
                return "success"
            } else if (response && response.status === 401) {
                logout()
                setLoginError(configs.unauthorizedErrorMessage)
            } else {
                return response.data.error
            }
        } catch (error) {

        }
    }

    // export global context
    return (
        <CouponContext.Provider
            value={{
                couponList,
                setcouponList,
                getAllcouponList,
                deletecouponById,
                restorecouponById,
                editcoupon,
                setEditcoupon,
                editcouponData,
                setEditcouponData,
                getcouponDataById,
                createEditcoupon,
                showDeletedcoupon,
                setShowDeletedcoupon,
                showRTPage,
                setShowRTPage,
                couponsnapshot,
                setcouponsnapshot,
                couponkeyHigh,
                setcouponkeyHigh,
                coupondeal,
                setcoupondeal,
                couponterms,
                setcouponterms,
                couponfaq,
                setcouponfaq,
                coupondoc,
                setcoupondoc
            }}
        >
            {children}
        </CouponContext.Provider>
    );
}

// return coupon management context
export function useCouponContext() {
    return useContext(CouponContext);
}