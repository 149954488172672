import React from 'react';
import { createContext, useContext, useState } from 'react';
import { configs } from '../../../assets/Config';
import { createUpdateNews, deleteNews, getAllNews, restoreNews } from '../../apis/companyManagement/NewsApi';
import { useLogin } from '../AdminLoginContext';

// create global news management context
export const NewsContext = createContext();

export function NewsProvider({ children }) {

    const { token, logout, setLoginError } = useLogin();

    const [newsList, setNewsList] = useState("")
    const [editNews, setEditNews] = useState(false)
    const [editNewsData, setEditNewsData] = useState({})
    const [showDeletedNews, setShowDeletedNews] = useState(false)

    // get all companies
    async function getAllNewsList(getDeleted) {
        try {
            let response = await getAllNews(token, getDeleted);
            if (response && response.status === 200) {
                setNewsList(response.data)
            } else if (response && response.status === 401) {
                logout()
                setLoginError(configs.unauthorizedErrorMessage)
            } else {
                setNewsList("")
            }
        } catch (error) {

        }
    }

    // create or update news
    async function createEditNews(companyId, newsId, news_link, news_heading, news_description, news_image, news_date) {
        try {
            let response = await createUpdateNews(token, companyId, newsId, news_link, news_heading, news_description, news_image, news_date, editNews);
            if (response && response.status === 200) {
                return "success"
            } else if (response && response.status === 401) {
                logout()
                setLoginError(configs.unauthorizedErrorMessage)
            } else {
                return response.data.error
            }
        } catch (error) {

        }
    }

    // delete news
    async function deleteNewsById(newsId) {
        try {
            let response = await deleteNews(token, newsId);
            if (response && response.status === 200) {
                return "success"
            } else if (response && response.status === 401) {
                logout()
                setLoginError(configs.unauthorizedErrorMessage)
            } else {
                return response.data.error
            }
        } catch (error) {

        }
    }

    // restore news
    async function restoreNewsById(newsId) {
        try {
            let response = await restoreNews(token, newsId);
            if (response && response.status === 200) {
                return "success"
            } else if (response && response.status === 401) {
                logout()
                setLoginError(configs.unauthorizedErrorMessage)
            } else {
                return response.data.error
            }
        } catch (error) {

        }
    }
    // export global context
    return (
        <NewsContext.Provider
            value={{
                newsList,
                setNewsList,
                getAllNewsList,
                deleteNewsById,
                restoreNewsById,
                editNews,
                setEditNews,
                editNewsData,
                setEditNewsData,
                createEditNews,
                showDeletedNews,
                setShowDeletedNews,
            }}
        >
            {children}
        </NewsContext.Provider>
    );
}

// return news management context
export function useNewsContext() {
    return useContext(NewsContext);
}