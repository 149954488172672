import React, { useEffect, useState } from 'react';
import '../../../assets/styles/KYC.css';
import {
    DataGrid, GridToolbar, GridToolbarContainer,
    GridToolbarExport,
} from '@material-ui/data-grid';
import { useKYCContext } from '../../../services/contexts/KYCContext';
import { configs } from '../../../assets/Config';
import { Link } from 'react-router-dom';
import { useLogin } from '../../../services/contexts/AdminLoginContext';
import { lendboxGetUsers } from '../../../services/apis/LendboxApi';
import { useHistory } from 'react-router-dom';
import TopBarPage from '../../includes/dashboard/TopBarPage';
import LeftBarPage from '../../includes/dashboard/LeftBarPage';


export default function LendboxKYCPage() {

    const { setCurrModule, allowedFunc, token, logout } = useLogin()
    const { setKycRequesterUserDetail } = useKYCContext()
    const [data, setData] = useState("")

    const history = useHistory()

    useEffect(() => {
        if (allowedFunc.includes(configs.functionList['lendbox_data:lendbox_data'])) {
            (async () => {
                try {
                    setCurrModule(configs.modules.lbkyc)
                    let response = await lendboxGetUsers(token)
                    if (response && response.status === 200) {
                        setData(response.data.data)
                    } else if (response && response.status === 401) {
                        logout()
                    }
                } catch (error) {
                    console.error(error);
                }
            })()
        } else {
            return history.goBack()
        }
    }, [])


    // declare table rows
    const [columns, setColumns] = useState([
        { field: "id", headerName: "User ID", minWidth: 100, flex: 0.5, hide: true, filterable: false },
        {
            field: "email", headerName: "E-mail", minWidth: 150, flex: 0.4, cellClassName: "break-text f-12", renderCell: (params) => {
                if (allowedFunc.includes(configs.functionList['user_management:load_user_details'])) {
                    return <Link to={`/customerProfile/${params.row.id}`} onClick={() => {
                        setKycRequesterUserDetail(params.row)
                    }}>{params.row.email}</Link>
                } else {
                    return params.row.email
                }
            }, valueGetter: (value) => value.row.email
        },
        { field: "name", headerName: "Name", minWidth: 100, flex: 0.4, cellClassName: "break-text" },
        {
            field: "mobile", headerName: "Phone No.", minWidth: 100, flex: 0.3, cellClassName: "break-text", renderCell: (params) => {
                return <a href={"https://wa.me/" + params.row.mobile} target="_blank">{params.row.mobile ? `+${params.row.mobile}` : params.row.mobile}</a>
            }, valueGetter: (value) => value.row.mobile ? `+${value.row.mobile}` : value.row.mobile
        },
        {
            field: "kyc_status", headerName: "KYC Status", minWidth: 100, flex: 0.3, cellClassName: "break-text", renderCell: (params) => {
                return params.row.kyc_status ? "Verified" : "Pending"
            }, valueGetter: (params) => params.row.kyc_status ? "Verified" : "Pending"
        },
        { field: "dob", headerName: "Date of Birth", minWidth: 100, flex: 0.5 },
        { field: "fathers_name", headerName: "Father Name", minWidth: 100, flex: 0.5 },
        { field: "gender", headerName: "Gender", minWidth: 100, flex: 0.5 },
    ])

    // react data table export option
    function CustomToolbar() {
        return (
            <GridToolbarContainer>
                <GridToolbarExport />
            </GridToolbarContainer>
        );
    }

    // admin kyc requesters basic details ui code
    return (

        <>
            <TopBarPage />
            <div style={{ display: "flex" }}>
                <LeftBarPage />
                <div id='kycContainer' className="kyc-container kyc-main">
                    <div className="container-fluid">
                        <div className="row position-relative px-2">
                            <p className="text-custom-grey h3 mt-2 ml-3">Lendbox Users</p>
                            <div className="kyc-table">
                                {data ? <DataGrid
                                    rows={data}
                                    columns={columns}
                                    resizable={true}
                                    autoPageSize
                                    rowHeight={40}
                                    headerHeight={30}
                                    pagination
                                    components={{
                                        Toolbar: GridToolbar,
                                    }}
                                />
                                    : <div className="loader"></div>}
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </>


    )
}