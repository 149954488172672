import { DataGrid, GridToolbar } from '@material-ui/data-grid';
import React, { useEffect, useState } from 'react';
import { configs } from '../../../assets/Config';
import { useLogin } from '../../../services/contexts/AdminLoginContext';
import LeftBarPage from '../../includes/dashboard/LeftBarPage';
import TopBarPage from '../../includes/dashboard/TopBarPage';
import '../../../assets/styles/NM.css'
import { useNMContext } from '../../../services/contexts/NMContext';
import ToastPage from '../../includes/ToastPage';
import { Link } from 'react-router-dom';
import { useHistory, useParams } from 'react-router';
import { useKYCContext } from '../../../services/contexts/KYCContext';
import { Autocomplete } from '@material-ui/lab';
import { TextField } from '@material-ui/core';

export default function WhatsAppMessagePage() {

    const { whatsappMessages, whatsappResponseMessages, changeMessageStatus } = useNMContext()
    const { setCurrModule, setShowSuccess, allowedFunc } = useLogin()
    const { setShowError } = useKYCContext()

    const history = useHistory()

    useEffect(() => {
        try {
            setCurrModule(configs.modules.whatsappMessage)
            if (allowedFunc.includes(configs.functionList['whatsapp:whatsapp'])) {
                (async () => {
                    await whatsappResponseMessages()
                })()
            } else {
                return history.goBack()
            }
        } catch (error) {
            console.error(error)
        }

    }, [])

    const historyColumns = [
        { field: "id", headerName: "ID", minWidth: 100, flex: 0.5, hide: true, filterable: false },
        { field: "name", headerName: "Name", minWidth: 150, flex: 0.3, cellClassName: "break-text" },
        {
            field: "from", headerName: "Message From", minWidth: 100, flex: 0.3, cellClassName: "break-text", renderCell: (params) => {
                return params.row.from ? <a href={"https://wa.me/" + params.row.from} target="_blank">+{params.row.from}</a> : ""
            }, valueGetter: (value) => value.row.from ? `+${value.row.from}` : value.row.from
        },
        { field: "message", headerName: "Message", minWidth: 400, flex: 0.8, cellClassName: "break-text" },
        { field: "datetime", headerName: "Timestamp", minWidth: 50, flex: 0.2, cellClassName: "break-text" },
        {
            field: "is_msg_responded", headerName: "Message Responded", minWidth: 50, flex: 0.2, cellClassName: "break-text", renderCell: (params) => {
                return <input type="checkbox" id="ben_add" name="ben_add" checked={params.row.is_msg_responded} onChange={async (e) => {
                    e.preventDefault();
                    let response = await changeMessageStatus(params.row.id, e.target.checked);
                    if (response) {
                        whatsappResponseMessages();
                        setShowSuccess(true);
                    } else {
                        setShowError(true);
                    }
                }} />
            }, valueGetter: (params) => params.row.is_msg_responded ? "Yes" : "No"
        },
        {
            field: "responded_by", headerName: "Responded By", minWidth: 100, flex: 0.3, cellClassName: "break-text", renderCell: (params) => {
                return params.row.responded_by.name ? params.row.responded_by.name : ""
            }, valueGetter: (params) => params.row.responded_by.name ? params.row.responded_by.name : ""
        }
    ]


    // notification management main ui code
    return (
        <>
            <TopBarPage />
            <div style={{ display: "flex" }}>
                <LeftBarPage />
                <div id='nmContainer' className="nm-container">
                    <div style={{ width: "100%" }}>
                        <div className="row d-flex align-items-center mt-2 ml-2 mb-2 mr-3">
                            <div className="col-md-9 text-left">
                                <p className="h4 text-custom-grey">WhatsApp Responses</p>
                            </div>
                        </div>
                        {whatsappMessages ?
                            <div className=" nm-role-table">
                                <DataGrid
                                    rows={whatsappMessages}
                                    headerHeight={40}
                                    rowHeight={40}
                                    columns={historyColumns}
                                    resizable={true}
                                    autoPageSize
                                    pagination
                                    components={{
                                        Toolbar: GridToolbar,
                                    }} />
                            </div>
                            : <div className="loader"></div>}
                    </div>
                </div>
            </div>
            <ToastPage />
        </>
    )
}