import { DataGrid, GridToolbar } from '@material-ui/data-grid';
import React, { useEffect, useState } from 'react';
import { configs } from '../../../assets/Config';
import '../../../assets/styles/RM.css'
import { useLogin } from '../../../services/contexts/AdminLoginContext';
import { useRMContext } from '../../../services/contexts/RMContext';
import ConfirmModalPage from '../../includes/ConfirmModalPage';

export default function DeletedRolePage() {
    const { showDeletedRoles, setShowDeletedRoles, deletedRoles, setDeletedRoles, getDeletedRoleList } = useRMContext();
    const { setShowConfirm, showConfirm } = useLogin()

    const [confirmId, setConfirmId] = useState({})

    useEffect(() => {
        (async () => {
            try {
                await getDeletedRoleList()
            } catch (error) {

            }
        })()
    })

    const columns = [{ field: "id", headerName: "User ID", minWidth: 100, flex: 0.5, hide: true, filterable: false },
    { field: "name", headerName: "Name", minWidth: 100, flex: 0.4, cellClassName: "break-text" },
    { field: "description", headerName: "Description", minWidth: 100, flex: 0.5, cellClassName: "break-text" },
    { field: "restore", headerName: " ", minWidth: 50, flex: 0.1, cellClassName: "break-text", cellClassName: "text-primary" }]

    // role list page ui code
    return (
        <div style={{ width: "100%" }}>
            <div className="row d-flex align-items-center mt-2 ml-3 mb-1 mr-2">
                <div className="col-lg-6 col-7">
                    <p className="h4 text-custom-grey">Deleted Roles</p>
                </div>
                <div className="col-lg-6 col-5 text-right">
                    <a href="#" className="custom-link" onClick={(e) => {
                        e.preventDefault()
                        setShowDeletedRoles(!showDeletedRoles)
                    }}><i className={showDeletedRoles ? "fas fa-user mr-2" : "fas fa-user-slash mr-2"}></i>{showDeletedRoles ? "Active Roles" : "Deleted roles"}</a>
                </div>
            </div>
            <div className=" deleted-role-table">
                {deletedRoles ? <DataGrid
                    rows={deletedRoles}
                    headerHeight={40}
                    rowHeight={30}
                    columns={columns}
                    resizable={true}
                    autoPageSize
                    pagination
                    components={{
                        Toolbar: GridToolbar,
                    }}
                    onCellClick={(params, event) => {
                        event.defaultMuiPrevented = true;
                        if (params.value === "Restore") {
                            setShowConfirm(true)
                            setConfirmId({
                                "category": configs.modules.rm,
                                "type": "Role",
                                "name": params.row.name,
                                "id": params.row.id
                            })
                        }
                    }} /> : <div className="loader"></div>}
            </div>
            <ConfirmModalPage {...confirmId} />

        </div>
    )
}