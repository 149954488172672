import axios from "axios";
import { configs } from "../../../assets/Config";

// get order list by order type
export function getSellsOrderList(token, type) {
    return new Promise(async (resolve, reject) => {
        try {

            var config = {
                method: 'get',
                url: `${process.env.REACT_APP_BASEURL}/admin/sell/orders/${type}`,
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            };

            let res = await axios(config)
            resolve(res)
        } catch (err) {
            resolve(err?.response)
        }
    })
}

// get demat account list
export function getDematAccList(token) {
    return new Promise(async (resolve, reject) => {
        try {

            var config = {
                method: 'get',
                url: `${process.env.REACT_APP_BASEURL}/admin/sell/loadDematAccount`,
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            };

            let res = await axios(config)
            resolve(res)
        } catch (err) {
            resolve(err?.response)
        }
    })
}

// reject sell order 
export function rejectSellOrder(token, orderId, remark) {
    return new Promise(async (resolve, reject) => {
        try {
            var data = {
                "order_id": orderId,
                "customer_remark": remark
            }
            var config = {
                method: 'post',
                url: `${process.env.REACT_APP_BASEURL}/admin/sell/rejectUserOrder`,
                headers: {
                    "Authorization": `Bearer ${token}`,
                    'Content-Type': 'application/json'
                },
                data: data
            };

            let res = await axios(config)
            resolve(res)
        } catch (err) {
            resolve(err?.response)
        }
    })
}

// edit sell order
export function editSellOrder(token, orderId, quantity, price) {
    return new Promise(async (resolve, reject) => {
        try {
            var data = {
                "order_id": orderId,
                "quantity": quantity,
                "price_per_share": price
            }
            var config = {
                method: 'post',
                url: `${process.env.REACT_APP_BASEURL}/admin/sell/updateGeneratedOrders`,
                headers: {
                    "Authorization": `Bearer ${token}`,
                    'Content-Type': 'application/json'
                },
                data: data
            };

            let res = await axios(config)
            resolve(res)
        } catch (err) {
            resolve(err?.response)
        }
    })
}

// approve sell order 
export function approveSellOrder(token, orderId, dematId) {
    return new Promise(async (resolve, reject) => {
        try {
            var data = {
                "order_id": orderId,
                "demat_id": dematId
            }
            var config = {
                method: 'post',
                url: `${process.env.REACT_APP_BASEURL}/admin/sell/approveOrder`,
                headers: {
                    "Authorization": `Bearer ${token}`,
                    'Content-Type': 'application/json'
                },
                data: data
            };

            let res = await axios(config)
            resolve(res)
        } catch (err) {
            resolve(err?.response)
        }
    })
}

// acknowledge sell order 
export function acknowSellOrder(token, orderId, remark, status) {
    return new Promise(async (resolve, reject) => {
        try {
            var data = {
                "order_id": orderId,
                "customer_remark": remark,
                "status": status
            }
            var config = {
                method: 'post',
                url: `${process.env.REACT_APP_BASEURL}/admin/sell/acknowledgeShareTransfer`,
                headers: {
                    "Authorization": `Bearer ${token}`,
                    'Content-Type': 'application/json'
                },
                data: data
            };

            let res = await axios(config)
            resolve(res)
        } catch (err) {
            resolve(err?.response)
        }
    })
}

// complete sell order 
export function completeSellOrder(token, orderId, remark, dealerUTR) {
    return new Promise(async (resolve, reject) => {
        try {
            var data = {
                "order_id": orderId,
                "customer_remark": remark,
                "dealer_transaction_utr_confirmation": dealerUTR
            }
            var config = {
                method: 'post',
                url: `${process.env.REACT_APP_BASEURL}/admin/sell/completeOrder`,
                headers: {
                    "Authorization": `Bearer ${token}`,
                    'Content-Type': 'application/json'
                },
                data: data
            };

            let res = await axios(config)
            resolve(res)
        } catch (err) {
            resolve(err?.response)
        }
    })
}

// confirm sell order share transfer
export function confirmSellOrderShareTransfer(token, orderId, remark) {
    return new Promise(async (resolve, reject) => {
        try {
            var data = {
                "order_id": orderId,
                "customer_remark": remark,
                "consent": 1
            }
            var config = {
                method: 'post',
                url: `${process.env.REACT_APP_BASEURL}/admin/sell/transferShareOnUserBehalf`,
                headers: {
                    "Authorization": `Bearer ${token}`,
                    'Content-Type': 'application/json'
                },
                data: data
            };

            let res = await axios(config)
            resolve(res)
        } catch (err) {
            resolve(err?.response)
        }
    })
}

// delete order by id
export function deleteSellOrder(token, orderId) {
    return new Promise(async (resolve, reject) => {
        try {
            var data = {
                "order_id": orderId
            }
            var config = {
                method: 'post',
                url: `${process.env.REACT_APP_BASEURL}/admin/sell/deleteOrder`,
                headers: {
                    "Authorization": `Bearer ${token}`
                },
                data: data
            };

            let res = await axios(config)
            resolve(res)
        } catch (err) {
            resolve(err?.response)
        }
    })
}

// restore deleted order by id
export function restoreSellOrder(token, orderId) {
    return new Promise(async (resolve, reject) => {
        try {
            var data = {
                "order_id": orderId
            }
            var config = {
                method: 'post',
                url: `${process.env.REACT_APP_BASEURL}/admin/sell/restoreOrder`,
                headers: {
                    "Authorization": `Bearer ${token}`
                },
                data: data
            };

            let res = await axios(config)
            resolve(res)
        } catch (err) {
            resolve(err?.response)
        }
    })
}

// download delivery note
export function downloadDeliveryNote(token, orderId) {
    return new Promise(async (resolve, reject) => {
        try {
            var config = {
                method: 'get',
                url: `${process.env.REACT_APP_BASEURL}/admin/sell/downloadDeliveryNote/${orderId}`,
                headers: {
                    "Authorization": `Bearer ${token}`
                }
            };

            let res = await axios(config)
            resolve(res)
        } catch (err) {
            resolve(err?.response)
        }
    })
}

// get HDFC sheet
export function downloadHDFCSheet(token, orderIds) {
    return new Promise(async (resolve, reject) => {
        try {
            var config = {
                method: 'post',
                url: `${process.env.REACT_APP_BASEURL}/admin/sell/HDFCPaymentTransferSheet`,
                headers: {
                    "Authorization": `Bearer ${token}`
                },
                data: {
                    order_ids: orderIds
                }
            };

            let res = await axios(config)
            resolve(res)
        } catch (err) {
            resolve(err?.response)
        }
    })
}

// sell manual order punch by customer id
export function sellManualOrderPunchCustomerId(token, userId, companyId, price, quantity, dealerOrder, distributorId, upmark, upmark_final_pay) {
    return new Promise(async (resolve, reject) => {
        try {
            var data = {
                "user_id": userId,
                "company_id": companyId,
                "quantity": quantity,
                "price_per_share": price,
                "is_dealer_order": dealerOrder,
                "upmark": upmark,
                "upmark_final_pay": upmark_final_pay,
                "distributor_id": distributorId
            }
            var config = {
                method: 'post',
                url: `${process.env.REACT_APP_BASEURL}/admin/sell/manualOrderPunching`,
                headers: {
                    "Authorization": `Bearer ${token}`,
                    'Content-Type': 'application/json'
                },
                data: data
            };

            let res = await axios(config)
            resolve(res)
        } catch (err) {
            resolve(err?.response)
        }
    })
}

// update sell order comment
export function sellOrderComment(token, orderId, comment) {
    return new Promise(async (resolve, reject) => {
        try {
            var data = {
                "order_id": orderId,
                "comment": comment
            }
            var config = {
                method: 'post',
                url: `${process.env.REACT_APP_BASEURL}/admin/sell/comment`,
                headers: {
                    "Authorization": `Bearer ${token}`,
                    'Content-Type': 'application/json'
                },
                data: data
            };

            let res = await axios(config)
            resolve(res)
        } catch (err) {
            resolve(err?.response)
        }
    })
}