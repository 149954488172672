import React, { useEffect, useState } from "react";
import { configs } from "../../../assets/Config";
import { useLogin } from "../../../services/contexts/AdminLoginContext";
import LeftBarPage from "../../includes/dashboard/LeftBarPage";
import TopBarPage from "../../includes/dashboard/TopBarPage";
import "../../../assets/styles/BuyOrder.css"
import { Autocomplete } from "@material-ui/lab";
import { TextField } from "@material-ui/core";
import { useKYCContext } from "../../../services/contexts/KYCContext";
import { useBuyOrderContext } from "../../../services/contexts/BuyOrdersContext";
import ToastPage from "../../includes/ToastPage";
import { useHistory } from "react-router";
import ErrorModalPage from "../../includes/ErrorModalPage";

export default function GenerateImagePage() {

    const { getCompanyList, downloadSocialMediaImageByCompany } = useBuyOrderContext()
    const { setCurrModule, setShowSuccess, allowedFunc } = useLogin()
    const { setShowError } = useKYCContext()

    const [companyList, setCompanyList] = useState("")

    const [companyId, setCompanyId] = useState("")
    const [date, setDate] = useState("")
    const [title, setTitle] = useState("")
    const [desc, setDesc] = useState("")

    const [error, setError] = useState("")

    const history = useHistory()

    useEffect(() => {
        try {
            setCurrModule(configs.modules.socialMediaImage)
            if (allowedFunc.includes(configs.functionList["social_media:social_media"])) {
                (async () => {
                    // await getKYCVerifiedCustomer()
                    let response = await getCompanyList()
                    if (response.success) {
                        setCompanyList(response.data)
                    } else {

                    }
                })()
            } else {
                return history.goBack()
            }
        } catch (error) {
            console.error(error)
        }
    }, [])

    const handleCompanyChange = (e, value) => {
        try {
            let temp = []
            if (value && (value.length >= 4 && value.length <= 10)) {
                value.forEach(element => {
                    temp.push(element.id)
                });
                setCompanyId(temp);
                setError("")
            } else if (value && value.length < 4) {
                setError("Please select minimum 4 companies.")
            } else if (value && value.length > 10) {
                setError("Please select maximum 10 companies")
            }
        } catch (error) {

        }
    }

    async function downloadImage() {
        try {
            document.getElementById("imageDownBtn").classList.toggle("d-none")
            document.getElementById("imageDownBtnLoader").classList.toggle("d-none")

            let response = await downloadSocialMediaImageByCompany(companyId, date, title, "Tentative Prices")
            if (response && !response.success) {
                setError(response)
                setShowError(true)
            }else{
                setError("")
            }

            document.getElementById("imageDownBtn").classList.toggle("d-none")
            document.getElementById("imageDownBtnLoader").classList.toggle("d-none")
        } catch (error) {

        }
    }

    return (
        <>
            <TopBarPage />
            <div style={{ display: "flex" }}>
                <LeftBarPage />
                <div id="mpOrder" className="smPO-container">
                    <div className="col-12 text-left">
                        <p className="h3 text-custom-greyDark mb-0">Generate Social Media Image</p>
                    </div>
                    {companyList ?
                        <div className="smPOI-container">
                            <form action="">
                                <div className="form-row py-3">
                                    <div className="col-lg-6 form-group">
                                        <label htmlFor="companies">Select Companies</label>
                                        {companyList ? <Autocomplete
                                            multiple
                                            disableCloseOnSelect
                                            filterSelectedOptions
                                            className="f-12"
                                            id="companyIP"
                                            placeholder="Compnay Name"
                                            getOptionLabel={option => option.name}
                                            onChange={handleCompanyChange}
                                            options={companyList}
                                            renderInput={(params) => <TextField {...params} placeholder="Company Name" className="f-12" />}
                                        /> : null}
                                    </div>
                                    <div className="col-lg-6 form-group">
                                        <label htmlFor="date">Select a date</label>
                                        <input type="date" className="form-control" name="date" placeholder="Date" onChange={(e) => {
                                            setDate(e.target.value)
                                        }} />
                                    </div>
                                    <div className="col-12 form-group">
                                        <label htmlFor="title">Title</label>
                                        <input type="text" className="form-control" name="title" placeholder="Title" onChange={(e) => {
                                            setTitle(e.target.value)
                                        }} />
                                    </div>
                                    {/* <div className="col-12 form-group">
                                        <label htmlFor="description">Description</label>
                                        <input type="text" className="form-control" name="description" placeholder="Description" onChange={(e) => {
                                            setDesc(e.target.value)
                                        }} />
                                    </div> */}
                                </div>
                                <div className="form-check p-0">
                                    <p className="text-danger" id="generateSocialMediaImageError">{error}</p>
                                </div>

                                <div className="col-12 d-flex justify-content-center mt-3">
                                    <button id="imageDownBtn" className="btn btn-primary" onClick={(e) => {
                                        e.preventDefault()
                                        downloadImage()
                                    }}>Download Image</button>
                                    <div id="imageDownBtnLoader" className='animBtn d-none'><div className='gSignIn-Anim'></div></div>
                                </div>
                            </form>
                        </div>
                        : <div className="loader"></div>}

                </div>
                <ToastPage />
                <ErrorModalPage {...{ error: error }} />
            </div >
        </>
    )
}