import React from 'react';
import { createContext, useContext, useState } from 'react';
import { configs } from '../../../assets/Config';
import { createUpdateBond, deleteBond, getAllBonds, getBondById, restoreBond } from '../../apis/assetManagement/BondManagementApi';
import { useLogin } from '../AdminLoginContext';

// create global Bond management context
export const BondContext = createContext();

export function BondProvider({ children }) {

    const { token, logout, setLoginError } = useLogin();

    const [BondList, setBondList] = useState("")
    const [editBond, setEditBond] = useState(false)
    const [editBondData, setEditBondData] = useState({})
    const [showDeletedBond, setShowDeletedBond] = useState(false)
    const [showRTPage, setShowRTPage] = useState(false)
    const [bsnapshot, setBsnapshot] = useState("")
    const [bkeyHigh, setBkeyHigh] = useState("")
    const [bdeal, setBdeal] = useState("")
    const [bterms, setBterms] = useState("")
    const [bfaq, setBfaq] = useState("")
    const [bdoc, setBdoc] = useState("")

    // get all Bond
    async function getAllBondList(getDeleted) {
        try {
            let response = await getAllBonds(token, getDeleted);
            if (response && response.status === 200) {
                setBondList(response.data)
                return {
                    success: "success",
                    data: response.data
                }
            } else if (response && response.status === 401) {
                logout()
                setLoginError(configs.unauthorizedErrorMessage)
            } else {
                setBondList("")
            }
        } catch (error) {

        }
    }

    // get Bond data by id
    async function getBondDataById(BondId) {
        try {
            let response = await getBondById(token, BondId);
            if (response && response.status === 200) {
                let data = response.data
                setEditBondData({
                    ...editBondData,
                    ...data
                })
            } else if (response && response.status === 401) {
                logout()
                setLoginError(configs.unauthorizedErrorMessage)
            } else { }
        } catch (error) {

        }
    }

    // create or update Bond
    async function createEditBond(name, isin, rate_of_return, maturity_period, rating, disabletrading, description, key_1, value_1, key_2, value_2, logo, editBond, bondId) {
        try {
            let response = await createUpdateBond(token, name, isin, rate_of_return, maturity_period, rating, disabletrading, description, bsnapshot, bkeyHigh, bdeal, bterms, bfaq, bdoc, key_1, value_1, key_2, value_2, logo, editBond, bondId);
            if (response && response.status === 200) {
                return "success"
            } else if (response && response.status === 401) {
                logout()
                setLoginError(configs.unauthorizedErrorMessage)
            } else {
                return response.data.error
            }
        } catch (error) {

        }
    }


    // delete Bond
    async function deleteBondById(BondId) {
        try {
            let response = await deleteBond(token, BondId);
            if (response && response.status === 200) {
                return "success"
            } else if (response && response.status === 401) {
                logout()
                setLoginError(configs.unauthorizedErrorMessage)
            } else {
                return response.data.error
            }
        } catch (error) {

        }
    }

    // restore Bond
    async function restoreBondById(BondId) {
        try {
            let response = await restoreBond(token, BondId);
            if (response && response.status === 200) {
                return "success"
            } else if (response && response.status === 401) {
                logout()
                setLoginError(configs.unauthorizedErrorMessage)
            } else {
                return response.data.error
            }
        } catch (error) {

        }
    }

    // export global context
    return (
        <BondContext.Provider
            value={{
                BondList,
                setBondList,
                getAllBondList,
                deleteBondById,
                restoreBondById,
                editBond,
                setEditBond,
                editBondData,
                setEditBondData,
                getBondDataById,
                createEditBond,
                showDeletedBond,
                setShowDeletedBond,
                showRTPage,
                setShowRTPage,
                bsnapshot,
                setBsnapshot,
                bkeyHigh,
                setBkeyHigh,
                bdeal,
                setBdeal,
                bterms,
                setBterms,
                bfaq,
                setBfaq,
                bdoc,
                setBdoc
            }}
        >
            {children}
        </BondContext.Provider>
    );
}

// return Bond management context
export function useBondContext() {
    return useContext(BondContext);
}