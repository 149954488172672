import axios from 'axios';
import { configs } from '../../assets/Config';

export function getPromotionData(token) {
    return new Promise(async (resolve, reject) => {
        try {
            var config = {
                method: 'get',
                url: `${process.env.REACT_APP_BASEURL}/admin/offerText`,
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            };

            let res = await axios(config)
            resolve(res)
        } catch (err) {
            resolve(err?.response)
        }
    })
}

export function postPromotionData(token, website_text, website_text_color, website_background_color, trade_dashboard_text, trade_text_color, trade_background_color, website_link, trade_link) {
    return new Promise(async (resolve, reject) => {
        try {
            var config = {
                method: 'post',
                url: `${process.env.REACT_APP_BASEURL}/admin/offerText`,
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json'
                },
                data: JSON.stringify({
                    "website_text": website_text,
                    "trade_dashboard_text": trade_dashboard_text,
                    "website_text_color": website_text_color,
                    "website_background_color": website_background_color,
                    "trade_text_color": trade_text_color,
                    "trade_background_Color": trade_background_color,
                    "website_link": website_link,
                    "trade_link": trade_link
                })
            };

            let res = await axios(config)
            resolve(res)
        } catch (err) {
            resolve(err?.response)
        }
    })
}