import React, { useEffect, useState } from 'react';
import { configs, formatCurrency } from '../../../assets/Config';
import { useLogin } from '../../../services/contexts/AdminLoginContext';
import '../../../assets/styles/BuyOrder.css'
import TopBarPage from '../../includes/dashboard/TopBarPage';
import LeftBarPage from '../../includes/dashboard/LeftBarPage';
import { DataGrid, GridToolbar, GridToolbarContainer, GridToolbarExport } from '@material-ui/data-grid';
import { Link } from 'react-router-dom';
import { useDistributorContext } from '../../../services/contexts/DistributorContext';
import { useHistory } from 'react-router-dom';
import ToastPage from '../../includes/ToastPage';
import { Modal } from '@material-ui/core';
import { initiateCommissionPayout } from '../../../services/apis/Distributor/DistributorApi';

export default function DistributorCommissionPage() {

    const { getDistributorCommissionOrPayout, setKycRequesterUserDetail } = useDistributorContext()
    const { setCurrModule, allowedFunc, token, setLoginError, logout, setShowSuccess } = useLogin()

    const [tableData, setTableData] = useState([])
    const [payoutModal, setPayoutModal] = useState(false);
    const [modalDetail, setModalDetail] = useState("");
    const [amount, setAmount] = useState("");
    const [error, setError] = useState("");

    const history = useHistory()


    useEffect(() => {
        if (allowedFunc.includes(configs.functionList['distributor_management:load_distributor_details'])) {
            (async () => {
                setCurrModule(configs.modules.distComm)
                getCommission();
            })()
        } else {
            return history.goBack()
        }
    }, [])

    // get commission 
    async function getCommission() {
        try {
            let response = await getDistributorCommissionOrPayout()
            if (response.success && response.success === "success") {
                setTableData(response.data)
            } else {

            }
        } catch (error) {
            console.error(error);
        }
    }

    // columns for various data tables
    const tableColumns = [
        { field: "id", headerName: "ID", minWidth: 50, flex: 0.4, hide: true },
        {
            field: "name", headerName: "Name", minWidth: 150, flex: 0.4, cellClassName: "break-text f-12"
        },
        {
            field: "email", headerName: "E-mail", minWidth: 150, flex: 0.4, cellClassName: "break-text f-12", renderCell: (params) => {
                if (allowedFunc.includes(configs.functionList['distributor_management:load_distributor_details'])) {
                    return <Link to={`/distributorProfile/${params.row.id}`} onClick={() => {
                        setKycRequesterUserDetail(params.row)
                    }}>{params.row.email}</Link>
                } else {
                    return params.row.email
                }
            }, valueGetter: (value) => value.row.email
        },
        {
            field: "mobile", headerName: "Phone No.", minWidth: 100, flex: 0.3, cellClassName: "break-text f-12", renderCell: (params) => {
                return <a href={"https://wa.me/" + params.row.mobile} target="_blank">{params.row.mobile ? `+${params.row.mobile}` : params.row.mobile}</a>
            }, valueGetter: (value) => value.row.mobile ? `+${value.row.mobile}` : value.row.mobile
        },
        { field: "status", headerName: "Status", minWidth: 150, flex: 0.3, cellClassName: "break-text f-12" },
        {
            field: "total_commission", headerName: "Total Commission", minWidth: 100, flex: 0.4, cellClassName: "break-text f-12", renderCell: (params) => {
                return formatCurrency(parseFloat(params.row.total_commission))
            }, valueGetter: (value) => value.row.total_commission
        },
        {
            field: "total_payout", headerName: "Total Payout", minWidth: 100, flex: 0.4, cellClassName: "break-text f-12", renderCell: (params) => {
                return formatCurrency(parseFloat(params.row.total_payout))
            }, valueGetter: (value) => value.row.total_payout
        },
        {
            field: "available_balance", headerName: "Available Balance", minWidth: 100, flex: 0.4, cellClassName: "break-text f-12", renderCell: (params) => {
                return formatCurrency(parseFloat(params.row.available_balance))
            }, valueGetter: (value) => value.row.available_balance
        },
        {
            field: "payout", headerName: " ", minWidth: 150, flex: 0.4, cellClassName: "break-text f-12", filterable: false, renderCell: (cellValues) => {
                if (allowedFunc.includes(configs.functionList['distributor_management:load_distributor_details'])) {
                    return <a href="#" className="custom-link" onClick={(e) => {
                        e.preventDefault()
                        setModalDetail(cellValues.row);
                        setPayoutModal(true);
                    }}>Initiate Payout</a>
                } else {
                    return ""
                }
            }
        }
    ]

    // initiate payout function
    async function initiatePayout() {
        try {
            document.getElementById("updateCommentBtn").classList.toggle("d-none")
            document.getElementById("updateCommentBtnLoader").classList.toggle("d-none")

            let response = await initiateCommissionPayout(token, modalDetail.id, amount);
            if (response && response.status === 200) {
                setShowSuccess(true);
                handleClose();
                getCommission();
            } else if (response && response.status === 401) {
                logout()
                setLoginError(configs.unauthorizedErrorMessage)
            } else { setError(response.data.error) }

            document.getElementById("updateCommentBtn").classList.toggle("d-none")
            document.getElementById("updateCommentBtnLoader").classList.toggle("d-none")
        } catch (error) {
            console.error(error);
        }
    }

    const handleClose = () => {
        setPayoutModal(false);
        setAmount("");
        setModalDetail("");
        setError("");
    }

    // buy order ui code
    return (
        <>
            <TopBarPage />
            <div style={{ display: "flex" }}>
                <LeftBarPage />
                <div id='buyOrderContainer' className="smbo-container">
                    <div style={{ width: "100%" }}>
                        <div className="row mx-md-4 mx-2 my-2 p-0">
                            <div className="col-md-6 m-0 p-0">
                                <p className="h5 text-custom-grey">Distributor Commission</p>
                            </div>
                        </div>
                        <div className="kyc-table">
                            {tableData ? <DataGrid
                                rows={tableData}
                                columns={tableColumns}
                                resizable={true}
                                autoPageSize
                                pagination
                                headerHeight={30}
                                rowHeight={40}
                                components={{
                                    Toolbar: GridToolbar,
                                }}
                            />
                                : <div className="loader"></div>}
                        </div>
                    </div>
                </div>

                {/* // ------------------------payout initiation modal------------------------------- */}
                <Modal open={payoutModal} onClose={handleClose} style={{ overflow: "scroll" }}>
                    <div className="modal-fade" id="exampleModal" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                        <div className="modal-dialog" role="document">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h5 className="text-custom-grey h6" id="exampleModalLabel">Create payout - {modalDetail.name}</h5>
                                    <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={(e) => {
                                        e.preventDefault()
                                        handleClose()
                                    }}>
                                        <span aria-hidden="true">&times;</span>
                                    </button>
                                </div>
                                <div className="modal-body">
                                    <p><b>Total Commission:</b> {formatCurrency(parseFloat(modalDetail.total_commission))}</p>
                                    <p><b>Total Payout:</b> {formatCurrency(parseFloat(modalDetail.total_payout))}</p>
                                    <p><b>Available Balance:</b> {formatCurrency(parseFloat(modalDetail.available_balance))}</p>

                                    <form action="">
                                        <div className="form-group">
                                            <label>Payout Amount</label>
                                            <input type="number" className="form-control f-10 py-2" placeholder="Amount" value={amount} onChange={(e) => {
                                                setAmount(e.target.value)
                                            }} />
                                        </div>
                                    </form>

                                    <div className="form-check p-0">
                                        <p className="text-danger" id="remarkmodalError">{error}</p>
                                    </div>
                                </div>
                                <div className="modal-footer justify-content-center">
                                    <button id="updateCommentBtn" type="button" className="btn btn-primary" onClick={(e) => {
                                        e.preventDefault()
                                        initiatePayout()
                                    }}>Initiate Payout</button>
                                    <div id="updateCommentBtnLoader" className='animBtn d-none'><div className='gSignIn-Anim'></div></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Modal>

                <ToastPage />
            </div>
        </>
    )
}