import { DataGrid, GridToolbar } from '@material-ui/data-grid';
import React, { useEffect, useState } from 'react';
import { configs } from '../../../assets/Config';
import '../../../assets/styles/RM.css'
import { useLogin } from '../../../services/contexts/AdminLoginContext';
import { useMLDContext } from '../../../services/contexts/assetManagement/MLDContext';
import ConfirmModalPage from '../../includes/ConfirmModalPage';

export default function DeletedMLDPage() {
    const { MLDList, setMLDList, setShowDeletedMLD, showDeletedMLD, getAllMLDList } = useMLDContext()
    const { setShowConfirm, showConfirm } = useLogin()

    const [confirmId, setConfirmId] = useState({})

    const columns = [{ field: "id", headerName: "MLD ID", minWidth: 100, flex: 0.5, hide: true, filterable: false },
    { field: "name", headerName: "Name", minWidth: 300, flex: 0.5, cellClassName: "break-text" },
    { field: "isin", headerName: "ISIN", minWidth: 150, flex: 0.5, cellClassName: "break-text" },
    { field: "rate_of_return", headerName: "Rate of Return", minWidth: 150, flex: 0.5, cellClassName: "break-text" },
    { field: "maturity_period", headerName: "Maturity/Tenure ", minWidth: 150, flex: 0.5, cellClassName: "break-text" },
    { field: "interest_payment", headerName: "Interest Payment", minWidth: 150, flex: 0.5, cellClassName: "break-text" },
    { field: "launch_date", headerName: "Launch Date", minWidth: 150, flex: 0.5, cellClassName: "break-text" },
    { field: "issue_size", headerName: "Issue Size", minWidth: 150, flex: 0.5, cellClassName: "break-text" },
    { field: "rating", headerName: "Rating", minWidth: 150, flex: 0.5, cellClassName: "break-text" },
    {
        field: "disabletrading", headerName: "Disable Trading", minWidth: 130, flex: 0.1, cellClassName: "break-text", renderCell: (params) => {
            return params.row.disabletrading === 1 ? "Yes" : "No"
        }, valueGetter: (params) => params.row.disabletrading === 1 ? "Yes" : "No"
    },
    {
        field: "restore", headerName: " ", minWidth: 65, flex: 0.1, cellClassName: "break-text", cellClassName: "text-danger", renderCell: (params) => {
            return <a href="#" className="custom-link text-danger" onClick={(e) => {
                e.preventDefault()
                setConfirmId({
                    "category": configs.modules.mldMgmt,
                    "type": "MLD",
                    "name": params.row.name,
                    "id": params.row.id
                })
                setShowConfirm(true)
            }}>Restore</a>
        }
    }]

    useEffect(() => {
        (async () => {
            try {
                await getAllMLDList(true)
            } catch (error) {

            }
        })()
    }, [])

    // role list page ui code
    return (
        <div style={{ width: "100%" }}>
            <div className="row d-flex align-items-center mt-2 ml-3 mb-1 mr-2">
                <div className="col-lg-6 col-7">
                    <p className="h4 text-custom-grey">Deleted MLD</p>
                </div>
                <div className="col-lg-6 col-5 text-right">
                    <a href="#" className="custom-link" onClick={(e) => {
                        e.preventDefault()
                        setMLDList("")
                        setShowDeletedMLD(!showDeletedMLD)
                    }}><i className={showDeletedMLD ? "fas fa-user mr-2" : "fas fa-user-slash mr-2"}></i>{showDeletedMLD ? "Active MLD" : "Deleted MLD"}</a>
                </div>
            </div>
            <div className=" deleted-role-table">
                {MLDList ? <DataGrid
                    rows={MLDList}
                    headerHeight={40}
                    rowHeight={30}
                    columns={columns}
                    resizable={true}
                    autoPageSize
                    pagination
                    components={{
                        Toolbar: GridToolbar,
                    }} /> : <div className="loader"></div>}
            </div>
            <ConfirmModalPage {...confirmId} />

        </div>
    )
}