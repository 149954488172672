import axios from "axios";
import { configs } from "../../../assets/Config";

// get all notification template functions
export function getNotificationTemplateFunctions(token) {
    return new Promise(async (resolve, reject) => {
        try {
            var config = {
                method: 'get',
                url: `${process.env.REACT_APP_BASEURL}/admin/notificationmanagement/allTemplate`,
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            };

            let res = await axios(config)
            resolve(res)
        } catch (err) {
            resolve(err?.response)
        }
    })
}


// get email template by function 
export function getEmailTemplate(token, templateId) {
    return new Promise(async (resolve, reject) => {
        try {
            var config = {
                method: 'get',
                url: `${process.env.REACT_APP_BASEURL}/admin/notificationmanagement/emailTemplate/${templateId}`,
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            };

            let res = await axios(config)
            resolve(res)
        } catch (err) {
            resolve(err?.response)
        }
    })
}

// update email template
export function updateEmailTemplate(token, templateId, subject, template) {
    return new Promise(async (resolve, reject) => {
        try {
            var data = {
                "template_id": templateId,
                "subject": subject,
                "template": template
            }
            var config = {
                method: 'patch',
                url: `${process.env.REACT_APP_BASEURL}/admin/notificationmanagement/updateEmailTemplate`,
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json'
                },
                data: data
            };

            let res = await axios(config)
            resolve(res)
        } catch (err) {
            resolve(err?.response)
        }
    })
}

// get mobile template by function 
export function getMobileTemplate(token, templateId) {
    return new Promise(async (resolve, reject) => {
        try {
            var config = {
                method: 'get',
                url: `${process.env.REACT_APP_BASEURL}/admin/notificationmanagement/mobileTemplate/${templateId}`,
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            };

            let res = await axios(config)
            resolve(res)
        } catch (err) {
            resolve(err?.response)
        }
    })
}

// update mobile template
export function updateMobileTemplate(token, templateId, template) {
    return new Promise(async (resolve, reject) => {
        try {
            var data = {
                "template_id": templateId,
                "template": template
            }
            var config = {
                method: 'patch',
                url: `${process.env.REACT_APP_BASEURL}/admin/notificationmanagement/updateMobileTemplate`,
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json'
                },
                data: data
            };

            let res = await axios(config)
            resolve(res)
        } catch (err) {
            resolve(err?.response)
        }
    })
}

// get bell template by function 
export function getBellTemplate(token, templateId) {
    return new Promise(async (resolve, reject) => {
        try {
            var config = {
                method: 'get',
                url: `${process.env.REACT_APP_BASEURL}/admin/notificationmanagement/bellNotificationTemplate/${templateId}`,
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            };

            let res = await axios(config)
            resolve(res)
        } catch (err) {
            resolve(err?.response)
        }
    })
}

// update bell template
export function updateBellTemplate(token, templateId, heading, description) {
    return new Promise(async (resolve, reject) => {
        try {
            var data = {
                "template_id": templateId,
                "heading": heading,
                "description": description
            }
            var config = {
                method: 'patch',
                url: `${process.env.REACT_APP_BASEURL}/admin/notificationmanagement/updateBellNotificationTemplate`,
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json'
                },
                data: data
            };

            let res = await axios(config)
            resolve(res)
        } catch (err) {
            resolve(err?.response)
        }
    })
}

// get whatsapp template by function 
export function getWhatsappTemplate(token, templateId) {
    return new Promise(async (resolve, reject) => {
        try {
            var config = {
                method: 'get',
                url: `${process.env.REACT_APP_BASEURL}/admin/notificationmanagement/whatsappTemplate/${templateId}`,
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            };

            let res = await axios(config)
            resolve(res)
        } catch (err) {
            resolve(err?.response)
        }
    })
}

// update bell template
export function updateWhatsappTemplate(token, templateId, template_name, head_variable, body_variable) {
    return new Promise(async (resolve, reject) => {
        try {
            var data = {
                "template_id": templateId,
                "template_name": template_name,
                "head_variable": head_variable,
                "body_variable": body_variable
            }
            var config = {
                method: 'patch',
                url: `${process.env.REACT_APP_BASEURL}/admin/notificationmanagement/updateWhatsappTemplate`,
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json'
                },
                data: data
            };

            let res = await axios(config)
            resolve(res)
        } catch (err) {
            resolve(err?.response)
        }
    })
}