import React from 'react';
import { createContext, useContext, useState } from 'react';
import { configs } from '../../assets/Config';
import { createOrderLeadRmeark, createUpdateRemark, createUpdateRemarkNonUser, editOrderLeadRmeark, getAllLatestRemark, getAllLatestRemarkByRM, getAllLatestRemarkByUser, getAllRemark, getNoRMUser } from '../apis/CRMApi';
import { useLogin } from './AdminLoginContext';

// create global news management context
export const CRMContext = createContext();

export function CRMProvider({ children }) {

    const { token, logout, setLoginError } = useLogin();

    const [remarkList, setRemarkList] = useState("")
    const [editRemark, setEditRemark] = useState(false)
    const [editRemarkData, setEditRemarkData] = useState({})
    const [showDeletedRemark, setShowDeletedRemark] = useState(false)
    const [remarkListType, setRemarkListType] = useState("latest")
    const [customer, setCustomer] = useState({})

    // get all latest remark list
    async function getAllLatestRemarkList() {
        try {
            let response = await getAllLatestRemark(token);
            if (response && response.status === 200) {
                setRemarkList(response.data)
            } else if (response && response.status === 401) {
                logout()
                setLoginError(configs.unauthorizedErrorMessage)
            } else {
                setRemarkList("")
            }
        } catch (error) {

        }
    }

    // get all remark list
    async function getAllRemarkList(url) {
        try {
            let response = await getAllRemark(token, url);
            if (response && response.status === 200) {
                setRemarkList(response.data)
            } else if (response && response.status === 401) {
                logout()
                setLoginError(configs.unauthorizedErrorMessage)
            } else {
                setRemarkList("")
            }
        } catch (error) {

        }
    }

    // get no rm users list
    async function getNoRMUserList() {
        try {
            let response = await getNoRMUser(token);
            if (response && response.status === 200) {
                setRemarkList(response.data)
            } else if (response && response.status === 401) {
                logout()
                setLoginError(configs.unauthorizedErrorMessage)
            } else {
                setRemarkList([])
            }
        } catch (error) {

        }
    }

    // get all remark by rm
    async function getAllRemarkListByRM(adminId) {
        try {
            let response = await getAllLatestRemarkByRM(token, adminId);
            if (response && response.status === 200) {
                setRemarkList(response.data)
            } else if (response && response.status === 401) {
                logout()
                setLoginError(configs.unauthorizedErrorMessage)
            } else {
                setRemarkList([])
            }
        } catch (error) {

        }
    }

    // get all remark list by user
    async function getAllRemarkListByUser(userId, addBox) {
        try {
            let response = await getAllLatestRemarkByUser(token, userId);
            if (response && response.status === 200) {
                if (addBox)
                    setEditRemarkData(response.data && response.data.length > 0 ? response.data[0] : {})
                else
                    setRemarkList(response.data)
            } else if (response && response.status === 401) {
                logout()
                setLoginError(configs.unauthorizedErrorMessage)
            } else {
                setRemarkList([])
            }
        } catch (error) {

        }
    }

    // create or update news
    async function createEditRemark(userId, lastCallDate, remark, investable_surplus, interested_in, relationship_manager) {
        try {
            let response = await createUpdateRemark(token, userId, relationship_manager, lastCallDate, remark, investable_surplus, interested_in, editRemark);
            if (response && response.status === 200) {
                return "success"
            } else if (response && response.status === 401) {
                logout()
                setLoginError(configs.unauthorizedErrorMessage)
            } else {
                return response.data.error
            }
        } catch (error) {

        }
    }


    // create or update remark for non existing user
    async function createEditRemarkNonUser(name, email, mobile, lastCallDate, remark, investable_surplus, interested_in, relationship_manager) {
        try {
            let response = await createUpdateRemarkNonUser(token, name, email, mobile, relationship_manager, lastCallDate, remark, investable_surplus, interested_in, editRemark);
            if (response && response.status === 200) {
                return "success"
            } else if (response && response.status === 401) {
                logout()
                setLoginError(configs.unauthorizedErrorMessage)
            } else {
                return response.data.error
            }
        } catch (error) {

        }
    }


    // create order lead
    async function createOrderLead(customerId, name, email, mobile, companyName, Quantity, rate, orderType, remark, editRemark) {
        try {
            let response = await createOrderLeadRmeark(token, customerId, name, email, mobile, companyName, Quantity, rate, orderType, remark, editRemark);
            if (response && response.status === 200) {
                return "success"
            } else if (response && response.status === 401) {
                logout()
                setLoginError(configs.unauthorizedErrorMessage)
            } else {
                return response.data.error
            }
        } catch (error) {

        }
    }

    // edit order lead
    async function editOrderLead(insightId, status, remark) {
        try {
            let response = await editOrderLeadRmeark(token, insightId, status, remark);
            if (response && response.status === 200) {
                return "success"
            } else if (response && response.status === 401) {
                logout()
                setLoginError(configs.unauthorizedErrorMessage)
            } else {
                return response.data.error
            }
        } catch (error) {

        }
    }

    // export global context
    return (
        <CRMContext.Provider
            value={{
                remarkList,
                setRemarkList,
                getAllLatestRemarkList,
                getAllRemarkListByUser,
                editRemark,
                setEditRemark,
                editRemarkData,
                setEditRemarkData,
                createEditRemark,
                createEditRemarkNonUser,
                showDeletedRemark,
                setShowDeletedRemark,
                getAllRemarkList,
                getNoRMUserList,
                getAllRemarkListByRM,
                remarkListType,
                setRemarkListType,
                customer,
                setCustomer,
                createOrderLead,
                editOrderLead
            }}
        >
            {children}
        </CRMContext.Provider>
    );
}

// return news management context
export function useCRMContext() {
    return useContext(CRMContext);
}