import React from 'react';
import { createContext, useContext, useState } from 'react';
import { configs } from '../../../assets/Config';
import { createUpdateBucket, deleteBucket, getAllBuckets, getBucketById, restoreBucket } from '../../apis/assetManagement/BucketManagementApi';
import { useLogin } from '../AdminLoginContext';

// create global Bucket management context
export const BucketContext = createContext();

export function BucketProvider({ children }) {

    const { token, logout, setLoginError } = useLogin();

    const [BucketList, setBucketList] = useState("")
    const [editBucket, setEditBucket] = useState(false)
    const [editBucketData, setEditBucketData] = useState({})
    const [showDeletedBucket, setShowDeletedBucket] = useState(false)
    const [showRTPage, setShowRTPage] = useState(false)
    const [BucketOverview, setBucketOverview] = useState("")
    const [BucketRationale, setBucketRationale] = useState("")
    const [BucketAssetList, setBucketAssetList] = useState([])

    // get all Bucket
    async function getAllBucketList(getDeleted) {
        try {
            let response = await getAllBuckets(token, getDeleted);
            if (response && response.status === 200) {
                setBucketList(response.data)
                return {
                    success: "success",
                    data: response.data
                }
            } else if (response && response.status === 401) {
                logout()
                setLoginError(configs.unauthorizedErrorMessage)
            } else {
                setBucketList("")
            }
        } catch (error) {

        }
    }

    // get Bucket data by id
    async function getBucketDataById(BucketId) {
        try {
            let response = await getBucketById(token, BucketId);
            if (response && response.status === 200) {
                let data = response.data
                setEditBucketData({
                    ...editBucketData,
                    ...data
                })
            } else if (response && response.status === 401) {
                logout()
                setLoginError(configs.unauthorizedErrorMessage)
            } else { }
        } catch (error) {

        }
    }

    // create or update Bucket
    async function createEditBucket(name, isin, suggested_holding_period, minimum_investment, number_of_stocks, disabletrading, description, email_description, bell_notification_description, mobile_description, whatsapp_description, key_1, value_1, key_2, value_2, logo, editBucket, BucketId) {
        try {
            let response = await createUpdateBucket(token, name, isin, suggested_holding_period, minimum_investment, number_of_stocks, disabletrading, description, BucketOverview, BucketRationale, BucketAssetList, email_description, bell_notification_description, mobile_description, whatsapp_description, key_1, value_1, key_2, value_2, logo, editBucket, BucketId);
            if (response && response.status === 200) {
                return "success"
            } else if (response && response.status === 401) {
                logout()
                setLoginError(configs.unauthorizedErrorMessage)
            } else {
                return response.data.error
            }
        } catch (error) {

        }
    }

    // delete Bucket
    async function deleteBucketById(BucketId) {
        try {
            let response = await deleteBucket(token, BucketId);
            if (response && response.status === 200) {
                return "success"
            } else if (response && response.status === 401) {
                logout()
                setLoginError(configs.unauthorizedErrorMessage)
            } else {
                return response.data.error
            }
        } catch (error) {

        }
    }

    // restore Bucket
    async function restoreBucketById(BucketId) {
        try {
            let response = await restoreBucket(token, BucketId);
            if (response && response.status === 200) {
                return "success"
            } else if (response && response.status === 401) {
                logout()
                setLoginError(configs.unauthorizedErrorMessage)
            } else {
                return response.data.error
            }
        } catch (error) {

        }
    }

    // export global context
    return (
        <BucketContext.Provider
            value={{
                BucketList,
                setBucketList,
                getAllBucketList,
                deleteBucketById,
                restoreBucketById,
                editBucket,
                setEditBucket,
                editBucketData,
                setEditBucketData,
                getBucketDataById,
                createEditBucket,
                showDeletedBucket,
                setShowDeletedBucket,
                showRTPage,
                setShowRTPage,
                BucketOverview,
                setBucketOverview,
                BucketRationale,
                setBucketRationale,
                BucketAssetList,
                setBucketAssetList
            }}
        >
            {children}
        </BucketContext.Provider>
    );
}

// return Bucket management context
export function useBucketContext() {
    return useContext(BucketContext);
}