import axios from 'axios';
import { configs } from '../../assets/Config';

export function getDashboardData(token) {
    return new Promise(async (resolve, reject) => {
        try {
            var config = {
                method: 'get',
                url: `${process.env.REACT_APP_BASEURL}/admin/dashboard/user`,
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            };

            let res = await axios(config)
            resolve(res)
        } catch (err) {
            resolve(err?.response)
        }
    })
}

export function postDashboardData(token, name, value) {
    return new Promise(async (resolve, reject) => {
        try {
            var config = {
                method: 'post',
                url: `${process.env.REACT_APP_BASEURL}/admin/dashboard/user`,
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json'
                },
                data: JSON.stringify({
                    name: name,
                    data: value
                })
            };

            let res = await axios(config)
            resolve(res)
        } catch (err) {
            resolve(err?.response)
        }
    })
}


export function addHighlights(token, data, companyId) {
    return new Promise(async (resolve, reject) => {
        try {
            var config = {
                method: 'post',
                url: `${process.env.REACT_APP_BASEURL}/admin/story/add`,
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json'
                },
                data: JSON.stringify({
                    company_id: companyId,
                    data: data
                })
            };

            let res = await axios(config)
            resolve(res)
        } catch (err) {
            resolve(err?.response)
        }
    })
}


export function getHighlights(token, company) {
    return new Promise(async (resolve, reject) => {
        try {
            let url = company ? `admin/story/get/${company}` : "admin/story/get"
            var config = {
                method: 'get',
                url: `${process.env.REACT_APP_BASEURL}/${url}`,
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            };

            let res = await axios(config)
            if (res.status === 200) {
                resolve(res.data.data && res.data.data.length > 0 ? res.data : {
                    data: [{
                        file: company ? "test" : '',
                        heading: company ? "test" : '',
                        highlights: [{
                            file: '',
                            btnText: "",
                            btnLink: ""
                        }]
                    }]
                })
            } else {
                resolve(res)
            }
            resolve(res)
        } catch (err) {
            resolve(err?.response)
        }
    })
}