import { DataGrid, GridToolbar } from '@material-ui/data-grid';
import React, { useEffect, useState } from 'react';
import { configs } from '../../../assets/Config';
import '../../../assets/styles/RM.css'
import { useLogin } from '../../../services/contexts/AdminLoginContext';
import { useCMContext } from '../../../services/contexts/companyManagement/CMContext';
import ConfirmModalPage from '../../includes/ConfirmModalPage';

export default function DeletedCompanyPage() {
    const { setCompanyList, companyList, setShowDeletedCompanies, showDeletedCompanies, getAllCompaniesList } = useCMContext()
    const { setShowConfirm, showConfirm } = useLogin()

    const [confirmId, setConfirmId] = useState({})

    const columns = [{ field: "id", headerName: "Company ID", minWidth: 100, flex: 0.5, hide: true, filterable: false },
    { field: "name", headerName: "Name", minWidth: 300, flex: 0.5, cellClassName: "break-text" },
    { field: "display_on_home", headerName: "Display on Home", minWidth: 100, flex: 0.5, cellClassName: "break-text" },
    { field: "isin", headerName: "ISIN", minWidth: 150, flex: 0.5, cellClassName: "break-text" },
    { field: "industry", headerName: "Industry", minWidth: 150, flex: 0.5, cellClassName: "break-text" },
    { field: "sector", headerName: "Sector", minWidth: 200, flex: 0.5, cellClassName: "break-text" },
    { field: "unique_title", headerName: "Unique Title", minWidth: 400, flex: 0.5, cellClassName: "break-text" },
    { field: "cdsl", headerName: "CDSL", minWidth: 120, flex: 0.1, cellClassName: "break-text" },
    { field: "nsdl", headerName: "NSDL", minWidth: 120, flex: 0.1, cellClassName: "break-text" },
    { field: "disabletrading", headerName: "Disable Trading", minWidth: 130, flex: 0.1, cellClassName: "break-text" },
    { field: "status", headerName: "Status", minWidth: 120, flex: 0.5, cellClassName: "break-text" },
    {
        field: "restore", headerName: " ", minWidth: 65, flex: 0.1, cellClassName: "break-text", cellClassName: "text-danger", renderCell: (params) => {
            return <a href="#" className="custom-link text-danger" onClick={(e) => {
                e.preventDefault()
                setConfirmId({
                    "category": configs.modules.cm,
                    "type": "Company",
                    "name": params.row.name,
                    "id": params.row.id
                })
                setShowConfirm(true)
            }}>Restore</a>
        }
    }]

    useEffect(() => {
        (async () => {
            try {
                await getAllCompaniesList(true)
            } catch (error) {

            }
        })()
    }, [])

    // role list page ui code
    return (
        <div style={{width: "100%" }}>
            <div className="row d-flex align-items-center mt-2 ml-3 mb-1 mr-2">
                <div className="col-lg-6 col-7">
                    <p className="h4 text-custom-grey">Deleted Companies</p>
                </div>
                <div className="col-lg-6 col-5 text-right">
                    <a href="#" className="custom-link" onClick={(e) => {
                        e.preventDefault()
                        setCompanyList("")
                        setShowDeletedCompanies(!showDeletedCompanies)
                    }}><i className={showDeletedCompanies ? "fas fa-user mr-2" : "fas fa-user-slash mr-2"}></i>{showDeletedCompanies ? "Active Companies" : "Deleted Companies"}</a>
                </div>
            </div>
            <div className=" deleted-role-table">
                {companyList ? <DataGrid
                    rows={companyList}
                    headerHeight={40}
                    rowHeight={30}
                    columns={columns}
                    resizable={true}
                    autoPageSize
                    pagination
                    components={{
                        Toolbar: GridToolbar,
                    }} /> : <div className="loader"></div>}
            </div>
            <ConfirmModalPage {...confirmId} />

        </div>
    )
}