import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { configs } from '../../../assets/Config';
import { useLogin } from '../../../services/contexts/AdminLoginContext';
import LeftBarPage from '../../includes/dashboard/LeftBarPage';
import TopBarPage from '../../includes/dashboard/TopBarPage';
import ToastPage from '../../includes/ToastPage';
import '../../../assets/styles/RM.css'
import { useBucketContext } from '../../../services/contexts/assetManagement/BucketContext';
import ListBucketPage from './ListBucketPage';
import CreateBucketPage from './CreateBucketPage';
import DeletedBucketPage from './DeletedBucketPage';


export default function BucketMainPage() {

    const { BucketList, getAllBucketList, showDeletedBucket } = useBucketContext()
    const { setCurrModule, allowedFunc } = useLogin()

    const history = useHistory()

    useEffect(() => {
        (async () => {
            try {
                if (allowedFunc.includes(configs.functionList['bucket_management:view_asset_data'])) {
                    setCurrModule(configs.modules.bucketMgmt)
                    getAllBucketList()
                } else {
                    return history.goBack()
                }
            } catch (error) {

            }
        })()
    }, [])

    // role management main ui code
    return (
        <>
            <TopBarPage />
            <div style={{ display: "flex" }}>
                <LeftBarPage />
                {showDeletedBucket ? <div id='rmContainer' className="rm-container"><DeletedBucketPage /></div> :
                    <div id='rmContainer' className="rm-container">
                        <ListBucketPage />
                        {allowedFunc.includes(configs.functionList['bucket_management:create_asset']) || allowedFunc.includes(configs.functionList['bucket_management:update_asset']) ?
                            <CreateBucketPage />
                            : null}
                        <ToastPage />
                    </div>
                }
            </div>
        </>
    )
}