import { TextField } from "@material-ui/core";
import Modal from "@material-ui/core/Modal";
import { Autocomplete } from "@material-ui/lab";
import { useRef, useState, useEffect } from "react";
import { configs } from "../../assets/Config";
import '../../assets/styles/TopBar.css'
import { useLogin } from "../../services/contexts/AdminLoginContext";
import { useBondContext } from "../../services/contexts/assetManagement/BondContext";
import { useBucketContext } from "../../services/contexts/assetManagement/BucketContext";
import { useGSecContext } from "../../services/contexts/assetManagement/GSecContext";
import { useMixBucketContext } from "../../services/contexts/assetManagement/MixBucketContext";
import { useMLDContext } from "../../services/contexts/assetManagement/MLDContext";
import { useStartupContext } from "../../services/contexts/assetManagement/StartupContext";
import { useCMContext } from "../../services/contexts/companyManagement/CMContext";

export default function MultiAssetPage(type) {

    const { addAssetPopup, setaddAssetPopup } = useLogin()
    const { BucketAssetList, setBucketAssetList } = useBucketContext()
    const { MixBucketAssetList, setMixBucketAssetList } = useMixBucketContext()
    const { getAllBondList } = useBondContext()
    const { getAllMLDList } = useMLDContext()
    const { getAllCompaniesList } = useCMContext()
    const { getAllGSecList } = useGSecContext()
    const { getAllStartupList } = useStartupContext()

    const [assetClass, setassetClass] = useState(configs.assetList[2])
    const [assetList, setAssetList] = useState([])
    const [assetId, setAssetId] = useState()
    const [assetQuantity, setAssetQuantity] = useState("")
    const [assetAddList, setAssetAddList] = useState(type.type === configs.modules.bucketMgmt ? BucketAssetList : MixBucketAssetList)
    const [error, setError] = useState("")

    useEffect(() => {
        setAssetAddList(type.type === configs.modules.bucketMgmt ? BucketAssetList : MixBucketAssetList)
    }, [BucketAssetList, MixBucketAssetList])

    useEffect(() => {
        (async () => {
            try {
                if (assetClass === configs.assetList[0]) {
                    var response = await getAllBondList()
                }
                else if (assetClass === configs.assetList[1]) {
                    response = await getAllMLDList()
                }
                else if (assetClass === configs.assetList[2]) {
                    response = await getAllCompaniesList()
                }
                else if (assetClass === configs.assetList[3]) {
                    response = await getAllGSecList()
                }
                else if (assetClass === configs.assetList[4]) {
                    response = await getAllStartupList()
                }

                if (response && response.success) {
                    setAssetList(response.data)
                }
            } catch (error) {

            }
        })()
    }, [assetClass])

    const handleAssetChange = (e, value) => {
        try {
            setAssetId(value);
            setError("")
        } catch (error) {

        }
    }

    async function addAsset() {
        try {

            if (!assetId) {
                setError("Please select an asset.")
            } else if (!assetQuantity || parseInt(assetQuantity) <= 0) {
                setError("Please enter valid quantity.")
            } else {
                let addList = type.type === configs.modules.bucketMgmt ? BucketAssetList : MixBucketAssetList
                addList.push({
                    asset_id: assetId.id,
                    asset_name: assetId.name,
                    quantity: assetQuantity
                })

                if (type.type === configs.modules.bucketMgmt) {
                    setBucketAssetList(addList)
                } else {
                    setMixBucketAssetList(addList)
                }

                setAssetId(null)
                setAssetQuantity("")
            }

        } catch (error) {
            console.error(error);
        }
    }

    // delete asset
    function deleteAsset(element) {
        try {
            let addList = type.type === configs.modules.bucketMgmt ? BucketAssetList : MixBucketAssetList
            let index = addList.indexOf(element)
            addList.splice(index, 1)
            if (type.type === configs.modules.bucketMgmt) {
                setBucketAssetList(addList)
            } else {
                setMixBucketAssetList(addList)
            }
            setError("")
        } catch (error) {

        }
    }

    const handleClose = () => {
        setaddAssetPopup(false);
    };


    return (
        <Modal open={addAssetPopup} onClose={handleClose} disableEnforceFocus>
            <div className="modal-fade" id="KYCSubmitModal" tabIndex="-1" role="dialog" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered modal-width" role="document">
                    <div className="modal-content">
                        <div className="modal-header border-0">
                            <h2 className="text-capitalize">Modify Asset {type.name}</h2>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={handleClose}>
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body py-3" style={{ height: "500px", overflow: 'auto' }}>
                            <div className="row m-0 p-0">
                                {type.showAssetClasses ?
                                    <div className="col-md-4 col-12 m-0">
                                        <div className="form-group">
                                            <label className="f-12">Asset Class</label>
                                            <select name="" id="" className="col-12 py-1 px-2 border rounded f-12" value={assetClass != null ? assetClass : ""} onChange={(e) => {
                                                setassetClass(e.target.value)
                                                setError("")
                                            }}>
                                                <option className='f-12' value=""> Select Asset Class </option>
                                                {configs.assetList.map((asset, key) => {
                                                    if (!asset.includes("Bucket")) {
                                                        return <option className='f-12' key={`asset${key}`} value={asset}> {asset} </option>
                                                    }
                                                })}
                                            </select>
                                        </div>
                                    </div>
                                    : null}
                                <div className={`${type.showAssetClasses ? "col-md-4" : "col-md-6"} col-12 m-0`}>
                                    <div className="form-group">
                                        {assetList && assetList.length > 0 ?
                                            <>
                                                <label className="f-12">Select Asset</label>
                                                < Autocomplete
                                                    value={assetId ? assetId : null}
                                                    disableCloseOnSelect={false}
                                                    filterSelectedOptions
                                                    className="f-12"
                                                    id="utmip"
                                                    placeholder="Select Asset"
                                                    getOptionLabel={option => `${option.name}`}
                                                    onChange={handleAssetChange}
                                                    options={assetList}
                                                    renderInput={(params) => <TextField {...params} placeholder="Select Asset" className="f-12" />}
                                                />
                                            </>
                                            : null}
                                    </div>
                                </div>
                                <div className={`${type.showAssetClasses ? "col-md-4" : "col-md-6"} col-12 m-0`}>
                                    <div className="form-group">
                                        <label className="f-12">Quantity</label>
                                        <input type="number" id="roleName" className="form-control f-10" placeholder="Quantity" value={assetQuantity} required onChange={(e) => {
                                            setAssetQuantity(e.target.value)
                                            setError("")
                                        }} />
                                    </div>
                                </div>
                            </div>
                            <div className="row m-0 p-0">
                                <div className="col-md-9 col-12">
                                    <div className="form-check p-0">
                                        <p className="text-danger" id="addAssetError">{error}</p>
                                    </div>
                                </div>
                                <div className="col-md-3 col-12 text-md-right">
                                    <div className="form-group">
                                        <button className="text-primary custom-button" onClick={(e) => {
                                            e.preventDefault()
                                            addAsset()
                                        }}><i className="fas fa-plus-circle mr-2"></i>Add Asset</button>
                                    </div>
                                </div>
                            </div>
                            <div className="row d-flex m-0">
                                {assetAddList.length > 0 ? assetAddList.map((element, key) => {
                                    return (<div key={`assetAddList` + key} id={`assetAddList` + key} className=" col-lg-4 col-md-6 utr-card">
                                        <div className="row d-flex align-items-center">
                                            <div className="col-9">
                                                <p className="mb-0">
                                                    <span className="text-primary font-weight-bold">{element.asset_name}</span> <br />
                                                    <span className="text-custom-grey">{element.quantity}</span>
                                                </p>
                                            </div>
                                            <div className="col-3 text-right">
                                                <a href="#" className="custom-link text-danger" onClick={(e) => {
                                                    e.preventDefault()
                                                    deleteAsset(element)
                                                    document.getElementById(`assetAddList` + key).style.display = "none";
                                                }}><i className="far fa-trash-alt"></i></a>
                                            </div>
                                        </div>
                                    </div>)
                                }) : null}
                            </div>
                            <button type="button" data-dismiss="modal" className="btn btn-primary text-center mt-3" onClick={(e) => {
                                e.preventDefault()
                                handleClose()
                            }}>Done</button>
                        </div>
                    </div>
                </div>
            </div>
        </Modal>
    )
}