import { Modal, TextField } from '@material-ui/core';
import { DataGrid, GridToolbar } from '@material-ui/data-grid';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { configs, downloadDoc, downloadImage } from '../../../../assets/Config';
import '../../../../assets/styles/RM.css'
import { useLogin } from '../../../../services/contexts/AdminLoginContext';
import DeleteModalPage from '../../../includes/DeleteModalPage';
import { useCRMContext } from '../../../../services/contexts/CRMContext';
import { useKYCContext } from '../../../../services/contexts/KYCContext';
import { Link } from 'react-router-dom';
import { useRMContext } from '../../../../services/contexts/RMContext';
import { Autocomplete } from '@material-ui/lab';

export default function UniqueEntryPage() {

    const { remarkList, setRemarkList, getAllRemarkListByUser, downloadFile, setEditRemark, setEditRemarkData, editNewsData, getNewsDataById, setShowDeletedRemark, showDeletedRemark, getAllRemarkList, getNoRMUserList, getAllRemarkListByRM, remarkListType, setRemarkListType, setCustomer } = useCRMContext()
    const { setShowDelete, setShowSuccess, allowedFunc } = useLogin()
    const { setKycRequesterUserDetail, kycRequestersList } = useKYCContext()
    const { adminList } = useRMContext()

    const history = useHistory()

    const [deleteId, setDeleteId] = useState({})
    const [userName, setUserName] = useState("")

    const columns = [{ field: "id", headerName: "Remark ID", minWidth: 100, flex: 0.5, hide: true, filterable: false },
    {
        field: "cust_name", headerName: "Customer Name", minWidth: 150, flex: 0.5, cellClassName: "break-text", renderCell: (params) => {
            return params.row.user ? params.row.user.name : params.row.name
        }, valueGetter: (params) => params.row.user ? params.row.user.name : params.row.name
    },
    {
        field: "mobile", headerName: "Mobile No.", minWidth: 150, flex: 0.4, cellClassName: "break-text", renderCell: (params) => {
            return params.row.mobile ? <a href={"https://wa.me/" + params.row.mobile} target="_blank">+{params.row.mobile}</a> : ""
        }, valueGetter: (value) => value.row.mobile ? `+${value.row.mobile}` : value.row.mobile
    },
    { field: "company_name", headerName: "Company Name", minWidth: 250, flex: 0.1, cellClassName: "break-text" },
    { field: "quantity", headerName: "Quantity", minWidth: 110, flex: 0.1, cellClassName: "break-text" },
    { field: "price_per_share", headerName: "Rate/Share", minWidth: 110, flex: 0.1, cellClassName: "break-text" },
    { field: "order_type", headerName: "Order Type", minWidth: 110, flex: 0.1, cellClassName: "break-text" },
    { field: "remark", headerName: "Remark", minWidth: 200, flex: 0.5, cellClassName: "break-text" },
    { field: "status", headerName: "Status", minWidth: 200, flex: 0.5, cellClassName: "break-text" },
    { field: "date", headerName: "Date", minWidth: 200, flex: 0.5, cellClassName: "break-text" },
    {
        field: "rm_name", headerName: "Relationship Manager", minWidth: 150, flex: 0.5, cellClassName: "break-text", renderCell: (params) => {
            return params.row.relationship_manager ? params.row.relationship_manager.name : ""
        }, valueGetter: (params) => params.row.relationship_manager ? params.row.relationship_manager.name : ""
    },
    {
        field: "cust_email", headerName: "Customer Email", minWidth: 150, flex: 0.5, cellClassName: "break-text", renderCell: (params) => {
            if (allowedFunc.includes(configs.functionList['user_management:load_user_details'])) {
                return <Link to={`/customerProfile/${params.row.user ? params.row.user.id : params.row.id}`} onClick={() => {
                    setKycRequesterUserDetail(params.row)
                }}>{params.row.user ? params.row.user.email : params.row.email}</Link>
            } else {
                return params.row.user ? params.row.user.email : params.row.email
            }
        }, valueGetter: (params) => params.row.user ? params.row.user.email : params.row.email
    },
    {
        field: "view", headerName: " ", minWidth: 60, flex: 0.1, cellClassName: "break-text", cellClassName: "text-primary", renderCell: (params) => {
            return <a href="#" className="custom-link text-primary" onClick={(e) => {
                e.preventDefault()
                setEditRemark(true);
                setEditRemarkData(params.row);
            }}>Edit Status</a>
        }
    },
    ]

    useEffect(() => {
        (async () => {
            try {
                if (remarkListType === "all")
                    await getAllRemarkList("order-insight")
                else
                    await getAllRemarkList(`order-insight/status/${remarkListType}`)
            } catch (error) {

            }
        })()
    }, [remarkListType])

    // role list page ui code
    return (
        <div id="newsListPage" className='d-md-block' style={{ width: "100%" }}>
            <div className="row d-flex align-items-center mt-2 ml-2 mb-1 mr-3">
                <div className="col-lg-6 col-8 m-0 p-0">
                    <p className="h4 text-custom-grey">Remark Management</p>
                </div>
                <div className="col-lg-6 col-4 text-right m-0 p-0">
                    <select className='' id="selectRemarkData" value={remarkListType} onChange={(e) => {
                        e.preventDefault()
                        setRemarkList("")
                        setRemarkListType(e.target.value)
                    }}>
                        <option value="all">All Remarks</option>
                        <option value="OPEN">Open</option>
                        <option value="PROCESSED">Processed</option>
                        <option value="REJECTED_BY_ALTIUS">Rejected by Altius</option>
                        <option value="REJECTED_BY_USER">Rejected by Investor</option>
                    </select>
                </div>
            </div>
            <div className='row m-0 mx-3 p-0 d-md-none d-block'>
                <a href='#' className='custom-link' onClick={(e) => {
                    e.preventDefault()
                    document.getElementById("rmCreateNews").classList.toggle('d-none')
                    document.getElementById("newsListPage").classList.toggle('d-none')
                }}><p className='m-0 p-0'>Add New Remark</p></a>
            </div>
            {remarkList ?

                <div className="nm-role-table m-2">
                    <DataGrid
                        rows={remarkList}
                        headerHeight={30}
                        rowHeight={40}
                        columns={columns}
                        resizable={true}
                        autoPageSize
                        pagination
                        components={{
                            Toolbar: GridToolbar,
                        }}

                    // onSelectionModelChange={onRowSelected}
                    />
                </div>
                : <div className="loader"></div>}
            <DeleteModalPage {...deleteId} />

        </div>
    )
}