import { Modal } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { configs } from '../../../assets/Config';
import { useLogin } from '../../../services/contexts/AdminLoginContext';
import { useRMContext } from '../../../services/contexts/RMContext';

export default function CreateAdminPage() {

    const { roleUserList, getAllRoleUsersList, createAdmin, updateAdmin, setUpdateAdmin, createAdminRoleId, setCreateAdminRoleId, editAdminDetail, setEditAdminDetail } = useRMContext()
    const { setShowSuccess } = useLogin()

    const [name, setName] = useState("")
    const [mobile, setMobile] = useState("")
    const [email, setEmail] = useState("")
    const [role, setRole] = useState("")
    const [password, setPassword] = useState("")
    const [error, setError] = useState("")

    // add admin function
    async function createNewAdmin() {
        try {
            document.getElementById("createAdminBtn").classList.toggle("d-none")
            document.getElementById("createAdminBtnLoader").classList.toggle("d-none")

            if (name && !name.trim()) {
                setError("Please enter a valid name to proceed.")
            } else if (email && !email.trim() || !configs.emailRegex.test(email)) {
                setError("Please enter a valid email to proceed.")
            } if (mobile && !mobile.trim() || mobile.length !== 10) {
                setError("Please enter a valid mobile no. to proceed.")
            } else if (!role) {
                setError("Please select a valid role to proceed.")
            } else if (!editAdminDetail.edit && (!password.trim() || !configs.passRegex.test(password))) {
                setError("Please enter a valid password to proceed.")
            } else if (editAdminDetail.edit && password && !configs.passRegex.test(password)) {
                setError("Please enter a valid password to proceed.")
            }
            else {
                let response = await createAdmin(name, email, password, role, mobile)
                if (response === "success") {
                    setShowSuccess(true)
                    handleClose()
                } else {
                    setError(response)
                }
            }

            document.getElementById("createAdminBtn").classList.toggle("d-none")
            document.getElementById("createAdminBtnLoader").classList.toggle("d-none")
        } catch (error) {
            console.error(error);
        }
    }

    // handle modal close
    const handleClose = () => {
        try {
            setName("")
            setEmail("")
            setMobile("")
            setRole("")
            setPassword("")
            setError("")
            setCreateAdminRoleId("")
            setEditAdminDetail({
                "edit": false,
                "data": {}
            })
            setUpdateAdmin(false)
        } catch (error) {

        }
    }

    useEffect(() => {
        try {
            if (editAdminDetail.edit) {
                setName(editAdminDetail.data.name)
                setEmail(editAdminDetail.data.email)
                setMobile(editAdminDetail.data.mobile)
                setRole(editAdminDetail.data.role_id)
            }
        } catch (error) {

        }

    }, [editAdminDetail, createAdminRoleId])

    // create admin ui code
    return (
        <Modal open={updateAdmin} onClose={handleClose}>
            <div className="modal-fade" id="editAdmin" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title">{editAdminDetail.edit ? "Update Admin" : "Add Admin"}</h5>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={(e) => {
                                e.preventDefault()
                                handleClose()
                            }}>
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <form action="" autoComplete="off">
                                <input type="hidden" value="something" />
                                <div className="form-group">
                                    <label htmlFor="admin_name">Name</label>
                                    <input type="text" id="admin_name" className="form-control" value={name ? name : ""} required onChange={(e) => {
                                        setName(e.target.value)
                                    }} />
                                </div>
                                <div className="form-group">
                                    <label htmlFor="admin_email">Email</label>
                                    <input type="email" id="admin_email" className="form-control" value={email ? email : ""} autoComplete="off" required onChange={(e) => {
                                        setEmail(e.target.value)
                                    }} />
                                </div>
                                <div className="form-group">
                                    <label htmlFor="admin_mobile">Mobile</label>
                                    <input type="text" id="admin_mobile" className="form-control" maxLength={10} value={mobile ? mobile : ""} required onChange={(e) => {
                                        setMobile(e.target.value)
                                    }} />
                                </div>
                                <div className="form-group">
                                    <label htmlFor="admin_role">Select role</label>
                                    <select name="admin_role" id="admin_role" className="form-control" value={role ? role : "default"} onChange={(e) => {
                                        setRole(e.target.value)
                                    }}>
                                        <option disabled value="default"> Select an option </option>
                                        {roleUserList ? roleUserList.map((element, key) => {

                                            return (<option key={`rolUser` + key} value={element.id}>{element.name}</option>)
                                        }) : null}
                                    </select>
                                </div>
                                <div className="form-group">
                                    <label htmlFor="admin_password">Password</label>
                                    <input type="password" id="admin_password" className="form-control" autoComplete="off" required onChange={(e) => {
                                        setPassword(e.target.value)
                                    }} />
                                    <label>{configs.passValidationMessage}</label>
                                </div>
                                <div className="form-check p-0">
                                    <p className="text-danger" id="addAdminError">{error}</p>
                                </div>
                                <div className="form-row text-center">
                                    <div className="col-12 d-flex justify-content-center">
                                        <button id="createAdminBtn" type="submit" className="btn btn-primary mt-3 mb-3" disabled={editAdminDetail.edit ?
                                            name && email && role ?
                                                false : true :
                                            name && email && role && password ?
                                                false :
                                                true} onClick={(e) => {
                                                    e.preventDefault();
                                                    createNewAdmin()
                                                }}>{editAdminDetail.edit ? "Update Admin" : "Add Admin"}</button>

                                        <div id="createAdminBtnLoader" className='animBtn d-none'><div className='gSignIn-Anim'></div></div>
                                    </div>
                                </div>

                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </Modal>
    )
}