import React, { useEffect, useState } from "react";
import { useRMContext } from "../../../services/contexts/RMContext";
import { useLogin } from "../../../services/contexts/AdminLoginContext";
import { Modal } from "@material-ui/core";
import '../../../assets/styles/RM.css'



export default function EditFunctionPage() {

    const { functionDetail, editFunctions, editFunction, setEditFunction } = useRMContext();

    const { setShowSuccess } = useLogin()
    const [error, setError] = useState("");
    const [extraName, setExtraName] = useState("")
    const [description, setDescription] = useState("")

    useEffect(() => {
        if (editFunction) {
            try {
                setExtraName(functionDetail.data.extra_name)
                setDescription(functionDetail.data.description)
            } catch (error) {

            }
        }
    }, [editFunction])

    // handle modal close
    const handleClose = () => {
        setEditFunction(false)
        setError("")
        setExtraName("")
        setDescription("")

    }

    // edit function save data
    async function updateFunctionDetail() {
        try {
            if (!extraName.trim()) {
                setError("Please provide a valid extra name for function.")
            } else if (!description.trim()) {
                setError("Please provide a valid description for function.")
            } else if (extraName !== functionDetail.data.extra_name || description !== functionDetail.data.description) {
                let response = await editFunctions(extraName, description);
                if (response === "success") {
                    setError("")
                    handleClose()
                    setShowSuccess(true)
                } else {
                    setError(response)
                }
            } else {
                setError("Details have not been changed. Please modify details to update.")
            }
        } catch (error) {

        }
    }

    //edit function ui code
    return (editFunction ? <Modal open={editFunction} onClose={handleClose}>
        <div className="modal-fade" id="KYCSubmitModal" tabIndex="-1" role="dialog" aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered" role="document">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title" id="exampleModalLongTitle">Manage Function</h5>
                        <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={(e) => {
                            e.preventDefault();
                            setEditFunction(false)
                        }}>
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div className="modal-body">
                        <form action="">
                            <div className="form-group">
                                <label htmlFor="function_name" >Name</label>
                                <input type="text" className="form-control" disabled value={functionDetail.data.name ? functionDetail.data.name : ""} />
                            </div>
                            <div className="form-group">
                                <label htmlFor="function_extra_name" >Extra Name</label>
                                <input type="text" className="form-control" value={extraName} onChange={(e) => {
                                    setExtraName(e.target.value)
                                }} />
                            </div>
                            <div className="form-group">
                                <label htmlFor="function_description" >Description</label>
                                <textarea className="form-control w-100" rows="5" value={description} onChange={(e) => {
                                    setDescription(e.target.value)
                                }} />
                            </div>
                        </form>
                        <div className="form-check p-0">
                            <p className="text-danger" id="editFunctionError">{error}</p>
                        </div>
                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-primary" disabled={extraName && description ? false : true} onClick={(e) => {
                            updateFunctionDetail(e)
                        }}>Update Function</button>
                    </div>
                </div>
            </div>
        </div>
    </Modal>
        : null
    )
}