import { DataGrid, GridToolbar } from '@material-ui/data-grid';
import React, { useEffect, useState } from 'react';
import { configs } from '../../../assets/Config';
import '../../../assets/styles/RM.css'
import { useLogin } from '../../../services/contexts/AdminLoginContext';
import { useCMContext } from '../../../services/contexts/companyManagement/CMContext';
import ConfirmModalPage from '../../includes/ConfirmModalPage';
import { getCompanyOpenOrders, getInventoryAnalytics } from '../../../services/apis/shareManagement/InventoryAPI';
import { useKYCContext } from '../../../services/contexts/KYCContext';
import { Link } from 'react-router-dom';

export default function InventoryAnalyticsPage() {
    const { allowedFunc, setCurrModule, token } = useLogin();
    const { getInventoryAnalyticsData, analytics, analyticsDate, setAnalyticsDate, setAnalytics, companyDetail, setCompanyDetail } = useCMContext();
    const { setKycRequesterUserDetail } = useKYCContext();

    useEffect(() => {
        (async () => {
            try {
                if (allowedFunc.includes(configs.functionList['buy_module:export_inventory']) || allowedFunc.includes(configs.functionList['buy_module:update_inventory'])) {
                    setCurrModule(configs.modules.smInventory);
                    getInventoryAnalyticsData(companyDetail);
                } else {

                }
            } catch (error) {

            }
        })()
    }, [companyDetail])




    const columns = [{ field: "id", headerName: "Company Id", minWidth: 150, flex: 0.5, hide: true, filterable: false },
    { field: "isin", headerName: "ISIN", minWidth: 150, flex: 0.5, hide: true, filterable: false },
    { field: "name", headerName: "Name", minWidth: 300, flex: 0.5, cellClassName: "break-text" },
    { field: "inventory_quantity", headerName: "Inventory Quantity", minWidth: 300, flex: 0.5, cellClassName: "break-text", hide: true },
    { field: "nsdlv_quantity", headerName: "NSDL V Quantity", minWidth: 150, flex: 0.5, cellClassName: "break-text text-right" },
    { field: "nsdlk_quantity", headerName: "NSDL K Quantity", minWidth: 150, flex: 0.5, cellClassName: "break-text text-right" },
    { field: "cdsl_quantity", headerName: "CDSL Quantity", minWidth: 150, flex: 0.5, cellClassName: "break-text text-right" },
    { field: "physical_inventory", headerName: "Physical Quantity", minWidth: 150, flex: 0.5, cellClassName: "break-text text-right" },
    { field: "buy_order_share_transfer_pending", headerName: "Buy Order Share Transfer Pending", minWidth: 150, flex: 0.5, cellClassName: "break-text text-right" },
    { field: "buy_order_acknowledgement_pending", headerName: "Buy Order Acknowledgment Pending", minWidth: 150, flex: 0.5, cellClassName: "break-text text-right" },
    { field: "sell_order_payment_pending_with_dealer_flag", headerName: "Sell Order Payment Pending Dealer Flag", minWidth: 150, flex: 0.5, cellClassName: "break-text text-right" },
    { field: "net_inventory", headerName: "Net Inventory", minWidth: 150, flex: 0.1, cellClassName: "break-text text-right" },
    { field: "bs", headerName: "BS", minWidth: 150, flex: 0.5, cellClassName: "break-text text-right" },
    { field: "ideal_inventory", headerName: "Ideal Inventory", minWidth: 150, flex: 0.5, cellClassName: "break-text text-right" },
    { field: "sell_order_share_transfer_pending", headerName: "Sell Order Share Transfer Pending", minWidth: 150, flex: 0.5, cellClassName: "break-text text-right" },
    { field: "buy_order_payment_pending", headerName: "Buy Order Payment Pending", minWidth: 150, flex: 0.5, cellClassName: "break-text text-right" },
    { field: "portal_change", headerName: "Portal Change", minWidth: 150, flex: 0.1, cellClassName: "break-text text-right" },
    { field: "total_sell_quantity", headerName: "Total Sell Quantity", minWidth: 150, flex: 0.5, cellClassName: "break-text text-right" },
    { field: "total_buy_quantity", headerName: "Total Buy Quantity", minWidth: 150, flex: 0.5, cellClassName: "break-text text-right" },
    {
        field: "Buy", headerName: "Open Buy Orders", minWidth: 150, flex: 0.5, cellClassName: "break-text", cellClassName: "text-primary", renderCell: (params) => {
            return <a href="#" className="custom-link text-primary" onClick={(e) => {
                e.preventDefault()
                setAnalytics("");
                setCompanyDetail({
                    name: params.row.name,
                    id: params.row.id,
                    type: "Buy"
                })
            }}>Open Buy Orders</a>
        }
    },
    {
        field: "Sell", headerName: "Open Sell Orders", minWidth: 150, flex: 0.5, cellClassName: "break-text", cellClassName: "text-primary", renderCell: (params) => {
            return <a href="#" className="custom-link text-primary" onClick={(e) => {
                e.preventDefault()
                setAnalytics("");
                setCompanyDetail({
                    name: params.row.name,
                    id: params.row.id,
                    type: "Sell"
                })
            }}>Open Sell Orders</a>
        }
    }
    ]

    const companyColumns = [{ field: "id", headerName: "Order Id", minWidth: 70, flex: 0.3, filterable: false },
    {
        field: "name", headerName: "Name", minWidth: 150, flex: 0.4, cellClassName: "break-text f-12", valueGetter: (params) => {
            return params.row.user.name
        }
    },
    {
        field: "mobile", headerName: "Phone No.", minWidth: 100, flex: 0.5, cellClassName: "break-text f-12", renderCell: (params) => {
            return <a href={"https://wa.me/" + params.row.user.mobile} target="_blank">{params.row.user.mobile ? `+${params.row.user.mobile}` : params.row.user.mobile}</a>
        }, valueGetter: (value) => value.row.user.mobile ? `+${value.row.user.mobile}` : value.row.user.mobile
    },
    {
        field: "email", headerName: "E-mail", minWidth: 250, flex: 0.5, cellClassName: "break-text f-12", renderCell: (params) => {
            if (allowedFunc.includes(configs.functionList['user_management:load_user_details'])) {
                return <Link to={`/customerProfile/${params.row.user.id}`} onClick={() => {
                    setKycRequesterUserDetail(params.row.user)
                }}>{params.row.user.email}</Link>
            } else {
                return params.row.user.email
            }
        }, valueGetter: (value) => value.row.user.email
    },
    { field: "quantity", headerName: "Quantity", minWidth: 150, flex: 0.5, filterable: false },
    { field: "price_per_share", headerName: "Price per Share", minWidth: 150, flex: 0.5, cellClassName: "break-text" },
    { field: "status", headerName: "Status", minWidth: 300, flex: 0.5, cellClassName: "break-text", hide: true },
    { field: "statusMessage", headerName: "Status Message", minWidth: 300, flex: 0.5, cellClassName: "break-text" },
    { field: "datetime", headerName: "Timestamp", minWidth: 150, flex: 0.5, cellClassName: "break-text" }
    ]

    return (
        <div id="inventoryAnalyticsListPage" style={{ width: "100%" }}>
            <div className="row d-flex align-items-center mt-2 ml-3 mb-1 mr-2">
                <div className="col-md-6 col-12">
                    <p className=""><span className='h4 text-custom-grey'>{companyDetail ? <i className="fas fa-arrow-circle-left mr-1 curPoint text-primary" aria-hidden="true" onClick={(e) => {
                        setCompanyDetail("")
                        setAnalytics("");
                        setAnalyticsDate("");
                    }}></i> : null}{companyDetail ? companyDetail.name : "Inventory Analytics"}</span>{companyDetail ? ` (Open ${companyDetail.type} orders)` : ""}</p>
                </div>
                {analyticsDate ?
                    <div className='col-md-6 col-12'>
                        <p className='m-0'>Inventory Updated: <b>{analyticsDate.inventory_update}</b></p>
                        <p className='m-0'>Depository Updated: <b>{analyticsDate.depository_update}</b></p>
                    </div> : null}
                <div className='row m-0 mx-3 p-0 d-md-none d-block'>
                    <a href='#' className='custom-link' onClick={(e) => {
                        e.preventDefault()
                        document.getElementById("updateExportInventory").classList.toggle('d-none')
                        document.getElementById("inventoryAnalyticsListPage").classList.toggle('d-none')
                    }}><p className='m-0 p-0'>Update/Export Inventory</p></a>
                </div>
            </div>
            <div className=" deleted-role-table">
                {analytics ? <DataGrid
                    rows={analytics}
                    headerHeight={40}
                    rowHeight={30}
                    columns={companyDetail ? companyColumns : columns}
                    resizable={true}
                    autoPageSize
                    pagination
                    components={{
                        Toolbar: GridToolbar,
                    }} /> : <div className="loader"></div>}
            </div>
        </div>
    )
}