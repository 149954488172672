import React from "react";
import { Redirect, Route } from "react-router-dom";
import { useLogin } from "../contexts/AdminLoginContext";


export default function LoginPrivateRoute({ children, ...rest }) {
    const { token } = useLogin();
    return (
        <Route
            {...rest}
            render={({ location }) =>
                token ? (
                    children
                ) : (
                    <Redirect to={{ pathname: "/login", state: { from: location } }} />
                )
            }
        />
    );
}
