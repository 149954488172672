import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { configs } from '../../../assets/Config';
import { useLogin } from '../../../services/contexts/AdminLoginContext';
import LeftBarPage from '../../includes/dashboard/LeftBarPage';
import TopBarPage from '../../includes/dashboard/TopBarPage';
import ToastPage from '../../includes/ToastPage';
import '../../../assets/styles/RM.css'
import { useStartupContext } from '../../../services/contexts/assetManagement/StartupContext';
import ListStartupPage from './ListStartupPage';
import CreateStartupPage from './CreateStartupPage';
import DeletedStartupPage from './DeletedStartupPage';


export default function StartupMainPage() {

    const { StartupList, getAllStartupList, showDeletedStartup } = useStartupContext()
    const { setCurrModule, allowedFunc } = useLogin()

    const history = useHistory()

    useEffect(() => {
        (async () => {
            try {
                if (allowedFunc.includes(configs.functionList['startup_management:view_asset_data'])) {
                    setCurrModule(configs.modules.startupMgmt)
                    getAllStartupList()
                } else {
                    return history.goBack()
                }
            } catch (error) {

            }
        })()
    }, [])

    // role management main ui code
    return (
        <>
            <TopBarPage />
            <div style={{ display: "flex" }}>
                <LeftBarPage />
                {showDeletedStartup ? <div id='rmContainer' className="rm-container"><DeletedStartupPage /></div> :
                    <div id='rmContainer' className="rm-container">
                        <ListStartupPage />
                        {allowedFunc.includes(configs.functionList['startup_management:create_asset']) || allowedFunc.includes(configs.functionList['startup_management:update_asset']) ?
                            <CreateStartupPage />
                            : null}
                        <ToastPage />
                    </div>
                }
            </div>
        </>
    )
}