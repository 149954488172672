import React from 'react';
import { createContext, useContext, useState } from 'react';
import { configs } from '../../assets/Config';
import { credentialLogin, adminLogout, getAdminFunctions, getRemarkComment, getRemarkCommentTemlate, addMasterRemark, editMasterRemark, deleteMasterRemark, getReferralDetails, getDistributorReferralDetails, resetAdminPassword } from '../apis/AdminLoginApi';

// create global login context
export const LoginContext = createContext();

export function LoginProvider({ children }) {
    // fetch existing login session 
    const { isUserLoggedIn, token: savedToken } = JSON.parse(localStorage?.getItem("alogin")) || {};
    const [login, setLogin] = useState(isUserLoggedIn);
    const [token, setToken] = useState(savedToken);
    const [loginError, setLoginError] = useState("");
    const [profileModal, setProfileModal] = useState(false);
    const [showSuccess, setShowSuccess] = useState(false);
    const [showDelete, setShowDelete] = useState(false);
    const [showConfirm, setShowConfirm] = useState(false);
    const [currModule, setCurrModule] = useState(configs.modules.mn);
    const [allowedFunc, setAllowedFunc] = useState(configs.testFunc)
    const [remarkComments, setRemarkComments] = useState("")
    const [addAssetPopup, setaddAssetPopup] = useState(false)
    const [dashboardHighlight, setDashboardHighlight] = useState([]);
    const [highlightManagement, setHighlightManagement] = useState({
        asset: '',
        showPage: false
    });

    // set update login session details
    async function saveLoginDetails(token) {
        try {
            setLogin(true);
            setToken(token);
            const time = Date.now();
            localStorage.setItem(
                "alogin",
                JSON.stringify({ isUserLoggedIn: true, token, time })
            );
        } catch (error) {

        }
    }

    // login admin with credentials shared
    async function loginUserWithCredentials(username, password) {
        try {
            let response = await credentialLogin(username, password);
            if (response && response.status === 200) {
                if (response.data.functions && response.data.functions.length > 0) {
                    saveLoginDetails(response.data.token);
                    setAllowedFunc(response.data.functions)
                } else {
                    setLogin(false)
                    setLoginError("You are not allowd to login. Kindly contact administrator to grant you permission.")
                }
            } else {
                setLogin(false)
                setLoginError(response.data.error)
            }
        } catch (error) {

        }
    }

    // get logged in admin's functions
    async function getAdminAllowedFunc() {
        try {
            let response = await getAdminFunctions(token);
            if (response && response.status === 200) {
                if (response.data && response.data.length > 0) {
                    setAllowedFunc(response.data)
                } else {
                    setLogin(false)
                    setLoginError("You are not allowd to login. Kindly contact administrator to grant you permission.")
                }
            } else {
                setLogin(false)
                setLoginError("You are not allowd to login. Kindly contact administrator to grant you permission.")
            }

        } catch (error) {

        }
    }

    // get remarks master comments
    async function getAllRemarkComments(type) {
        try {
            type = type ? type : ""
            let response = await getRemarkComment(token, type);
            if (response && response.status === 200) {
                setRemarkComments(response.data)
            } else if (response && response.status === 401) {
                logout();
            }
            else { return response.data.error }

        } catch (error) {

        }
    }

    // get remarks template for comment
    async function getAllRemarkTemplate() {
        try {
            let response = await getRemarkCommentTemlate(token);
            if (response && response.status === 200) {
                return {
                    success: "success",
                    data: response.data
                }
            } else if (response && response.status === 401) {
                logout();
            }
            else { return response.data.error }

        } catch (error) {

        }
    }

    // add remarks template for comment
    async function addRemarkTemplate(templateId, remark) {
        try {
            let response = await addMasterRemark(token, templateId, remark);
            if (response && response.status === 200) {
                return {
                    success: "success",
                }
            } else if (response && response.status === 401) {
                logout();
            }
            else { return response.data.error }

        } catch (error) {

        }
    }

    // edit remarks template for comment
    async function editRemarkTemplate(templateId, remark) {
        try {
            let response = await editMasterRemark(token, templateId, remark);
            if (response && response.status === 200) {
                return {
                    success: "success",
                }
            } else if (response && response.status === 401) {
                logout();
            }
            else { return response.data.error }

        } catch (error) {

        }
    }

    // delete remarks template for comment
    async function deleteRemarkTemplate(templateId) {
        try {
            let response = await deleteMasterRemark(token, templateId);
            if (response && response.status === 200) {
                return {
                    success: "success",
                }
            } else if (response && response.status === 401) {
                logout();
            }
            else { return response.data.error }

        } catch (error) {

        }
    }

    // get referral details
    async function getAllReferralDetails() {
        try {
            let response = await getReferralDetails(token);
            if (response && response.status === 200) {
                return {
                    success: "success",
                    data: response.data
                }
            } else if (response && response.status === 401) {
                logout();
            }
            else { return response.data.error }

        } catch (error) {

        }
    }

    // get distributor referral details
    async function getDistributorAllReferralDetails() {
        try {
            let response = await getDistributorReferralDetails(token);
            if (response && response.status === 200) {
                return {
                    success: "success",
                    data: response.data
                }
            } else if (response && response.status === 401) {
                logout();
            }
            else { return response.data.error }

        } catch (error) {

        }
    }

    // clear session on logout
    async function logout() {
        try {
            setLogin(false);
            setToken(null);
            localStorage.removeItem("alogin");
            let response = await adminLogout(token);
        } catch (error) {
            console.error(error);
        }

    }

    // admin update password
    async function adminChangePassword(currentPass, newPass, cnfPass) {
        try {
            let response = await resetAdminPassword(currentPass, newPass, cnfPass, token);
            if (response && response.status === 200) {
                return {
                    success: "success",
                    data: response.data
                }
            } else if (response && response.status === 401) {
                logout();
            }
            else { return response.data.error }

        } catch (error) {

        }
    }

    // export global context
    return (
        <LoginContext.Provider
            value={{
                login,
                loginUserWithCredentials,
                getAdminAllowedFunc,
                setLogin,
                logout,
                loginError,
                setLoginError,
                token,
                saveLoginDetails,
                profileModal,
                setProfileModal,
                showSuccess,
                setShowSuccess,
                currModule,
                setCurrModule,
                showDelete,
                setShowDelete,
                showConfirm,
                setShowConfirm,
                allowedFunc,
                getAllRemarkComments,
                remarkComments,
                setRemarkComments,
                getAllRemarkTemplate,
                addRemarkTemplate,
                editRemarkTemplate,
                deleteRemarkTemplate,
                getAllReferralDetails,
                getDistributorAllReferralDetails,
                addAssetPopup,
                setaddAssetPopup,
                adminChangePassword,
                dashboardHighlight,
                setDashboardHighlight,
                highlightManagement,
                setHighlightManagement
            }}
        >
            {children}
        </LoginContext.Provider>
    );
}

// check if existing login session has expired 
export function useLogin() {
    // const { time } = JSON.parse(localStorage?.getItem("alogin")) || {};
    // const currentTime = Date.now();
    // if (currentTime - time > 2 * 60 * 60 * 1000) {
    //     localStorage.removeItem("alogin");
    // }

    return useContext(LoginContext);
}