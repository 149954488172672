import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { configs } from '../../../assets/Config';
import { useLogin } from '../../../services/contexts/AdminLoginContext';
import LeftBarPage from '../../includes/dashboard/LeftBarPage';
import TopBarPage from '../../includes/dashboard/TopBarPage';
import ToastPage from '../../includes/ToastPage';
import '../../../assets/styles/RM.css'
import { useMLDContext } from '../../../services/contexts/assetManagement/MLDContext';
import ListMLDPage from './ListMLDPage';
import CreateMLDPage from './CreateMLDPage';
import DeletedMLDPage from './DeletedMLDPage';


export default function MLDMainPage() {

    const { MLDList, getAllMLDList, showDeletedMLD } = useMLDContext()
    const { setCurrModule, allowedFunc } = useLogin()

    const history = useHistory()

    useEffect(() => {
        (async () => {
            try {
                if (allowedFunc.includes(configs.functionList['mld_management:view_asset_data'])) {
                    setCurrModule(configs.modules.mldMgmt)
                    getAllMLDList()
                } else {
                    return history.goBack()
                }
            } catch (error) {

            }
        })()
    }, [])

    // role management main ui code
    return (
        <>
            <TopBarPage />
            <div style={{ display: "flex" }}>
                <LeftBarPage />
                {showDeletedMLD ? <div id='rmContainer' className="rm-container"><DeletedMLDPage /></div> :
                    <div id='rmContainer' className="rm-container">
                        <ListMLDPage />
                        {allowedFunc.includes(configs.functionList['mld_management:create_asset']) || allowedFunc.includes(configs.functionList['mld_management:update_asset']) ?
                            <CreateMLDPage />
                            : null}
                        <ToastPage />
                    </div>
                }
            </div>
        </>
    )
}