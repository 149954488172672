import { Modal } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { configs } from '../../../assets/Config';
import '../../../assets/styles/RM.css'
import { uploadCompanyDataDoc } from '../../../services/apis/companyManagement/CMApi';
import { useLogin } from '../../../services/contexts/AdminLoginContext';
import { useBondContext } from '../../../services/contexts/assetManagement/BondContext';
import { useCMContext } from '../../../services/contexts/companyManagement/CMContext';
import RichTextEditorPage from '../../includes/RichTextEditorPage';

export default function CreateBondPage() {

    const { setShowSuccess, token, logout, setLoginError, allowedFunc } = useLogin()
    const { editBond, setEditBond, editBondData, setEditBondData, createEditBond, getAllBondList, setBsnapshot, setBkeyHigh, setBdeal, setBterms, setBfaq, setBdoc } = useBondContext()
    const { setShowRTPage, showRTPage } = useCMContext()

    const statusValues = ["DRHP Not Filed", "DRHP Filed", "DRHP Approved", "Delisted", "Listed but not traded", "IPO in Process", "Now Listed"]

    const [logoFile, setLogoFile] = useState("")
    const [name, setName] = useState(editBondData.name)
    const [description, setDescription] = useState(editBondData.description)
    const [key1, setKey1] = useState(editBondData.key_value ? editBondData.key_value.key_1 : "")
    const [key2, setKey2] = useState(editBondData.key_value ? editBondData.key_value.key_2 : "")
    const [value1, setValue1] = useState(editBondData.key_value ? editBondData.key_value.value_1 : "")
    const [value2, setValue2] = useState(editBondData.key_value ? editBondData.key_value.value_2 : "")
    const [isin, setISIN] = useState(editBondData.isin)
    const [rrr, setRRR] = useState(editBondData.rate_of_return)
    const [maturity, setMaturity] = useState(editBondData.maturity_period)
    const [rating, setRating] = useState(editBondData.rating)
    const [disabletrading, setDisableTrading] = useState(editBondData.disabletrading)
    const [error, setError] = useState("")
    const [rtPageType, setRTPageType] = useState("")

    const [file, setFile] = useState("")
    const [fileError, setFileError] = useState("")
    const [errorDetail, setErrorDetail] = useState("")
    const [showErrorModal, setShowErrorModal] = useState(false)

    const history = useHistory()


    useEffect(() => {
        try {
            if (editBondData) {
                setName(editBondData.name)
                setDescription(editBondData.description)
                setKey1(editBondData.key_value.key_1)
                setKey2(editBondData.key_value.key_2)
                setValue1(editBondData.key_value.value_1)
                setValue2(editBondData.key_value.value_2)
                setISIN(editBondData.isin)
                setRRR(editBondData.rate_of_return)
                setMaturity(editBondData.maturity_period)
                setRating(editBondData.rating)
                setDisableTrading(editBondData.disabletrading.toString())
                setBsnapshot(editBondData.section_data.snapshot)
                setBkeyHigh(editBondData.section_data.key_investment_highlights)
                setBdeal(editBondData.section_data.deal_structure)
                setBterms(editBondData.section_data.deal_terms)
                setBfaq(editBondData.section_data.faqs)
                setBdoc(editBondData.section_data.documents)
                setError("")
            }
        } catch (error) {

        }
    }, [editBondData])


    // add company function
    async function addBond() {
        try {

            document.getElementById("bondBtn").classList.toggle("d-none")
            document.getElementById("bondBtnLoader").classList.toggle("d-none")

            const red = /^[0-9]\d*(\.\d{1,2})?$/;
            // .toString().match(red)
            if (editBond && !editBondData.id) {
                setError("Please select a valid Bond to edit.")
            } else if (!name || (name && name.length > 200)) {
                setError("Please provide a valid Bond name.")
            } else if (!description) {
                setError("Please provide a valid Description.")
            } else if (!isin || (isin && !isin.match(/^[a-zA-Z0-9]{12}$/))) {
                setError("Please provide a valid ISIN.")
            } else if (!rrr) {
                setError("Please enter a valid Rate of Return.")
            } else if (!maturity) {
                setError("Please enter a valid Maturity/Tenure.")
            } else if (!rating) {
                setError("Please enter a valid rating.")
            } else if (!disabletrading || (disabletrading != null && (disabletrading != 0 && disabletrading != 1))) {
                setError("Please select valid Disable trading value.")
            } else if (!editBond && !logoFile) {
                setError("Please select a valid logo file.")
            } else {
                let response = await createEditBond(name, isin, rrr, maturity, rating, disabletrading, description, key1, value1, key2, value2, logoFile, editBond, editBondData.id)

                if (response && response === "success") {
                    resetValues()
                    setShowSuccess(true)
                    getAllBondList()
                } else {
                    setError(response)
                }
            }

            document.getElementById("bondBtn").classList.toggle("d-none")
            document.getElementById("bondBtnLoader").classList.toggle("d-none")

        } catch (error) {
        }
    }

    // reset all values
    function resetValues() {
        try {
            setName("")
            setDescription("")
            setKey1("")
            setKey2("")
            setValue1("")
            setValue2("")
            setISIN("")
            setRRR("")
            setMaturity("")
            setRating("")
            setDisableTrading("")
            setLogoFile("")
            setEditBond(false)
            setError("")
            setBsnapshot("")
            setBkeyHigh("")
            setBdeal("")
            setBterms("")
            setBfaq("")
            setBdoc("")
        } catch (error) {

        }
    }

    // get file contents through file chooser
    function uploadFile(file, type) {
        try {
            if (type === "Logo" && (file.type === "image/jpg" || file.type === "image/jpeg" || file.type === "image/png")) {
                setLogoFile(file)
            } else if (type !== "Logo" && (file.type === ".xls" || file.type === "xlsx" || file.type === "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" || file.type === "application/vnd.ms-excel")) {
                setFile(file)
                setFileError("")
            } else {
                type === "Logo" ? alert("Only jpg or png files are allowed.") : alert("Only csv files are allowed.")
            }
        } catch (error) {
            console.error(error)
        }
    }

    // create role ui code
    return (
        <div id="rmCreateCompany" className="col-lg-3 col-md-4 d-md-block d-none pt-3 main-rmPanel">
            {((allowedFunc.includes(configs.functionList["bond_management:update_asset"]) && editBond) || allowedFunc.includes(configs.functionList["bond_management:create_asset"])) ?
                <>
                    <p className="h6 text-custom-grey">{editBond ? "Edit Bond Information" : "Add a Bond"}</p>

                    <div className="function-container-asset">
                        <form id="createRoleForm" action="" className='mr-1'>
                            <div className="form-group">
                                <label className="f-12">Bond Name</label>
                                <input type="text" id="roleName" className="form-control f-10" placeholder="Bond Name" value={name} required onChange={(e) => {
                                    setName(e.target.value)
                                }} />
                            </div>
                            <div className="form-group">
                                <label className="f-12">Description</label>
                                <input type="text" id="roleName" className="form-control f-10" placeholder="Description" value={description} required onChange={(e) => {
                                    setDescription(e.target.value)
                                }} />
                            </div>
                            <div className="form-group">
                                <label className="f-12">ISIN</label>
                                <input type="text" id="roleName" className="form-control f-10" placeholder="ISIN" value={isin} required onChange={(e) => {
                                    setISIN(e.target.value)
                                }} />
                            </div>
                            <div className="form-group">
                                <label className="f-12">Rate of Return</label>
                                <input type="text" id="roleName" className="form-control f-10" placeholder="Rate of Return" value={rrr} required onChange={(e) => {
                                    setRRR(e.target.value)
                                }} />
                            </div>
                            <div className="form-group">
                                <label className="f-12">Maturity/Tenure</label>
                                <input type="text" id="roleName" className="form-control f-10" placeholder="Maturity/Tenure" value={maturity} required onChange={(e) => {
                                    setMaturity(e.target.value)
                                }} />
                            </div>
                            <div className="form-group">
                                <label className="f-12">Rating</label>
                                <input type="text" id="roleName" className="form-control f-10" placeholder="Rating" value={rating} required onChange={(e) => {
                                    setRating(e.target.value)
                                }} />
                            </div>
                            <div className="form-group">
                                <label className="f-12">Category 1 Title</label>
                                <input type="text" id="roleName" className="form-control f-10" placeholder="Category 1 Title" value={key1} required onChange={(e) => {
                                    setKey1(e.target.value)
                                }} />
                            </div>
                            <div className="form-group">
                                <label className="f-12">Category 1 Value</label>
                                <input type="text" id="roleName" className="form-control f-10" placeholder="Category 1 Value" value={value1} required onChange={(e) => {
                                    setValue1(e.target.value)
                                }} />
                            </div>
                            <div className="form-group">
                                <label className="f-12">Category 2 Title</label>
                                <input type="text" id="roleName" className="form-control f-10" placeholder="Category 2 Title" value={key2} required onChange={(e) => {
                                    setKey2(e.target.value)
                                }} />
                            </div>
                            <div className="form-group">
                                <label className="f-12">Category 2 Value</label>
                                <input type="text" id="roleName" className="form-control f-10" placeholder="Category 2 Value" value={value2} required onChange={(e) => {
                                    setValue2(e.target.value)
                                }} />
                            </div>
                            <div className="form-group">
                                <label className="f-12">Disable Trading</label>
                                <select name="" id="" className="col-12 py-1 px-2 border rounded f-12" value={disabletrading != null ? disabletrading : "default"} onChange={(e) => {
                                    setDisableTrading(e.target.value)
                                }}>
                                    <option className='f-12' value="default"> ---- </option>
                                    <option className='f-12' value="0"> No </option>
                                    <option className='f-12' value="1"> Yes </option>
                                </select>
                            </div>
                            <div className="form-group">
                                <a className="f-12" href='#' onClick={(e) => {
                                    e.preventDefault()
                                    setShowRTPage(true)
                                    setRTPageType("Bond Snapshot")
                                }}>Add Snapshot</a>
                            </div>
                            <div className="form-group">
                                <a className="f-12" href='#' onClick={(e) => {
                                    e.preventDefault()
                                    setShowRTPage(true)
                                    setRTPageType("Bond Key Investment Highlights")
                                }}>Add Key Investment HighLights</a>
                            </div>
                            <div className="form-group">
                                <a className="f-12" href='#' onClick={(e) => {
                                    e.preventDefault()
                                    setShowRTPage(true)
                                    setRTPageType("Bond Deal Structure")
                                }}>Add Deal Structure</a>
                            </div>
                            <div className="form-group">
                                <a className="f-12" href='#' onClick={(e) => {
                                    e.preventDefault()
                                    setShowRTPage(true)
                                    setRTPageType("Bond Deal Terms")
                                }}>Add Deal Terms</a>
                            </div>
                            <div className="form-group">
                                <a className="f-12" href='#' onClick={(e) => {
                                    e.preventDefault()
                                    setShowRTPage(true)
                                    setRTPageType("Bond FAQ")
                                }}>Add FAQs</a>
                            </div>
                            <div className="form-group">
                                <a className="f-12" href='#' onClick={(e) => {
                                    e.preventDefault()
                                    setShowRTPage(true)
                                    setRTPageType("Bond Documents")
                                }}>Add Documents</a>
                            </div>
                            <div className="form-group">
                                <label className="f-12">Logo</label>
                                <p className="pb-0 mb-0 f-12 ">
                                    <input type="file" id="logo" required hidden accept="image/jpg, image/jpeg, image/png" onChange={(e) => {
                                        uploadFile(e.target.files[0], "Logo")
                                    }} />
                                    <label htmlFor="logo" className="mb-0 p-1  curPoint btn-primary">Choose Logo File</label>
                                    <br /> File to be uploaded: <span className="text-theme">{logoFile.name}</span>
                                </p>
                            </div>
                        </form>
                    </div>
                    <div className="form-check p-0">
                        <p className="text-danger" id="createRoleError">{error}</p>
                    </div>
                    <div className='row m-0 p-0'>
                        <div className='col-9 m-0 p-0'>
                            <button id="bondBtn" className="btn btn-primary f-12" onClick={(e) => {
                                e.preventDefault()
                                addBond()
                            }}>{editBond ? "Update Bond" : "Add Bond"}</button>
                            <div id="bondBtnLoader" className='animBtn d-none'><div className='gSignIn-Anim'></div></div>
                        </div>
                        <div className='col-3 m-0 p-0'>
                            <button className="btn btn-primary f-12" onClick={(e) => {
                                e.preventDefault()
                                resetValues()
                            }}>Reset</button>
                        </div>
                    </div>
                    <div className='row m-0 p-0'>
                        <button className="btn btn-primary mt-3 d-md-none" onClick={(e) => {
                            e.preventDefault()
                            document.getElementById("rmCreateCompany").classList.toggle('d-none')
                            document.getElementById("companyListPage").classList.toggle('d-none')
                            history.push('/assetManagement/bond')
                        }}>View All Bond</button>
                    </div>
                </>
                : null}
            {/* --------------------------------create compnay overview--------------------------------- */}
            {showRTPage ? <RichTextEditorPage {...{ type: rtPageType }} /> : null}
        </div>
    )
}