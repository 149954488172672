import { useEffect, useState } from "react"
import { useRMContext } from "../../services/contexts/RMContext";

export default function TreeSelection({ title, content, anySelected }) {

    const { arFunction, setarFunction, removeFunction, setRemoveFunction } = useRMContext();

    const [isActive, setIsActive] = useState(false);

    useEffect(() => {
        setIsActive(anySelected)
    }, [anySelected])

    return (
        <div className="accordion">
            <div className="accordion-item">
                <div className="accordion-title">
                    <div style={{
                        alignItems: "center",
                        display: "flex"
                    }} >
                        <input type="checkbox" className="mr-1" checked={arFunction && arFunction.length > 0 && arFunction.includes(title.id)} onChange={(e) => {
                            let list = arFunction;
                            if (e.target.checked) {
                                if (!list.includes(title.id)) {
                                    list.push(title.id)
                                    setarFunction([title.id, ...arFunction])
                                    setRemoveFunction([title.id, ...removeFunction])
                                }
                                content.forEach(element => {
                                    if (!list.includes(element.id)) {
                                        list.push(element.id)
                                        setarFunction([title.id, ...arFunction])
                                    }
                                });
                                // setarFunction(list);
                            } else {
                                if (list.includes(title.id)) {
                                    list.splice(list.indexOf(title.id, 1));
                                    setarFunction(arFunction.filter(id => id !== title.id))
                                    setRemoveFunction(removeFunction.filter(id => id !== title.id))
                                }
                                content.forEach(element => {
                                    if (list.includes(element.id)) {
                                        list.splice(list.indexOf(element.id), 1)
                                        setarFunction(arFunction.filter(id => id !== element.id))
                                    }
                                });
                                // setarFunction(list);
                            }
                        }} />
                        <label className="m-0" style={{ overflowWrap: "anywhere" }}>{title.title}</label>
                    </div>
                    <div className="accordion-collapse" onClick={() => setIsActive(!isActive)}>{isActive ? '-' : '+'}</div>
                </div>
                {isActive && <div className="accordion-content">
                    <ul className="pl-2 m-0">
                        {content.map((element, key) => {
                            return (
                                <div key={`roleFunc` + key} style={{
                                    alignItems: "center",
                                    display: "flex"
                                }}>
                                    <input type="checkbox" className="mr-1" checked={arFunction && arFunction.length > 0 && arFunction.includes(element.id)} onChange={(e) => {
                                        if (e.target.checked) {
                                            setarFunction([element.id, ...arFunction])
                                        } else {
                                            setarFunction(arFunction.filter(id => id !== element.id && id !== title.id))
                                            setRemoveFunction(removeFunction.filter(id => id !== title.id))
                                        }
                                    }} />
                                    <label className="m-0" style={{ overflowWrap: "anywhere" }}>{element.extra_name}</label>
                                </div>
                            )
                        })}
                    </ul>
                </div>
                }
            </div>
        </div>
    )
}