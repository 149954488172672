import axios from "axios";
import { configs } from "../../../assets/Config";
import FormData from 'form-data';

// get all Bucket list
export function getAllBuckets(token, getDeleted) {
    return new Promise(async (resolve, reject) => {
        try {
            let url = 'bucket/preIPO'
            if (getDeleted) {
                url = 'bucket/preIPO/deleted'
            }

            var config = {
                method: 'get',
                url: `${process.env.REACT_APP_BASEURL}/admin/${url}`,
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            };

            let res = await axios(config)
            resolve(res)
        } catch (err) {
            resolve(err?.response)
        }
    })
}

// get Bucket data by id
export function getBucketById(token, BucketId) {
    return new Promise(async (resolve, reject) => {
        try {

            var config = {
                method: 'get',
                url: `${process.env.REACT_APP_BASEURL}/admin/bucket/preIPO/${BucketId}`,
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            };

            let res = await axios(config)
            resolve(res)
        } catch (err) {
            resolve(err?.response)
        }
    })
}

// create or update Bucket
export function createUpdateBucket(token, name, isin, suggested_holding_period, minimum_investment, number_of_stocks, disabletrading, description, overview, investing_rational, bucket_object, email_description, bell_notification_description, mobile_description, whatsapp_description, key_1, value_1, key_2, value_2, logo, editBucket, BucketId) {
    return new Promise(async (resolve, reject) => {
        try {
            var data = new FormData();
            data.append("name", name)
            data.append("isin", isin)
            data.append("suggested_holding_period", suggested_holding_period)
            data.append("minimum_investment", minimum_investment)
            data.append("number_of_stocks", number_of_stocks)
            data.append("disabletrading", disabletrading)
            data.append("description", description)
            data.append("overview", overview)
            data.append("investing_rational", investing_rational)
            data.append("bucket_object", JSON.stringify(bucket_object))
            data.append("email_description", email_description)
            data.append("bell_notification_description", bell_notification_description)
            data.append("mobile_description", mobile_description)
            data.append("whatsapp_description", whatsapp_description)
            data.append("key_1", key_1)
            data.append("value_1", value_1)
            data.append("key_2", key_2)
            data.append("value_2", value_2)
            data.append("logo", logo)

            let urlE = 'create'

            if (editBucket) {
                urlE = 'update'
                data.append('bucket_id', BucketId)
            }

            var config = {
                method: 'POST',
                url: `${process.env.REACT_APP_BASEURL}/admin/bucket/preIPO/${urlE}`,
                headers: {
                    'Authorization': `Bearer ${token}`
                },
                data: data
            };

            let res = await axios(config)
            resolve(res)
        } catch (err) {
            resolve(err?.response)
        }
    })
}

// delete bucket
export function deleteBucket(token, BucketId) {
    return new Promise(async (resolve, reject) => {
        try {

            var data = {
                company_id: BucketId
            }
            var config = {
                method: 'DELETE',
                url: `${process.env.REACT_APP_BASEURL}/admin/bucket/preIPO/delete`,
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json'
                },
                data: data
            };

            let res = await axios(config)
            resolve(res)
        } catch (err) {
            resolve(err?.response)
        }
    })
}

// restore bucket
export function restoreBucket(token, BucketId) {
    return new Promise(async (resolve, reject) => {
        try {

            var data = {
                company_id: BucketId
            }
            var config = {
                method: 'PATCH',
                url: `${process.env.REACT_APP_BASEURL}/admin/bucket/preIPO/restore`,
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json'
                },
                data: data
            };

            let res = await axios(config)
            resolve(res)
        } catch (err) {
            resolve(err?.response)
        }
    })
}
