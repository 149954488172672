import React from 'react';
import { createContext, useContext, useState } from 'react';
import { configs } from '../../../assets/Config';
import { createUpdateMixBucket, deleteMixBucket, getAllMixBuckets, getMixBucketById, restoreMixBucket } from '../../apis/assetManagement/MixBucketManagementApi';
import { useLogin } from '../AdminLoginContext';

// create global MixBucket management context
export const MixBucketContext = createContext();

export function MixBucketProvider({ children }) {

    const { token, logout, setLoginError } = useLogin();

    const [MixBucketList, setMixBucketList] = useState("")
    const [editMixBucket, setEditMixBucket] = useState(false)
    const [editMixBucketData, setEditMixBucketData] = useState({})
    const [showDeletedMixBucket, setShowDeletedMixBucket] = useState(false)
    const [showRTPage, setShowRTPage] = useState(false)
    const [MixBucketOverview, setMixBucketOverview] = useState("")
    const [MixBucketRationale, setMixBucketRationale] = useState("")
    const [MixBucketAssetList, setMixBucketAssetList] = useState([])

    // get all MixBucket
    async function getAllMixBucketList(getDeleted) {
        try {
            let response = await getAllMixBuckets(token, getDeleted);
            if (response && response.status === 200) {
                setMixBucketList(response.data)
                return {
                    success: "success",
                    data: response.data
                }
            } else if (response && response.status === 401) {
                logout()
                setLoginError(configs.unauthorizedErrorMessage)
            } else {
                setMixBucketList("")
            }
        } catch (error) {

        }
    }

    // get MixBucket data by id
    async function getMixBucketDataById(MixBucketId) {
        try {
            let response = await getMixBucketById(token, MixBucketId);
            if (response && response.status === 200) {
                let data = response.data
                setEditMixBucketData({
                    ...editMixBucketData,
                    ...data
                })
            } else if (response && response.status === 401) {
                logout()
                setLoginError(configs.unauthorizedErrorMessage)
            } else { }
        } catch (error) {

        }
    }

    // create or update MixBucket
    async function createEditMixBucket(name, isin, suggested_holding_period, minimum_investment, number_of_stocks, disabletrading, description, email_description, bell_notification_description, mobile_description, whatsapp_description, key_1, value_1, key_2, value_2, logo, editMixBucket, MixBucketId) {
        try {
            let response = await createUpdateMixBucket(token, name, isin, suggested_holding_period, minimum_investment, number_of_stocks, disabletrading, description, MixBucketOverview, MixBucketRationale, MixBucketAssetList, email_description, bell_notification_description, mobile_description, whatsapp_description, key_1, value_1, key_2, value_2, logo, editMixBucket, MixBucketId);
            if (response && response.status === 200) {
                return "success"
            } else if (response && response.status === 401) {
                logout()
                setLoginError(configs.unauthorizedErrorMessage)
            } else {
                return response.data.error
            }
        } catch (error) {

        }
    }

    // delete MixBucket
    async function deleteMixBucketById(MixBucketId) {
        try {
            let response = await deleteMixBucket(token, MixBucketId);
            if (response && response.status === 200) {
                return "success"
            } else if (response && response.status === 401) {
                logout()
                setLoginError(configs.unauthorizedErrorMessage)
            } else {
                return response.data.error
            }
        } catch (error) {

        }
    }

    // restore MixBucket
    async function restoreMixBucketById(MixBucketId) {
        try {
            let response = await restoreMixBucket(token, MixBucketId);
            if (response && response.status === 200) {
                return "success"
            } else if (response && response.status === 401) {
                logout()
                setLoginError(configs.unauthorizedErrorMessage)
            } else {
                return response.data.error
            }
        } catch (error) {

        }
    }

    // export global context
    return (
        <MixBucketContext.Provider
            value={{
                MixBucketList,
                setMixBucketList,
                getAllMixBucketList,
                deleteMixBucketById,
                restoreMixBucketById,
                editMixBucket,
                setEditMixBucket,
                editMixBucketData,
                setEditMixBucketData,
                getMixBucketDataById,
                createEditMixBucket,
                showDeletedMixBucket,
                setShowDeletedMixBucket,
                showRTPage,
                setShowRTPage,
                MixBucketOverview,
                setMixBucketOverview,
                MixBucketRationale,
                setMixBucketRationale,
                MixBucketAssetList,
                setMixBucketAssetList
            }}
        >
            {children}
        </MixBucketContext.Provider>
    );
}

// return MixBucket management context
export function useMixBucketContext() {
    return useContext(MixBucketContext);
}