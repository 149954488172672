import { Modal } from '@material-ui/core';
import { DataGrid, GridToolbar } from '@material-ui/data-grid';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { configs, downloadDoc, downloadImage } from '../../../assets/Config';
import '../../../assets/styles/RM.css'
import { useLogin } from '../../../services/contexts/AdminLoginContext';
import { useMLDContext } from '../../../services/contexts/assetManagement/MLDContext';
import DeleteModalPage from '../../includes/DeleteModalPage';


export default function ListMLDPage() {

    const { MLDList, setMLDList, getAllMLDList, setEditMLD, setEditMLDData, editMLDData, getMLDDataById, setShowDeletedMLD, showDeletedMLD } = useMLDContext()
    const { setShowDelete, setShowSuccess, allowedFunc } = useLogin()

    const history = useHistory()
    const [deleteId, setDeleteId] = useState({})

    const columns = [{ field: "id", headerName: "MLD ID", minWidth: 100, flex: 0.5, hide: true, filterable: false },
    { field: "name", headerName: "Name", minWidth: 300, flex: 0.5, cellClassName: "break-text" },
    { field: "isin", headerName: "ISIN", minWidth: 150, flex: 0.5, cellClassName: "break-text" },
    { field: "rate_of_return", headerName: "Rate of Return", minWidth: 150, flex: 0.5, cellClassName: "break-text" },
    { field: "maturity_period", headerName: "Maturity/Tenure ", minWidth: 150, flex: 0.5, cellClassName: "break-text" },
    { field: "interest_payment", headerName: "Interest Payment", minWidth: 150, flex: 0.5, cellClassName: "break-text" },
    { field: "launch_date", headerName: "Launch Date", minWidth: 150, flex: 0.5, cellClassName: "break-text" },
    { field: "issue_size", headerName: "Issue Size", minWidth: 150, flex: 0.5, cellClassName: "break-text" },
    { field: "rating", headerName: "Rating", minWidth: 150, flex: 0.5, cellClassName: "break-text" },
    {
        field: "disabletrading", headerName: "Disable Trading", minWidth: 130, flex: 0.1, cellClassName: "break-text", renderCell: (params) => {
            return params.row.disabletrading === 1 ? "Yes" : "No"
        }, valueGetter: (params) => params.row.disabletrading === 1 ? "Yes" : "No"
    },
    {
        field: "logo", headerName: "Logo", minWidth: 110, flex: 0.1, cellClassName: "break-text", cellClassName: "text-primary", renderCell: (params) => {
            return <div className='row pl-2' id={`comp` + params.row.id}>
                <a href={`${process.env.REACT_APP_BASEURL}/all/companyLogo/${params.row.logo}`} target="_blank">
                    <i className="fa fa-external-link kyc-openDoc-prof curPoint"></i></a>
                <i className="fa fa-download kyc-openDoc-prof curPoint" onClick={(e) => {
                    e.preventDefault()
                    downloadImage(`${process.env.REACT_APP_BASEURL}/all/companyLogo/${params.row.logo}`, params.row.id)
                }}></i>
            </div >
        }
    },
    {
        field: "edit", headerName: " ", minWidth: 40, flex: 0.1, cellClassName: "break-text", cellClassName: "text-primary", hide: !allowedFunc.includes(configs.functionList["mld_management:update_asset"]) ? true : false, renderCell: (params) => {
            return <a href="#" className="custom-link text-primary" onClick={(e) => {
                e.preventDefault()
                setEditMLD(true)
                getMLDDataById(params.row.id)
                document.getElementById("rmCreateCompany").classList.toggle('d-none')
                document.getElementById("companyListPage").classList.toggle('d-none')
            }}>Edit</a>
        }
    },
    {
        field: "delete", headerName: " ", minWidth: 65, flex: 0.1, cellClassName: "break-text", cellClassName: "text-danger", hide: !allowedFunc.includes(configs.functionList["mld_management:delete_or_restore_company"]) ? true : false, renderCell: (params) => {
            return <a href="#" className="custom-link text-danger" onClick={(e) => {
                e.preventDefault()
                setDeleteId({
                    "category": configs.modules.mldMgmt,
                    "type": "MLD",
                    "name": params.row.name,
                    "id": params.row.id
                })
                setShowDelete(true)
            }}>Delete</a>
        }
    }]

    useEffect(() => {
        (async () => {
            try {
                await getAllMLDList()
            } catch (error) {

            }
        })()
    }, [])


    // role list page ui code
    return (
        <div id="companyListPage" className='d-md-block' style={{ width: "100%" }}>
            <div className="row d-flex align-items-center mt-2 ml-3 mb-1 mr-2">
                <div className="col-lg-6 col-8 m-0 p-0">
                    <p className="h4 text-custom-grey">MLD Management</p>
                </div>
                <div className="col-lg-6 col-4 text-right m-0 p-0">
                    {allowedFunc.includes(configs.functionList["mld_management:delete_or_restore_company"]) ?
                        <a href="#" className="custom-link" onClick={(e) => {
                            e.preventDefault()
                            setMLDList("")
                            setShowDeletedMLD(!showDeletedMLD)
                        }}><i className={showDeletedMLD ? "fas fa-user mr-2" : "fas fa-user-slash mr-2"}></i>{showDeletedMLD ? "Active MLD" : "Deleted MLD"}</a>
                        : null}
                </div>
            </div>
            {allowedFunc.includes(configs.functionList['mld_management:create_asset']) || allowedFunc.includes(configs.functionList['mld_management:update_asset']) ?
                <div className='row m-0 mx-3 p-0 d-md-none d-block'>
                    <a href='#' className='custom-link' onClick={(e) => {
                        e.preventDefault()
                        document.getElementById("rmCreateCompany").classList.toggle('d-none')
                        document.getElementById("companyListPage").classList.toggle('d-none')
                    }}><p className='m-0 p-0'>Create New MLD</p></a>
                </div>
                : null}
            {MLDList ?
                <div className="nm-role-table m-2">
                    <DataGrid
                        rows={MLDList}
                        headerHeight={30}
                        rowHeight={40}
                        columns={columns}
                        resizable={true}
                        autoPageSize
                        pagination
                        components={{
                            Toolbar: GridToolbar,
                        }}

                    // onSelectionModelChange={onRowSelected}
                    />
                </div>
                : <div className="loader"></div>}
            <DeleteModalPage {...deleteId} />
        </div>
    )
}