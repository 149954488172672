import React from "react";
import Modal from "@material-ui/core/Modal";
import { useState } from "react";
import { useRMContext } from "../../../services/contexts/RMContext";


export default function UserDetailPage() {

    const { userDetail, showUserDetail, createAdmin } = useRMContext();
    const [name, setname] = useState("");
    const [email, setemail] = useState("");
    const [password, setpassword] = useState("");
    const [error, setError] = useState("")

    // create admin

    async function createAdminButton(e) {
        try {
            e.preventDefault();
            if (!name.trim()) {
                setError("Name is required.")
            } else if (!email.trim()) {
                setError("Email is required.")
            } else if (!password.trim()) {
                setError("Password is required.")
            } else {
                let result = await createAdmin(name, email, password)
                if (result === "success") {
                    setname("")
                    setemail("")
                    setpassword("")
                    setError("")
                    handleClose()
                }
            }
        } catch (error) {

        }
    }

    const handleClose = () => {
        showUserDetail(false)
        setname("")
        setemail("")
        setpassword("")
        setError("")
    };
    return (<Modal open={userDetail} onClose={handleClose}>
        <div className="modal-fade" id="KYCSubmitModal" tabIndex="-1" role="dialog" aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered" role="document">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title" id="exampleModalLongTitle">Manage Admin</h5>
                        <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={(e) => {
                            e.preventDefault();
                            showUserDetail(false);
                        }}>
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div className="modal-body">
                        <form action="">
                            <div className="form-group">
                                <label htmlFor="admin_name" >Name</label>
                                <input type="text" className="form-control" onChange={(e) => {
                                    setname(e.target.value)
                                }} />
                            </div>
                            <div className="form-group">
                                <label htmlFor="admin_email" >Email</label>
                                <input type="email" className="form-control" onChange={(e) => {
                                    setemail(e.target.value)
                                }} />
                            </div>
                            <div className="form-group">
                                <label htmlFor="admin_email" >Password</label>
                                <input type="password" className="form-control" onChange={(e) => {
                                    setpassword(e.target.value)
                                }} />
                            </div>
                        </form>
                        <div className="form-check p-0">
                            <p className="text-danger" id="createRoleError">{error}</p>
                        </div>
                    </div>
                    <div className="modal-footer">
                        {/* <button type="button" className="btn btn-danger" data-dismiss="modal">Delete User</button> */}
                        <button type="button" className="btn btn-primary" disabled={name && email && password ? false : true} onClick={(e) => {
                            createAdminButton(e)
                        }}>Save changes</button>
                    </div>
                </div>
            </div>
        </div>
    </Modal>)
}