import React from 'react';
import { createContext, useContext, useState } from 'react';
import { configs } from '../../assets/Config';
import { acknowSellOrder, approveSellOrder, completeSellOrder, confirmSellOrderShareTransfer, deleteSellOrder, downloadDeliveryNote, downloadHDFCSheet, editSellOrder, getDematAccList, getSellsOrderList, rejectSellOrder, restoreSellOrder, sellManualOrderPunchCustomerId, sellOrderComment } from '../apis/shareManagement/SellOrdersApi';
import { useLogin } from './AdminLoginContext';

// create sellOrder management context
export const sellOrderContext = createContext();


export function SellOrderProvider({ children }) {

    const { token, setLoginError, logout } = useLogin();

    const [tableData, setTableData] = useState("")
    const [dematList, setDematList] = useState("")
    const [orderId, setOrderId] = useState("")
    const [approveQuantity, setApproveQuantity] = useState("")
    const [approvePrice, setApprovePrice] = useState("")
    const [approveInstrument, setApproveInstrument] = useState("")
    const [editModal, setEditModal] = useState(false)
    const [ackModal, setAckModal] = useState(false)
    const [rejectModal, setRejectModal] = useState(false)
    const [commentModal, setCommentModal] = useState(false)
    const [updateRemark, setUpdateRemark] = useState("")
    const [demat, setDemat] = useState("")
    const [activeStatusSell, setActiveStatusSell] = useState();
    const [isDealerOrder, setIsDealerOrder] = useState("");

    // get sell orders 
    async function getSellOrder(type) {
        try {
            let response = await getSellsOrderList(token, type)
            if (response && response.status === 200) {
                setTableData(response.data)
                return {
                    "success": "success",
                    "data": response.data
                }
            } else if (response && response.status === 401) {
                logout();
                setLoginError(configs.unauthorizedErrorMessage);
            }
            else { return response.data.error }
        } catch (error) {

        }
    }

    // get demat account list
    async function getDematList() {
        try {
            let response = await getDematAccList(token)
            if (response && response.status === 200) {
                setDematList(response.data)
                setDemat(response.data[0].id)
                return {
                    "success": "success",
                    "data": response.data
                }
            } else if (response && response.status === 401) {
                logout();
                setLoginError(configs.unauthorizedErrorMessage);
            }
            else { return response.data.error }
        } catch (error) {

        }
    }

    // reject sell order
    async function rejectSellOrderById(orderId, remark) {
        try {
            let response = await rejectSellOrder(token, orderId, remark);
            if (response && response.status === 200) {
                return "success"
            } else if (response && response.status === 401) {
                logout()
                setLoginError(configs.unauthorizedErrorMessage)
            } else {
                return response.data.error
            }
        } catch (error) {

        }
    }

    // update sell order
    async function editSellOrderById(orderId, quantity, price) {
        try {
            let response = await editSellOrder(token, orderId, quantity, price);
            if (response && response.status === 200) {
                // setUpdate(false)
                return "success"
            } else if (response && response.status === 401) {
                logout()
                setLoginError(configs.unauthorizedErrorMessage)
            } else {
                return response.data.error
            }
        } catch (error) {

        }
    }

    // approve sell order
    async function approveSellOrderById(orderId, dematId) {
        try {
            let response = await approveSellOrder(token, orderId, dematId);
            if (response && response.status === 200) {
                return "success"
            } else if (response && response.status === 401) {
                logout()
                setLoginError(configs.unauthorizedErrorMessage)
            } else {
                return response.data.error
            }
        } catch (error) {

        }
    }

    // acknow sell order
    async function acknowSellOrderById(orderId, remark, status) {
        try {
            let response = await acknowSellOrder(token, orderId, remark, status);
            if (response && response.status === 200) {
                return "success"
            } else if (response && response.status === 401) {
                logout()
                setLoginError(configs.unauthorizedErrorMessage)
            } else {
                return response.data.error
            }
        } catch (error) {

        }
    }


    // complete sell order
    async function completeSellOrderById(orderId, remark, dealerUTR) {
        try {
            let response = await completeSellOrder(token, orderId, remark, dealerUTR);
            if (response && response.status === 200) {
                return "success"
            } else if (response && response.status === 401) {
                logout()
                setLoginError(configs.unauthorizedErrorMessage)
            } else {
                return response.data.error
            }
        } catch (error) {

        }
    }

    // confirm sell order share transfer
    async function confirmSellOrderShareTransferId(orderId, remark) {
        try {
            let response = await confirmSellOrderShareTransfer(token, orderId, remark);
            if (response && response.status === 200) {
                return "success"
            } else if (response && response.status === 401) {
                logout()
                setLoginError(configs.unauthorizedErrorMessage)
            } else {
                return response.data.error
            }
        } catch (error) {

        }
    }

    // delete sell order
    async function deleteSellOrderById(orderId) {
        try {
            let response = await deleteSellOrder(token, orderId);
            if (response && response.status === 200) {
                return "success"
            } else if (response && response.status === 401) {
                logout()
                setLoginError(configs.unauthorizedErrorMessage)
            } else {
                return response.data.error
            }
        } catch (error) {

        }
    }

    // restore sell order
    async function restoreSellOrderById(orderId) {
        try {
            let response = await restoreSellOrder(token, orderId);
            if (response && response.status === 200) {
                return "success"
            } else if (response && response.status === 401) {
                logout()
                setLoginError(configs.unauthorizedErrorMessage)
            } else {
                return response.data.error
            }
        } catch (error) {

        }
    }

    // async function download delivery note
    async function downloadDeliveryNoteByOrderId(orderId) {
        try {
            let response = await downloadDeliveryNote(token, orderId);
            if (response && response.status === 200) {
                let a = document.createElement("a"); //Create <a>
                a.href = response.data; //Image Base64 Goes here
                a.download = `Delivery_Note_${orderId}.pdf`; //File name Here
                a.click();
            } else if (response && response.status === 401) {
                logout();
                //setLoginError(configs.unauthorizedErrorMessage);
            }
            else { }
        } catch (error) {
            console.error(error)
        }
    }

    // function to download HDFC sheet
    async function downloadHDFCSheetByOrderId(orderIds) {
        try {
            let response = await downloadHDFCSheet(token, orderIds);
            if (response && response.status === 200) {
                let a = document.createElement("a"); //Create <a>
                a.href = response.data.file; //Image Base64 Goes here
                a.download = response.data.fileName; //File name Here
                a.click();
                return "success"
            } else if (response && response.status === 401) {
                logout();
                //setLoginError(configs.unauthorizedErrorMessage);
            }
            else {
                return response.data.error;
            }
        } catch (error) {
            console.error(error)
        }
    }

    // sell order manual punch
    async function sellManualOrderPunch(userId, companyId, price, quantity, dealerOrder, distributorId, upmark, upmark_final_pay) {
        try {
            let response = await sellManualOrderPunchCustomerId(token, userId, companyId, price, quantity, dealerOrder, distributorId, upmark, upmark_final_pay);
            if (response && response.status === 200) {
                return {
                    "success": "success",
                    "data": response.data
                }
            } else if (response && response.status === 401) {
                logout()
                setLoginError(configs.unauthorizedErrorMessage)
            } else {
                return response.data.error
            }
        } catch (error) {

        }
    }

    // update sell order comment
    async function updateSellOrderComment(orderId, comment) {
        try {
            let response = await sellOrderComment(token, orderId, comment);
            if (response && response.status === 200) {
                return "success"
            } else if (response && response.status === 401) {
                logout()
                setLoginError(configs.unauthorizedErrorMessage)
            } else {
                return response.data.error
            }
        } catch (error) {

        }
    }

    return (
        <sellOrderContext.Provider
            value={{
                tableData,
                setTableData,
                activeStatusSell,
                setActiveStatusSell,
                getSellOrder,
                dematList,
                setDematList,
                getDematList,
                demat,
                setDemat,
                rejectSellOrderById,
                editSellOrderById,
                approveSellOrderById,
                acknowSellOrderById,
                completeSellOrderById,
                orderId,
                setOrderId,
                approveQuantity,
                setApproveQuantity,
                approvePrice,
                setApprovePrice,
                approveInstrument,
                setApproveInstrument,
                editModal,
                setEditModal,
                rejectModal,
                setRejectModal,
                ackModal,
                setAckModal,
                updateRemark,
                setUpdateRemark,
                deleteSellOrderById,
                restoreSellOrderById,
                downloadDeliveryNoteByOrderId,
                downloadHDFCSheetByOrderId,
                sellManualOrderPunch,
                confirmSellOrderShareTransferId,
                commentModal,
                setCommentModal,
                updateSellOrderComment,
                isDealerOrder,
                setIsDealerOrder
            }}
        >
            {children}
        </sellOrderContext.Provider>)

}

export function useSellOrderContext() {
    return useContext(sellOrderContext)
}

