import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import '../../../assets/styles/AdminLogin.css';
import '../../../assets/styles/animation.css';
import { useLogin } from "../../../services/contexts/AdminLoginContext";
import imgdn from '../../../assets/images/leftBG.png'
import logo from '../../../assets/images/logo.png'
import { configs } from "../../../assets/Config";


export default function AdminLoginPage() {
    // fetching global login context
    const { loginUserWithCredentials, login, loginError, allowedFunc } = useLogin()
    const history = useHistory();

    // hook declaration to process login
    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");

    // check if active Admin sesssion is available and bypass login
    useEffect(() => {

        if (login && allowedFunc) {
            for (let i = 0; i < allowedFunc.length; i++) {
                if (configs.functionListUrl[allowedFunc[i]]) {
                    return history.push(configs.functionListUrl[allowedFunc[i]])
                }
            }
        } else if (login) {
            history.push('/kyc')
        }
    }, [login, allowedFunc])

    // login Admin based on credentials filled
    async function credentialLogin(e) {
        try {
            e.preventDefault();
            document.getElementById("signInBtn").classList.toggle("d-none")
            document.getElementById("signInBtnLoader").classList.toggle("d-none")

            await loginUserWithCredentials(username, password)

            document.getElementById("signInBtn").classList.toggle("d-none")
            document.getElementById("signInBtnLoader").classList.toggle("d-none")
        } catch (error) {

        }
    }

    // login page ui code
    return (
        <section>
            <div className="container-fluid">
                <div className="row">
                    <div className="col-6 d-none d-sm-block p-0 m-0">
                        <div className="login-screen-wallpaper">
                            <img src={imgdn} className="w-100 h-100" alt="image" />
                        </div>
                    </div>
                    <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12 d-flex align-items-center login-screen-form-wrapper">
                        <div className="w-100">
                            <div className="login-screen-logo">
                                <img className="w-100 h-100" src={logo} alt="logo" />
                            </div>
                            <form>
                                <div className="form-group">
                                    <h4>Login</h4>
                                </div>
                                <div className="form-group">
                                    <input type="text" className="form-control login-screen-input" name="username" placeholder="User ID" onChange={(e) => {
                                        setUsername(e.target.value)
                                    }} />
                                </div>
                                <div className="form-group">
                                    <input type="password" className="form-control login-screen-input" name="password" placeholder="Password" onChange={(e) => {
                                        setPassword(e.target.value)
                                    }} />
                                </div>
                                <div className="form-check p-0">
                                    <p className="text-danger" id="loginFormError">{loginError}</p>
                                </div>
                                <div className="form-row mt-4">
                                    <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                                        <button id="signInBtn" type="submit" className="btn btn-sm btn-primary rounded px-3 login-screen-sign-in-button" onClick={credentialLogin}>Signin</button>
                                        <div id="signInBtnLoader" className='animBtn d-none'><div className='gSignIn-Anim'></div></div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}
