import { useEffect, useState } from "react"
import { configs, generateBase64 } from "../../../../assets/Config";
import { useLogin } from "../../../../services/contexts/AdminLoginContext";
import TopBarPage from "../../../includes/dashboard/TopBarPage";
import LeftBarPage from "../../../includes/dashboard/LeftBarPage";
import { useHistory } from 'react-router';
import AddStatusData from "./AddStatusData";
import { addHighlights, getHighlights } from "../../../../services/apis/CustomerDashboardManagementApi";
import ToastPage from "../../../includes/ToastPage";

export default function CustomerHighlightManagementPage() {

    const { setCurrModule, allowedFunc, token, setShowSuccess, logout, dashboardHighlight, setDashboardHighlight } = useLogin()
    const history = useHistory()

    const [error, setError] = useState("");

    useEffect(() => {
        (async () => {
            try {
                setCurrModule(configs.modules.custDashHighMng)
                if (allowedFunc.includes(configs.functionList['dashboard_management:manage_dashboard'])) {
                    let response = await getHighlights(token);
                    if (response && response.data) {
                        setDashboardHighlight(response.data)
                    }
                } else {
                    history.goBack();
                }
            } catch (error) {
                console.error(error);
            }
        })()
    }, [])

    // get file contents through file chooser
    async function uploadFile(file, index) {
        try {
            if (file.type === "image/jpg" || file.type === "image/jpeg" || file.type === "image/png") {
                let highlights = dashboardHighlight;
                highlights[index].file = await generateBase64(file)
                highlights[index].fileName = file.name
                setDashboardHighlight([...dashboardHighlight], highlights);
            } else {
                alert("Only jpg or png files are allowed.")
            }
        } catch (error) {
            console.error(error)
        }
    }

    // update heading
    function uploadText(text, index) {
        try {
            let highlights = dashboardHighlight;
            highlights[index].heading = text
            setDashboardHighlight([...dashboardHighlight], highlights);

        } catch (error) {
            console.error(error)
        }
    }


    // add highlight function
    function modifyHighlight(index, setDelete) {
        try {
            if (setDelete && dashboardHighlight.length > 1) {
                let highlight = dashboardHighlight
                highlight.splice(index, 1);

                setDashboardHighlight([...dashboardHighlight], highlight)
            } else if (dashboardHighlight[dashboardHighlight.length - 1]?.file && dashboardHighlight[dashboardHighlight.length - 1]?.heading && dashboardHighlight[dashboardHighlight.length - 1]?.highlights[dashboardHighlight[dashboardHighlight.length - 1].highlights.length - 1]?.file) {
                let highlight = dashboardHighlight
                highlight.push({
                    file: '',
                    heading: '',
                    highlights: [{
                        file: '',
                        btnText: "",
                        btnLink: ""
                    }]
                })
                setDashboardHighlight([...dashboardHighlight], highlight)
                setError("");
            } else if (!setDelete) {
                setError("New highlight component could not be created as above highlight component has empty highlight")
            }
        } catch (error) {
            console.error(error);
        }
    }


    // save highlight
    async function saveHighLight() {
        try {
            document.getElementById("saveHighlightBtn").classList.toggle("d-none")
            document.getElementById("saveHighlightBtnLoader").classList.toggle("d-none")

            if (dashboardHighlight[dashboardHighlight.length - 1]?.file && dashboardHighlight[dashboardHighlight.length - 1]?.heading && dashboardHighlight[dashboardHighlight.length - 1]?.highlights[dashboardHighlight[dashboardHighlight.length - 1].highlights.length - 1]?.file) {
                let response = await addHighlights(token, dashboardHighlight);
                if (response && response.status === 200) {
                    setError("");
                    setShowSuccess(true)
                } else if (response && response.status === 401) {
                    logout();
                } else {
                    setError(response.data.error);
                }
            } else {
                setError("The highlight component could not be saved as above highlight component has empty highlight")
            }
        } catch (error) {
            setError(error.error ? error.error : error)
            console.error(error);
        } finally {
            document.getElementById("saveHighlightBtn").classList.toggle("d-none")
            document.getElementById("saveHighlightBtnLoader").classList.toggle("d-none")
        }
    }

    return (
        <>
            <TopBarPage />
            <div style={{ display: "flex" }}>
                <LeftBarPage />
                <div id='nmContainer' className="smPO-container">
                    <div style={{ width: "100%" }}>
                        <div className="row d-flex align-items-center mt-2 ml-2 mb-4 mr-3">
                            <div className="col-lg-6 text-left">
                                <p className="h4 text-custom-grey">Dashboard Highlight Management</p>
                            </div>
                        </div>
                        <div className="smPOI-container">
                            {dashboardHighlight && dashboardHighlight.length > 0 ?
                                dashboardHighlight.map((ele, key) => {
                                    return <div className={`mx-0 px-0 py-2 bg-light ${key > 0 ? "mt-5" : ""}`}>
                                        <div className='row m-0 p-0' key={`dashboardHighlight${key}`}>
                                            <div className='col-md-4'>
                                                <div className="form-group">
                                                    <div className='f-12'>
                                                        <label htmlFor="title" className="font-weight-bold">Icon File</label>
                                                    </div>
                                                    <div className="file-border rounded py-4" onDragOver={(e) => {
                                                        e.preventDefault();
                                                    }} onDrop={(e) => {
                                                        uploadFile(e.dataTransfer.files[0], key)
                                                        e.preventDefault();
                                                    }}>
                                                        <i className="fas fa-file-upload f-22"></i>
                                                        <p className="pb-0 mb-0 f-12">
                                                            Drag your file or &nbsp;
                                                            <input type="file" id={`highlight${key}`} required hidden accept="image/jpg, image/jpeg, image/png" onChange={(e) => {
                                                                uploadFile(e.target.files[0], key)
                                                            }} />
                                                            <label htmlFor={`highlight${key}`} className="mb-0 pb-0">Click here</label>
                                                            &nbsp; to choose <br /> Upload: <span className="text-theme">{ele.fileName}</span>
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='col-md-4'>
                                                <div className="form-group">
                                                    <div className='f-12'>
                                                        <label htmlFor="title" className="font-weight-bold">Heading</label>
                                                    </div>
                                                    <input type="text" className="form-control" placeholder="Heading Text" value={ele.heading} onChange={(e) => {
                                                        uploadText(e.target.value, key)
                                                    }} />
                                                </div>
                                            </div>
                                            <div className='col-md-4'>
                                                <div className="form-group">
                                                    <div className="justify-content-end d-flex">
                                                        <button href="#" data-toggle="modal" data-target="#deleteRole" className="btn btn-danger f-10" onClick={(e) => {
                                                            e.preventDefault()
                                                            modifyHighlight(key, true)
                                                        }}><i className="fas fa-trash mr-2 f-10"></i>Delete Component</button>
                                                    </div>
                                                    <p className="font-weight-bold">Current Image File</p>
                                                    <img style={{ width: "10rem", "objectFit": "contain" }} src={ele.file} />
                                                </div>
                                            </div>
                                        </div>
                                        {ele.highlights.map((ele2, key2) => {
                                            return <AddStatusData parentIndex={key} index={key2} data={ele2} lastEle={key2 === ele.highlights.length - 1 ? true : false} />
                                        })}
                                    </div>
                                })
                                : null}
                            <div className='row m-0 p-0 mt-3'>
                                <p className="text-danger">{error}</p>
                                <div className='col-12 m-0 p-0 d-flex justify-content-end'>
                                    <div className="form-group d-flex">
                                        <button className="text-primary custom-button" onClick={(e) => {
                                            e.preventDefault()
                                            modifyHighlight()
                                        }}><i className="fas fa-plus-circle mr-2"></i>Add New Highlight</button>

                                        <div className="">
                                            <button id="saveHighlightBtn" className="btn btn-primary" onClick={(e) => {
                                                e.preventDefault()
                                                saveHighLight()
                                            }}>Update Highlights</button>
                                            <div id="saveHighlightBtnLoader" className='animBtn d-none'><div className='gSignIn-Anim'></div></div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <ToastPage />
        </>
    )
}