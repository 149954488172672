import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { configs } from '../../../assets/Config';
import { useLogin } from '../../../services/contexts/AdminLoginContext';
import LeftBarPage from '../../includes/dashboard/LeftBarPage';
import TopBarPage from '../../includes/dashboard/TopBarPage';
import ToastPage from '../../includes/ToastPage';
import '../../../assets/styles/RM.css'
import { useNewsContext } from '../../../services/contexts/companyManagement/NewsContext';
import DeletedNewsPage from './DeletedNewsPage';
import ListNewsPage from './ListNewsPage';
import CreateNewsPage from './CreateNewsPage';
import { useCMContext } from '../../../services/contexts/companyManagement/CMContext';


export default function NewsMainPage() {

    const { newsList, getAllNewsList, showDeletedNews } = useNewsContext()
    const { getAllCompaniesList } = useCMContext()
    const { setCurrModule, allowedFunc } = useLogin()

    const history = useHistory()

    useEffect(() => {
        (async () => {
            try {
                if (allowedFunc.includes(configs.functionList['company_management:news_management'])) {
                    setCurrModule(configs.modules.newsManage)
                    getAllNewsList()
                    getAllCompaniesList()
                } else {
                    return history.goBack()
                }
            } catch (error) {

            }
        })()
    }, [])

    // role management main ui code
    return (
        <>
            <TopBarPage />
            <div style={{ display: "flex" }}>
                <LeftBarPage />
                {showDeletedNews ? <div id='rmContainer' className="rm-container"><DeletedNewsPage /></div> :
                    <div id='rmContainer' className="rm-container">
                        <ListNewsPage />
                        <CreateNewsPage />
                        <ToastPage />
                    </div>
                }
            </div>
        </>
    )
}