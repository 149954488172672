import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { configs } from '../../../assets/Config';
import { useLogin } from '../../../services/contexts/AdminLoginContext';
import LeftBarPage from '../../includes/dashboard/LeftBarPage';
import TopBarPage from '../../includes/dashboard/TopBarPage';
import ToastPage from '../../includes/ToastPage';
import '../../../assets/styles/RM.css'
import { useBondContext } from '../../../services/contexts/assetManagement/BondContext';
import ListBondPage from './ListBondPage';
import CreateBondPage from './CreateBondPage';
import DeletedBondPage from './DeletedBondPage';


export default function BondMainPage() {

    const { BondList, getAllBondList, showDeletedBond } = useBondContext()
    const { setCurrModule, allowedFunc } = useLogin()

    const history = useHistory()

    useEffect(() => {
        (async () => {
            try {
                if (allowedFunc.includes(configs.functionList['bond_management:view_asset_data'])) {
                    setCurrModule(configs.modules.bondMgmt)
                    getAllBondList()
                } else {
                    return history.goBack()
                }
            } catch (error) {

            }
        })()
    }, [])

    // role management main ui code
    return (
        <>
            <TopBarPage />
            <div style={{ display: "flex" }}>
                <LeftBarPage />
                {showDeletedBond ? <div id='rmContainer' className="rm-container"><DeletedBondPage /></div> :
                    <div id='rmContainer' className="rm-container">
                        <ListBondPage />
                        {allowedFunc.includes(configs.functionList['bond_management:create_asset']) || allowedFunc.includes(configs.functionList['bond_management:update_asset']) ?
                            <CreateBondPage />
                            : null}
                        <ToastPage />
                    </div>
                }
            </div>
        </>
    )
}