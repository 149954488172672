import { DataGrid, GridToolbar } from '@material-ui/data-grid';
import React, { useEffect, useState } from 'react';
import { configs, formatCurrency } from '../../../assets/Config';
import { useLogin } from '../../../services/contexts/AdminLoginContext';
import LeftBarPage from '../../includes/dashboard/LeftBarPage';
import TopBarPage from '../../includes/dashboard/TopBarPage';
import '../../../assets/styles/NM.css'
import { useNMContext } from '../../../services/contexts/NMContext';
import ToastPage from '../../includes/ToastPage';
import { Link } from 'react-router-dom';
import { useHistory } from 'react-router';
import { Modal } from '@material-ui/core';
import { useKYCContext } from '../../../services/contexts/KYCContext';
import { useBuyOrderContext } from '../../../services/contexts/BuyOrdersContext';
import { Autocomplete } from '@material-ui/lab';
import { TextField } from "@material-ui/core";
import { getDashboardData, postDashboardData } from '../../../services/apis/CustomerDashboardManagementApi';
import { getProPlans, updateProPlans } from '../../../services/apis/KYCApi';


export default function ProPlansPage() {

    const { setCurrModule, allowedFunc, token, setShowSuccess, logout } = useLogin()

    const [research, setResearch] = useState([])
    const [plOp, setPlOp] = useState("")
    const [plSp, setPlSp] = useState("")
    const [plDesc, setPlDesc] = useState("")
    const [ppOp, setPpOp] = useState("")
    const [ppSp, setPpSp] = useState("")
    const [ppDesc, setPpDesc] = useState("")
    const [pmOp, setPmOp] = useState("")
    const [pmSp, setPmSp] = useState("")
    const [pmDesc, setPmDesc] = useState("")
    const [error, setError] = useState("")

    const history = useHistory()


    useEffect(() => {
        (async () => {
            try {
                if (allowedFunc.includes(configs.functionList['user_pro:user_pro'])) {
                    (async () => {
                        setCurrModule(configs.modules.custProPricing)
                        let response = await getProPlans(token)
                        if (response && response.status === 200) {
                            setPlOp(response.data.pro_lite.original_price)
                            setPlSp(response.data.pro_lite.selling_price)
                            setPlDesc(response.data.pro_lite.description)
                            setPpOp(response.data.pro_plus.original_price)
                            setPpSp(response.data.pro_plus.selling_price)
                            setPpDesc(response.data.pro_plus.description)
                            setPmOp(response.data.pro_max.original_price)
                            setPmSp(response.data.pro_max.selling_price)
                            setPmDesc(response.data.pro_max.description)
                        } else if (response && response.status === 401) {
                            logout();
                        } else {
                        }
                    })()
                } else {
                    return history.goBack()
                }
            } catch (error) {
                console.error(error)
            }
        })()
    }, [])

    async function saveData() {
        try {

            document.getElementById("saveDataBtn").classList.toggle("d-none")
            document.getElementById("saveDataBtnLoader").classList.toggle("d-none")
            let response = await updateProPlans(token, plOp, plSp, plDesc, ppOp, ppSp, ppDesc, pmOp, pmSp, pmDesc)
            if (response && response.status === 200) {
                setShowSuccess(true)
                setError("")
            } else if (response && response.status === 401) {
                logout();
            } else {
                setError(response.data.error)
            }
            document.getElementById("saveDataBtn").classList.toggle("d-none")
            document.getElementById("saveDataBtnLoader").classList.toggle("d-none")
        } catch (error) {
            console.error(error)
        }
    }


    // customer pro plans pricing management main ui code
    return (
        <>
            <TopBarPage />
            <div style={{ display: "flex" }}>
                <LeftBarPage />
                <div id='nmContainer' className="smPO-container">
                    <div style={{ width: "100%" }}>
                        <div className="row d-flex align-items-center mt-2 ml-2 mb-4 mr-3">
                            <div className="col-lg-6 text-left">
                                <p className="h4 text-custom-grey">PRO Plans Pricing</p>
                            </div>
                        </div>
                        <div className="smPOI-container">
                            <div className='row'>
                                <div className='col-md-4 col-12 mt-md-0 mt-5'>
                                    <form>
                                        <div>
                                            <label htmlFor="title" className='font-weight-bold'>PRO Lite</label>
                                        </div>
                                        <div className="row ">
                                            <div className='col-12 f-12'>
                                                <label htmlFor="title">Original Price</label>
                                            </div>
                                            <div className='col-12'>
                                                <input type="number" className="form-control mb-1 f-10" name="title" value={plOp} placeholder="Original Price" onChange={(e) => {
                                                    setPlOp(e.target.value)
                                                }} />
                                            </div>
                                        </div>
                                        <div className="row ">
                                            <div className='col-12 f-12'>
                                                <label htmlFor="title">Selling Price</label>
                                            </div>
                                            <div className='col-12'>
                                                <input type="number" className="form-control mb-1 f-10" name="title" value={plSp} placeholder="Selling Price" onChange={(e) => {
                                                    setPlSp(e.target.value)
                                                }} />
                                            </div>
                                        </div>
                                        <div className="row ">
                                            <div className='col-12 f-12'>
                                                <label htmlFor="title">Message</label>
                                            </div>
                                            <div className='col-12'>
                                                <input type="text" className="form-control mb-1 f-10" name="title" value={plDesc} placeholder="Message" onChange={(e) => {
                                                    setPlDesc(e.target.value)
                                                }} />
                                            </div>
                                        </div>
                                    </form>
                                </div>
                                <div className='col-md-4 col-12 mt-md-0 mt-5'>
                                    <form>
                                        <div>
                                            <label htmlFor="title" className='font-weight-bold'>PRO Plus</label>
                                        </div>
                                        <div className="row ">
                                            <div className='col-12 f-12'>
                                                <label htmlFor="title">Original Price</label>
                                            </div>
                                            <div className='col-12'>
                                                <input type="number" className="form-control mb-1 f-10" name="title" value={ppOp} placeholder="Original Price" onChange={(e) => {
                                                    setPpOp(e.target.value)
                                                }} />
                                            </div>
                                        </div>
                                        <div className="row ">
                                            <div className='col-12 f-12'>
                                                <label htmlFor="title">Selling Price</label>
                                            </div>
                                            <div className='col-12'>
                                                <input type="number" className="form-control mb-1 f-10" name="title" value={ppSp} placeholder="Selling Price" onChange={(e) => {
                                                    setPpSp(e.target.value)
                                                }} />
                                            </div>
                                        </div>
                                        <div className="row ">
                                            <div className='col-12 f-12'>
                                                <label htmlFor="title">Message</label>
                                            </div>
                                            <div className='col-12'>
                                                <input type="text" className="form-control mb-1 f-10" name="title" value={ppDesc} placeholder="Message" onChange={(e) => {
                                                    setPpDesc(e.target.value)
                                                }} />
                                            </div>
                                        </div>
                                    </form>
                                </div>
                                <div className='col-md-4 col-12 mt-md-0 mt-5'>
                                    <form>
                                        <div>
                                            <label htmlFor="title" className='font-weight-bold'>PRO Maxx</label>
                                        </div>
                                        <div className="row ">
                                            <div className='col-12 f-12'>
                                                <label htmlFor="title">Original Price</label>
                                            </div>
                                            <div className='col-12'>
                                                <input type="number" className="form-control mb-1 f-10" name="title" value={pmOp} placeholder="Original Price" onChange={(e) => {
                                                    setPmOp(e.target.value)
                                                }} />
                                            </div>
                                        </div>
                                        <div className="row ">
                                            <div className='col-12 f-12'>
                                                <label htmlFor="title">Selling Price</label>
                                            </div>
                                            <div className='col-12'>
                                                <input type="number" className="form-control mb-1 f-10" name="title" value={pmSp} placeholder="Selling Price" onChange={(e) => {
                                                    setPmSp(e.target.value)
                                                }} />
                                            </div>
                                        </div>
                                        <div className="row ">
                                            <div className='col-12 f-12'>
                                                <label htmlFor="title">Message</label>
                                            </div>
                                            <div className='col-12'>
                                                <input type="text" className="form-control mb-1 f-10" name="title" value={pmDesc} placeholder="Message" onChange={(e) => {
                                                    setPmDesc(e.target.value)
                                                }} />
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                            <div className='mt-4'>
                                <div className="form-check p-0">
                                    <p className="text-danger m-0" id="generateSocialMediaImageError">{error}</p>
                                </div>

                                <div className=" mt-1">
                                    <button id="saveDataBtn" className="btn btn-primary" onClick={(e) => {
                                        e.preventDefault()
                                        saveData()
                                    }}>Update Plans Pricing</button>
                                    <div id="saveDataBtnLoader" className='animBtn d-none'><div className='gSignIn-Anim'></div></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <ToastPage />
            </div>
        </>
    )
}