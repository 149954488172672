import React, { useEffect, useState } from 'react';
import { configs, formatCurrency } from '../../../assets/Config';
import { useLogin } from '../../../services/contexts/AdminLoginContext';
import '../../../assets/styles/BuyOrder.css'
import TopBarPage from '../../includes/dashboard/TopBarPage';
import LeftBarPage from '../../includes/dashboard/LeftBarPage';
import { DataGrid, GridToolbarContainer, GridToolbarExport, GridToolbar, GridToolbarColumnsButton, GridToolbarFilterButton, GridToolbarDensitySelector, GridMenu } from '@material-ui/data-grid';
import { Link } from 'react-router-dom';
import { useDistributorContext } from '../../../services/contexts/DistributorContext';
import { useHistory } from 'react-router-dom';
import ToastPage from '../../includes/ToastPage';
import { downloadHDFCSheet, downloadPayoutInvoice, updatePayoutStatus } from '../../../services/apis/Distributor/DistributorApi';
import { Modal } from '@material-ui/core';
import { useKYCContext } from '../../../services/contexts/KYCContext';
import ErrorModalPage from '../../includes/ErrorModalPage';
import { hide } from '@popperjs/core';

export default function DistributorPayoutPage() {

    const { getDistributorCommissionOrPayout, setKycRequesterUserDetail } = useDistributorContext()
    const { setCurrModule, allowedFunc, token, setLoginError, logout, setShowSuccess } = useLogin()
    const { showError, setShowError } = useKYCContext()


    const [tableData, setTableData] = useState([])
    const [payoutModal, setPayoutModal] = useState(false);
    const [modalDetail, setModalDetail] = useState("");
    const [status, setStatus] = useState("SUCCESSFUL");
    const [activeStatus, setActiveStatus] = useState("");
    const [remark, setRemark] = useState("");
    const [selectionModel, setSelectionModel] = useState([]);

    const [error, setError] = useState("");

    const history = useHistory()


    useEffect(() => {
        if (allowedFunc.includes(configs.functionList['distributor_management:load_distributor_details'])) {
            (async () => {
                setCurrModule(configs.modules.distPayout)
                getpayout();
            })()
        } else {
            return history.goBack()
        }
    }, [activeStatus])

    // get payout 
    async function getpayout() {
        try {
            let response = await getDistributorCommissionOrPayout(true, activeStatus);
            if (response.success && response.success === "success") {
                setTableData(response.data)
            } else {

            }
        } catch (error) {
            console.error(error);
        }
    }

    // columns for various data tables
    const tableColumns = [
        { field: "id", headerName: "ID", minWidth: 50, flex: 0.4, hide: true },
        { field: "distributor_id", headerName: "Distributor ID", minWidth: 50, flex: 0.4, hide: true },
        {
            field: "distributor_name", headerName: "Name", minWidth: 150, flex: 0.4, cellClassName: "break-text f-12"
        },
        {
            field: "distributor_email", headerName: "E-mail", minWidth: 150, flex: 0.4, cellClassName: "break-text f-12", renderCell: (params) => {
                if (allowedFunc.includes(configs.functionList['distributor_management:load_distributor_details'])) {
                    return <Link to={`/distributorProfile/${params.row.distributor_id}`} onClick={() => {
                        setKycRequesterUserDetail(params.row)
                    }}>{params.row.distributor_email}</Link>
                } else {
                    return params.row.distributor_email
                }
            }, valueGetter: (value) => value.row.distributor_email
        },
        {
            field: "distributor_mobile", headerName: "Phone No.", minWidth: 100, flex: 0.3, cellClassName: "break-text f-12", renderCell: (params) => {
                return <a href={"https://wa.me/" + params.row.distributor_mobile} target="_blank">{params.row.distributor_mobile ? `+${params.row.distributor_mobile}` : params.row.distributor_mobile}</a>
            }, valueGetter: (value) => value.row.distributor_mobile ? `+${value.row.distributor_mobile}` : value.row.distributor_mobile
        },
        {
            field: "amount", headerName: "Amount", minWidth: 100, flex: 0.4, cellClassName: "break-text f-12", renderCell: (params) => {
                return formatCurrency(parseFloat(params.row.amount))
            }, valueGetter: (value) => value.row.amount
        },
        {
            field: "tds", headerName: "TDS", minWidth: 100, flex: 0.4, cellClassName: "break-text f-12", renderCell: (params) => {
                return formatCurrency(parseFloat(params.row.tds))
            }, valueGetter: (value) => value.row.tds
        },
        { field: "status", headerName: "Status", minWidth: 150, flex: 0.3, cellClassName: "break-text f-12" },
        { field: "remark", headerName: "Remark", minWidth: 150, flex: 0.3, cellClassName: "break-text f-12" },
        { field: "timestamp", headerName: "Time Stamp", minWidth: 150, flex: 0.3, cellClassName: "break-text f-12" },
        {
            field: "update", headerName: " ", minWidth: 150, flex: 0.4, cellClassName: "break-text f-12", filterable: false, hide: activeStatus === "INITIATED" ? false : true, renderCell: (cellValues) => {
                if (allowedFunc.includes(configs.functionList['distributor_management:load_distributor_details'])) {
                    return <a href="#" className="custom-link" onClick={(e) => {
                        e.preventDefault()
                        setModalDetail(cellValues.row);
                        setPayoutModal(true);
                    }}>Update Payout</a>
                } else {
                    return ""
                }
            }
        },
        {
            field: "payout", headerName: " ", minWidth: 150, flex: 0.4, cellClassName: "break-text f-12", filterable: false, hide: activeStatus === "SUCCESSFUL" ? false : true, renderCell: (cellValues) => {
                if (allowedFunc.includes(configs.functionList['distributor_management:load_distributor_details'])) {
                    return <a href="#" className="custom-link" onClick={(e) => {
                        e.preventDefault()
                        downloadInvoiceByPayoutId(cellValues.row.id);
                    }}>Download Invoice</a>
                } else {
                    return ""
                }
            }
        }
    ]


    // update payout function
    async function updatePayout() {
        try {
            document.getElementById("updateCommentBtn").classList.toggle("d-none")
            document.getElementById("updateCommentBtnLoader").classList.toggle("d-none")

            let response = await updatePayoutStatus(token, modalDetail.id, status, remark);
            if (response && response.status === 200) {
                setShowSuccess(true);
                handleClose();
                getpayout();
            } else if (response && response.status === 401) {
                logout()
                setLoginError(configs.unauthorizedErrorMessage)
            } else { setError(response.data.error) }

            document.getElementById("updateCommentBtn").classList.toggle("d-none")
            document.getElementById("updateCommentBtnLoader").classList.toggle("d-none")
        } catch (error) {
            console.error(error);
        }
    }

    // function to download HDFC sheet
    async function downloadHDFCSheetByPayoutId(PayoutIds) {
        try {
            let response = await downloadHDFCSheet(token, PayoutIds);
            if (response && response.status === 200) {
                let a = document.createElement("a"); //Create <a>
                a.href = response.data.file; //Image Base64 Goes here
                a.download = response.data.fileName; //File name Here
                a.click();
                return "success"
            } else if (response && response.status === 401) {
                logout();
                //setLoginError(configs.unauthorizedErrorMessage);
            }
            else {
                return response.data.error;
            }
        } catch (error) {
            console.error(error)
        }
    }

    // function to download payout invoice
    async function downloadInvoiceByPayoutId(payoutId) {
        try {
            let response = await downloadPayoutInvoice(token, payoutId);
            if (response && response.status === 200) {
                let a = document.createElement("a"); //Create <a>
                a.href = response.data; //Image Base64 Goes here
                a.download = `Payout_Invoice_${payoutId}.pdf`; //File name Here
                a.click();
                return "success"
            } else if (response && response.status === 401) {
                logout();
                //setLoginError(configs.unauthorizedErrorMessage);
            }
            else {
                return response.data.error;
            }
        } catch (error) {
            console.error(error)
        }
    }

    const handleClose = () => {
        setPayoutModal(false);
        setStatus("SUCCESSFUL");
        setRemark("");
        setModalDetail("");
        setError("");
    }

    // download hdfc sheet
    async function exportHDFC() {
        try {
            let ele = document.getElementById('buyOrderContainer').click();
            let response = await downloadHDFCSheetByPayoutId(selectionModel)
            if (response && response === "success") {
                setSelectionModel([])
            } else {
                setError(response)
                setShowError(true)
            }
        } catch (error) {
            console.error(error)
        }
    }

    // add hdfc export option in export toolbar
    function addHDFCExportButton() {
        try {
            setTimeout(() => {
                // get root element to add element
                let ele = document.getElementsByClassName("MuiList-root MuiDataGrid-gridMenuList MuiList-padding")
                if ((ele.length > 0) && (selectionModel.length > 0)) {
                    //create new element to add
                    var li = document.createElement('li')
                    li.className = "MuiButtonBase-root MuiListItem-root MuiMenuItem-root MuiMenuItem-gutters MuiListItem-gutters MuiListItem-button";
                    let span = document.createElement('span');
                    span.className = "MuiTouchRipple-root"
                    li.appendChild(document.createTextNode("HDFC Sheet"))
                    li.appendChild(span)
                    li.tabIndex = 0
                    li.setAttribute("role", "menu-item")
                    li.ariaDisabled = "false"
                    li.addEventListener("click", exportHDFC);

                    //add new elemet to parent
                    ele[0].appendChild(li)
                }
            }, 200)
        } catch (error) {
            console.error(error)
        }
    }

    // react data table export option
    function CustomToolbar() {
        try {
            let ele = document.querySelectorAll("[aria-label^='Export']");
            if (ele.length > 0) {
                ele[0].addEventListener("click", addHDFCExportButton)
            }
        } catch (error) {
            console.error(error)
        }
        return (
            <GridToolbarContainer>
                <GridToolbarColumnsButton />
                <GridToolbarFilterButton />
                <GridToolbarDensitySelector />
                <GridToolbarExport />
            </GridToolbarContainer>
        );
    }

    // buy order ui code
    return (
        <>
            <TopBarPage />
            <div style={{ display: "flex" }}>
                <LeftBarPage />
                <div id='buyOrderContainer' className="smbo-container">
                    <div style={{ width: "100%" }}>
                        <div className="row mx-md-4 mx-2 my-2 p-0">
                            <div className="col-md-6 m-0 p-0">
                                <p className="h5 text-custom-grey">Distributor Payout</p>
                            </div>
                            <div className="col-md-6 text-md-right text-left m-0 p-0">
                                <span className="text-primary mr-2">Status</span>
                                <select name="" id="" className="w-50 py-1 px-2 border rounded" value={activeStatus} onChange={(e) => {
                                    setActiveStatus(e.target.value);
                                }}>
                                    <option value={""}>All</option>
                                    <option value={"INITIATED"}>Initiated</option>
                                    <option value={"SUCCESSFUL"}>Successful</option>
                                    <option value={"FAILED"}>Failed</option>
                                </select>
                            </div>
                        </div>
                        <div className="kyc-table">
                            {tableData ? <DataGrid
                                rows={tableData}
                                columns={tableColumns}
                                resizable={true}
                                autoPageSize
                                pagination
                                headerHeight={30}
                                rowHeight={40}
                                checkboxSelection={activeStatus === "INITIATED" ? true : false}
                                onSelectionModelChange={(newSelectionModel) => {
                                    setSelectionModel(newSelectionModel);
                                    let ele = document.getElementById('buyOrderContainer').click();
                                }}
                                selectionModel={selectionModel}
                                components={{
                                    Toolbar: CustomToolbar,
                                }}
                            />
                                : <div className="loader"></div>}
                        </div>
                    </div>
                </div>
                {/* // ------------------------payout initiation modal------------------------------- */}
                <Modal open={payoutModal} onClose={handleClose} style={{ overflow: "scroll" }}>
                    <div className="modal-fade" id="exampleModal" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                        <div className="modal-dialog" role="document">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h5 className="text-custom-grey h6" id="exampleModalLabel">Update payout - {modalDetail.id}</h5>
                                    <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={(e) => {
                                        e.preventDefault()
                                        handleClose()
                                    }}>
                                        <span aria-hidden="true">&times;</span>
                                    </button>
                                </div>
                                <div className="modal-body">
                                    <p><b>Distributor Name:</b> {modalDetail.distributor_name}</p>
                                    <p><b>Payout Amount:</b> {formatCurrency(parseFloat(modalDetail.amount))}</p>

                                    <form action="">
                                        <div className="form-group">
                                            <label>Payout Status</label>
                                            <select className="form-control f-10 py-2" value={status} onChange={(e) => {
                                                setStatus(e.target.value);
                                            }}>
                                                <option value="SUCCESSFUL">Successful</option>
                                                <option value="FAILED">Failed</option>
                                            </select>
                                        </div>
                                        <div className="form-group">
                                            <label>Remark</label>
                                            <input type="text" className="form-control f-10 py-2" placeholder="Remark" value={remark} onChange={(e) => {
                                                setRemark(e.target.value)
                                            }} />
                                        </div>
                                    </form>

                                    <div className="form-check p-0">
                                        <p className="text-danger" id="remarkmodalError">{error}</p>
                                    </div>
                                </div>
                                <div className="modal-footer justify-content-center">
                                    <button id="updateCommentBtn" type="button" className="btn btn-primary" onClick={(e) => {
                                        e.preventDefault()
                                        updatePayout()
                                    }}>Update Payout</button>
                                    <div id="updateCommentBtnLoader" className='animBtn d-none'><div className='gSignIn-Anim'></div></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Modal>

                <ToastPage />
                <ErrorModalPage />
            </div>
        </>
    )
}