import { DataGrid, GridToolbar } from '@material-ui/data-grid';
import React, { useEffect, useState } from 'react';
import { configs } from '../../../assets/Config';
import { useLogin } from '../../../services/contexts/AdminLoginContext';
import LeftBarPage from '../../includes/dashboard/LeftBarPage';
import TopBarPage from '../../includes/dashboard/TopBarPage';
import '../../../assets/styles/NM.css'
import { useNMContext } from '../../../services/contexts/NMContext';
import ToastPage from '../../includes/ToastPage';
import { Link } from 'react-router-dom';
import { useHistory } from 'react-router';
import { Modal } from '@material-ui/core';

export default function RemarkMasterPage() {

    const { setCurrModule, allowedFunc, getAllRemarkComments, remarkComments, getAllRemarkTemplate, logout, addRemarkTemplate, editRemarkTemplate, deleteRemarkTemplate, setShowSuccess } = useLogin()

    const history = useHistory()

    const [showModal, setShowModal] = useState(false)
    const [templateMaster, setTemplateMaster] = useState([])
    const [templateId, setTemplateId] = useState("0")
    const [remark, setRemark] = useState("")
    const [action, setAction] = useState("")
    const [error, setError] = useState("")
    const [id, setId] = useState("")

    const handleClose = () => {

        setShowModal(false)
        getAllRemarkComments()
        setTemplateMaster("")
        setTemplateId("0")
        setRemark("")
        setAction("")
        setError("")
        setId("")
    }

    useEffect(() => {
        (async () => {
            try {
                setCurrModule(configs.modules.remarkMaster)
                if (allowedFunc.includes(configs.functionList['remark_management:remark_management'])) {
                    getAllRemarkComments()
                } else {
                    return history.goBack()
                }
            } catch (error) {

            }
        })()
    }, [])

    useEffect(() => {
        if (showModal) {
            (async () => {
                try {
                    let response = await getAllRemarkTemplate()
                    if (response && response.success) {
                        setTemplateMaster(response.data)
                    }
                } catch (error) {

                }
            })()
        }
    }, [showModal])

    // add remark
    async function addRemark() {
        try {
            if (!templateId) {
                setError("Please select a Module.")
            } else if (!remark) {
                setError("Please provide a valid remark.")
            } else {
                let response = await addRemarkTemplate(templateId, remark)
                if (response && response.success) {
                    handleClose()
                    setShowSuccess(true)
                } else {
                    setError(response)
                }
            }
        } catch (error) {

        }
    }

    // edit remark
    async function editRemark() {
        try {
            if (!id) {
                setError("Please select a Reamrk to edit.")
            } else if (!remark) {
                setError("Please provide a valid remark.")
            } else {
                let response = await editRemarkTemplate(id, remark)
                if (response && response.success) {
                    handleClose()
                    setShowSuccess(true)
                } else {
                    setError(response)
                }
            }
        } catch (error) {

        }
    }

    // delete remark
    async function deleteRemark() {
        try {
            if (!id) {
                setError("Please select a Reamrk to edit.")
            } else if (!remark) {
                setError("Please provide a valid remark.")
            } else {
                let response = await deleteRemarkTemplate(id)
                if (response && response.success) {
                    handleClose()
                    setShowSuccess(true)
                } else {
                    setError(response)
                }
            }
        } catch (error) {

        }
    }


    const columns = [{ field: "id", headerName: "Template ID", minWidth: 100, flex: 0.2, hide: true, filterable: false },
    {
        field: "template", headerName: "Template", minWidth: 200, flex: 0.5, cellClassName: "break-text f-12", valueGetter: (value) => value.row.template
    },
    {
        field: "function", headerName: "Module", minWidth: 200, flex: 0.5, cellClassName: "break-text f-12", valueGetter: (value) => value.row.master.name
    },
    {
        field: "edit", headerName: " ", minWidth: 40, flex: 0.1, cellClassName: "break-text", filterable: false, cellClassName: "text-primary", renderCell: (params) => {
            return <a href="#" className="custom-link text-primary" onClick={(e) => {
                e.preventDefault()
                setAction("Edit")
                setId(params.row.id)
                setRemark(params.row.template)
                setTemplateId(params.row.master.id)
                setShowModal(true)
            }}>Edit</a>
        }
    },
    {
        field: "delete", headerName: " ", minWidth: 65, flex: 0.1, cellClassName: "break-text", filterable: false, cellClassName: "text-danger", renderCell: (params) => {
            return <a href="#" className="custom-link text-danger" onClick={(e) => {
                e.preventDefault()
                setAction("Delete")
                setId(params.row.id)
                setRemark(params.row.template)
                setTemplateId(params.row.master.id)
                setShowModal(true)
            }}>Delete</a>
        }
    }
    ]


    // notification management main ui code
    return (
        <>
            <TopBarPage />
            <div style={{ display: "flex" }}>
                <LeftBarPage />
                <div id='nmContainer' className="nm-container">
                    <div style={{ width: "100%" }}>
                        <div className="row d-flex align-items-center mt-2 ml-2 mb-4 mr-3">
                            <div className="col-md-6 col-7 m-0 p-0">
                                <p className="h4 text-custom-grey">Remark Template Management</p>
                            </div>
                            <div className="col-md-6 col-5 text-right m-0 p-0">
                                <a href="#" className="custom-link text-primary" onClick={(e) => {
                                    e.preventDefault();
                                    setAction("Add")
                                    setShowModal(true)
                                }}><i className="fas fa-id-card-alt mr-2"></i>Add Remark Template</a>
                            </div>
                        </div>
                        <div className="nm-role-table m-2">
                            {remarkComments ? <DataGrid
                                rows={remarkComments}
                                headerHeight={40}
                                rowHeight={30}
                                columns={columns}
                                resizable={true}
                                autoPageSize
                                pagination
                                components={{
                                    Toolbar: GridToolbar,
                                }} />
                                : <div className="loader"></div>}
                        </div>
                    </div>
                </div>
                <ToastPage />
            </div>

            {/* ----------------- Remark modify modal--------------------------------------- */}
            <Modal open={showModal} onClose={handleClose} style={{ overflow: "scroll" }}>
                <div className="modal-fade" id="exampleModal" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div className="modal-dialog" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="text-custom-grey h6" id="exampleModalLabel">{action} Remark</h5>
                                <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={(e) => {
                                    e.preventDefault()
                                    handleClose()
                                }}>
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div className="modal-body">

                                <form action="">
                                    <div className="form-group my-1">
                                        <label>Module</label>
                                        <select name="" id="" className="col-12 py-1 px-2 border rounded" value={templateId ? templateId : "0"} onChange={(e) => {
                                            setTemplateId(e.target.value)
                                        }}>
                                            <option disabled value="0"> -- Select a Module -- </option>
                                            {templateMaster ? templateMaster.map((element, key) => {
                                                return (
                                                    <option key={`remarkTemplate` + key} value={element.function}>{element.name}</option>
                                                )
                                            }) : null}
                                        </select>
                                    </div>
                                    <div className="form-group">
                                        <label>Remark</label>
                                        <textarea className="form-control" placeholder="Please enter a remark" value={remark ? remark : ""} onChange={(e) => {
                                            setRemark(e.target.value)
                                        }} />
                                    </div>
                                </form>


                                <div className="form-check p-0">
                                    <p className="text-danger" id="remarkmodalError">{error}</p>
                                </div>
                            </div>
                            <div className="modal-footer justify-content-center">
                                {action === "Add" ?
                                    <button type="button" className="btn btn-primary" onClick={(e) => {
                                        e.preventDefault()
                                        addRemark()
                                    }}>Add Remark</button>
                                    : action === "Edit" ?
                                        <button type="button" className="btn btn-success" onClick={(e) => {
                                            e.preventDefault()
                                            editRemark()
                                        }}>Update Remark</button>
                                        :
                                        <button type="button" className="btn btn-danger" onClick={(e) => {
                                            e.preventDefault()
                                            deleteRemark()
                                        }}>Delete Remark</button>

                                }
                            </div>
                        </div>
                    </div>
                </div>
            </Modal>
            <ToastPage />
        </>
    )
}