import axios from "axios";
import { configs } from "../../../assets/Config";
import FormData from 'form-data';

// upload csv to server
export function uploadCSVDoc(token, file, dep) {
    return new Promise(async (resolve, reject) => {
        try {

            var data = new FormData();
            data.append(dep ? "depository_inventory" : "inventory", file, "inventory.csv")

            var config = {
                method: 'post',
                url: `${process.env.REACT_APP_BASEURL}/admin/${dep ? "inventory/depository" : "buy/inventory"}`,
                headers: {
                    'Authorization': `Bearer ${token}`
                },
                data: data
            };

            let res = await axios(config)
            resolve(res)
        } catch (err) {
            resolve(err?.response)
        }
    })
}


// download csv from server
export function downloadCSVDoc(token, dep) {
    return new Promise(async (resolve, reject) => {
        try {

            var config = {
                method: 'get',
                url: `${process.env.REACT_APP_BASEURL}/admin/${dep ? "inventory/depository" : "buy/inventory"}`,
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            };

            let res = await axios(config)
            resolve(res)
        } catch (err) {
            resolve(err?.response)
        }
    })
}

// get inventory analytics
export function getInventoryAnalytics(token) {
    return new Promise(async (resolve, reject) => {
        try {

            var config = {
                method: 'get',
                url: `${process.env.REACT_APP_BASEURL}/admin/inventory/analytics`,
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            };

            let res = await axios(config)
            resolve(res)
        } catch (err) {
            resolve(err?.response)
        }
    })
}

// get open orders by company and type
export function getCompanyOpenOrders(token, company, type) {
    return new Promise(async (resolve, reject) => {
        try {

            var config = {
                method: 'get',
                url: `${process.env.REACT_APP_BASEURL}/admin/inventory/open-orders?company_id=${company}&type=${type}`,
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            };

            let res = await axios(config)
            resolve(res)
        } catch (err) {
            resolve(err?.response)
        }
    })
}