import { DataGrid, GridToolbar } from '@material-ui/data-grid';
import React, { useEffect, useState } from 'react';
import { configs, downloadImage } from '../../../assets/Config';
import '../../../assets/styles/RM.css'
import { useLogin } from '../../../services/contexts/AdminLoginContext';
import { useNewsContext } from '../../../services/contexts/companyManagement/NewsContext';
import ConfirmModalPage from '../../includes/ConfirmModalPage';

export default function DeletedNewsPage() {
    const { setNewsList, newsList, setShowDeletedNews, showDeletedNews, getAllNewsList } = useNewsContext()
    const { setShowConfirm, showConfirm } = useLogin()

    const [confirmId, setConfirmId] = useState({})

    const columns = [{ field: "id", headerName: "News ID", minWidth: 100, flex: 0.5, hide: true, filterable: false },
    { field: "news_heading", headerName: "Heading", minWidth: 300, flex: 0.5, cellClassName: "break-text" },
    { field: "news_description", headerName: "Description", minWidth: 500, flex: 0.5, cellClassName: "break-text" },
    { field: "news_date", headerName: "Date", minWidth: 150, flex: 0.5, cellClassName: "break-text" },
    { field: "company_name", headerName: "Company", minWidth: 150, flex: 0.5, cellClassName: "break-text" },
    {
        field: "link", headerName: "Link", minWidth: 110, flex: 0.1, cellClassName: "break-text", cellClassName: "text-primary", renderCell: (params) => {
            return <a href={`${params.row.news_link}`} target="_blank">{params.row.news_link}</a>
        }
    },
    {
        field: "website", headerName: "Website", minWidth: 110, flex: 0.1, cellClassName: "break-text", cellClassName: "text-primary", renderCell: (params) => {
            return <a href={`${params.row.news_website}`} target="_blank">{params.row.news_website}</a>
        }
    },
    {
        field: "image", headerName: "Image", minWidth: 110, flex: 0.1, cellClassName: "break-text", cellClassName: "text-primary", renderCell: (params) => {
            return <div className='row pl-2' id={`news` + params.row.id}>
                <a href={`${params.row.news_image}`} target="_blank">
                    <i className="fa fa-external-link kyc-openDoc-prof curPoint"></i></a>
                <i className="fa fa-download kyc-openDoc-prof curPoint" onClick={(e) => {
                    e.preventDefault()
                    downloadImage(`${params.row.news_image}`, params.row.id)
                }}></i>
            </div >
        }
    },
    {
        field: "restore", headerName: " ", minWidth: 65, flex: 0.1, cellClassName: "break-text", cellClassName: "text-danger", renderCell: (params) => {
            return <a href="#" className="custom-link text-danger" onClick={(e) => {
                e.preventDefault()
                setConfirmId({
                    "category": configs.modules.newsManage,
                    "type": "News",
                    "name": params.row.name,
                    "id": params.row.id
                })
                setShowConfirm(true)
            }}>Restore</a>
        }
    }]

    useEffect(() => {
        (async () => {
            try {
                await getAllNewsList(true)
            } catch (error) {

            }
        })()
    }, [])

    // role list page ui code
    return (
        <div style={{ width: "100%" }}>
            <div className="row d-flex align-items-center mt-2 ml-3 mb-1 mr-2">
                <div className="col-lg-6 col-7">
                    <p className="h4 text-custom-grey">Deleted News</p>
                </div>
                <div className="col-lg-6 col-5 text-right">
                    <a href="#" className="custom-link" onClick={(e) => {
                        e.preventDefault()
                        setNewsList("")
                        setShowDeletedNews(!showDeletedNews)
                    }}><i className={showDeletedNews ? "fas fa-user mr-2" : "fas fa-user-slash mr-2"}></i>{showDeletedNews ? "Active News" : "Deleted News"}</a>
                </div>
            </div>
            <div className=" deleted-role-table">
                {newsList ? <DataGrid
                    rows={newsList}
                    headerHeight={40}
                    rowHeight={30}
                    columns={columns}
                    resizable={true}
                    autoPageSize
                    pagination
                    components={{
                        Toolbar: GridToolbar,
                    }} /> : <div className="loader"></div>}
            </div>
            <ConfirmModalPage {...confirmId} />

        </div>
    )
}