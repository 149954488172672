import React from 'react';
import { createContext, useContext, useState } from 'react';
import { configs, convertToBlob } from '../../assets/Config';
import { customerCompleteKYC, distributorFormLeads, getCustomerDocuments, getCustomerStatistics, getDistributorCommissionsPayout, kycRequesters, kycVerified } from '../apis/Distributor/DistributorApi';
import { useLogin } from "./AdminLoginContext";

// create global login context
export const DistributorContext = createContext();

export function DistributorProvider({ children }) {

    const { token, logout, setLoginError } = useLogin();
    const [error, setError] = useState("");
    const [kycRequestersList, setKycRequestersList] = useState("");
    const [formLeads, setFormLeads] = useState("");
    const [kycVerifiedList, setKycVerifiedList] = useState("");
    const [kycRequesterUserDetail, setKycRequesterUserDetail] = useState("");
    const [kycRequesterUserComplete, setKycRequesterUserComplete] = useState("");
    const [panDoc, setpanDoc] = useState("null");
    const [addressDoc, setaddressDoc] = useState("null");
    const [dematDoc, setdematDoc] = useState("null");
    const [otherDoc, setOtherDoc] = useState("null");
    const [bankDoc, setbankDoc] = useState("null");
    const [showError, setShowError] = useState(false);
    const [activeStatus, setActiveStatus] = useState("")

    // get form leads
    async function getFormLeads() {
        try {
            let response = await distributorFormLeads(token);
            if (response && response.status === 200) {
                setFormLeads(response.data)
            }
            else if (response && response.status === 401) {
                logout()
                setLoginError(configs.unauthorizedErrorMessage)
            } else { setError(response.data.error) }
        } catch (error) {

        }
    }

    // get kyc all requesters
    async function getKYCRequesters() {
        try {
            let response = await kycRequesters(token);
            if (response && response.status === 200) {
                setKycRequestersList(response.data)
            }
            else if (response && response.status === 401) {
                logout()
                setLoginError(configs.unauthorizedErrorMessage)
            } else { setError(response.data.error) }
        } catch (error) {

        }
    }

    // get kyc verified users
    async function getKYCVerifiedCustomer() {
        try {
            let response = await kycVerified(token);
            if (response && response.status === 200) {
                setKycVerifiedList(response.data)
            }
            else if (response && response.status === 401) {
                logout()
                setLoginError(configs.unauthorizedErrorMessage)
            } else { setError(response.data.error) }
        } catch (error) {

        }
    }

    // get complete kyc detail of customer
    async function getKYCCustomerDetail(type) {
        try {
            let response = await customerCompleteKYC(token, kycRequesterUserDetail.id);
            if (response && response.status === 200) {
                setKycRequesterUserComplete(response.data)
            } else if (response && response.status === 401) {
                logout()
                setLoginError(configs.unauthorizedErrorMessage)
            } else { setError(response.data.error) }

            getPanProof(type)
            getAddressProof(type)
            getBankProof(type)
            getDematProof(type)
            getOtherProof(type)

            return response.data
        } catch (error) {

        }

    }

    // get customer stats
    async function getCustomerStats(type) {
        try {
            let response = await getCustomerStatistics(token, type);
            if (response && response.status === 200) {
                return {
                    "success": "success",
                    "data": response.data
                }
            } else if (response && response.status === 401) {
                logout()
                setLoginError(configs.unauthorizedErrorMessage)
            } else { setError(response.data.error) }

            getPanProof()
            getAddressProof()
            getBankProof()
            getDematProof()

            return response.data
        } catch (error) {

        }

    }

    // get customer uploaded documents
    async function getPanProof(type) {
        try {
            type = type === undefined ? "" : type
            let response = await getCustomerDocuments(token, configs.docTypes.pan_card, kycRequesterUserDetail.id);
            if (response && response.status === 200) {
                if (response.data.includes("application/pdf")) {
                    setpanDoc(response.data)
                    convertToBlob(response.data, `${type}panFrame`)
                } else {
                    setpanDoc(response.data)
                }
            } else if (response && response.status === 401) {
                logout();
                setLoginError(configs.unauthorizedErrorMessage);
            }
            else { }
        } catch (error) {

        }
    }
    async function getAddressProof(type) {
        try {
            type = type === undefined ? "" : type
            let response = await getCustomerDocuments(token, configs.docTypes.address_proof, kycRequesterUserDetail.id);
            if (response && response.status === 200) {
                if (response.data.includes("application/pdf")) {
                    setaddressDoc(response.data)
                    convertToBlob(response.data, `${type}addressFrame`)
                } else {
                    setaddressDoc(response.data)
                }
            } else if (response && response.status === 401) {
                logout();
                setLoginError(configs.unauthorizedErrorMessage);
            }
            else { }
        } catch (error) {

        }
    }
    async function getBankProof(type) {
        try {
            type = type === undefined ? "" : type
            let response = await getCustomerDocuments(token, configs.docTypes.bank_proof, kycRequesterUserDetail.id);
            if (response && response.status === 200) {
                if (response.data.includes("application/pdf")) {
                    setbankDoc(response.data)
                    convertToBlob(response.data, `${type}bankFrame`)
                } else {
                    setbankDoc(response.data)
                }
            } else if (response && response.status === 401) {
                logout();
                setLoginError(configs.unauthorizedErrorMessage);
            }
            else { }
        } catch (error) {

        }
    }
    async function getDematProof(type) {
        try {
            type = type === undefined ? "" : type
            let response = await getCustomerDocuments(token, configs.docTypes.demat_proof, kycRequesterUserDetail.id);
            if (response && response.status === 200) {
                if (response.data.includes("application/pdf")) {
                    setdematDoc(response.data)
                    convertToBlob(response.data, `${type}dematFrame`)
                } else {
                    setdematDoc(response.data)
                }
            } else if (response && response.status === 401) {
                logout();
                setLoginError(configs.unauthorizedErrorMessage);
            }
            else { }
        } catch (error) {

        }
    }
    async function getOtherProof(type) {
        try {
            type = type === undefined ? "" : type
            let response = await getCustomerDocuments(token, configs.docTypes.other_document, kycRequesterUserDetail.id);
            if (response && response.status === 200) {
                if (response.data.includes("application/pdf")) {
                    setOtherDoc(response.data)
                    convertToBlob(response.data, `${type}otherFrame`)
                } else {
                    setOtherDoc(response.data)
                }
            } else if (response && response.status === 401) {
                logout();
                setLoginError(configs.unauthorizedErrorMessage);
            }
            else { }
        } catch (error) {

        }
    }

    // get distributor commission
    async function getDistributorCommissionOrPayout(payout, status) {
        try {
            let response = await getDistributorCommissionsPayout(token, payout, status);
            if (response && response.status === 200) {
                return {
                    "success": "success",
                    "data": response.data
                }
            } else if (response && response.status === 401) {
                logout()
                setLoginError(configs.unauthorizedErrorMessage)
            } else { setError(response.data.error) }

            return response.data
        } catch (error) {
        }
    }

    // export global context
    return (
        <DistributorContext.Provider
            value={{
                error,
                getKYCRequesters,
                kycRequestersList,
                kycRequesterUserDetail,
                setKycRequesterUserDetail,
                kycVerifiedList,
                getKYCVerifiedCustomer,
                kycRequesterUserComplete,
                setKycRequesterUserComplete,
                getKYCCustomerDetail,
                panDoc,
                addressDoc,
                bankDoc,
                dematDoc,
                otherDoc,
                setpanDoc,
                setaddressDoc,
                setbankDoc,
                setdematDoc,
                setOtherDoc,
                showError,
                setShowError,
                getCustomerStats,
                activeStatus,
                setActiveStatus,
                getFormLeads,
                getDistributorCommissionOrPayout
            }}
        >
            {children}
        </DistributorContext.Provider>
    );
}

// use kyc context globally
export function useDistributorContext() {
    return useContext(DistributorContext);
}