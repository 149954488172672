import { DataGrid, GridToolbar } from '@material-ui/data-grid';
import React, { useEffect, useRef, useState } from 'react';
import { configs, generateBase64 } from '../../../assets/Config';
import { useLogin } from '../../../services/contexts/AdminLoginContext';
import LeftBarPage from '../../includes/dashboard/LeftBarPage';
import TopBarPage from '../../includes/dashboard/TopBarPage';
import '../../../assets/styles/NM.css'
import ToastPage from '../../includes/ToastPage';
import { useHistory } from 'react-router';
import JoditEditor from "jodit-react";
import { getCareerPage, postCareerPage } from '../../../services/apis/roleManagement/RoleAPI';


export default function CareerManagement() {

    const { token, setCurrModule, setShowSuccess, allowedFunc } = useLogin()

    const history = useHistory()

    const [careerData, setCareerData] = useState([{
        file: '',
        heading: '',
        detail: '',
        google_btn_link: '',
        linkedin_btn_link: ''
    }]);
    const [error, setError] = useState("");

    useEffect(() => {
        (async () => {
            try {
                if (allowedFunc.includes(configs.functionList['career_page_management:career_page_management'])) {
                    setCurrModule(configs.modules.careerMgmt)
                    let response = await getCareerPage(token);
                    if (response && response.status == 200) {
                        if (response.data.data && response.data.data.length > 0)
                            setCareerData(response.data.data);
                    } else {
                        setError(response.data.error)
                    }
                } else {
                    return history.goBack()
                }
            } catch (error) {

            }
        })()
    }, [])

    // get file contents through file chooser
    async function uploadFile(file, index) {
        try {
            if (file.type === "image/jpg" || file.type === "image/jpeg" || file.type === "image/png") {
                let data = careerData;
                data[index].file = await generateBase64(file)
                data[index].fileName = file.name
                setCareerData([...careerData], data);
            } else {
                alert("Only jpg or png files are allowed.")
            }
        } catch (error) {
            console.error(error)
        }
    }

    // update heading
    function uploadText(text, index) {
        try {
            let data = careerData;
            data[index].heading = text
            setCareerData([...careerData], data);

        } catch (error) {
            console.error(error)
        }
    }

    // update google button link
    function updateGoogleButtonLink(text, index) {
        try {
            let data = careerData;
            data[index].google_btn_link = text
            setCareerData([...careerData], data);

        } catch (error) {
            console.error(error)
        }
    }

    // update linkedin button text
    function updateLinkedinButtonLink(text, index) {
        try {
            let data = careerData;
            data[index].linkedin_btn_link = text
            setCareerData([...careerData], data);

        } catch (error) {
            console.error(error)
        }
    }

    // add career detail function
    function modifyCareerSection(index, setDelete) {
        try {
            if (setDelete && careerData.length > 1) {
                let highlight = careerData
                highlight.splice(index, 1);

                setCareerData([...careerData], highlight)
            } else if (careerData[careerData.length - 1]?.file && careerData[careerData.length - 1]?.heading && careerData[careerData.length - 1]?.google_btn_link && careerData[careerData.length - 1]?.linkedin_btn_link && careerData[careerData.length - 1]?.detail) {
                let highlight = careerData
                highlight.push({
                    file: '',
                    heading: '',
                    detail: ''
                })
                setCareerData([...careerData], highlight)
                setError("");
            } else if (!setDelete) {
                setError("New career data component could not be created as above component has empty data.")
            }
        } catch (error) {
            console.error(error);
        }
    }

    const editor = useRef(null)

    const config = {
        readonly: false,
        controls: {
            font: {
                list: {
                    'Roboto Medium,Arial,sans-serif': 'Roboto',
                    'Comic Neue cursive': 'Comic Sans'
                }
            }
        }
    }

    function handleChange(value, index) {
        let data = careerData;
        data[index].detail = value
        setCareerData([...careerData], data);
    }

    async function postCareerPageData() {
        try {
            document.getElementById("postCareerBtn").classList.toggle("d-none")
            document.getElementById("postCareerBtnLoader").classList.toggle("d-none")

            if (careerData && careerData.length > 0) {
                let response = await postCareerPage(token, careerData);
                if (response && response.status === 200) {
                    setShowSuccess(true);
                    setError("");
                } else {
                    setError(response.data.error);
                }
            } else {
                setError("Please provide data for career page.")
            }
        } catch (error) {
            console.error(error);
        } finally {
            document.getElementById("postCareerBtn").classList.toggle("d-none")
            document.getElementById("postCareerBtnLoader").classList.toggle("d-none")
        }
    }


    // notification management main ui code
    return (
        <>
            <TopBarPage />
            <div style={{ display: "flex" }}>
                <LeftBarPage />
                <div id='nmContainer' className="nm-container">
                    <div style={{ width: "100%" }}>
                        <div className="row d-flex align-items-center mt-2 ml-2 mb-4 mr-3">
                            <div className="col-md-6 col-7 m-0 p-0">
                                <p className="h4 text-custom-grey">Career Management</p>
                            </div>

                        </div>
                        <div className="smPOI-container">
                            {careerData.map((ele, key) => {
                                return (
                                    <div className={`mx-0 px-0 py-2 bg-light ${key > 0 ? "mt-5" : ""}`}>
                                        <div className='row m-0 p-0' key={`career${key}`}>
                                            <div className='col-md-4'>
                                                <div className="form-group">
                                                    <div className='f-12'>
                                                        <label htmlFor="title" className="font-weight-bold">Icon File</label>
                                                    </div>
                                                    <div className="file-border rounded py-4" onDragOver={(e) => {
                                                        e.preventDefault();
                                                    }} onDrop={(e) => {
                                                        uploadFile(e.dataTransfer.files[0], key)
                                                        e.preventDefault();
                                                    }}>
                                                        <i className="fas fa-file-upload f-22"></i>
                                                        <p className="pb-0 mb-0 f-12">
                                                            Drag your file or &nbsp;
                                                            <input type="file" id={`highlight${key}`} required hidden accept="image/jpg, image/jpeg, image/png" onChange={(e) => {
                                                                uploadFile(e.target.files[0], key)
                                                            }} />
                                                            <label htmlFor={`highlight${key}`} className="mb-0 pb-0">Click here</label>
                                                            &nbsp; to choose <br /> Upload: <span className="text-theme">{ele.fileName}</span>
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='col-md-4'>
                                                <div className="form-group">
                                                    <div className='f-12'>
                                                        <label htmlFor="title" className="font-weight-bold">Heading</label>
                                                    </div>
                                                    <input type="text" className="form-control" placeholder="Heading Text" value={ele.heading ? ele.heading : ""} onChange={(e) => {
                                                        uploadText(e.target.value, key)
                                                    }} />
                                                </div>
                                                <div className="form-group">
                                                    <div className='f-12'>
                                                        <label htmlFor="title" className="font-weight-bold">Google Button Link</label>
                                                    </div>
                                                    <input type="text" className="form-control" placeholder="Google Button Link" value={ele.google_btn_link ? ele.google_btn_link : ""} onChange={(e) => {
                                                        updateGoogleButtonLink(e.target.value, key)
                                                    }} />
                                                </div>
                                                <div className="form-group">
                                                    <div className='f-12'>
                                                        <label htmlFor="title" className="font-weight-bold">LinkedIn Button Link</label>
                                                    </div>
                                                    <input type="text" className="form-control" placeholder="LinkedIn Button Link" value={ele.linkedin_btn_link ? ele.linkedin_btn_link : ""} onChange={(e) => {
                                                        updateLinkedinButtonLink(e.target.value, key)
                                                    }} />
                                                </div>
                                            </div>
                                            <div className='col-md-4'>
                                                <div className="form-group">
                                                    <div className="justify-content-end d-flex">
                                                        <button href="#" data-toggle="modal" data-target="#deleteRole" className="btn btn-danger f-10" onClick={(e) => {
                                                            e.preventDefault()
                                                            // modifyHighlight(key, true)
                                                        }}><i className="fas fa-trash mr-2 f-10"></i>Delete Component</button>
                                                    </div>
                                                    <p className="font-weight-bold">Current Image File</p>
                                                    <img style={{ width: "10rem", "objectFit": "contain" }} src={ele.file ? ele.file : ""} />
                                                </div>
                                            </div>
                                            <div className='col-md-12'>
                                                <div className="form-group">
                                                    <div className='f-12'>
                                                        <label htmlFor="title" className="font-weight-bold">Profile Detail Page</label>
                                                    </div>
                                                    <JoditEditor
                                                        ref={editor}
                                                        value={ele.detail ? ele.detail : ""}
                                                        config={config}
                                                        tabIndex={1} // tabIndex of textarea
                                                        onBlur={(value) => handleChange(value, key)} // preferred to use only this option to update the content for performance reasons
                                                    // onChange={handleChange}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )
                            })}

                            <div className='row m-0 p-0 mt-3'>
                                <p className="text-danger">{error}</p>
                                <div className='col-12 m-0 p-0 d-flex justify-content-end'>
                                    <div className="form-group d-flex">
                                        <button className="text-primary custom-button" onClick={(e) => {
                                            e.preventDefault()
                                            modifyCareerSection()
                                        }}><i className="fas fa-plus-circle mr-2"></i>Add New Position</button>

                                        <div className="">
                                            <button id="postCareerBtn" className="btn btn-primary" onClick={(e) => {
                                                e.preventDefault()
                                                postCareerPageData()
                                            }}>Update Position</button>
                                            <div id="postCareerBtnLoader" className='animBtn d-none'><div className='gSignIn-Anim'></div></div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <ToastPage />
            </div >

        </>
    )
}