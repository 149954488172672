import Modal from "@material-ui/core/Modal";
import { useRef, useState, useEffect } from "react";
import { configs } from "../../assets/Config";
import '../../assets/styles/TopBar.css'
import { useLogin } from "../../services/contexts/AdminLoginContext";
import { useBuyOrderContext } from "../../services/contexts/BuyOrdersContext";
import { useCMContext } from "../../services/contexts/companyManagement/CMContext";
import { useNewsContext } from "../../services/contexts/companyManagement/NewsContext";
import { useRMContext } from "../../services/contexts/RMContext";
import { useSellOrderContext } from "../../services/contexts/SellOrdersContext";
import ToastPage from "./ToastPage";
import JoditEditor from "jodit-react";
import { useBondContext } from "../../services/contexts/assetManagement/BondContext";
import { useMLDContext } from "../../services/contexts/assetManagement/MLDContext";
import { useGSecContext } from "../../services/contexts/assetManagement/GSecContext";
import { useStartupContext } from "../../services/contexts/assetManagement/StartupContext";
import { useBucketContext } from "../../services/contexts/assetManagement/BucketContext";
import { useMixBucketContext } from "../../services/contexts/assetManagement/MixBucketContext";


export default function RichTextEditorPage(type) {
    const { showRTPage, setShowRTPage, overview, setOverview, insight, setInsight, annualReport, setAnnualReport } = useCMContext()
    const { bsnapshot, setBsnapshot, bkeyHigh, setBkeyHigh, bdeal, setBdeal, bterms, setBterms, bfaq, setBfaq, bdoc, setBdoc } = useBondContext()
    const { mldsnapshot, setMLDsnapshot, mldkeyHigh, setMLDkeyHigh, mlddeal, setMLDdeal, mldterms, setMLDterms, mldfaq, setMLDfaq, mlddoc, setMLDdoc } = useMLDContext()
    const { StartupCompanyPitch, setStartupCompanyPitch, StartupAboutTeam, setStartupAboutTeam, Startupterms, setStartupterms, Startupfaq, setStartupfaq, Startupdoc, setStartupdoc } = useStartupContext()
    const { GSecsnapshot, setGSecsnapshot, GSecfaq, setGSecfaq } = useGSecContext()
    const { BucketRationale, setBucketRationale, BucketOverview, setBucketOverview } = useBucketContext()
    const { MixBucketRationale, setMixBucketRationale, MixBucketOverview, setMixBucketOverview } = useMixBucketContext()

    const handleClose = () => {
        setShowRTPage(false);
    };


    function handleChange(value) {
        if (type.type === "overview") {
            setOverview(value)
        } else if (type.type === "insight") {
            setInsight(value)
        } else if (type.type === "annualReport") {
            setAnnualReport(value)
        } else if (type.type === "Bond Snapshot") {
            setBsnapshot(value)
        } else if (type.type === "Bond Key Investment Highlights") {
            setBkeyHigh(value)
        } else if (type.type === "Bond Deal Structure") {
            setBdeal(value)
        } else if (type.type === "Bond Deal Terms") {
            setBterms(value)
        } else if (type.type === "Bond FAQ") {
            setBfaq(value)
        } else if (type.type === "Bond Documents") {
            setBdoc(value)
        } else if (type.type === "MLD Snaphost") {
            setMLDsnapshot(value)
        } else if (type.type === "MLD Key Investment Highlights") {
            setMLDkeyHigh(value)
        } else if (type.type === "MLD Deal Structure") {
            setMLDdeal(value)
        } else if (type.type === "MLD Deal Terms") {
            setMLDterms(value)
        } else if (type.type === "MLD FAQ") {
            setMLDfaq(value)
        } else if (type.type === "MLD Documents") {
            setMLDdoc(value)
        } else if (type.type === "Startup Company Pitch") {
            setStartupCompanyPitch(value)
        } else if (type.type === "Startup About The Team") {
            setStartupAboutTeam(value)
        } else if (type.type === "Startup Deal Terms") {
            setStartupterms(value)
        } else if (type.type === "Startup FAQ") {
            setStartupfaq(value)
        } else if (type.type === "GSec Snaphost") {
            setGSecsnapshot(value)
        } else if (type.type === "GSec FAQ") {
            setGSecfaq(value)
        } else if (type.type === "Bucket Overview") {
            setBucketOverview(value)
        } else if (type.type === "Bucket Rationale") {
            setBucketRationale(value)
        } else if (type.type === "MixBucket Overview") {
            setMixBucketOverview(value)
        } else if (type.type === "MixBucket Rationale") {
            setMixBucketRationale(value)
        }

    }

    const editor = useRef(null)

    const config = {
        readonly: false,
        controls: {
            font: {
                list: {
                    'Roboto Medium,Arial,sans-serif': 'Roboto',
                    'Comic Neue cursive': 'Comic Sans'
                }
            }
        }
    }

    useEffect(() => {

        try {
            setTimeout(() => {
                let ele = document.getElementsByClassName('jodit-status-bar-link')[0]
                if (ele) {
                    ele.remove()
                }
            }, 100)
        } catch (error) {

        }

    }, [showRTPage])

    return (<Modal open={showRTPage} onClose={handleClose} disableEnforceFocus>
        <div className="modal-fade" id="KYCSubmitModal" tabIndex="-1" role="dialog" aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered modal-width" role="document">
                <div className="modal-content">
                    <div className="modal-header border-0">
                        <h2 className="text-capitalize">Update {type.type}</h2>
                        <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={handleClose}>
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div className="modal-body py-3" style={{ height: "500px", overflow: 'auto' }}>


                        <JoditEditor
                            ref={editor}
                            value={type.type === "overview" ? overview : type.type === "insight" ? insight : type.type === "annualReport" ? annualReport : type.type === "Bond Snapshot" ? bsnapshot : type.type === "Bond Key Investment Highlights" ? bkeyHigh : type.type === "Bond Deal Structure" ? bdeal : type.type === "Bond Deal Terms" ? bterms : type.type === "Bond FAQ" ? bfaq : type.type === "Bond Documents" ? bdoc : type.type === "MLD Snaphost" ? mldsnapshot : type.type === "MLD Key Investment Highlights" ? mldkeyHigh : type.type === "MLD Deal Structure" ? mlddeal : type.type === "MLD Deal Terms" ? mldterms : type.type === "MLD FAQ" ? mldfaq : type.type === "MLD Documents" ? mlddoc : type.type === "GSec Snaphost" ? GSecsnapshot : type.type === "GSec FAQ" ? GSecfaq : type.type === "Startup Company Pitch" ? StartupCompanyPitch : type.type === "Startup About The Team" ? StartupAboutTeam : type.type === "Startup FAQ" ? Startupfaq : type.type === "Startup Deal Terms" ? Startupterms : type.type === "Bucket Overview" ? BucketOverview : type.type === "Bucket Rationale" ? BucketRationale : type.type === "MixBucket Overview" ? MixBucketOverview : type.type === "MixBucket Rationale" ? MixBucketRationale : ""}
                            config={config}
                            tabIndex={1} // tabIndex of textarea
                            onBlur={handleChange} // preferred to use only this option to update the content for performance reasons
                        // onChange={handleChange}
                        />
                        <button type="button" data-dismiss="modal" className="btn btn-primary text-center mt-3" onClick={(e) => {
                            e.preventDefault()
                            handleClose()
                        }}>Done</button>
                    </div>
                </div>
            </div>
        </div>
    </Modal>)
}