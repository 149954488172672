import React from 'react';
import { createContext, useContext, useState } from 'react';
import { configs } from '../../../assets/Config';
import { createUpdateMLD, deleteMLD, getAllMLDs, getMLDById, restoreMLD } from '../../apis/assetManagement/MLDManagementApi';
import { useLogin } from '../AdminLoginContext';

// create global MLD management context
export const MLDContext = createContext();

export function MLDProvider({ children }) {

    const { token, logout, setLoginError } = useLogin();

    const [MLDList, setMLDList] = useState("")
    const [editMLD, setEditMLD] = useState(false)
    const [editMLDData, setEditMLDData] = useState({})
    const [showDeletedMLD, setShowDeletedMLD] = useState(false)
    const [showRTPage, setShowRTPage] = useState(false)
    const [mldsnapshot, setMLDsnapshot] = useState("")
    const [mldkeyHigh, setMLDkeyHigh] = useState("")
    const [mlddeal, setMLDdeal] = useState("")
    const [mldterms, setMLDterms] = useState("")
    const [mldfaq, setMLDfaq] = useState("")
    const [mlddoc, setMLDdoc] = useState("")

    // get all MLD
    async function getAllMLDList(getDeleted) {
        try {
            let response = await getAllMLDs(token, getDeleted);
            if (response && response.status === 200) {
                setMLDList(response.data)
                return {
                    success: "success",
                    data: response.data
                }
            } else if (response && response.status === 401) {
                logout()
                setLoginError(configs.unauthorizedErrorMessage)
            } else {
                setMLDList("")
            }
        } catch (error) {

        }
    }

    // get MLD data by id
    async function getMLDDataById(MLDId) {
        try {
            let response = await getMLDById(token, MLDId);
            if (response && response.status === 200) {
                let data = response.data
                setEditMLDData({
                    ...editMLDData,
                    ...data
                })
            } else if (response && response.status === 401) {
                logout()
                setLoginError(configs.unauthorizedErrorMessage)
            } else { }
        } catch (error) {

        }
    }

    // create or update MLD
    async function createEditMLD(name, isin, rate_of_return, maturity_period, rating, disabletrading, description, key_1, value_1, key_2, value_2, logo, editMLD, mldId) {
        try {
            let response = await createUpdateMLD(token, name, isin, rate_of_return, maturity_period, rating, disabletrading, description, mldsnapshot, mldkeyHigh, mlddeal, mldterms, mldfaq, mlddoc, key_1, value_1, key_2, value_2, logo, editMLD, mldId);
            if (response && response.status === 200) {
                return "success"
            } else if (response && response.status === 401) {
                logout()
                setLoginError(configs.unauthorizedErrorMessage)
            } else {
                return response.data.error
            }
        } catch (error) {

        }
    }

    // delete MLD
    async function deleteMLDById(MLDId) {
        try {
            let response = await deleteMLD(token, MLDId);
            if (response && response.status === 200) {
                return "success"
            } else if (response && response.status === 401) {
                logout()
                setLoginError(configs.unauthorizedErrorMessage)
            } else {
                return response.data.error
            }
        } catch (error) {

        }
    }

    // restore MLD
    async function restoreMLDById(MLDId) {
        try {
            let response = await restoreMLD(token, MLDId);
            if (response && response.status === 200) {
                return "success"
            } else if (response && response.status === 401) {
                logout()
                setLoginError(configs.unauthorizedErrorMessage)
            } else {
                return response.data.error
            }
        } catch (error) {

        }
    }

    // export global context
    return (
        <MLDContext.Provider
            value={{
                MLDList,
                setMLDList,
                getAllMLDList,
                deleteMLDById,
                restoreMLDById,
                editMLD,
                setEditMLD,
                editMLDData,
                setEditMLDData,
                getMLDDataById,
                createEditMLD,
                showDeletedMLD,
                setShowDeletedMLD,
                showRTPage,
                setShowRTPage,
                mldsnapshot,
                setMLDsnapshot,
                mldkeyHigh,
                setMLDkeyHigh,
                mlddeal,
                setMLDdeal,
                mldterms,
                setMLDterms,
                mldfaq,
                setMLDfaq,
                mlddoc,
                setMLDdoc
            }}
        >
            {children}
        </MLDContext.Provider>
    );
}

// return MLD management context
export function useMLDContext() {
    return useContext(MLDContext);
}