import axios from "axios";
import { configs } from "../../../assets/Config";
import FormData from 'form-data';

// get all coupon list
export function getAllcoupons(token, getDeleted) {
    return new Promise(async (resolve, reject) => {
        try {
            let url = 'coupon'
            if (getDeleted) {
                url = '/coupon/deleted'
            }

            var config = {
                method: 'get',
                url: `${process.env.REACT_APP_BASEURL}/admin/${url}`,
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            };

            let res = await axios(config)
            resolve(res)
        } catch (err) {
            resolve(err?.response)
        }
    })
}

// get coupon data by id
export function getcouponById(token, couponId) {
    return new Promise(async (resolve, reject) => {
        try {

            var config = {
                method: 'get',
                url: `${process.env.REACT_APP_BASEURL}/admin/coupon/${couponId}`,
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            };

            let res = await axios(config)
            resolve(res)
        } catch (err) {
            resolve(err?.response)
        }
    })
}

// create or update coupon
export function createUpdatecoupon(token, code, discount, max_discount, expiry_date, utm, company_id, asset_class, first_order, editcoupon, couponId) {
    return new Promise(async (resolve, reject) => {
        try {

            let data = {
                code: code,
                discount: discount,
                max_discount: max_discount,
                expiry_date: expiry_date,
                utm: utm,
                company_id: company_id,
                asset_class: asset_class,
                first_order: first_order
            }

            let urlE = 'post'

            if (editcoupon) {
                urlE = 'put'
                data['coupon_id'] = couponId
            }

            var config = {
                method: urlE,
                url: `${process.env.REACT_APP_BASEURL}/admin/coupon/`,
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json'
                },
                data: data
            };

            let res = await axios(config)
            resolve(res)
        } catch (err) {
            resolve(err?.response)
        }
    })
}

// delete company
export function deletecoupon(token, couponId) {
    return new Promise(async (resolve, reject) => {
        try {

            var config = {
                method: 'DELETE',
                url: `${process.env.REACT_APP_BASEURL}/admin/coupon/${couponId}`,
                headers: {
                    'Authorization': `Bearer ${token}`,
                }
            };

            let res = await axios(config)
            resolve(res)
        } catch (err) {
            resolve(err?.response)
        }
    })
}

// restore company
export function restorecoupon(token, couponId) {
    return new Promise(async (resolve, reject) => {
        try {

            var data = {
                company_id: couponId
            }
            var config = {
                method: 'PATCH',
                url: `${process.env.REACT_APP_BASEURL}/admin/coupon/${couponId}`,
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            };

            let res = await axios(config)
            resolve(res)
        } catch (err) {
            resolve(err?.response)
        }
    })
}
