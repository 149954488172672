import React, { useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { configs } from '../../../assets/Config';
import '../../../assets/styles/KYC.css'
import { useLogin } from '../../../services/contexts/AdminLoginContext';
import { useKYCContext } from '../../../services/contexts/KYCContext';
// import { useCustomerKYCContext } from '../../../services/contexts/CustomerKYCContext';
import LeftBarPage from '../../includes/dashboard/LeftBarPage';
import TopBarPage from '../../includes/dashboard/TopBarPage';
import KYCReviewRequestDetailPage from './KYCReviewRequestDetailPage';
import KYCReviewRequestsPage from './KYCReviewRequestsPage';


export default function KYCMainPage() {

    const { kycRequesterUserDetail, setKycRequesterUserDetail } = useKYCContext()
    const { setCurrModule, allowedFunc } = useLogin()

    const history = useHistory()

    let { customerId } = useParams();

    useEffect(() => {
        if (allowedFunc.includes(configs.functionList['kyc:read_user_kyc_info'] || allowedFunc.includes(configs.functionList['kyc:update_user_kyc_status']))) {
            setCurrModule(configs.modules.kyc)
        } else {
            return history.goBack()
        }
    }, []);

    // customer kyc main ui code
    return (
        <>
            <TopBarPage />
            <div style={{ display: "flex" }}>
                <LeftBarPage />
                <div id='kycContainer' className="kyc-container kyc-main">
                    {customerId ? <KYCReviewRequestDetailPage /> : <KYCReviewRequestsPage />}
                </div>
            </div>
        </>
    )
}