import { Modal } from '@material-ui/core';
import { DataGrid, GridToolbar } from '@material-ui/data-grid';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { configs, downloadDoc, downloadImage } from '../../../assets/Config';
import '../../../assets/styles/RM.css'
import { useLogin } from '../../../services/contexts/AdminLoginContext';
import { useCouponContext } from '../../../services/contexts/couponManagement/CouponContext';
import DeleteModalPage from '../../includes/DeleteModalPage';


export default function ListCouponPage() {

    const { couponList, setcouponList, getAllcouponList, setEditcoupon, setEditcouponData, editcouponData, getcouponDataById, setShowDeletedcoupon, showDeletedcoupon } = useCouponContext()
    const { setShowDelete, setShowSuccess, allowedFunc } = useLogin()

    const history = useHistory()
    const [deleteId, setDeleteId] = useState({})

    const columns = [{ field: "id", headerName: "Coupon ID", minWidth: 100, flex: 0.5, hide: true, filterable: false },
    { field: "code", headerName: "Code", minWidth: 100, flex: 0.5, cellClassName: "break-text" },
    { field: "discount", headerName: "Discount", minWidth: 150, flex: 0.5, cellClassName: "break-text" },
    { field: "max_discount", headerName: "Maximum Discount", minWidth: 150, flex: 0.5, cellClassName: "break-text" },
    { field: "expiry_date", headerName: "Expiry Date", minWidth: 180, flex: 0.5, cellClassName: "break-text" },
    { field: "utm", headerName: "UTM", minWidth: 150, flex: 0.5, cellClassName: "break-text" },
    { field: "asset_name", headerName: "Asset Name", minWidth: 150, flex: 0.5, cellClassName: "break-text" },
    { field: "asset_class", headerName: "Asset Class", minWidth: 150, flex: 0.5, cellClassName: "break-text" },
    {
        field: "first_order", headerName: "First Order", minWidth: 130, flex: 0.1, cellClassName: "break-text", renderCell: (params) => {
            return params.row.first_order === 1 ? "Yes" : "No"
        }, valueGetter: (params) => params.row.first_order === 1 ? "Yes" : "No"
    },
    {
        field: "edit", headerName: " ", minWidth: 40, flex: 0.1, cellClassName: "break-text", cellClassName: "text-primary", hide: !allowedFunc.includes(configs.functionList["coupon_management:coupon_management"]) ? true : false, renderCell: (params) => {
            return <a href="#" className="custom-link text-primary" onClick={(e) => {
                e.preventDefault()
                setEditcoupon(true)
                setEditcouponData(params.row)
                document.getElementById("rmCreateCompany").classList.toggle('d-none')
                document.getElementById("companyListPage").classList.toggle('d-none')
            }}>Edit</a>
        }
    },
    {
        field: "delete", headerName: " ", minWidth: 65, flex: 0.1, cellClassName: "break-text", cellClassName: "text-danger", hide: !allowedFunc.includes(configs.functionList["coupon_management:coupon_management"]) ? true : false, renderCell: (params) => {
            return <a href="#" className="custom-link text-danger" onClick={(e) => {
                e.preventDefault()
                setDeleteId({
                    "category": configs.modules.couponMgmt,
                    "type": "coupon",
                    "name": params.row.code,
                    "id": params.row.id
                })
                setShowDelete(true)
            }}>Delete</a>
        }
    }]

    useEffect(() => {
        (async () => {
            try {
                await getAllcouponList()
            } catch (error) {

            }
        })()
    }, [])


    // role list page ui code
    return (
        <div id="companyListPage" className='d-md-block' style={{ width: "100%" }}>
            <div className="row d-flex align-items-center mt-2 ml-3 mb-1 mr-2">
                <div className="col-lg-6 col-8 m-0 p-0">
                    <p className="h4 text-custom-grey">Coupon Management</p>
                </div>
                <div className="col-lg-6 col-4 text-right m-0 p-0">
                    {allowedFunc.includes(configs.functionList["coupon_management:coupon_management"]) ?
                        <a href="#" className="custom-link" onClick={(e) => {
                            e.preventDefault()
                            setcouponList("")
                            setShowDeletedcoupon(!showDeletedcoupon)
                        }}><i className={showDeletedcoupon ? "fas fa-user mr-2" : "fas fa-user-slash mr-2"}></i>{showDeletedcoupon ? "Active coupon" : "Deleted coupon"}</a>
                        : null}
                </div>
            </div>
            {allowedFunc.includes(configs.functionList['coupon_management:coupon_management']) || allowedFunc.includes(configs.functionList['coupon_management:coupon_management']) ?
                <div className='row m-0 mx-3 p-0 d-md-none d-block'>
                    <a href='#' className='custom-link' onClick={(e) => {
                        e.preventDefault()
                        document.getElementById("rmCreateCompany").classList.toggle('d-none')
                        document.getElementById("companyListPage").classList.toggle('d-none')
                    }}><p className='m-0 p-0'>Create New coupon</p></a>
                </div>
                : null}
            {couponList ?
                <div className="nm-role-table m-2">
                    <DataGrid
                        rows={couponList}
                        headerHeight={30}
                        rowHeight={40}
                        columns={columns}
                        resizable={true}
                        autoPageSize
                        pagination
                        components={{
                            Toolbar: GridToolbar,
                        }}

                    // onSelectionModelChange={onRowSelected}
                    />
                </div>
                : <div className="loader"></div>}
            <DeleteModalPage {...deleteId} />
        </div>
    )
}