import axios from "axios";
import { configs } from "../../../assets/Config";
import FormData from 'form-data';

// get all Startup list
export function getAllStartups(token, getDeleted) {
    return new Promise(async (resolve, reject) => {
        try {
            let url = 'startup'
            if (getDeleted) {
                url = '/startup/deleted'
            }

            var config = {
                method: 'get',
                url: `${process.env.REACT_APP_BASEURL}/admin/${url}`,
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            };

            let res = await axios(config)
            resolve(res)
        } catch (err) {
            resolve(err?.response)
        }
    })
}

// get Startup data by id
export function getStartupById(token, StartupId) {
    return new Promise(async (resolve, reject) => {
        try {

            var config = {
                method: 'get',
                url: `${process.env.REACT_APP_BASEURL}/admin/startup/${StartupId}`,
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            };

            let res = await axios(config)
            resolve(res)
        } catch (err) {
            resolve(err?.response)
        }
    })
}

// create or update Startup
export function createUpdateStartup(token, name, isin, campaign_end_date, video_link, disabletrading, description, company_pitch, about_the_team, deal_terms, faqs, documents, key_1, value_1, key_2, value_2, logo, editStartup, StartupId) {
    return new Promise(async (resolve, reject) => {
        try {
            var data = new FormData();
            data.append("name", name)
            data.append("isin", isin)
            data.append("campaign_end_date", campaign_end_date)
            data.append("video_link", video_link)
            data.append("disabletrading", disabletrading)
            data.append("description", description)
            data.append("company_pitch", company_pitch)
            data.append("about_the_team", about_the_team)
            data.append("deal_terms", deal_terms)
            data.append("faqs", faqs)
            data.append("documents", documents)
            data.append("key_1", key_1)
            data.append("value_1", value_1)
            data.append("key_2", key_2)
            data.append("value_2", value_2)
            data.append("logo", logo)

            let urlE = 'create'

            if (editStartup) {
                urlE = 'update'
                data.append('startup_id', StartupId)
            }

            var config = {
                method: 'POST',
                url: `${process.env.REACT_APP_BASEURL}/admin/startup/${urlE}`,
                headers: {
                    'Authorization': `Bearer ${token}`
                },
                data: data
            };

            let res = await axios(config)
            resolve(res)
        } catch (err) {
            resolve(err?.response)
        }
    })
}

// delete company
export function deleteStartup(token, StartupId) {
    return new Promise(async (resolve, reject) => {
        try {

            var data = {
                company_id: StartupId
            }
            var config = {
                method: 'DELETE',
                url: `${process.env.REACT_APP_BASEURL}/admin/startup/delete`,
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json'
                },
                data: data
            };

            let res = await axios(config)
            resolve(res)
        } catch (err) {
            resolve(err?.response)
        }
    })
}

// restore company
export function restoreStartup(token, StartupId) {
    return new Promise(async (resolve, reject) => {
        try {

            var data = {
                company_id: StartupId
            }
            var config = {
                method: 'PATCH',
                url: `${process.env.REACT_APP_BASEURL}/admin/startup/restore`,
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json'
                },
                data: data
            };

            let res = await axios(config)
            resolve(res)
        } catch (err) {
            resolve(err?.response)
        }
    })
}
