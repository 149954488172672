import { Modal } from '@material-ui/core';
import { DataGrid, GridToolbar } from '@material-ui/data-grid';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { configs, downloadDoc, downloadImage } from '../../../assets/Config';
import '../../../assets/styles/RM.css'
import { useLogin } from '../../../services/contexts/AdminLoginContext';
import { useNewsContext } from '../../../services/contexts/companyManagement/NewsContext';
import DeleteModalPage from '../../includes/DeleteModalPage';

export default function ListNewsPage() {

    const { newsList, setNewsList, getAllNewsList, downloadFile, setEditNews, setEditNewsData, editNewsData, getNewsDataById, setShowDeletedNews, showDeletedNews } = useNewsContext()
    const { setShowDelete, setShowSuccess } = useLogin()

    const history = useHistory()
    const [deleteId, setDeleteId] = useState({})

    const columns = [{ field: "id", headerName: "News ID", minWidth: 100, flex: 0.5, hide: true, filterable: false },
    { field: "news_heading", headerName: "Heading", minWidth: 300, flex: 0.5, cellClassName: "break-text" },
    { field: "news_description", headerName: "Description", minWidth: 500, flex: 0.5, cellClassName: "break-text" },
    { field: "news_date", headerName: "Date", minWidth: 150, flex: 0.5, cellClassName: "break-text" },
    { field: "company_name", headerName: "Company", minWidth: 150, flex: 0.5, cellClassName: "break-text" },
    {
        field: "link", headerName: "Link", minWidth: 110, flex: 0.1, cellClassName: "break-text", cellClassName: "text-primary", renderCell: (params) => {
            return <a href={`${params.row.news_link}`} target="_blank">{params.row.news_link}</a>
        }
    },
    {
        field: "website", headerName: "Website", minWidth: 110, flex: 0.1, cellClassName: "break-text", cellClassName: "text-primary", renderCell: (params) => {
            return <a href={`${params.row.news_website}`} target="_blank">{params.row.news_website}</a>
        }
    },
    {
        field: "image", headerName: "Image", minWidth: 110, flex: 0.1, cellClassName: "break-text", cellClassName: "text-primary", renderCell: (params) => {
            return <div className='row pl-2' id={`news` + params.row.id}>
                <a href={`${params.row.news_image}`} target="_blank">
                    <i className="fa fa-external-link kyc-openDoc-prof curPoint"></i></a>
                <i className="fa fa-download kyc-openDoc-prof curPoint" onClick={(e) => {
                    e.preventDefault()
                    downloadImage(`${params.row.news_image}`, params.row.id)
                }}></i>
            </div >
        }
    },
    {
        field: "edit", headerName: " ", minWidth: 40, flex: 0.1, cellClassName: "break-text", cellClassName: "text-primary", renderCell: (params) => {
            return <a href="#" className="custom-link text-primary" onClick={(e) => {
                e.preventDefault()
                setEditNews(true)
                setEditNewsData(params.row)
                document.getElementById("rmCreateNews").classList.toggle('d-none')
                document.getElementById("newsListPage").classList.toggle('d-none')
            }}>Edit</a>
        }
    },
    {
        field: "delete", headerName: " ", minWidth: 65, flex: 0.1, cellClassName: "break-text", cellClassName: "text-danger", renderCell: (params) => {
            return <a href="#" className="custom-link text-danger" onClick={(e) => {
                e.preventDefault()
                setDeleteId({
                    "category": configs.modules.newsManage,
                    "type": "News",
                    "name": params.row.news_heading,
                    "id": params.row.id
                })
                setShowDelete(true)
            }}>Delete</a>
        }
    }]

    useEffect(() => {
        (async () => {
            try {
                await getAllNewsList()
            } catch (error) {

            }
        })()
    }, [])

    // role list page ui code
    return (
        <div id="newsListPage" className='d-md-block' style={{ width: "100%" }}>
            <div className="row d-flex align-items-center mt-2 ml-3 mb-1 mr-2">
                <div className="col-lg-6 col-8 m-0 p-0">
                    <p className="h4 text-custom-grey">News Detail Management</p>
                </div>
                <div className="col-lg-6 col-4 text-right m-0 p-0">
                    <a href="#" className="custom-link" onClick={(e) => {
                        e.preventDefault()
                        setNewsList("")
                        setShowDeletedNews(!showDeletedNews)
                    }}><i className={showDeletedNews ? "fas fa-user mr-2" : "fas fa-user-slash mr-2"}></i>{showDeletedNews ? "Active News" : "Deleted News"}</a>
                </div>
            </div>
            <div className='row m-0 mx-3 p-0 d-md-none d-block'>
                <a href='#' className='custom-link' onClick={(e) => {
                    e.preventDefault()
                    document.getElementById("rmCreateNews").classList.toggle('d-none')
                    document.getElementById("newsListPage").classList.toggle('d-none')
                }}><p className='m-0 p-0'>Add New News</p></a>
            </div>
            {newsList ?

                <div className="nm-role-table m-2">
                    <DataGrid
                        rows={newsList}
                        headerHeight={30}
                        rowHeight={40}
                        columns={columns}
                        resizable={true}
                        autoPageSize
                        pagination
                        components={{
                            Toolbar: GridToolbar,
                        }}

                    // onSelectionModelChange={onRowSelected}
                    />
                </div>
                : <div className="loader"></div>}
            <DeleteModalPage {...deleteId} />

        </div>
    )
}