import { DataGrid, GridToolbar } from '@material-ui/data-grid';
import React, { useEffect, useState } from 'react';
import { configs } from '../../../assets/Config';
import { useLogin } from '../../../services/contexts/AdminLoginContext';
import LeftBarPage from '../../includes/dashboard/LeftBarPage';
import TopBarPage from '../../includes/dashboard/TopBarPage';
import '../../../assets/styles/NM.css'
import { useNMContext } from '../../../services/contexts/NMContext';
import ToastPage from '../../includes/ToastPage';
import { Link } from 'react-router-dom';
import { useHistory, useParams } from 'react-router';
import { useKYCContext } from '../../../services/contexts/KYCContext';
import { Autocomplete } from '@material-ui/lab';
import { TextField } from '@material-ui/core';

export default function WhatsAppBroadCastPage() {

    const { broadcastList, setBroadcastList, getWhatsappBroadcastCategory, sendWhatsappBroadcastByCategory, whatsappBroadcastHistory, broadCastHistory, whatsappBroadcastHistoryDetail } = useNMContext()
    const { setCurrModule, setShowSuccess, allowedFunc } = useLogin()
    const { utmList, setUtmList, getAllUTMList } = useKYCContext()

    const [template, setTemplate] = useState("")
    const [headVariable, setHeadVariable] = useState("")
    const [bodyVariable, setBodyVariable] = useState("")
    const [utm, setUtm] = useState("")
    const [broadcastCategory, setBroadcastCategory] = useState("")
    const [error, setError] = useState("")
    const [broadCastHistoryDetail, setbroadCastHistoryDetail] = useState("")

    const history = useHistory()

    useEffect(() => {
        try {
            setCurrModule(configs.modules.whatsappBroadcast)
            if (allowedFunc.includes(configs.functionList['broadcast:whatsapp'])) {
                (async () => {
                    await getAllUTMList()
                    await getWhatsappBroadcastCategory()
                    await whatsappBroadcastHistory()
                })()
            } else {
                return history.goBack()
            }
        } catch (error) {
            console.error(error)
        }

    }, [])

    const handleUtmChange = (e, value) => {
        try {
            let temp = [];
            value.forEach(ele => {
                temp.push(ele.utm)
            })
            setUtm(temp)
        } catch (error) {

        }
    }

    const handleCategoryChange = (e, value) => {
        try {
            setBroadcastCategory(value);
        } catch (error) {

        }
    }


    // update mobile template
    async function updateTemplate() {
        try {
            document.getElementById("whatsappBtn").classList.toggle("d-none")
            document.getElementById("whatsappBtnLoader").classList.toggle("d-none")
            if (!broadcastCategory.trim()) {
                setError("Please provide a valid Broadcast category")
            } else if (!template.trim()) {
                setError("Please provide a valid Template Name")
            } else {
                let response = await sendWhatsappBroadcastByCategory(broadcastCategory, template, headVariable, bodyVariable, utm)
                if (response === "success") {
                    setTemplate("")
                    setHeadVariable("")
                    setBodyVariable("")
                    setUtm("")
                    setBroadcastCategory("")
                    setError("")
                    await whatsappBroadcastHistory()
                    setShowSuccess(true)
                } else {
                    setError(response)
                }
            }

            document.getElementById("whatsappBtn").classList.toggle("d-none")
            document.getElementById("whatsappBtnLoader").classList.toggle("d-none")
        } catch (error) {
            console.error(error);
        }
    }

    async function getWhatsappHistoryDetail(id) {
        try {
            setbroadCastHistoryDetail("")
            let response = await whatsappBroadcastHistoryDetail(id)
            if (response && response.success) {
                setbroadCastHistoryDetail(response.data)
            }
        } catch (error) {
            console.error(error);
        }
    }

    const historyColumns = [{ field: "id", headerName: "Broadcast ID", minWidth: 100, flex: 0.5, hide: true, filterable: false },
    { field: "template_name", headerName: "Template Name", minWidth: 250, flex: 0.3, cellClassName: "break-text" },
    { field: "broadcast_type", headerName: "Broadcast Type", minWidth: 150, flex: 0.3, cellClassName: "break-text" },
    { field: "utm_list", headerName: "UTM List", minWidth: 100, flex: 0.5, cellClassName: "break-text" },
    { field: "total_user", headerName: "Total user", minWidth: 100, flex: 0.5, cellClassName: "break-text" },
    { field: "total_processed", headerName: "Users Processed", minWidth: 100, flex: 0.5, cellClassName: "break-text" },
    { field: "total_sent", headerName: "Successful Users", minWidth: 100, flex: 0.5, cellClassName: "break-text" },
    { field: "total_failure", headerName: "Failed Users", minWidth: 100, flex: 0.5, cellClassName: "break-text" },
    { field: "datetime", headerName: "Timestamp", minWidth: 100, flex: 0.5, cellClassName: "break-text" },
    {
        field: "details", headerName: "View Details", minWidth: 100, flex: 0.2, cellClassName: "break-text f-12", filterable: false, renderCell: (cellValues) => {
            return <a href="#" className="custom-link text-primary" onClick={(e) => {
                e.preventDefault()
                getWhatsappHistoryDetail(cellValues.row.id)
            }}>View Report</a>
        }
    }
    ]

    const historyDetailColumns = [{ field: "id", headerName: "Detail ID", minWidth: 100, flex: 0.5, hide: true, filterable: false },
    { field: "mobile", headerName: "Mobile No.", minWidth: 100, flex: 0.3, cellClassName: "break-text" },
    {
        field: "is_processed", headerName: "Processing Status", minWidth: 100, flex: 0.3, cellClassName: "break-text", renderCell: (params) => {
            return params.row.is_processed === 1 ? "Processed" : "Pending"
        }, valueGetter: (params) => params.row.is_processed === 1 ? "Processed" : "Pending"
    },
    {
        field: "is_sent", headerName: "Status", minWidth: 100, flex: 0.3, cellClassName: "break-text", renderCell: (params) => {
            return params.row.is_sent === 1 ? "Successful" : "Failed"
        }, valueGetter: (params) => params.row.is_sent === 1 ? "Successful" : "Failed"
    },
    { field: "error", headerName: "Failure Reason", minWidth: 300, flex: 0.5, cellClassName: "break-text" },
    { field: "sent_on", headerName: "Sent Timestamp", minWidth: 100, flex: 0.5, cellClassName: "break-text" },
    ]


    // notification management main ui code
    return (
        <>
            <TopBarPage />
            <div style={{ display: "flex" }}>
                <LeftBarPage />
                <div id='whatsappBroadcastContainer' className="nm-container">
                    <div style={{ width: "100%" }}>
                        <div className="row d-flex align-items-center mt-2 ml-2 mb-2 mr-3">
                            <div className="col-md-9 text-left">
                                <p className="h4 text-custom-grey">WhatsApp Broadcast</p>
                            </div>
                        </div>
                        {broadcastList && Object.keys(broadcastList) && Object.keys(broadcastList).length > 0 ?
                            <div className="mt-2 mb-4 mr-4 nm-template">
                                <p className="text-primary h6 my-4">WhatsApp Broadcast Template Variables</p>
                                <div className="row pl-3">
                                    {broadcastList.template_variables ?
                                        broadcastList.template_variables.map((element, key) => {
                                            return (
                                                <div key={`broadcastList` + key} className="row d-flex align-items-center justify-content-center variable-card ml-2">
                                                    <div className="col-10">
                                                        <p className="text-custom-grey p-0 m-0">{element}</p>
                                                    </div>
                                                    <div className="col-2 text-right">
                                                        <a href="#" onClick={(e) => {
                                                            e.preventDefault()
                                                            navigator.clipboard.writeText(element);
                                                        }}><i className="far fa-copy text-primary cursor" ></i></a>
                                                    </div>
                                                </div>

                                            )
                                        })

                                        : null}
                                </div>
                                <p className="text-primary h6 mt-5 pl-0 ml-0">Broadcast Details</p>
                                <div className="pr-3">
                                    <form action="">
                                        <div className='row m-0 p-0'>
                                            <div className='col-md-6 col-12 m-0'>
                                                <div className="form-group">
                                                    <label htmlFor="brodcast_category">Select Category</label>
                                                    <Autocomplete
                                                        value={broadcastCategory ? broadcastCategory : null}
                                                        disableCloseOnSelect={false}
                                                        filterSelectedOptions
                                                        className="f-12"
                                                        id="categoryip"
                                                        placeholder="Select Category"
                                                        getOptionLabel={option => option}
                                                        onChange={handleCategoryChange}
                                                        options={broadcastList.category}
                                                        renderInput={(params) => <TextField {...params} placeholder="Select Category" className="f-12" />}
                                                    />
                                                </div>
                                            </div>
                                            <div className='col-md-6 col-12 m-0'>
                                                <div className="form-group">
                                                    <label htmlFor="whatsapp_utm">Select UTMs</label>
                                                    <Autocomplete
                                                        multiple
                                                        disableCloseOnSelect={true}
                                                        filterSelectedOptions
                                                        className="f-12"
                                                        id="utmip"
                                                        placeholder="Select UTM"
                                                        getOptionLabel={option => `${option.distributor} - ${option.utm}`}
                                                        onChange={handleUtmChange}
                                                        options={utmList}
                                                        renderInput={(params) => <TextField {...params} placeholder="Select UTM" className="f-12" />}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="form-group">
                                            <label htmlFor="whatsapp_body">Template Name</label>
                                            <input type="text" className="form-control" value={template ? template : ""} onChange={(e) => {
                                                setTemplate(e.target.value)
                                            }}></input>
                                        </div>
                                        <div className="form-group">
                                            <label htmlFor="whatsapp_body">Head Variable</label>
                                            <input type="text" className="form-control" value={headVariable ? headVariable : ""} onChange={(e) => {
                                                setHeadVariable(e.target.value)
                                            }}></input>
                                        </div>
                                        <div className="form-group">
                                            <label htmlFor="whatsapp_body">Body Variable</label>
                                            <input type="text" className="form-control" value={bodyVariable ? bodyVariable : ""} onChange={(e) => {
                                                setBodyVariable(e.target.value)
                                            }}></input>
                                        </div>
                                        <div className="form-check p-0">
                                            <p className="text-danger" id="templateError">{error}</p>
                                        </div>
                                        <div className="form-row">
                                            <div className="col-12 d-flex justify-content-center">
                                                <button id="whatsappBtn" type="submit" className="btn btn-primary" onClick={(e) => {
                                                    e.preventDefault()
                                                    updateTemplate()
                                                }}>Send Broadcast</button>
                                                <div id="whatsappBtnLoader" className='animBtn d-none'><div className='gSignIn-Anim'></div></div>
                                            </div>
                                        </div>
                                    </form>
                                </div>

                                {broadCastHistory ?
                                    <div className="h-50 my-5">
                                        <p className="h5 text-custom-grey">WhatsApp Broadcast History</p>
                                        <DataGrid
                                            rows={broadCastHistory}
                                            headerHeight={40}
                                            rowHeight={30}
                                            columns={historyColumns}
                                            resizable={true}
                                            autoPageSize
                                            pagination
                                            components={{
                                                Toolbar: GridToolbar,
                                            }} />
                                    </div>
                                    : null}

                                {broadCastHistoryDetail ?
                                    <div className="h-50 mt-5">
                                        <p className="h5 text-custom-grey">WhatsApp Broadcast Delivery Report</p>
                                        <DataGrid
                                            rows={broadCastHistoryDetail}
                                            headerHeight={40}
                                            rowHeight={30}
                                            columns={historyDetailColumns}
                                            resizable={true}
                                            autoPageSize
                                            pagination
                                            components={{
                                                Toolbar: GridToolbar,
                                            }} />
                                    </div>
                                    : null}
                            </div>
                            : <div className="loader"></div>}

                    </div>
                </div>
                <ToastPage />
            </div>
        </>
    )
}