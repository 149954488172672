import { Modal } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { configs } from '../../../assets/Config';
import '../../../assets/styles/RM.css'
import { uploadCompanyDataDoc } from '../../../services/apis/companyManagement/CMApi';
import { useLogin } from '../../../services/contexts/AdminLoginContext';
import { useBucketContext } from '../../../services/contexts/assetManagement/BucketContext';
import { useCMContext } from '../../../services/contexts/companyManagement/CMContext';
import MultiAssetPage from '../../includes/MultiAssetPage';
import RichTextEditorPage from '../../includes/RichTextEditorPage';

export default function CreateBucketPage() {

    const { setShowSuccess, token, logout, setLoginError, allowedFunc, setaddAssetPopup } = useLogin()
    const { editBucket, setEditBucket, editBucketData, setEditBucketData, createEditBucket, getAllBucketList, setBucketOverview, setBucketRationale, setBucketAssetList } = useBucketContext()
    const { setShowRTPage, showRTPage } = useCMContext()


    const statusValues = ["DRHP Not Filed", "DRHP Filed", "DRHP Approved", "Delisted", "Listed but not traded", "IPO in Process", "Now Listed"]

    const [logoFile, setLogoFile] = useState("")
    const [name, setName] = useState(editBucketData.name)
    const [description, setDescription] = useState(editBucketData.description)
    const [key1, setKey1] = useState(editBucketData.key_value ? editBucketData.key_value.key_1 : "")
    const [key2, setKey2] = useState(editBucketData.key_value ? editBucketData.key_value.key_2 : "")
    const [value1, setValue1] = useState(editBucketData.key_value ? editBucketData.key_value.value_1 : "")
    const [value2, setValue2] = useState(editBucketData.key_value ? editBucketData.key_value.value_2 : "")
    const [isin, setISIN] = useState(editBucketData.isin)
    const [holdinPeriod, setholdinPeriod] = useState(editBucketData.suggested_holding_period)
    const [minInvestment, setminInvestment] = useState(editBucketData.minimum_investment)
    const [stockCount, setstockCount] = useState(editBucketData.number_of_stocks)
    const [emailDesc, setemailDesc] = useState(editBucketData.email_description)
    const [bellDesc, setbellDesc] = useState(editBucketData.bell_notification_description)
    const [mobDesc, setmobDesc] = useState(editBucketData.mobile_description)
    const [whatsappDesc, setwhatsappDesc] = useState(editBucketData.whatsapp_description)
    const [disabletrading, setDisableTrading] = useState(editBucketData.disabletrading)
    const [error, setError] = useState("")
    const [rtPageType, setRTPageType] = useState("")

    const [file, setFile] = useState("")
    const [fileError, setFileError] = useState("")
    const [errorDetail, setErrorDetail] = useState("")
    const [showErrorModal, setShowErrorModal] = useState(false)

    const history = useHistory()


    useEffect(() => {
        try {
            if (editBucketData) {
                setName(editBucketData.name)
                setDescription(editBucketData.description)
                setKey1(editBucketData.key_value.key_1)
                setKey2(editBucketData.key_value.key_2)
                setValue1(editBucketData.key_value.value_1)
                setValue2(editBucketData.key_value.value_2)
                setISIN(editBucketData.isin)
                setholdinPeriod(editBucketData.suggested_holding_period)
                setminInvestment(editBucketData.minimum_investment)
                setstockCount(editBucketData.number_of_stocks)
                setemailDesc(editBucketData.email_description)
                setbellDesc(editBucketData.bell_notification_description)
                setmobDesc(editBucketData.mobile_description)
                setwhatsappDesc(editBucketData.whatsapp_description)
                setDisableTrading(editBucketData.disabletrading.toString())
                setBucketOverview(editBucketData.section_data.overview)
                setBucketRationale(editBucketData.section_data.investing_rational)
                setBucketAssetList(editBucketData.bucket_object)
                setError("")
            }
        } catch (error) {

        }
    }, [editBucketData])


    // add company function
    async function addBucket() {
        try {

            document.getElementById("BucketBtn").classList.toggle("d-none")
            document.getElementById("BucketBtnLoader").classList.toggle("d-none")

            const red = /^[0-9]\d*(\.\d{1,2})?$/;
            const re = /^[0-9]+$/;
            // .toString().match(red)
            if (editBucket && !editBucketData.id) {
                setError("Please select a valid Bucket to edit.")
            } else if (!name || (name && name.length > 200)) {
                setError("Please provide a valid Bucket name.")
            } else if (!description) {
                setError("Please provide a valid Description.")
            } else if (!isin || (isin && !isin.match(/^[a-zA-Z0-9]{12}$/))) {
                setError("Please provide a valid ISIN.")
            } else if (!holdinPeriod) {
                setError("Please enter valid Holding Period.")
            } else if (!minInvestment.toString().match(red)) {
                setError("Please enter a valid Min investment amount.")
            } else if (!stockCount.toString().match(re)) {
                setError("Please enter a valid stock count.")
            } else if (!disabletrading || (disabletrading != null && (disabletrading != 0 && disabletrading != 1))) {
                setError("Please select valid Disable trading value.")
            } else if (!emailDesc) {
                setError("Please enter valid Email Description.")
            } else if (!bellDesc) {
                setError("Please enter valid Bell Description.")
            } else if (!mobDesc) {
                setError("Please enter valid Mobile Description.")
            } else if (!whatsappDesc) {
                setError("Please enter valid WhatsApp Description.")
            } else if (!editBucket && !logoFile) {
                setError("Please select a valid logo file.")
            } else {
                let response = await createEditBucket(name, isin, holdinPeriod, minInvestment, stockCount, disabletrading, description, emailDesc, bellDesc, mobDesc, whatsappDesc, key1, value1, key2, value2, logoFile, editBucket, editBucketData.id)

                if (response && response === "success") {
                    resetValues()
                    setShowSuccess(true)
                    getAllBucketList()
                } else {
                    setError(response)
                }
            }

            document.getElementById("BucketBtn").classList.toggle("d-none")
            document.getElementById("BucketBtnLoader").classList.toggle("d-none")

        } catch (error) {
        }
    }

    // reset all values
    function resetValues() {
        try {
            setName("")
            setDescription("")
            setKey1("")
            setKey2("")
            setValue1("")
            setValue2("")
            setISIN("")
            setholdinPeriod("")
            setminInvestment("")
            setstockCount("")
            setDisableTrading("")
            setLogoFile("")
            setEditBucket(false)
            setemailDesc("")
            setbellDesc("")
            setmobDesc("")
            setwhatsappDesc("")
            setError("")
            setBucketOverview("")
            setBucketAssetList("")
            setBucketRationale("")
        } catch (error) {

        }
    }

    // get file contents through file chooser
    function uploadFile(file, type) {
        try {
            if (type === "Logo" && (file.type === "image/jpg" || file.type === "image/jpeg" || file.type === "image/png")) {
                setLogoFile(file)
            } else if (type !== "Logo" && (file.type === ".xls" || file.type === "xlsx" || file.type === "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" || file.type === "application/vnd.ms-excel")) {
                setFile(file)
                setFileError("")
            } else {
                type === "Logo" ? alert("Only jpg or png files are allowed.") : alert("Only csv files are allowed.")
            }
        } catch (error) {
            console.error(error)
        }
    }

    // create role ui code
    return (
        <div id="rmCreateCompany" className="col-lg-3 col-md-4 d-md-block d-none pt-3 main-rmPanel">
            {((allowedFunc.includes(configs.functionList["bucket_management:create_asset"]) && editBucket) || allowedFunc.includes(configs.functionList["bucket_management:create_asset"])) ?
                <>
                    <p className="h6 text-custom-grey">{editBucket ? "Edit Bucket Information" : "Add a Bucket"}</p>

                    <div className="function-container-asset">
                        <form id="createRoleForm" action="" className='mr-1'>
                            <div className="form-group">
                                <label className="f-12">Bucket Name</label>
                                <input type="text" id="roleName" className="form-control f-10" placeholder="Bucket Name" value={name} required onChange={(e) => {
                                    setName(e.target.value)
                                }} />
                            </div>
                            <div className="form-group">
                                <label className="f-12">Description</label>
                                <input type="text" id="roleName" className="form-control f-10" placeholder="Description" value={description} required onChange={(e) => {
                                    setDescription(e.target.value)
                                }} />
                            </div>
                            <div className="form-group">
                                <label className="f-12">ISIN</label>
                                <input type="text" id="roleName" className="form-control f-10" placeholder="ISIN" value={isin} required onChange={(e) => {
                                    setISIN(e.target.value)
                                }} />
                            </div>
                            <div className="form-group">
                                <label className="f-12">Suggested Holding Period</label>
                                <input type="text" id="roleName" className="form-control f-10" placeholder="Suggested Holding Period" value={holdinPeriod} required onChange={(e) => {
                                    setholdinPeriod(e.target.value)
                                }} />
                            </div>
                            <div className="form-group">
                                <label className="f-12">Minimum Investment</label>
                                <input type="number" id="roleName" className="form-control f-10" placeholder="Minimum Investment" value={minInvestment} required onChange={(e) => {
                                    setminInvestment(e.target.value)
                                }} />
                            </div>
                            <div className="form-group">
                                <label className="f-12">Number of Stocks</label>
                                <input type="number" id="roleName" className="form-control f-10" placeholder="Number of Stocks" value={stockCount} required onChange={(e) => {
                                    setstockCount(e.target.value)
                                }} />
                            </div>
                            <div className="form-group">
                                <label className="f-12">Email Notifcation Description</label>
                                <input type="text" id="roleName" className="form-control f-10" placeholder="Email Notifcation Description" value={emailDesc} required onChange={(e) => {
                                    setemailDesc(e.target.value)
                                }} />
                            </div>
                            <div className="form-group">
                                <label className="f-12">Bell Notifcation Description</label>
                                <input type="text" id="roleName" className="form-control f-10" placeholder="Bell Notifcation Description" value={bellDesc} required onChange={(e) => {
                                    setbellDesc(e.target.value)
                                }} />
                            </div>
                            <div className="form-group">
                                <label className="f-12">Mobile Notifcation Description</label>
                                <input type="text" id="roleName" className="form-control f-10" placeholder="Mobile Notifcation Description" value={mobDesc} required onChange={(e) => {
                                    setmobDesc(e.target.value)
                                }} />
                            </div>
                            <div className="form-group">
                                <label className="f-12">WhatsApp Notifcation Description</label>
                                <input type="text" id="roleName" className="form-control f-10" placeholder="WhatsApp Notifcation Description" value={whatsappDesc} required onChange={(e) => {
                                    setwhatsappDesc(e.target.value)
                                }} />
                            </div>
                            <div className="form-group">
                                <label className="f-12">Category 1 Title</label>
                                <input type="text" id="roleName" className="form-control f-10" placeholder="Category 1 Title" value={key1} required onChange={(e) => {
                                    setKey1(e.target.value)
                                }} />
                            </div>
                            <div className="form-group">
                                <label className="f-12">Category 1 Value</label>
                                <input type="text" id="roleName" className="form-control f-10" placeholder="Category 1 Value" value={value1} required onChange={(e) => {
                                    setValue1(e.target.value)
                                }} />
                            </div>
                            <div className="form-group">
                                <label className="f-12">Category 2 Title</label>
                                <input type="text" id="roleName" className="form-control f-10" placeholder="Category 2 Title" value={key2} required onChange={(e) => {
                                    setKey2(e.target.value)
                                }} />
                            </div>
                            <div className="form-group">
                                <label className="f-12">Category 2 Value</label>
                                <input type="text" id="roleName" className="form-control f-10" placeholder="Category 2 Value" value={value2} required onChange={(e) => {
                                    setValue2(e.target.value)
                                }} />
                            </div>
                            <div className="form-group">
                                <label className="f-12">Disable Trading</label>
                                <select name="" id="" className="col-12 py-1 px-2 border rounded f-12" value={disabletrading != null ? disabletrading : "default"} onChange={(e) => {
                                    setDisableTrading(e.target.value)
                                }}>
                                    <option className='f-12' value="default"> ---- </option>
                                    <option className='f-12' value="0"> No </option>
                                    <option className='f-12' value="1"> Yes </option>
                                </select>
                            </div>
                            <div className="form-group">
                                <a className="f-12" href='#' onClick={(e) => {
                                    e.preventDefault()
                                    setShowRTPage(true)
                                    setRTPageType("Bucket Overview")
                                }}>Add Overview</a>
                            </div>
                            <div className="form-group">
                                <a className="f-12" href='#' onClick={(e) => {
                                    e.preventDefault()
                                    setShowRTPage(true)
                                    setRTPageType("Bucket Rationale")
                                }}>Add Investing Rationale</a>
                            </div>
                            <div className="form-group">
                                <a className="f-12" href='#' onClick={(e) => {
                                    e.preventDefault()
                                    setaddAssetPopup(true)
                                }}>Add Assets</a>
                            </div>
                            <div className="form-group">
                                <label className="f-12">Logo</label>
                                <p className="pb-0 mb-0 f-12 ">
                                    <input type="file" id="logo" required hidden accept="image/jpg, image/jpeg, image/png" onChange={(e) => {
                                        uploadFile(e.target.files[0], "Logo")
                                    }} />
                                    <label htmlFor="logo" className="mb-0 p-1  curPoint btn-primary">Choose Logo File</label>
                                    <br /> File to be uploaded: <span className="text-theme">{logoFile.name}</span>
                                </p>
                            </div>
                        </form>
                    </div>
                    <div className="form-check p-0">
                        <p className="text-danger" id="createRoleError">{error}</p>
                    </div>
                    <div className='row m-0 p-0'>
                        <div className='col-9 m-0 p-0'>
                            <button id="BucketBtn" className="btn btn-primary f-12" onClick={(e) => {
                                e.preventDefault()
                                addBucket()
                            }}>{editBucket ? "Update Bucket" : "Add Bucket"}</button>
                            <div id="BucketBtnLoader" className='animBtn d-none'><div className='gSignIn-Anim'></div></div>
                        </div>
                        <div className='col-3 m-0 p-0'>
                            <button className="btn btn-primary f-12" onClick={(e) => {
                                e.preventDefault()
                                resetValues()
                            }}>Reset</button>
                        </div>
                    </div>
                    <div className='row m-0 p-0'>
                        <button className="btn btn-primary mt-3 d-md-none" onClick={(e) => {
                            e.preventDefault()
                            document.getElementById("rmCreateCompany").classList.toggle('d-none')
                            document.getElementById("companyListPage").classList.toggle('d-none')
                            history.push('/assetManagement/Bucket')
                        }}>View All Bucket</button>
                    </div>
                </>
                : null}
            {/* --------------------------------create compnay overview--------------------------------- */}
            {showRTPage ? <RichTextEditorPage {...{ type: rtPageType }} /> : null}
            <MultiAssetPage {...{ showAssetClasses: false, name: "Pre-IPO", type: configs.modules.bucketMgmt }} />
        </div>
    )
}