import { DataGrid, GridToolbar } from '@material-ui/data-grid';
import React, { useEffect, useState } from 'react';
import { configs } from '../../../assets/Config';
import '../../../assets/styles/RM.css'
import { useLogin } from '../../../services/contexts/AdminLoginContext';
import { useCouponContext } from '../../../services/contexts/couponManagement/CouponContext';
import ConfirmModalPage from '../../includes/ConfirmModalPage';

export default function DeletedCouponPage() {
    const { couponList, setcouponList, setShowDeletedcoupon, showDeletedcoupon, getAllcouponList } = useCouponContext()
    const { setShowConfirm, showConfirm } = useLogin()

    const [confirmId, setConfirmId] = useState({})

    const columns = [{ field: "id", headerName: "Coupon ID", minWidth: 100, flex: 0.5, hide: true, filterable: false },
    { field: "code", headerName: "Code", minWidth: 100, flex: 0.5, cellClassName: "break-text" },
    { field: "discount", headerName: "Discount", minWidth: 150, flex: 0.5, cellClassName: "break-text" },
    { field: "max_discount", headerName: "Maximum Discount", minWidth: 150, flex: 0.5, cellClassName: "break-text" },
    { field: "expiry_date", headerName: "Expiry Date", minWidth: 180, flex: 0.5, cellClassName: "break-text" },
    { field: "utm", headerName: "UTM", minWidth: 150, flex: 0.5, cellClassName: "break-text" },
    { field: "asset_name", headerName: "Asset Name", minWidth: 150, flex: 0.5, cellClassName: "break-text" },
    { field: "asset_class", headerName: "Asset Class", minWidth: 150, flex: 0.5, cellClassName: "break-text" },
    {
        field: "first_order", headerName: "First Order", minWidth: 130, flex: 0.1, cellClassName: "break-text", renderCell: (params) => {
            return params.row.first_order === 1 ? "Yes" : "No"
        }, valueGetter: (params) => params.row.first_order === 1 ? "Yes" : "No"
    },
    {
        field: "restore", headerName: " ", minWidth: 65, flex: 0.1, cellClassName: "break-text", cellClassName: "text-danger", renderCell: (params) => {
            return <a href="#" className="custom-link text-danger" onClick={(e) => {
                e.preventDefault()
                setConfirmId({
                    "category": configs.modules.couponMgmt,
                    "type": "coupon",
                    "name": params.row.code,
                    "id": params.row.id
                })
                setShowConfirm(true)
            }}>Restore</a>
        }
    }]

    useEffect(() => {
        (async () => {
            try {
                await getAllcouponList(true)
            } catch (error) {

            }
        })()
    }, [])

    // role list page ui code
    return (
        <div style={{ width: "100%" }}>
            <div className="row d-flex align-items-center mt-2 ml-3 mb-1 mr-2">
                <div className="col-lg-6 col-7">
                    <p className="h4 text-custom-grey">Deleted coupon</p>
                </div>
                <div className="col-lg-6 col-5 text-right">
                    <a href="#" className="custom-link" onClick={(e) => {
                        e.preventDefault()
                        setcouponList("")
                        setShowDeletedcoupon(!showDeletedcoupon)
                    }}><i className={showDeletedcoupon ? "fas fa-user mr-2" : "fas fa-user-slash mr-2"}></i>{showDeletedcoupon ? "Active coupon" : "Deleted coupon"}</a>
                </div>
            </div>
            <div className=" deleted-role-table">
                {couponList ? <DataGrid
                    rows={couponList}
                    headerHeight={40}
                    rowHeight={30}
                    columns={columns}
                    resizable={true}
                    autoPageSize
                    pagination
                    components={{
                        Toolbar: GridToolbar,
                    }} /> : <div className="loader"></div>}
            </div>
            <ConfirmModalPage {...confirmId} />

        </div>
    )
}