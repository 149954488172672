import React from 'react';
import { createContext, useContext, useState } from 'react';
import { configs } from '../../../assets/Config';
import { createUpdateStartup, deleteStartup, getAllStartups, getStartupById, restoreStartup } from '../../apis/assetManagement/StartupManagementApi';
import { useLogin } from '../AdminLoginContext';

// create global Startup management context
export const StartupContext = createContext();

export function StartupProvider({ children }) {

    const { token, logout, setLoginError } = useLogin();

    const [StartupList, setStartupList] = useState("")
    const [editStartup, setEditStartup] = useState(false)
    const [editStartupData, setEditStartupData] = useState({})
    const [showDeletedStartup, setShowDeletedStartup] = useState(false)
    const [showRTPage, setShowRTPage] = useState(false)
    const [StartupCompanyPitch, setStartupCompanyPitch] = useState("")
    const [StartupAboutTeam, setStartupAboutTeam] = useState("")
    const [Startupterms, setStartupterms] = useState("")
    const [Startupfaq, setStartupfaq] = useState("")
    const [Startupdoc, setStartupdoc] = useState("")

    // get all Startup
    async function getAllStartupList(getDeleted) {
        try {
            let response = await getAllStartups(token, getDeleted);
            if (response && response.status === 200) {
                setStartupList(response.data)
                return {
                    success: "success",
                    data: response.data
                }
            } else if (response && response.status === 401) {
                logout()
                setLoginError(configs.unauthorizedErrorMessage)
            } else {
                setStartupList("")
            }
        } catch (error) {

        }
    }

    // get Startup data by id
    async function getStartupDataById(StartupId) {
        try {
            let response = await getStartupById(token, StartupId);
            if (response && response.status === 200) {
                let data = response.data
                setEditStartupData({
                    ...editStartupData,
                    ...data
                })
            } else if (response && response.status === 401) {
                logout()
                setLoginError(configs.unauthorizedErrorMessage)
            } else { }
        } catch (error) {

        }
    }

    // create or update Startup
    async function createEditStartup(name, isin, campaign_end_date, video_link, disabletrading, description, key_1, value_1, key_2, value_2, logo, editStartup, StartupId) {
        try {
            let response = await createUpdateStartup(token, name, isin, campaign_end_date, video_link, disabletrading, description, StartupCompanyPitch, StartupAboutTeam, Startupterms, Startupfaq, Startupdoc, key_1, value_1, key_2, value_2, logo, editStartup, StartupId);
            if (response && response.status === 200) {
                return "success"
            } else if (response && response.status === 401) {
                logout()
                setLoginError(configs.unauthorizedErrorMessage)
            } else {
                return response.data.error
            }
        } catch (error) {

        }
    }

    // delete Startup
    async function deleteStartupById(StartupId) {
        try {
            let response = await deleteStartup(token, StartupId);
            if (response && response.status === 200) {
                return "success"
            } else if (response && response.status === 401) {
                logout()
                setLoginError(configs.unauthorizedErrorMessage)
            } else {
                return response.data.error
            }
        } catch (error) {

        }
    }

    // restore Startup
    async function restoreStartupById(StartupId) {
        try {
            let response = await restoreStartup(token, StartupId);
            if (response && response.status === 200) {
                return "success"
            } else if (response && response.status === 401) {
                logout()
                setLoginError(configs.unauthorizedErrorMessage)
            } else {
                return response.data.error
            }
        } catch (error) {

        }
    }

    // export global context
    return (
        <StartupContext.Provider
            value={{
                StartupList,
                setStartupList,
                getAllStartupList,
                deleteStartupById,
                restoreStartupById,
                editStartup,
                setEditStartup,
                editStartupData,
                setEditStartupData,
                getStartupDataById,
                createEditStartup,
                showDeletedStartup,
                setShowDeletedStartup,
                showRTPage,
                setShowRTPage,
                StartupCompanyPitch,
                setStartupCompanyPitch,
                StartupAboutTeam,
                setStartupAboutTeam,
                Startupterms,
                setStartupterms,
                Startupfaq,
                setStartupfaq,
                Startupdoc,
                setStartupdoc
            }}
        >
            {children}
        </StartupContext.Provider>
    );
}

// return Startup management context
export function useStartupContext() {
    return useContext(StartupContext);
}