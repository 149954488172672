import { Modal } from "@material-ui/core";
import { useLogin } from "../../../../services/contexts/AdminLoginContext";
import AddStatusData from "./AddStatusData";
import { useEffect, useState } from "react";
import { addHighlights, getHighlights } from "../../../../services/apis/CustomerDashboardManagementApi";
import ToastPage from "../../../includes/ToastPage";

export default function CompanyHighlightMgmt() {

    const { dashboardHighlight, setDashboardHighlight, highlightManagement, setHighlightManagement, token, logout, setShowSuccess } = useLogin()

    const [error, setError] = useState("");

    const [temp, setTemp] = useState("");

    const handleClose = () => {
        setHighlightManagement({
            asset: highlightManagement.asset,
            showPage: false
        });
    };

    useEffect(async () => {
        try {
            let response = await getHighlights(token, highlightManagement.asset.id);
            if (response && response.data) {
                setDashboardHighlight(response.data)
                setTemp(new Date());
            }
        } catch (error) {
            console.error(error);
        }
    }, [highlightManagement.asset])

    // save highlight
    async function saveHighLight() {
        try {
            document.getElementById("saveHighlightCompBtn").classList.toggle("d-none")
            document.getElementById("saveHighlightCompBtnLoader").classList.toggle("d-none")

            if (dashboardHighlight[dashboardHighlight.length - 1]?.file && dashboardHighlight[dashboardHighlight.length - 1]?.heading && dashboardHighlight[dashboardHighlight.length - 1]?.highlights[dashboardHighlight[dashboardHighlight.length - 1].highlights.length - 1]?.file) {
                let response = await addHighlights(token, dashboardHighlight, highlightManagement.asset.id);
                if (response && response.status === 200) {
                    setError("");
                    setShowSuccess(true)
                } else if (response && response.status === 401) {
                    logout();
                } else {
                    setError(response.data.error);
                }
            } else {
                setError("The highlight component could not be saved as above highlight component has empty highlight")
            }
        } catch (error) {
            setError(error.error ? error.error : error)
            console.error(error);
        } finally {
            document.getElementById("saveHighlightCompBtn").classList.toggle("d-none")
            document.getElementById("saveHighlightCompBtnLoader").classList.toggle("d-none")
        }
    }

    return (
        <Modal open={highlightManagement.showPage} onClose={handleClose} disableEnforceFocus>
            <div className="modal-fade" id="KYCSubmitModal" tabIndex="-1" role="dialog" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered modal-width" role="document">
                    <div className="modal-content">
                        <div className="modal-header border-0">
                            <h2 className="text-capitalize">Update {highlightManagement.asset.name} Highlights</h2>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={handleClose}>
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body py-3" style={{ height: "500px", overflow: 'auto' }}>
                            {dashboardHighlight && dashboardHighlight.length > 0 ?
                                dashboardHighlight[0].highlights.map((ele2, key2) => {
                                    return <AddStatusData parentIndex={0} index={key2} data={ele2} lastEle={key2 === dashboardHighlight[0].highlights.length - 1 ? true : false} />
                                })
                                : null}
                            <p className="text-danger">{error}</p>
                            <div className="">
                                <button id="saveHighlightCompBtn" className="btn btn-primary" onClick={(e) => {
                                    e.preventDefault()
                                    saveHighLight()
                                }}>Update Highlights</button>
                                <div id="saveHighlightCompBtnLoader" className='animBtn d-none'><div className='gSignIn-Anim'></div></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Modal>
    )
}