import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { configs } from '../../../assets/Config';
import { useLogin } from '../../../services/contexts/AdminLoginContext';
import LeftBarPage from '../../includes/dashboard/LeftBarPage';
import TopBarPage from '../../includes/dashboard/TopBarPage';
import ToastPage from '../../includes/ToastPage';
import '../../../assets/styles/RM.css'
import InventoryAnalyticsPage from './InventoryAnalyticsPage';
import UpdateInventoryPage from './UpdateInventoryPage';


export default function IMMainPage() {

    const { setCurrModule, allowedFunc, token } = useLogin()

    const history = useHistory()

    useEffect(() => {
        (async () => {
            try {
                if (allowedFunc.includes(configs.functionList['buy_module:export_inventory']) || allowedFunc.includes(configs.functionList['buy_module:update_inventory'])) {
                    setCurrModule(configs.modules.smInventory)
                } else {
                    return history.goBack()
                }
            } catch (error) {

            }
        })()
    }, [])

    // role management main ui code
    return (
        <>
            <TopBarPage />
            <div style={{ display: "flex" }}>
                <LeftBarPage />
                <div id='rmContainer' className="rm-container">
                    <InventoryAnalyticsPage />
                    {allowedFunc.includes(configs.functionList['buy_module:export_inventory']) || allowedFunc.includes(configs.functionList['buy_module:update_inventory']) ?
                        <UpdateInventoryPage />
                        : null}
                    <ToastPage />
                </div>
            </div>
        </>
    )
}