import { TextField } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router';
import { configs, downloadDoc, showDocNewTab } from '../../../assets/Config';
import '../../../assets/styles/KYC.css';
import { getUTMList, updateCustomerBankStatus, updateCustomerBasicStatus, updateCustomerDematStatus, updateCustomerRemark, updateCustomerType, updateCustomerUTM } from '../../../services/apis/KYCApi';
import { useLogin } from '../../../services/contexts/AdminLoginContext';
import { useKYCContext } from '../../../services/contexts/KYCContext';
import ErrorModalPage from '../../includes/ErrorModalPage';
import SuccessModalPage from '../../includes/SuccessModalPage';
import ToastPage from '../../includes/ToastPage';

export default function KYCReviewRequestDetailPage() {

    const { kycRequesterUserComplete, setKycRequesterUserComplete, getKYCCustomerDetail, panDoc, addressDoc, bankDoc, dematDoc, otherDoc, kycRequesterUserDetail, setKycRequesterUserDetail, setShowError, utmList, setUtmList, getAllUTMList, kycRequestersList, getKYCRequesters } = useKYCContext()

    const { token, logout, setLoginError, setShowSuccess, allowedFunc, getAllRemarkComments, remarkComments } = useLogin()

    const [remark, setRemark] = useState("")
    const [adminRemark, setAdminRemark] = useState("")
    const [error, setError] = useState("")
    const [update, setUpdate] = useState(false)
    const [addressStatus, setaddressStatus] = useState("")
    const [nameStatus, setnameStatus] = useState("")
    const [panStatus, setpanStatus] = useState("")
    const [bankStatus, setbankStatus] = useState("")
    const [dematStatus, setdematStatus] = useState("")
    const [utm, setUtm] = useState("")
    const [parentUser, setParentUser] = useState()
    const [accountType, setAccountType] = useState("")

    const history = useHistory()

    let { customerId } = useParams()

    useEffect(() => {
        if (customerId) {
            setKycRequesterUserDetail({ id: customerId })

            try {
                (async () => {
                    await getKYCRequesters();
                    await getAllUTMList()
                })()
            } catch (error) {
                console.error(error)
            }
        }
    }, [])

    // get customer kyc detail
    useEffect(() => {
        (async () => {
            try {
                if (kycRequesterUserDetail) {
                    await getAllRemarkComments(configs.remarkCategories['kyc:kyc_remark'])
                    let result = await getKYCCustomerDetail();
                    if (result.basic_details) {
                        setaddressStatus(result.basic_details.address_status)
                        setpanStatus(result.basic_details.pan_status)
                        setnameStatus(result.basic_details.name_status)
                        setbankStatus(result.bank_details.status)
                        setdematStatus(result.demat_details.status)
                        setRemark(result.remark)
                        setAdminRemark(result.internal_remark)
                        setAccountType(result.account_type)
                        setParentUser(result.parent && result.parent.id ? result.parent : "")
                        if (result.distributor && result.distributor.name && result.distributor.utm)
                            setUtm({
                                distributor: result.distributor.name,
                                utm: result.distributor.utm
                            })
                    }
                }
            } catch (error) {

            }
        })();
    }, [kycRequesterUserDetail])

    //update customer kyc status
    async function updateBasicDetailStatus(field, status) {
        try {
            let response = await updateCustomerBasicStatus(token, kycRequesterUserDetail.id, field, status);
            if (response && response.status === 200) {
                if (field === configs.fields.address) {
                    kycRequesterUserComplete.basic_details.address_status = status
                    setaddressStatus(status)
                }
                else if (field === configs.fields.name) {
                    kycRequesterUserComplete.basic_details.name_status = status
                    setnameStatus(status)
                }
                else if (field === configs.fields.pan) {
                    kycRequesterUserComplete.basic_details.pan_status = status
                    setpanStatus(status)
                }
                setKycRequesterUserComplete(kycRequesterUserComplete)
                setShowSuccess(true)
            } else if (response && response.status === 401) {
                logout();
                setLoginError(configs.unauthorizedErrorMessage)
            } else {
                setError(response.data.error)
                setShowError(true)
            }
        } catch (error) {

        }
    }

    async function updateBankDetailStatus(status) {
        try {
            let response = await updateCustomerBankStatus(token, kycRequesterUserDetail.id, status);
            if (response && response.status === 200) {
                kycRequesterUserComplete.bank_details.status = status
                setbankStatus(status)
                setKycRequesterUserComplete(kycRequesterUserComplete)
                setShowSuccess(true)
            } else if (response && response.status === 401) {
                logout();
                setLoginError(configs.unauthorizedErrorMessage)
            } else {
                setError(response.data.error)
                setShowError(true)
            }
        } catch (error) {

        }
    }

    async function updateDematDetailStatus(status) {
        try {
            let response = await updateCustomerDematStatus(token, kycRequesterUserDetail.id, status);
            if (response && response.status === 200) {
                kycRequesterUserComplete.demat_details.status = status
                setdematStatus(status)
                setKycRequesterUserComplete(kycRequesterUserComplete)
                setShowSuccess(true)
            } else if (response && response.status === 401) {
                logout();
                setLoginError(configs.unauthorizedErrorMessage)
            } else {
                setError(response.data.error)
                setShowError(true)
            }
        } catch (error) {

        }
    }

    // save admin remark on customer kyc
    async function updateRemark() {
        try {
            let response = await updateCustomerRemark(token, kycRequesterUserDetail.id, remark, adminRemark);
            if (response && response.status === 200) {
                kycRequesterUserComplete.remark = remark
                kycRequesterUserComplete.adminRemark = adminRemark
                setKycRequesterUserComplete(kycRequesterUserComplete)
                setShowSuccess(true)
            } else if (response && response.status === 401) {
                logout();
                setLoginError(configs.unauthorizedErrorMessage)
            } else {
                setError(response.data.error)
                setShowError(true)
            }
        } catch (error) {
            console.error(error)
        }
    }

    // save customer utm
    async function updateUTM() {
        try {

            document.getElementById("utmBtn").classList.toggle("d-none")
            document.getElementById("utmBtnLoader").classList.toggle("d-none")

            let response = await updateCustomerUTM(token, kycRequesterUserDetail.id, utm.utm);
            if (response && response.status === 200) {
                setShowSuccess(true)
            } else if (response && response.status === 401) {
                logout();
                setLoginError(configs.unauthorizedErrorMessage)
            } else {
                setError(response.data.error)
                setShowError(true)
            }

            document.getElementById("utmBtn").classList.toggle("d-none")
            document.getElementById("utmBtnLoader").classList.toggle("d-none")
        } catch (error) {
            console.error(error)
        }
    }

    // update customer type
    async function updateCustomerAccType() {
        try {

            document.getElementById("customerTypeBtn").classList.toggle("d-none")
            document.getElementById("customerTypeBtnLoader").classList.toggle("d-none")
            // if (accountType === configs.accountType[2] && !parentUser) {
            //     setError("Please select a parent.")
            //     setShowError(true)
            // } else {

                let response = await updateCustomerType(token, kycRequesterUserDetail.id, accountType, parentUser.id);
                if (response && response.status === 200) {
                    setShowSuccess(true)
                } else if (response && response.status === 401) {
                    logout();
                    setLoginError(configs.unauthorizedErrorMessage)
                } else {
                    setError(response.data.error)
                    setShowError(true)
                }
            // }

            document.getElementById("customerTypeBtn").classList.toggle("d-none")
            document.getElementById("customerTypeBtnLoader").classList.toggle("d-none")
        } catch (error) {
            console.error(error)
        }
    }

    const handleUtmChange = (e, value) => {
        try {
            setUtm(value);
        } catch (error) {

        }
    }
    const handleParentChange = (e, value) => {
        try {
            setParentUser(value);
        } catch (error) {

        }
    }
    return (
        <>
            {kycRequesterUserComplete ? <div className="container-fluid">
                <div className="row position-relative px-2">
                    <div className="col-lg-12 m-0 p-0">
                        <p className="h4 text-custom-grey mb-3 mt-4">
                            <span className="mr-3" onClick={(e) => {
                                setKycRequesterUserDetail(null)
                                history.push('/kyc')
                            }}><i className="far fa-arrow-alt-circle-left"></i></span>
                            Review KYC request</p>
                    </div>
                    <div className='col-lg-12 row m-0 p-0 align-items-center'>
                        <div className="col-md-7 col-12 row d-flex flex-wrap kyc-status-bar mb-2 m-0 p-0">
                            <div className={nameStatus === configs.statusValues.verified ? "text-success col-lg-2 col-md-3 col-4 m-0 p-0"
                                : nameStatus === configs.statusValues.rejected ? "text-danger col-lg-2 col-md-3 col-4 m-0 p-0"
                                    : "text-primary col-lg-2 col-md-3 col-4 m-0 p-0"}>
                                <i className={nameStatus === configs.statusValues.verified ? "far fa-check-square"
                                    : nameStatus === configs.statusValues.rejected ? "far fa-window-close"
                                        : "far fa-square"}></i>
                                <span>&nbsp; Name</span>
                            </div>
                            <div className={panStatus === configs.statusValues.verified ? "text-success col-lg-2 col-md-3 col-4 m-0 p-0"
                                : panStatus === configs.statusValues.rejected ? "text-danger col-lg-2 col-md-3 col-4 m-0 p-0"
                                    : "text-primary col-lg-2 col-md-3 col-4 m-0 p-0"}>
                                <i className={panStatus === configs.statusValues.verified ? "far fa-check-square"
                                    : panStatus === configs.statusValues.rejected ? "far fa-window-close"
                                        : "far fa-square"}></i>
                                <span>&nbsp; PAN</span>
                            </div>
                            <div className={addressStatus === configs.statusValues.verified ? "text-success col-lg-2 col-md-3 col-4 m-0 p-0"
                                : addressStatus === configs.statusValues.rejected ? "text-danger col-lg-2 col-md-3 col-4 m-0 p-0"
                                    : "text-primary col-lg-2 col-md-3 col-4 m-0 p-0"}>
                                <i className={addressStatus === configs.statusValues.verified ? "far fa-check-square"
                                    : addressStatus === configs.statusValues.rejected ? "far fa-window-close"
                                        : "far fa-square"}></i>
                                <span>&nbsp; Address</span>
                            </div>
                            <div className={bankStatus === configs.statusValues.verified ? "text-success col-lg-2 col-md-3 col-4 m-0 p-0"
                                : bankStatus === configs.statusValues.rejected ? "text-danger col-lg-2 col-md-3 col-4 m-0 p-0"
                                    : "text-primary col-lg-2 col-md-3 col-4 m-0 p-0"}>
                                <i className={bankStatus === configs.statusValues.verified ? "far fa-check-square"
                                    : bankStatus === configs.statusValues.rejected ? "far fa-window-close"
                                        : "far fa-square"}></i>
                                <span>&nbsp; Bank</span>
                            </div>
                            <div className={dematStatus === configs.statusValues.verified ? "text-success col-lg-2 col-md-3 col-4 m-0 p-0"
                                : dematStatus === configs.statusValues.rejected ? "text-danger col-lg-2 col-md-3 col-4 m-0 p-0"
                                    : "text-primary col-lg-2 col-md-3 col-4 m-0 p-0"}>
                                <i className={dematStatus === configs.statusValues.verified ? "far fa-check-square"
                                    : dematStatus === configs.statusValues.rejected ? "far fa-window-close"
                                        : "far fa-square"}></i>
                                <span>&nbsp; Demat</span>
                            </div>
                        </div>
                        {allowedFunc.includes(configs.functionList["user_management:update_user_utm"]) ?
                            <div className='col-md-5 col-12 m-0 p-0 mb-2 d-flex f-12 row align-items-center'>
                                <div className='col-3 m-0 p-0 text-left'>
                                    <label htmlFor="companies" className='m-0 p-0 font-weight-bold'>Select UTM</label>
                                </div>
                                <div className='col-6 m-0 p-0 text-center'>
                                    <Autocomplete
                                        value={utm ? utm : null}
                                        disableCloseOnSelect={false}
                                        filterSelectedOptions
                                        className="f-12"
                                        id="utmip"
                                        placeholder="Select UTM"
                                        getOptionLabel={option => `${option.distributor} - ${option.utm}\n${option.email}`}
                                        onChange={handleUtmChange}
                                        options={utmList}
                                        renderInput={(params) => <TextField {...params} placeholder="Select UTM" className="f-12" />}
                                    />
                                </div>
                                <div className='col-3 m-0 p-0 text-right'>
                                    <button id="utmBtn" className="btn btn-primary px-2 f-12" onClick={(e) => {
                                        e.preventDefault();
                                        updateUTM()
                                    }}> Update UTM</button>
                                    <div id="utmBtnLoader" className='animBtn d-none'><div className='gSignIn-Anim'></div></div>
                                </div>
                            </div>
                            : null}

                        <div className='col-md-7 col-12 m-0 p-0  p-md-1 mb-2 d-flex f-12 row align-items-center'>
                            <div className='col-3 m-0 p-0 text-left'>
                                <label htmlFor="companies" className='m-0 p-0 font-weight-bold'>Update Account Type</label>
                            </div>
                            <div className='col-6 m-0 text-center'>
                                <select name="" id="" className="col-12 py-1 px-2 border rounded f-12" value={accountType ? accountType : ""} onChange={(e) => {
                                    setAccountType(e.target.value)
                                    setError("")
                                }}>
                                    <option className='f-12' value=""> Select Account Type </option>
                                    {configs.accountType.map((accType, key) => {
                                        return <option className='f-12' key={`accType${key}`} value={accType}> {accType === configs.accountType[1] ? "PRO Plus" : accType === configs.accountType[2] ? "PRO Lite" : accType} </option>
                                    })}
                                </select>
                            </div>
                            {accountType !== configs.accountType[2] ?
                                <div className='col-md-2 col-3 m-0 p-0 text-right'>
                                    <button id="customerTypeBtn" className="btn btn-primary px-2 f-12" onClick={(e) => {
                                        e.preventDefault();
                                        updateCustomerAccType()
                                    }}> Update Type</button>
                                    <div id="customerTypeBtnLoader" className='animBtn d-none'><div className='gSignIn-Anim'></div></div>
                                </div>
                                : null}

                            <div className='col-2 d-md-flex d-none'></div>

                        </div>
                        {accountType === configs.accountType[2] ?
                            <div className='col-md-5 col-12 m-0 p-md-1 p-0 mb-2 d-flex f-12 row align-items-center'>
                                <div className='col-3 m-0 p-0 text-left'>
                                    <label htmlFor="companies" className='m-0 p-0 font-weight-bold'>Select Parent</label>
                                </div>
                                <div className='col-6 m-0 text-center'>
                                    {kycRequestersList ?
                                        <Autocomplete
                                            value={parentUser ? parentUser : null}
                                            filterSelectedOptions={true}
                                            className="f-12"
                                            id="parentSelect"
                                            placeholder="Select Parent"
                                            getOptionLabel={option => `${option.name} - ${option.email}`}
                                            onChange={handleParentChange}
                                            options={kycRequestersList.filter(parentUser => parentUser.account_type === configs.accountType[1])}
                                            renderInput={(params) => <TextField {...params} placeholder="Select Parent" className="f-12" />}
                                        />
                                        : null}
                                </div>
                                <div className='col-3 m-0 p-0 text-right'>
                                    <button id="customerTypeBtn" className="btn btn-primary px-2 f-12" onClick={(e) => {
                                        e.preventDefault();
                                        updateCustomerAccType()
                                    }}> Update Type</button>
                                    <div id="customerTypeBtnLoader" className='animBtn d-none'><div className='gSignIn-Anim'></div></div>
                                </div>
                            </div>
                            : null}
                    </div>
                    <div className="kyc-document-pcontainer">
                        <div className="col-lg-12 my-2 mx-0 p-0 row kyc-document-container">
                            <div className="col-md-6 col-12 kyc-document-display">
                                {panDoc.includes("application/pdf") ? <iframe id="panFrame" height="100%" width="100%"></iframe> : panDoc.includes("image") ? <img className="docs-box" width="100%" height="100%" src={panDoc} /> : null}
                                {(panDoc.includes("application/pdf") || panDoc.includes("image")) ?
                                    <div className="kyc-openDoc-container">
                                        <i className="fa fa-external-link kyc-openDoc curPoint" onClick={() => {
                                            showDocNewTab("Pan Document", panDoc)
                                        }}></i>
                                        <i className="fa fa-download kyc-openDoc curPoint" onClick={() => {
                                            downloadDoc(`${kycRequesterUserComplete.basic_details.name} Pan Document`, panDoc)
                                        }}></i>
                                    </div>
                                    : !kycRequesterUserComplete.user_documents.pan_card ? <h5 style={{ "margin": "3em" }}>No Document Uploaded</h5> : <div className="loader"></div>}

                            </div>
                            <div className="col-md-6 col-12 row m-0 p-0 kyc-document-status">
                                <div className="col-12">Password - {kycRequesterUserComplete.user_documents.pan_card_password} </div>
                                <div className="col-6">
                                    <p className="text-secondary m-0">Name Verification</p>
                                    <p className="my-1">{kycRequesterUserComplete.basic_details.name}</p>
                                    <p className="text-secondary m-0">Name from Cashfree (PAN): </p>
                                    <p className="my-1">{kycRequesterUserComplete.basic_details.pan_verification_name}</p>
                                    <p className="text-secondary m-0">Name from Cashfree (Bank):</p>
                                    <p className="my-1"> {kycRequesterUserComplete.bank_details.bank_verification_name}</p>
                                    {allowedFunc.includes(configs.functionList['kyc:update_user_kyc_status']) ?
                                        <form className="small mt-3">
                                            <div className="form-group my-1">
                                                <input type="radio" name="nameVerify" defaultChecked={kycRequesterUserComplete.basic_details.name_status === configs.statusValues.verified ? true : false} className="" value={configs.statusValues.verified} onChange={(e) => {
                                                    updateBasicDetailStatus(configs.fields.name, e.target.value)
                                                    //setnameStatus(e.target.value)
                                                }} /> Verified
                                            </div>
                                            <div className="form-group my-1">
                                                <input type="radio" name="nameVerify" defaultChecked={kycRequesterUserComplete.basic_details.name_status === configs.statusValues.rejected ? true : false} className="" value={configs.statusValues.rejected} onChange={(e) => {
                                                    updateBasicDetailStatus(configs.fields.name, e.target.value)
                                                    //setnameStatus(e.target.value)
                                                }} /> Rejected
                                            </div>
                                            <p className="mt-3"><b>Verified at -</b> {kycRequesterUserComplete.basic_details.name_verified_on} | {kycRequesterUserComplete.basic_details.name_verified_at}</p>
                                            <p><b>Verified by -</b> {kycRequesterUserComplete.basic_details.name_verified_by.name}</p>
                                        </form>
                                        : null}
                                </div>
                                <div className="col-6">
                                    <p className="text-secondary">PAN Verification</p>
                                    <p className="my-1">{kycRequesterUserComplete.basic_details.pan_number}</p>
                                    {allowedFunc.includes(configs.functionList['kyc:update_user_kyc_status']) ?
                                        <form className="small mt-3">
                                            <div className="form-group my-1">
                                                <input type="radio" name="panVerify" defaultChecked={kycRequesterUserComplete.basic_details.pan_status === configs.statusValues.verified ? true : false} className="" value={configs.statusValues.verified} onChange={(e) => {
                                                    updateBasicDetailStatus(configs.fields.pan, e.target.value)
                                                    //setpanStatus(e.target.value)
                                                }} /> Verified
                                            </div>
                                            <div className="form-group my-1">
                                                <input type="radio" name="panVerify" defaultChecked={kycRequesterUserComplete.basic_details.pan_status === configs.statusValues.rejected ? true : false} className="" value={configs.statusValues.rejected} onChange={(e) => {
                                                    updateBasicDetailStatus(configs.fields.pan, e.target.value)
                                                    //setpanStatus(e.target.value)
                                                }} /> Rejected
                                            </div>
                                            <p className="mt-3"><b>Verified at -</b> {kycRequesterUserComplete.basic_details.pan_verified_on} | {kycRequesterUserComplete.basic_details.pan_verified_at}</p>
                                            <p><b>Verified by -</b> {kycRequesterUserComplete.basic_details.pan_verified_by.name}</p>
                                        </form>
                                        : null}
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-12 my-2 mx-0 p-0 row kyc-document-container">
                            <div className="col-md-6 col-12 kyc-document-display">
                                {addressDoc.includes("application/pdf") ? <iframe id="addressFrame" height="100%" width="100%"></iframe> : addressDoc.includes("image") ? <img className="docs-box" width="100%" height="100%" src={addressDoc} /> : null}
                                {(addressDoc.includes("application/pdf") || addressDoc.includes("image")) ?
                                    <div className="kyc-openDoc-container">
                                        <i className="fa fa-external-link kyc-openDoc curPoint" onClick={() => {
                                            showDocNewTab("Address Document", addressDoc)
                                        }}></i>
                                        <i className="fa fa-download kyc-openDoc curPoint" onClick={() => {
                                            downloadDoc(`${kycRequesterUserComplete.basic_details.name} Address Document`, addressDoc)
                                        }}></i>
                                    </div>
                                    : !kycRequesterUserComplete.user_documents.address_proof ? <h5 style={{ "margin": "3em" }}>No Document Uploaded</h5> : <div className="loader"></div>}
                            </div>
                            <div className="col-6 row kyc-document-status">
                                <div className="col-12">Password - {kycRequesterUserComplete.user_documents.address_proof_password}</div>
                                <div className="col-12">
                                    <p className="text-secondary">Address Verification</p>
                                    <p className="my-1">{kycRequesterUserComplete.basic_details.address}</p>
                                    {allowedFunc.includes(configs.functionList['kyc:update_user_kyc_status']) ?
                                        <form className="small mt-3">
                                            <div className="form-group my-1">
                                                <input type="radio" name="addressVerify" defaultChecked={kycRequesterUserComplete.basic_details.address_status === configs.statusValues.verified ? true : false} className="" value={configs.statusValues.verified} onChange={(e) => {
                                                    updateBasicDetailStatus(configs.fields.address, e.target.value)
                                                    //setaddressStatus(e.target.value)
                                                }} /> Verified
                                            </div>
                                            <div className="form-group my-1">
                                                <input type="radio" name="addressVerify" defaultChecked={kycRequesterUserComplete.basic_details.address_status === configs.statusValues.rejected ? true : false} className="" value={configs.statusValues.rejected} onChange={(e) => {
                                                    updateBasicDetailStatus(configs.fields.address, e.target.value)
                                                    //setaddressStatus(e.target.value)
                                                }} /> Rejected
                                            </div>
                                            <p className="mt-3"><b>Verified at -</b> {kycRequesterUserComplete.basic_details.address_verified_on} | {kycRequesterUserComplete.basic_details.address_verified_at}</p>
                                            <p><b>Verified by -</b> {kycRequesterUserComplete.basic_details.address_verified_by.name}</p>
                                        </form>
                                        : null}
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-12 my-2 mx-0 p-0 row kyc-document-container">
                            <div className="col-md-6 col-12 kyc-document-display">
                                {bankDoc.includes("application/pdf") ? <iframe id="bankFrame" height="100%" width="100%"></iframe> : bankDoc.includes("image") ? <img className="docs-box" width="100%" height="100%" src={bankDoc} /> : null}
                                {(bankDoc.includes("application/pdf") || bankDoc.includes("image")) ?
                                    <div className="kyc-openDoc-container">
                                        <i className="fa fa-external-link kyc-openDoc curPoint" onClick={() => {
                                            showDocNewTab("Bank Document", bankDoc)
                                        }}></i>
                                        <i className="fa fa-download kyc-openDoc curPoint" onClick={() => {
                                            downloadDoc(`${kycRequesterUserComplete.basic_details.name} Bank Document`, bankDoc)
                                        }}></i>
                                    </div>
                                    : !kycRequesterUserComplete.user_documents.bank_proof ? <h5 style={{ "margin": "3em" }}>No Document Uploaded</h5> : <div className="loader"></div>}
                            </div>
                            <div className="col-6 row kyc-document-status">
                                <div className="col-12">Password - {kycRequesterUserComplete.user_documents.bank_proof_password}</div>
                                <div className="col-12">
                                    <p className="text-secondary">Bank Verification</p>
                                    <p className="my-1">Bank Name - {kycRequesterUserComplete.bank_details.bank_name}</p>
                                    <p className="my-1">Account Number - {kycRequesterUserComplete.bank_details.account_number}</p>
                                    <p className="my-1">IFSC Code - {kycRequesterUserComplete.bank_details.ifsc_code}</p>
                                    {allowedFunc.includes(configs.functionList['kyc:update_user_kyc_status']) ?
                                        <form className="small mt-3">
                                            <div className="form-group my-1">
                                                <input type="radio" name="bankVerify" defaultChecked={kycRequesterUserComplete.bank_details.status === configs.statusValues.verified ? true : false} className="" value={configs.statusValues.verified} onChange={(e) => {
                                                    updateBankDetailStatus(e.target.value)
                                                    //setbankStatus(e.target.value)
                                                }} /> Verified
                                            </div>
                                            <div className="form-group my-1">
                                                <input type="radio" name="bankVerify" defaultChecked={kycRequesterUserComplete.bank_details.status === configs.statusValues.rejected ? true : false} className="" value={configs.statusValues.rejected} onChange={(e) => {
                                                    updateBankDetailStatus(e.target.value)
                                                    //setbankStatus(e.target.value)
                                                }} /> Rejected
                                            </div>
                                            <p className="mt-3"><b>Verified at -</b> {kycRequesterUserComplete.bank_details.verified_on} | {kycRequesterUserComplete.bank_details.verified_at}</p>
                                            <p><b>Verified by -</b> {kycRequesterUserComplete.bank_details.verified_by.name}</p>
                                        </form>
                                        : null}
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-12 my-2 mx-0 p-0 row kyc-document-container">
                            <div className="col-md-6 col-12 kyc-document-display">
                                {dematDoc.includes("application/pdf") ? <iframe id="dematFrame" height="100%" width="100%"></iframe>
                                    : dematDoc.includes("image") ? <img className="docs-box" width="100%" height="100%" src={dematDoc} /> : null}
                                {(dematDoc.includes("application/pdf") || dematDoc.includes("image")) ?
                                    <div className="kyc-openDoc-container">
                                        <i className="fa fa-external-link kyc-openDoc curPoint" onClick={() => {
                                            showDocNewTab("Demat Document", dematDoc)
                                        }}></i>
                                        <i className="fa fa-download kyc-openDoc curPoint" onClick={() => {
                                            downloadDoc(`${kycRequesterUserComplete.basic_details.name} Demat Document`, dematDoc)
                                        }}></i>
                                    </div>
                                    : !kycRequesterUserComplete.user_documents.demat_proof ? <h5 style={{ "margin": "3em" }}>No Document Uploaded</h5> : <div className="loader"></div>}
                            </div>
                            <div className="col-6 row kyc-document-status">
                                <div className="col-12">Password - {kycRequesterUserComplete.user_documents.demat_proof_password}</div>
                                <div className="col-12">
                                    <p className="text-secondary">Demat Verification</p>
                                    <p className="my-1">DP ID - {kycRequesterUserComplete.demat_details.dp_id}</p>
                                    <p className="my-1">Client ID - {kycRequesterUserComplete.demat_details.client_id}</p>
                                    {allowedFunc.includes(configs.functionList['kyc:update_user_kyc_status']) ?
                                        <form className="small mt-3">
                                            <div className="form-group my-1">
                                                <input type="radio" name="dematVerify" defaultChecked={kycRequesterUserComplete.demat_details.status === configs.statusValues.verified ? true : false} className="" value={configs.statusValues.verified} onChange={(e) => {
                                                    updateDematDetailStatus(e.target.value)
                                                    //setdematStatus(e.target.value)
                                                }} /> Verified
                                            </div>
                                            <div className="form-group my-1">
                                                <input type="radio" name="dematVerify" defaultChecked={kycRequesterUserComplete.demat_details.status === configs.statusValues.rejected ? true : false} className="" value={configs.statusValues.rejected} onChange={(e) => {
                                                    updateDematDetailStatus(e.target.value)
                                                    //setdematStatus(e.target.value)
                                                }} /> Rejected
                                            </div>
                                            <p className="mt-3"><b>Verified at -</b> {kycRequesterUserComplete.demat_details.verified_on} | {kycRequesterUserComplete.demat_details.verified_at}</p>
                                            <p><b>Verified by -</b> {kycRequesterUserComplete.demat_details.verified_by.name}</p>
                                        </form>
                                        : null}
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-12 my-2 mx-0 p-0 row kyc-document-container">
                            <div className="col-md-6 col-12 kyc-document-display">
                                {
                                    otherDoc.includes("application/pdf") ? <iframe id="otherFrame" height="100%" width="100%"></iframe>
                                        : otherDoc.includes("image") ? <img className="docs-box" width="100%" height="100%" src={otherDoc} /> : null
                                }
                                {(otherDoc.includes("application/pdf") || otherDoc.includes("image")) ?
                                    <div className="kyc-openDoc-container">
                                        <i className="fa fa-external-link kyc-openDoc curPoint" onClick={() => {
                                            showDocNewTab("Other Document", otherDoc)
                                        }}></i>
                                        <i className="fa fa-download kyc-openDoc curPoint" onClick={() => {
                                            downloadDoc(`${kycRequesterUserComplete.basic_details.name} Other Document`, otherDoc)
                                        }}></i>
                                    </div>
                                    : !kycRequesterUserComplete.user_documents.other_document ? <h5 style={{ "margin": "3em" }}>No Document Uploaded</h5> : <div className="loader"></div>}
                            </div>
                            <div className="col-6 row kyc-document-status">
                                <div className="col-12">Password - {kycRequesterUserComplete.user_documents.other_document_password}</div>
                                <div className="col-12">
                                    <p className="text-secondary">Other Document</p>
                                </div>
                            </div>
                        </div>
                        <div className="row p-0 m-0">
                            <div className="col-md-6 pl-0 mt-5">
                                {allowedFunc.includes(configs.functionList['kyc:update_user_kyc_status']) ?
                                    <form action="">
                                        <div className="form-group my-1">
                                            <label className="text-secondary">Remark for customer</label>
                                            <select name="" id="" className="col-12 py-1 px-2 border rounded" value={remark ? remark : "0"} onChange={(e) => {
                                                setRemark(e.target.value)
                                            }}>
                                                <option value="0"> -- Select a Remark -- </option>
                                                {remarkComments && remarkComments.templates ? remarkComments.templates.map((element, key) => {
                                                    return (
                                                        <option key={`kycremark` + key} value={element.template}>{element.template}</option>
                                                    )
                                                }) : null}
                                            </select>
                                        </div>
                                        <div className="form-group my-1">
                                            <textarea name="" id="" className="form-control" rows="4" placeholder="Please write a remark or choose from above" value={remark ? remark : ""} onChange={(e) => {
                                                setRemark(e.target.value)
                                            }}></textarea>
                                        </div>
                                        <div className="form-group mt-4">
                                            <button className="btn btn-primary px-4" disabled={remark ? false : true} onClick={(e) => {
                                                e.preventDefault();
                                                updateRemark()
                                            }}> Submit Remark</button>
                                        </div>
                                    </form>
                                    : null}
                            </div>

                            <div className="col-md-6 pl-0 mt-5">
                                {allowedFunc.includes(configs.functionList['kyc:update_user_kyc_status']) ?
                                    <form action="">
                                        <div className="form-group my-1">
                                            <label className="text-secondary">Internal Remark</label>
                                            <textarea name="" id="" className="form-control" rows="4" placeholder="Please write a remark for internal purpose" value={adminRemark ? adminRemark : ""} onChange={(e) => {
                                                setAdminRemark(e.target.value)
                                            }}></textarea>
                                        </div>
                                        <div className="form-group mt-4">
                                            <button className="btn btn-primary px-4" disabled={adminRemark ? false : true} onClick={(e) => {
                                                e.preventDefault();
                                                updateRemark()
                                            }}> Submit Remark</button>
                                        </div>
                                    </form>
                                    : null}
                            </div>
                        </div>
                    </div>
                </div>
                <ToastPage />
                <ErrorModalPage {...{ error: error }} />
            </div >
                : <div className="loader"></div>
            }
        </>
    )
}