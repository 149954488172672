import axios from "axios";
import { configs } from "../../../assets/Config";
import FormData from 'form-data';

// get all GSec list
export function getAllGSecs(token, getDeleted) {
    return new Promise(async (resolve, reject) => {
        try {
            let url = 'gsec'
            if (getDeleted) {
                url = '/gsec/deleted'
            }

            var config = {
                method: 'get',
                url: `${process.env.REACT_APP_BASEURL}/admin/${url}`,
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            };

            let res = await axios(config)
            resolve(res)
        } catch (err) {
            resolve(err?.response)
        }
    })
}

// get GSec data by id
export function getGSecById(token, GSecId) {
    return new Promise(async (resolve, reject) => {
        try {

            var config = {
                method: 'get',
                url: `${process.env.REACT_APP_BASEURL}/admin/gsec/${GSecId}`,
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            };

            let res = await axios(config)
            resolve(res)
        } catch (err) {
            resolve(err?.response)
        }
    })
}

// create or update GSec
export function createUpdateGSec(token, name, isin, rate_of_return, maturity_period, disabletrading, description, snapshot, faqs, key_1, value_1, key_2, value_2, logo, editGSec, GSecId) {
    return new Promise(async (resolve, reject) => {
        try {
            var data = new FormData();
            data.append("name", name)
            data.append("isin", isin)
            data.append("rate_of_return", rate_of_return)
            data.append("maturity_period", maturity_period)
            data.append("disabletrading", disabletrading)
            data.append("description", description)
            data.append("snapshot", snapshot)
            data.append("faqs", faqs)
            data.append("key_1", key_1)
            data.append("value_1", value_1)
            data.append("key_2", key_2)
            data.append("value_2", value_2)
            data.append("logo", logo)

            let urlE = 'create'

            if (editGSec) {
                urlE = 'update'
                data.append('gsec_id', GSecId)
            }

            var config = {
                method: 'POST',
                url: `${process.env.REACT_APP_BASEURL}/admin/gsec/${urlE}`,
                headers: {
                    'Authorization': `Bearer ${token}`
                },
                data: data
            };

            let res = await axios(config)
            resolve(res)
        } catch (err) {
            resolve(err?.response)
        }
    })
}

// delete company
export function deleteGSec(token, GSecId) {
    return new Promise(async (resolve, reject) => {
        try {

            var data = {
                company_id: GSecId
            }
            var config = {
                method: 'DELETE',
                url: `${process.env.REACT_APP_BASEURL}/admin/gsec/delete`,
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json'
                },
                data: data
            };

            let res = await axios(config)
            resolve(res)
        } catch (err) {
            resolve(err?.response)
        }
    })
}

// restore company
export function restoreGSec(token, GSecId) {
    return new Promise(async (resolve, reject) => {
        try {

            var data = {
                company_id: GSecId
            }
            var config = {
                method: 'PATCH',
                url: `${process.env.REACT_APP_BASEURL}/admin/gsec/restore`,
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json'
                },
                data: data
            };

            let res = await axios(config)
            resolve(res)
        } catch (err) {
            resolve(err?.response)
        }
    })
}
