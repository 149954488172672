import { DataGrid, GridToolbar } from '@material-ui/data-grid';
import React, { useEffect } from 'react';
import { configs } from '../../../assets/Config';
import { useLogin } from '../../../services/contexts/AdminLoginContext';
import LeftBarPage from '../../includes/dashboard/LeftBarPage';
import TopBarPage from '../../includes/dashboard/TopBarPage';
import '../../../assets/styles/NM.css'
import { useNMContext } from '../../../services/contexts/NMContext';
import ToastPage from '../../includes/ToastPage';
import { Link } from 'react-router-dom';
import { useHistory } from 'react-router';

export default function NMTPage() {

    const { nmTempFunction, getNotificationTemplateFunction, setTemplateId, setEmailTemplate, setMobileTemplate, setBellTemplate, setWhatsappTemplate } = useNMContext()
    const { setCurrModule, setShowSuccess, allowedFunc } = useLogin()

    const history = useHistory()

    useEffect(() => {
        (async () => {
            try {
                setCurrModule(configs.modules.mnt)
                if (allowedFunc.includes(configs.functionList['notification_management:notification_management'])) {
                    getNotificationTemplateFunction()
                } else {
                    return history.goBack()
                }
            } catch (error) {

            }
        })()
    }, [])


    const columns = [{ field: "id", headerName: "Function ID", minWidth: 100, flex: 0.5, hide: true, filterable: false },
    { field: "name", headerName: "Function", minWidth: 100, flex: 0.3, cellClassName: "break-text" },
    { field: "receiver", headerName: "Reciever", minWidth: 100, flex: 0.3, cellClassName: "break-text" },
    { field: "description", headerName: "description", minWidth: 100, flex: 0.5, cellClassName: "break-text" },
    {
        field: "mobile", headerName: "Mobile", minWidth: 30, flex: 0.2, cellClassName: "break-text text-primary", renderCell: (cellValues) => {
            return <Link to={`/mobileTemplateManagement/${cellValues.row.id}`} onClick={(e) => {
                setMobileTemplate("")
                setTemplateId(cellValues.row.id)
            }}>Mobile Template</Link>
        }
    },
    {
        field: "email", headerName: "Email", minWidth: 30, flex: 0.2, cellClassName: "break-text text-danger", renderCell: (cellValues) => {
            return <Link to={`/emailTemplateManagement/${cellValues.row.id}`} onClick={(e) => {
                setEmailTemplate("")
                setTemplateId(cellValues.row.id)
            }}>Email Template</Link>
        }
    },
    {
        field: "bell", headerName: "Bell", minWidth: 30, flex: 0.2, cellClassName: "break-text text-danger", renderCell: (cellValues) => {
            return <Link to={`/bellTemplateManagement/${cellValues.row.id}`} onClick={(e) => {
                setBellTemplate("")
                setTemplateId(cellValues.row.id)
            }}>Bell Template</Link>
        }
    },
    {
        field: "whatsapp", headerName: "WhatsApp", minWidth: 30, flex: 0.2, cellClassName: "break-text text-danger", renderCell: (cellValues) => {
            return <Link to={`/whatsappTemplateManagement/${cellValues.row.id}`} onClick={(e) => {
                setWhatsappTemplate("")
                setTemplateId(cellValues.row.id)
            }}>WhatsApp Template</Link>
        }
    }
    ]


    // notification management main ui code
    return (
        <>
            <TopBarPage />
            <div style={{ display: "flex" }}>
                <LeftBarPage />
                <div id='nmContainer' className="nm-container">
                    <div style={{ width: "100%" }}>
                        <div className="row d-flex align-items-center mt-2 ml-2 mb-4 mr-3">
                            <div className="col-md-7 text-left">
                                <p className="h4 text-custom-grey">Notification Template Management</p>
                            </div>
                            <div className="col-md-5 text-left text-md-right">
                                <Link to="/notificationManagement"><i className="fas fa-id-card-alt mr-2"></i>Manage Notifications</Link>
                            </div>
                        </div>
                        <div className="nm-role-table">
                            {nmTempFunction ? <DataGrid
                                rows={nmTempFunction}
                                headerHeight={40}
                                rowHeight={30}
                                columns={columns}
                                resizable={true}
                                autoPageSize
                                pagination
                                components={{
                                    Toolbar: GridToolbar,
                                }} />
                                : <div className="loader"></div>}
                        </div>
                    </div>
                </div>
                <ToastPage />
            </div>
        </>
    )
}