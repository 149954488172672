import { DataGrid, GridToolbar } from '@material-ui/data-grid';
import React, { useEffect, useState } from 'react';
import { configs, formatCurrency } from '../../../assets/Config';
import { useLogin } from '../../../services/contexts/AdminLoginContext';
import LeftBarPage from '../../includes/dashboard/LeftBarPage';
import TopBarPage from '../../includes/dashboard/TopBarPage';
import '../../../assets/styles/NM.css'
import { useNMContext } from '../../../services/contexts/NMContext';
import ToastPage from '../../includes/ToastPage';
import { Link } from 'react-router-dom';
import { useHistory } from 'react-router';
import { Modal } from '@material-ui/core';
import { useKYCContext } from '../../../services/contexts/KYCContext';
import { useBuyOrderContext } from '../../../services/contexts/BuyOrdersContext';
import { Autocomplete } from '@material-ui/lab';
import { TextField } from "@material-ui/core";
import { getDashboardData, postDashboardData } from '../../../services/apis/CustomerDashboardManagementApi';


export default function CustomerDashboardPage() {

    const { setCurrModule, allowedFunc, token, setShowSuccess, logout } = useLogin()
    const { getCompanyList } = useBuyOrderContext()

    const [companyList, setCompanyList] = useState("")


    const [spotlight, setSpotLight] = useState([])
    const [description, setDescription] = useState("")
    const [spotlightError, setSpotLightError] = useState("")
    const [startup, setStartup] = useState([])
    const [startupError, setStartupError] = useState("")
    const [popular, setPopular] = useState([])
    const [popularError, setPopularError] = useState("")
    const [dy, setDY] = useState([])
    const [dyError, setDYError] = useState("")
    const [research, setResearch] = useState([])
    const [researchError, setResearchError] = useState("")
    const [altiusOverview, setaltiusOverview] = useState([])
    const [altiusOverviewError, setaltiusOverviewError] = useState("")
    const [preIPOInvest, setpreIPOInvest] = useState([])
    const [preIPOInvestError, setpreIPOInvestError] = useState("")

    const history = useHistory()


    useEffect(() => {
        (async () => {
            try {
                setCurrModule(configs.modules.custDashMng)
                if (allowedFunc.includes(configs.functionList['dashboard_management:manage_dashboard'])) {
                    (async () => {
                        let response = await getCompanyList()
                        if (response.success) {
                            setCompanyList(response.data)
                        } else {

                        }
                        response = await getDashboardData(token)
                        if (response && response.status === 200) {
                            if (response.data && response.data.spotlight && response.data.spotlight.length > 0) {
                                setSpotLight(response.data.spotlight[0])
                                setDescription(response.data.spotlight[0].description)
                            }
                            if (response.data && response.data.startup_unicorns && response.data.startup_unicorns.length > 0) {
                                setStartup(response.data.startup_unicorns)
                            }
                            if (response.data && response.data.popular_companies && response.data.popular_companies.length > 0) {
                                setPopular(response.data.popular_companies)
                            }
                            if (response.data && response.data.companies_with_dividend_yield && response.data.companies_with_dividend_yield.length > 0) {
                                setDY(response.data.companies_with_dividend_yield)
                            }
                            if (response.data && response.data.research_area && response.data.research_area.length > 0) {
                                setResearch(response.data.research_area)
                            }
                            if (response.data && response.data.altius_overview && response.data.altius_overview.length > 0) {
                                setaltiusOverview(response.data.altius_overview)
                            }
                            if (response.data && response.data.pre_ipo_investing && response.data.pre_ipo_investing.length > 0) {
                                setpreIPOInvest(response.data.pre_ipo_investing)
                            }
                        } else if (response && response.status === 401) {
                            logout();
                        } else {
                        }
                    })()
                } else {
                    return history.goBack()
                }
            } catch (error) {
                console.error(error)
            }
        })()
    }, [])

    const handleSpotlight = (e, value) => {
        try {
            setSpotLight(value);
        } catch (error) {

        }
    }
    const handleStartup = (e, value) => {
        try {
            setStartup(value)
        } catch (error) {

        }
    }
    const handlePopular = (e, value) => {
        try {
            setPopular(value)
        } catch (error) {

        }
    }
    const handleDY = (e, value) => {
        try {
            setDY(value)
        } catch (error) {

        }
    }

    async function saveData(type, btnId) {
        try {

            document.getElementById(btnId).classList.toggle("d-none")
            document.getElementById("saveDataBtnLoader").classList.toggle("d-none")

            let response;
            if (type == "spotlight") {
                if (spotlight && spotlight.id) {
                    response = await postDashboardData(token, type, [{
                        value: spotlight.id,
                        description: description
                    }])
                    if (response && response.status === 200) {
                        setSpotLightError("");
                        setShowSuccess(true)
                    } else if (response && response.status === 401) {
                        logout();
                    } else {
                        setSpotLightError(response.data.error);
                    }
                } else {
                    setSpotLightError("Please select a company to show as Spotlight.")
                }
            } else if (type == "research_area") {
                let allOk = true;
                research.forEach(element => {
                    if (!element.value || !element.description) {
                        allOk = false;
                    }
                })
                if ((research.length === 4) && allOk) {
                    response = await postDashboardData(token, type, research)
                    if (response && response.status === 200) {
                        setResearchError("");
                        setShowSuccess(true)
                    } else if (response && response.status === 401) {
                        logout();
                    } else {
                        setResearchError(response.data.error);
                    }

                } else {
                    setResearchError("Please provide 4 links and their label for research area.")
                }

            } else if (type == "startup_unicorns") {
                if (startup.length === 3) {
                    let temp = []
                    startup.forEach((element) => {
                        temp.push({
                            value: element.id,
                            description: null
                        })
                    })
                    response = await postDashboardData(token, type, temp)
                    if (response && response.status === 200) {
                        setStartupError("");
                        setShowSuccess(true)
                    } else if (response && response.status === 401) {
                        logout();
                    } else {
                        setStartupError(response.data.error);
                    }
                } else {
                    setStartupError("Please select 3 companies to show as Startup Unicorn.")
                }
            }
            else if (type == "popular_companies") {
                if (popular.length === 3) {
                    let temp = []
                    popular.forEach((element) => {
                        temp.push({
                            value: element.id,
                            description: null
                        })
                    })
                    response = await postDashboardData(token, type, temp)
                    if (response && response.status === 200) {
                        setPopularError("");
                        setShowSuccess(true)
                    } else if (response && response.status === 401) {
                        logout();
                    } else {
                        setPopularError(response.data.error);
                    }
                } else {
                    setPopularError("Please select 3 companies to show as Popular Companies.")
                }
            }
            else if (type == "companies_with_dividend_yield") {
                if (dy.length === 3) {
                    let temp = []
                    dy.forEach((element) => {
                        temp.push({
                            value: element.id,
                            description: null
                        })
                    })
                    response = await postDashboardData(token, type, temp)
                    if (response && response.status === 200) {
                        setDYError("");
                        setShowSuccess(true)
                    } else if (response && response.status === 401) {
                        logout();
                    } else {
                        setDYError(response.data.error);
                    }
                } else {
                    setDYError("Please select 3 companies to show as Companies with Dividend Yield.")
                }
            }
            else if (type == "altius_overview") {
                let allOk = true;
                altiusOverview.forEach(element => {
                    if (!element.value || !element.description) {
                        allOk = false;
                    }
                })
                if ((altiusOverview.length >= 3 && altiusOverview.length <= 7) && allOk) {
                    response = await postDashboardData(token, type, altiusOverview)
                    if (response && response.status === 200) {
                        setaltiusOverviewError("");
                        setShowSuccess(true)
                    } else if (response && response.status === 401) {
                        logout();
                    } else {
                        setaltiusOverviewError(response.data.error);
                    }

                } else {
                    setaltiusOverviewError("Please provide minimum 3 links and their label for Altius Overview.")
                }

            }
            else if (type == "pre_ipo_investing") {
                let allOk = true;
                preIPOInvest.forEach(element => {
                    if (!element.value || !element.description) {
                        allOk = false;
                    }
                })
                if ((preIPOInvest.length >= 3 && preIPOInvest.length <= 7) && allOk) {
                    response = await postDashboardData(token, type, preIPOInvest)
                    if (response && response.status === 200) {
                        setpreIPOInvestError("");
                        setShowSuccess(true)
                    } else if (response && response.status === 401) {
                        logout();
                    } else {
                        setpreIPOInvestError(response.data.error);
                    }

                } else {
                    setpreIPOInvestError("Please provide minimum 3 links and their label for PRE-IPO Investnment.")
                }

            }

            document.getElementById(btnId).classList.toggle("d-none")
            document.getElementById("saveDataBtnLoader").classList.toggle("d-none")
        } catch (error) {
            console.error(error)
        }
    }


    // customer dashboard management main ui code
    return (
        <>
            <TopBarPage />
            <div style={{ display: "flex" }}>
                <LeftBarPage />
                <div id='nmContainer' className="smPO-container">
                    <div style={{ width: "100%" }}>
                        <div className="row d-flex align-items-center mt-2 ml-2 mb-4 mr-3">
                            <div className="col-lg-6 text-left">
                                <p className="h4 text-custom-grey">Customer Dashboard Management</p>
                            </div>
                        </div>
                        <div className="smPOI-container">
                            <div className='row'>
                                <div className='col-md-4 col-12 mt-md-0'>
                                    <form>
                                        <div className="form-group">
                                            <label htmlFor="companies" className='font-weight-bold'>Select Spotlight</label>
                                            {companyList ? <Autocomplete
                                                value={spotlight ? spotlight : null}
                                                disableCloseOnSelect={false}
                                                filterSelectedOptions
                                                className="f-12"
                                                id="companyIP"
                                                placeholder="Compnay Name"
                                                getOptionLabel={option => option.name}
                                                onChange={handleSpotlight}
                                                options={companyList}
                                                renderInput={(params) => <TextField {...params} placeholder="Company Name" className="f-12" />}
                                            /> : null}
                                            <label htmlFor="companies" className='m-0 mt-3'>Additional Message</label>
                                            <textarea name="" id="" className="form-control" rows="4" placeholder="Additional message for Spotlight Company" value={description ? description : ""} onChange={(e) => {
                                                setDescription(e.target.value)
                                            }}></textarea>
                                        </div>
                                        <div className="form-check p-0">
                                            <p className="text-danger m-0" id="generateSocialMediaImageError">{spotlightError}</p>
                                        </div>

                                        <div className=" mt-1">
                                            <button id="spotLightBtn" className="btn btn-primary" onClick={(e) => {
                                                e.preventDefault()
                                                saveData("spotlight", "spotLightBtn")
                                            }}>Save</button>
                                            <div id="saveDataBtnLoader" className='animBtn d-none'><div className='gSignIn-Anim'></div></div>
                                        </div>
                                    </form>
                                </div>
                                <div className='col-md-8 col-12 mt-md-0 mt-5'>
                                    <form>
                                        <div>
                                            <label htmlFor="title" className='font-weight-bold'>Research Links</label>
                                        </div>
                                        <div className="row ">
                                            <div className='col-12 f-12'>
                                                <label htmlFor="title">Link 1</label>
                                            </div>
                                            <div className='col-md-6 col-12'>
                                                <input type="text" className="form-control mb-1 f-10" name="title" value={research && research[0] ? research[0].description : ""} placeholder="Display Text" onChange={(e) => {
                                                    let temp = [...research];
                                                    temp[0] = temp[0] ? temp[0] : {}
                                                    temp[0].description = e.target.value;
                                                    setResearch(temp)
                                                }} />
                                            </div>
                                            <div className='col-md-6 col-12'>
                                                <input type="text" className="form-control mb-1 f-10" name="title" value={research && research[0] ? research[0].value : ""} placeholder="Link" onChange={(e) => {
                                                    let temp = [...research];
                                                    temp[0] = temp[0] ? temp[0] : {}
                                                    temp[0].value = e.target.value;
                                                    setResearch(temp)
                                                }} />
                                            </div>
                                        </div>
                                        <div className="row ">
                                            <div className='col-12 f-12'>
                                                <label htmlFor="title">Link 2</label>
                                            </div>
                                            <div className='col-md-6 col-12'>
                                                <input type="text" className="form-control mb-1 f-10" name="title" value={research && research[1] ? research[1].description : ""} placeholder="Display Text" onChange={(e) => {
                                                    let temp = [...research];
                                                    temp[1] = temp[1] ? temp[1] : {}
                                                    temp[1].description = e.target.value;
                                                    setResearch(temp)
                                                }} />
                                            </div>
                                            <div className='col-md-6 col-12'>
                                                <input type="text" className="form-control mb-1 f-10" name="title" value={research && research[1] ? research[1].value : ""} placeholder="Link" onChange={(e) => {
                                                    let temp = [...research];
                                                    temp[1] = temp[1] ? temp[1] : {}
                                                    temp[1].value = e.target.value;
                                                    setResearch(temp)
                                                }} />
                                            </div>
                                        </div>
                                        <div className="row ">
                                            <div className='col-12 f-12'>
                                                <label htmlFor="title">Link 3</label>
                                            </div>
                                            <div className='col-md-6 col-12'>
                                                <input type="text" className="form-control mb-1 f-10" name="title" value={research && research[2] ? research[2].description : ""} placeholder="Display Text" onChange={(e) => {
                                                    let temp = [...research];
                                                    temp[2] = temp[2] ? temp[2] : {}
                                                    temp[2].description = e.target.value;
                                                    setResearch(temp)
                                                }} />
                                            </div>
                                            <div className='col-md-6 col-12'>
                                                <input type="text" className="form-control mb-1 f-10" name="title" value={research && research[2] ? research[2].value : ""} placeholder="Link" onChange={(e) => {
                                                    let temp = [...research];
                                                    temp[2] = temp[2] ? temp[2] : {}
                                                    temp[2].value = e.target.value;
                                                    setResearch(temp)
                                                }} />
                                            </div>
                                        </div>
                                        <div className="row ">
                                            <div className='col-12 f-12'>
                                                <label htmlFor="title">Link 4</label>
                                            </div>
                                            <div className='col-md-6 col-12'>
                                                <input type="text" className="form-control mb-1 f-10" name="title" value={research && research[3] ? research[3].description : ""} placeholder="Display Text" onChange={(e) => {
                                                    let temp = [...research];
                                                    temp[3] = temp[3] ? temp[3] : {}
                                                    temp[3].description = e.target.value;
                                                    setResearch(temp)
                                                }} />
                                            </div>
                                            <div className='col-md-6 col-12'>
                                                <input type="text" className="form-control mb-1 f-10" name="title" value={research && research[3] ? research[3].value : ""} placeholder="Link" onChange={(e) => {
                                                    let temp = [...research];
                                                    temp[3] = temp[3] ? temp[3] : {}
                                                    temp[3].value = e.target.value;
                                                    setResearch(temp)
                                                }} />
                                            </div>
                                        </div>
                                        <div className="form-check p-0">
                                            <p className="text-danger m-0" id="generateSocialMediaImageError">{researchError}</p>
                                        </div>

                                        <div className=" mt-1">
                                            <button id="research_areaBtn" className="btn btn-primary" onClick={(e) => {
                                                e.preventDefault()
                                                saveData("research_area", "research_areaBtn")
                                            }}>Save</button>
                                            <div id="saveDataBtnLoader" className='animBtn d-none'><div className='gSignIn-Anim'></div></div>
                                        </div>
                                    </form>
                                </div>

                            </div>
                            <div className='row my-4'>
                                <div className='col-md-4 col-12 mt-md-0 mt-5'>
                                    <form>
                                        <div className="form-group">
                                            <label htmlFor="companies" className='font-weight-bold'>Select Startup Unicorns</label>
                                            {companyList ? <Autocomplete
                                                value={startup ? startup : null}
                                                multiple={true}
                                                disableCloseOnSelect={true}
                                                filterSelectedOptions
                                                className="f-12"
                                                id="companyIP"
                                                placeholder="Compnay Name"
                                                getOptionLabel={option => option.name}
                                                onChange={handleStartup}
                                                options={companyList}
                                                renderInput={(params) => <TextField {...params} placeholder="Company Name" className="f-12" />}
                                            /> : null}
                                        </div>
                                        <div className="form-check p-0">
                                            <p className="text-danger m-0" id="generateSocialMediaImageError">{startupError}</p>
                                        </div>

                                        <div className=" mt-1">
                                            <button id="startup_unicornsBtn" className="btn btn-primary" onClick={(e) => {
                                                e.preventDefault()
                                                saveData("startup_unicorns", "startup_unicornsBtn")
                                            }}>Save</button>
                                            <div id="saveDataBtnLoader" className='animBtn d-none'><div className='gSignIn-Anim'></div></div>
                                        </div>
                                    </form>
                                </div>
                                <div className='col-md-4 col-12 mt-md-0 mt-5'>
                                    <form>
                                        <div className="form-group">
                                            <label htmlFor="companies" className='font-weight-bold'>Select Popular Companies</label>
                                            {companyList ? <Autocomplete
                                                value={popular ? popular : null}
                                                multiple={true}
                                                disableCloseOnSelect={true}
                                                filterSelectedOptions
                                                className="f-12"
                                                id="companyIP"
                                                placeholder="Compnay Name"
                                                getOptionLabel={option => option.name}
                                                onChange={handlePopular}
                                                options={companyList}
                                                renderInput={(params) => <TextField {...params} placeholder="Company Name" className="f-12" />}
                                            /> : null}
                                        </div>
                                        <div className="form-check p-0">
                                            <p className="text-danger m-0" id="generateSocialMediaImageError">{popularError}</p>
                                        </div>

                                        <div className=" mt-1">
                                            <button id="popular_companiesBtn" className="btn btn-primary" onClick={(e) => {
                                                e.preventDefault()
                                                saveData("popular_companies", "popular_companiesBtn")
                                            }}>Save</button>
                                            <div id="saveDataBtnLoader" className='animBtn d-none'><div className='gSignIn-Anim'></div></div>
                                        </div>
                                    </form>
                                </div>
                                <div className='col-md-4 col-12 mt-md-0 mt-5'>
                                    <form>
                                        <div className="form-group">
                                            <label htmlFor="companies" className='font-weight-bold'>Select Companies with Dividend Yield</label>
                                            {companyList ? <Autocomplete
                                                value={dy ? dy : null}
                                                multiple={true}
                                                disableCloseOnSelect={true}
                                                filterSelectedOptions
                                                className="f-12"
                                                id="companyIP"
                                                placeholder="Compnay Name"
                                                getOptionLabel={option => option.name}
                                                onChange={handleDY}
                                                options={companyList}
                                                renderInput={(params) => <TextField {...params} placeholder="Company Name" className="f-12" />}
                                            /> : null}
                                        </div>
                                        <div className="form-check p-0">
                                            <p className="text-danger m-0" id="generateSocialMediaImageError">{dyError}</p>
                                        </div>

                                        <div className=" mt-1">
                                            <button id="companies_with_dividend_yieldBtn" className="btn btn-primary" onClick={(e) => {
                                                e.preventDefault()
                                                saveData("companies_with_dividend_yield", "companies_with_dividend_yieldBtn")
                                            }}>Save</button>
                                            <div id="saveDataBtnLoader" className='animBtn d-none'><div className='gSignIn-Anim'></div></div>
                                        </div>
                                    </form>
                                </div>
                                <div className='col-12 mt-5'>
                                    <form>
                                        <div>
                                            <label htmlFor="title" className='font-weight-bold'>Altius Overview Links</label>
                                        </div>
                                        <div className="row ">
                                            <div className='col-12 f-12'>
                                                <label htmlFor="title">Link 1</label>
                                            </div>
                                            <div className='col-md-6 col-12'>
                                                <input type="text" className="form-control mb-1 f-10" name="title" value={altiusOverview && altiusOverview[0] ? altiusOverview[0].description : ""} placeholder="Display Text" onChange={(e) => {
                                                    let temp = [...altiusOverview];
                                                    temp[0] = temp[0] ? temp[0] : {}
                                                    temp[0].description = e.target.value;
                                                    setaltiusOverview(temp)
                                                }} />
                                            </div>
                                            <div className='col-md-6 col-12'>
                                                <input type="text" className="form-control mb-1 f-10" name="title" value={altiusOverview && altiusOverview[0] ? altiusOverview[0].value : ""} placeholder="Link" onChange={(e) => {
                                                    let temp = [...altiusOverview];
                                                    temp[0] = temp[0] ? temp[0] : {}
                                                    temp[0].value = e.target.value;
                                                    setaltiusOverview(temp)
                                                }} />
                                            </div>
                                        </div>
                                        <div className="row ">
                                            <div className='col-12 f-12'>
                                                <label htmlFor="title">Link 2</label>
                                            </div>
                                            <div className='col-md-6 col-12'>
                                                <input type="text" className="form-control mb-1 f-10" name="title" value={altiusOverview && altiusOverview[1] ? altiusOverview[1].description : ""} placeholder="Display Text" onChange={(e) => {
                                                    let temp = [...altiusOverview];
                                                    temp[1] = temp[1] ? temp[1] : {}
                                                    temp[1].description = e.target.value;
                                                    setaltiusOverview(temp)
                                                }} />
                                            </div>
                                            <div className='col-md-6 col-12'>
                                                <input type="text" className="form-control mb-1 f-10" name="title" value={altiusOverview && altiusOverview[1] ? altiusOverview[1].value : ""} placeholder="Link" onChange={(e) => {
                                                    let temp = [...altiusOverview];
                                                    temp[1] = temp[1] ? temp[1] : {}
                                                    temp[1].value = e.target.value;
                                                    setaltiusOverview(temp)
                                                }} />
                                            </div>
                                        </div>
                                        <div className="row ">
                                            <div className='col-12 f-12'>
                                                <label htmlFor="title">Link 3</label>
                                            </div>
                                            <div className='col-md-6 col-12'>
                                                <input type="text" className="form-control mb-1 f-10" name="title" value={altiusOverview && altiusOverview[2] ? altiusOverview[2].description : ""} placeholder="Display Text" onChange={(e) => {
                                                    let temp = [...altiusOverview];
                                                    temp[2] = temp[2] ? temp[2] : {}
                                                    temp[2].description = e.target.value;
                                                    setaltiusOverview(temp)
                                                }} />
                                            </div>
                                            <div className='col-md-6 col-12'>
                                                <input type="text" className="form-control mb-1 f-10" name="title" value={altiusOverview && altiusOverview[2] ? altiusOverview[2].value : ""} placeholder="Link" onChange={(e) => {
                                                    let temp = [...altiusOverview];
                                                    temp[2] = temp[2] ? temp[2] : {}
                                                    temp[2].value = e.target.value;
                                                    setaltiusOverview(temp)
                                                }} />
                                            </div>
                                        </div>
                                        <div className="row ">
                                            <div className='col-12 f-12'>
                                                <label htmlFor="title">Link 4</label>
                                            </div>
                                            <div className='col-md-6 col-12'>
                                                <input type="text" className="form-control mb-1 f-10" name="title" value={altiusOverview && altiusOverview[3] ? altiusOverview[3].description : ""} placeholder="Display Text" onChange={(e) => {
                                                    let temp = [...altiusOverview];
                                                    temp[3] = temp[3] ? temp[3] : {}
                                                    temp[3].description = e.target.value;
                                                    setaltiusOverview(temp)
                                                }} />
                                            </div>
                                            <div className='col-md-6 col-12'>
                                                <input type="text" className="form-control mb-1 f-10" name="title" value={altiusOverview && altiusOverview[3] ? altiusOverview[3].value : ""} placeholder="Link" onChange={(e) => {
                                                    let temp = [...altiusOverview];
                                                    temp[3] = temp[3] ? temp[3] : {}
                                                    temp[3].value = e.target.value;
                                                    setaltiusOverview(temp)
                                                }} />
                                            </div>
                                        </div>
                                        <div className="row ">
                                            <div className='col-12 f-12'>
                                                <label htmlFor="title">Link 5</label>
                                            </div>
                                            <div className='col-md-6 col-12'>
                                                <input type="text" className="form-control mb-1 f-10" name="title" value={altiusOverview && altiusOverview[4] ? altiusOverview[4].description : ""} placeholder="Display Text" onChange={(e) => {
                                                    let temp = [...altiusOverview];
                                                    temp[4] = temp[4] ? temp[4] : {}
                                                    temp[4].description = e.target.value;
                                                    setaltiusOverview(temp)
                                                }} />
                                            </div>
                                            <div className='col-md-6 col-12'>
                                                <input type="text" className="form-control mb-1 f-10" name="title" value={altiusOverview && altiusOverview[4] ? altiusOverview[4].value : ""} placeholder="Link" onChange={(e) => {
                                                    let temp = [...altiusOverview];
                                                    temp[4] = temp[4] ? temp[4] : {}
                                                    temp[4].value = e.target.value;
                                                    setaltiusOverview(temp)
                                                }} />
                                            </div>
                                        </div>
                                        <div className="row ">
                                            <div className='col-12 f-12'>
                                                <label htmlFor="title">Link 6</label>
                                            </div>
                                            <div className='col-md-6 col-12'>
                                                <input type="text" className="form-control mb-1 f-10" name="title" value={altiusOverview && altiusOverview[5] ? altiusOverview[5].description : ""} placeholder="Display Text" onChange={(e) => {
                                                    let temp = [...altiusOverview];
                                                    temp[5] = temp[5] ? temp[5] : {}
                                                    temp[5].description = e.target.value;
                                                    setaltiusOverview(temp)
                                                }} />
                                            </div>
                                            <div className='col-md-6 col-12'>
                                                <input type="text" className="form-control mb-1 f-10" name="title" value={altiusOverview && altiusOverview[5] ? altiusOverview[5].value : ""} placeholder="Link" onChange={(e) => {
                                                    let temp = [...altiusOverview];
                                                    temp[5] = temp[5] ? temp[5] : {}
                                                    temp[5].value = e.target.value;
                                                    setaltiusOverview(temp)
                                                }} />
                                            </div>
                                        </div>
                                        <div className="row ">
                                            <div className='col-12 f-12'>
                                                <label htmlFor="title">Link 7</label>
                                            </div>
                                            <div className='col-md-6 col-12'>
                                                <input type="text" className="form-control mb-1 f-10" name="title" value={altiusOverview && altiusOverview[6] ? altiusOverview[6].description : ""} placeholder="Display Text" onChange={(e) => {
                                                    let temp = [...altiusOverview];
                                                    temp[6] = temp[6] ? temp[6] : {}
                                                    temp[6].description = e.target.value;
                                                    setaltiusOverview(temp)
                                                }} />
                                            </div>
                                            <div className='col-md-6 col-12'>
                                                <input type="text" className="form-control mb-1 f-10" name="title" value={altiusOverview && altiusOverview[6] ? altiusOverview[6].value : ""} placeholder="Link" onChange={(e) => {
                                                    let temp = [...altiusOverview];
                                                    temp[6] = temp[6] ? temp[6] : {}
                                                    temp[6].value = e.target.value;
                                                    setaltiusOverview(temp)
                                                }} />
                                            </div>
                                        </div>
                                        <div className="form-check p-0">
                                            <p className="text-danger m-0" id="generateSocialMediaImageError">{altiusOverviewError}</p>
                                        </div>

                                        <div className=" mt-1">
                                            <button id="altius_overviewBtn" className="btn btn-primary" onClick={(e) => {
                                                e.preventDefault()
                                                saveData("altius_overview", "altius_overviewBtn")
                                            }}>Save</button>
                                            <div id="saveDataBtnLoader" className='animBtn d-none'><div className='gSignIn-Anim'></div></div>
                                        </div>
                                    </form>
                                </div>
                                <div className='col-12 mt-5'>
                                    <form>
                                        <div>
                                            <label htmlFor="title" className='font-weight-bold'>Pre-IPO Investment Links</label>
                                        </div>
                                        <div className="row ">
                                            <div className='col-12 f-12'>
                                                <label htmlFor="title">Link 1</label>
                                            </div>
                                            <div className='col-md-6 col-12'>
                                                <input type="text" className="form-control mb-1 f-10" name="title" value={preIPOInvest && preIPOInvest[0] ? preIPOInvest[0].description : ""} placeholder="Display Text" onChange={(e) => {
                                                    let temp = [...preIPOInvest];
                                                    temp[0] = temp[0] ? temp[0] : {}
                                                    temp[0].description = e.target.value;
                                                    setpreIPOInvest(temp)
                                                }} />
                                            </div>
                                            <div className='col-md-6 col-12'>
                                                <input type="text" className="form-control mb-1 f-10" name="title" value={preIPOInvest && preIPOInvest[0] ? preIPOInvest[0].value : ""} placeholder="Link" onChange={(e) => {
                                                    let temp = [...preIPOInvest];
                                                    temp[0] = temp[0] ? temp[0] : {}
                                                    temp[0].value = e.target.value;
                                                    setpreIPOInvest(temp)
                                                }} />
                                            </div>
                                        </div>
                                        <div className="row ">
                                            <div className='col-12 f-12'>
                                                <label htmlFor="title">Link 2</label>
                                            </div>
                                            <div className='col-md-6 col-12'>
                                                <input type="text" className="form-control mb-1 f-10" name="title" value={preIPOInvest && preIPOInvest[1] ? preIPOInvest[1].description : ""} placeholder="Display Text" onChange={(e) => {
                                                    let temp = [...preIPOInvest];
                                                    temp[1] = temp[1] ? temp[1] : {}
                                                    temp[1].description = e.target.value;
                                                    setpreIPOInvest(temp)
                                                }} />
                                            </div>
                                            <div className='col-md-6 col-12'>
                                                <input type="text" className="form-control mb-1 f-10" name="title" value={preIPOInvest && preIPOInvest[1] ? preIPOInvest[1].value : ""} placeholder="Link" onChange={(e) => {
                                                    let temp = [...preIPOInvest];
                                                    temp[1] = temp[1] ? temp[1] : {}
                                                    temp[1].value = e.target.value;
                                                    setpreIPOInvest(temp)
                                                }} />
                                            </div>
                                        </div>
                                        <div className="row ">
                                            <div className='col-12 f-12'>
                                                <label htmlFor="title">Link 3</label>
                                            </div>
                                            <div className='col-md-6 col-12'>
                                                <input type="text" className="form-control mb-1 f-10" name="title" value={preIPOInvest && preIPOInvest[2] ? preIPOInvest[2].description : ""} placeholder="Display Text" onChange={(e) => {
                                                    let temp = [...preIPOInvest];
                                                    temp[2] = temp[2] ? temp[2] : {}
                                                    temp[2].description = e.target.value;
                                                    setpreIPOInvest(temp)
                                                }} />
                                            </div>
                                            <div className='col-md-6 col-12'>
                                                <input type="text" className="form-control mb-1 f-10" name="title" value={preIPOInvest && preIPOInvest[2] ? preIPOInvest[2].value : ""} placeholder="Link" onChange={(e) => {
                                                    let temp = [...preIPOInvest];
                                                    temp[2] = temp[2] ? temp[2] : {}
                                                    temp[2].value = e.target.value;
                                                    setpreIPOInvest(temp)
                                                }} />
                                            </div>
                                        </div>
                                        <div className="row ">
                                            <div className='col-12 f-12'>
                                                <label htmlFor="title">Link 4</label>
                                            </div>
                                            <div className='col-md-6 col-12'>
                                                <input type="text" className="form-control mb-1 f-10" name="title" value={preIPOInvest && preIPOInvest[3] ? preIPOInvest[3].description : ""} placeholder="Display Text" onChange={(e) => {
                                                    let temp = [...preIPOInvest];
                                                    temp[3] = temp[3] ? temp[3] : {}
                                                    temp[3].description = e.target.value;
                                                    setpreIPOInvest(temp)
                                                }} />
                                            </div>
                                            <div className='col-md-6 col-12'>
                                                <input type="text" className="form-control mb-1 f-10" name="title" value={preIPOInvest && preIPOInvest[3] ? preIPOInvest[3].value : ""} placeholder="Link" onChange={(e) => {
                                                    let temp = [...preIPOInvest];
                                                    temp[3] = temp[3] ? temp[3] : {}
                                                    temp[3].value = e.target.value;
                                                    setpreIPOInvest(temp)
                                                }} />
                                            </div>
                                        </div>
                                        <div className="row ">
                                            <div className='col-12 f-12'>
                                                <label htmlFor="title">Link 5</label>
                                            </div>
                                            <div className='col-md-6 col-12'>
                                                <input type="text" className="form-control mb-1 f-10" name="title" value={preIPOInvest && preIPOInvest[4] ? preIPOInvest[4].description : ""} placeholder="Display Text" onChange={(e) => {
                                                    let temp = [...preIPOInvest];
                                                    temp[4] = temp[4] ? temp[4] : {}
                                                    temp[4].description = e.target.value;
                                                    setpreIPOInvest(temp)
                                                }} />
                                            </div>
                                            <div className='col-md-6 col-12'>
                                                <input type="text" className="form-control mb-1 f-10" name="title" value={preIPOInvest && preIPOInvest[4] ? preIPOInvest[4].value : ""} placeholder="Link" onChange={(e) => {
                                                    let temp = [...preIPOInvest];
                                                    temp[4] = temp[4] ? temp[4] : {}
                                                    temp[4].value = e.target.value;
                                                    setpreIPOInvest(temp)
                                                }} />
                                            </div>
                                        </div>
                                        <div className="row ">
                                            <div className='col-12 f-12'>
                                                <label htmlFor="title">Link 6</label>
                                            </div>
                                            <div className='col-md-6 col-12'>
                                                <input type="text" className="form-control mb-1 f-10" name="title" value={preIPOInvest && preIPOInvest[5] ? preIPOInvest[5].description : ""} placeholder="Display Text" onChange={(e) => {
                                                    let temp = [...preIPOInvest];
                                                    temp[5] = temp[5] ? temp[5] : {}
                                                    temp[5].description = e.target.value;
                                                    setpreIPOInvest(temp)
                                                }} />
                                            </div>
                                            <div className='col-md-6 col-12'>
                                                <input type="text" className="form-control mb-1 f-10" name="title" value={preIPOInvest && preIPOInvest[5] ? preIPOInvest[5].value : ""} placeholder="Link" onChange={(e) => {
                                                    let temp = [...preIPOInvest];
                                                    temp[5] = temp[5] ? temp[5] : {}
                                                    temp[5].value = e.target.value;
                                                    setpreIPOInvest(temp)
                                                }} />
                                            </div>
                                        </div>
                                        <div className="row ">
                                            <div className='col-12 f-12'>
                                                <label htmlFor="title">Link 7</label>
                                            </div>
                                            <div className='col-md-6 col-12'>
                                                <input type="text" className="form-control mb-1 f-10" name="title" value={preIPOInvest && preIPOInvest[6] ? preIPOInvest[6].description : ""} placeholder="Display Text" onChange={(e) => {
                                                    let temp = [...preIPOInvest];
                                                    temp[6] = temp[6] ? temp[6] : {}
                                                    temp[6].description = e.target.value;
                                                    setpreIPOInvest(temp)
                                                }} />
                                            </div>
                                            <div className='col-md-6 col-12'>
                                                <input type="text" className="form-control mb-1 f-10" name="title" value={preIPOInvest && preIPOInvest[6] ? preIPOInvest[6].value : ""} placeholder="Link" onChange={(e) => {
                                                    let temp = [...preIPOInvest];
                                                    temp[6] = temp[6] ? temp[6] : {}
                                                    temp[6].value = e.target.value;
                                                    setpreIPOInvest(temp)
                                                }} />
                                            </div>
                                        </div>
                                        <div className="form-check p-0">
                                            <p className="text-danger m-0" id="generateSocialMediaImageError">{preIPOInvestError}</p>
                                        </div>

                                        <div className=" mt-1">
                                            <button id="pre_ipo_investingBtn" className="btn btn-primary" onClick={(e) => {
                                                e.preventDefault()
                                                saveData("pre_ipo_investing", "pre_ipo_investingBtn")
                                            }}>Save</button>
                                            <div id="saveDataBtnLoader" className='animBtn d-none'><div className='gSignIn-Anim'></div></div>
                                        </div>
                                    </form>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
                <ToastPage />
            </div>
        </>
    )
}