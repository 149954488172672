import { DataGrid, GridToolbar } from '@material-ui/data-grid';
import React, { useEffect, useState } from 'react';
import { configs } from '../../../assets/Config';
import '../../../assets/styles/RM.css'
import { useLogin } from '../../../services/contexts/AdminLoginContext';
import { useBucketContext } from '../../../services/contexts/assetManagement/BucketContext';
import ConfirmModalPage from '../../includes/ConfirmModalPage';

export default function DeletedBucketPage() {
    const { BucketList, setBucketList, setShowDeletedBucket, showDeletedBucket, getAllBucketList } = useBucketContext()
    const { setShowConfirm, showConfirm } = useLogin()

    const [confirmId, setConfirmId] = useState({})

    const columns = [{ field: "id", headerName: "Bucket ID", minWidth: 100, flex: 0.5, hide: true, filterable: false },
    { field: "name", headerName: "Name", minWidth: 300, flex: 0.5, cellClassName: "break-text" },
    { field: "isin", headerName: "ISIN", minWidth: 150, flex: 0.5, cellClassName: "break-text" },
    { field: "suggested_holding_period", headerName: "Suggested Holding Period", minWidth: 150, flex: 0.5, cellClassName: "break-text" },
    { field: "minimum_investment", headerName: "Minimum Investment", minWidth: 150, flex: 0.5, cellClassName: "break-text" },
    { field: "number_of_stocks", headerName: "Number of Stocks", minWidth: 150, flex: 0.5, cellClassName: "break-text" },
    {
        field: "disabletrading", headerName: "Disable Trading", minWidth: 130, flex: 0.1, cellClassName: "break-text", renderCell: (params) => {
            return params.row.disabletrading === 1 ? "Yes" : "No"
        }, valueGetter: (params) => params.row.disabletrading === 1 ? "Yes" : "No"
    },
    {
        field: "restore", headerName: " ", minWidth: 65, flex: 0.1, cellClassName: "break-text", cellClassName: "text-danger", renderCell: (params) => {
            return <a href="#" className="custom-link text-danger" onClick={(e) => {
                e.preventDefault()
                setConfirmId({
                    "category": configs.modules.bucketMgmt,
                    "type": "Pre-IPO Bucket",
                    "name": params.row.name,
                    "id": params.row.id
                })
                setShowConfirm(true)
            }}>Restore</a>
        }
    }]

    useEffect(() => {
        (async () => {
            try {
                await getAllBucketList(true)
            } catch (error) {

            }
        })()
    }, [])

    // role list page ui code
    return (
        <div style={{ width: "100%" }}>
            <div className="row d-flex align-items-center mt-2 ml-3 mb-1 mr-2">
                <div className="col-lg-6 col-7">
                    <p className="h4 text-custom-grey">Deleted Pre-IPO Bucket</p>
                </div>
                <div className="col-lg-6 col-5 text-right">
                    <a href="#" className="custom-link" onClick={(e) => {
                        e.preventDefault()
                        setBucketList("")
                        setShowDeletedBucket(!showDeletedBucket)
                    }}><i className={showDeletedBucket ? "fas fa-user mr-2" : "fas fa-user-slash mr-2"}></i>{showDeletedBucket ? "Active Bucket" : "Deleted Bucket"}</a>
                </div>
            </div>
            <div className=" deleted-role-table">
                {BucketList ? <DataGrid
                    rows={BucketList}
                    headerHeight={40}
                    rowHeight={30}
                    columns={columns}
                    resizable={true}
                    autoPageSize
                    pagination
                    components={{
                        Toolbar: GridToolbar,
                    }} /> : <div className="loader"></div>}
            </div>
            <ConfirmModalPage {...confirmId} />

        </div>
    )
}