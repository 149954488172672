import { DataGrid } from '@material-ui/data-grid';
import React, { useEffect, useState } from 'react';
import { configs } from '../../../assets/Config';
import { useLogin } from '../../../services/contexts/AdminLoginContext';
import LeftBarPage from '../../includes/dashboard/LeftBarPage';
import TopBarPage from '../../includes/dashboard/TopBarPage';
import '../../../assets/styles/NM.css'
import { useNMContext } from '../../../services/contexts/NMContext';
import ToastPage from '../../includes/ToastPage';
import { Link } from 'react-router-dom';
import { useHistory, useParams } from 'react-router';

export default function MobileTemplatePage() {

    const { mobileTemplate, getMobileTemplateById, updateMobileTemplateById, templateId, setTemplateId } = useNMContext()
    const { setCurrModule, setShowSuccess, allowedFunc } = useLogin()

    const [template, setTemplate] = useState("")
    const [error, setError] = useState("")

    const history = useHistory()

    const { id } = useParams()

    useEffect(() => {
        setTemplateId(id)
    }, [])

    useEffect(() => {
        if (templateId) {
            try {
                setCurrModule(configs.modules.mnt)
                if (allowedFunc.includes(configs.functionList['notification_management:notification_management'])) {
                    (async () => {
                        await getMobileTemplateById()
                    })()
                } else {
                    return history.goBack()
                }
            } catch (error) {

            }
        }
    }, [templateId])

    useEffect(() => {
        try {
            setTemplate(mobileTemplate.mobile_template)
        } catch (error) {

        }
    }, [mobileTemplate])


    // update mobile template
    async function updateTemplate() {
        try {

            document.getElementById("mobBtn").classList.toggle("d-none")
            document.getElementById("mobBtnLoader").classList.toggle("d-none")

            if (!template.trim()) {
                setError("Please provide a valid template.")
            } else {
                let response = await updateMobileTemplateById(template)
                if (response === "success") {
                    setShowSuccess(true)
                } else {
                    setError(response)
                }
            }

            document.getElementById("mobBtn").classList.toggle("d-none")
            document.getElementById("mobBtnLoader").classList.toggle("d-none")
        } catch (error) {

        }
    }


    // notification management main ui code
    return (
        <>
            <TopBarPage />
            <div style={{ display: "flex" }}>
                <LeftBarPage />
                <div id='nmMobContainer' className="nm-container">
                    <div style={{ width: "100%" }}>
                        <div className="row d-flex align-items-center mt-2 ml-2 mb-2 mr-3">
                            <div className="col-md-9 text-left">
                                <p className="h4 text-custom-grey">Mobile Notification Template - {mobileTemplate.name}</p>
                                <p>{mobileTemplate.description}</p>
                            </div>
                            <div className="col-md-3 text-left text-md-right">
                                <Link to="/notificationTemplateManagement"><i className="fas fa-id-card-alt mr-2"></i>Manage Templates</Link>
                            </div>
                        </div>
                        {mobileTemplate ?
                            <div className="mt-2 mb-4 mr-4 nm-template">
                                <p className="text-primary h6 my-4">Mobile Template Variables</p>
                                <div className="row pl-3">
                                    {mobileTemplate.variables ?
                                        mobileTemplate.variables.map((element, key) => {
                                            return (
                                                <div key={`mobTemplate` + key} className="row d-flex align-items-center justify-content-center variable-card ml-2">
                                                    <div className="col-10">
                                                        <p className="text-custom-grey p-0 m-0">{element.name} ({element.symbol})</p>
                                                    </div>
                                                    <div className="col-2 text-right">
                                                        <a href="#" onClick={(e) => {
                                                            e.preventDefault()
                                                            navigator.clipboard.writeText(element.symbol);
                                                        }}><i className="far fa-copy text-primary cursor" ></i></a>
                                                    </div>
                                                </div>

                                            )
                                        })

                                        : null}
                                </div>
                                <p className="text-primary h6 mt-5 pl-0 ml-0">Mobile Template Admins</p>
                                <div className="pr-3">
                                    <form action="">

                                        <div className="form-group">
                                            <label htmlFor="mobile_body">Mobile Template</label>
                                            <textarea name="mobile_body" id="mobile_body" className="w-100 form-control" rows="10" value={template ? template : ""} onChange={(e) => {
                                                setTemplate(e.target.value)
                                            }}></textarea>
                                        </div>
                                        <div className="form-check p-0">
                                            <p className="text-danger" id="templateError">{error}</p>
                                        </div>
                                        <div className="form-row">
                                            <div id="mobBtn" className="col-12 d-flex justify-content-center">
                                                <button type="submit" className="btn btn-primary" onClick={(e) => {
                                                    e.preventDefault()
                                                    updateTemplate()
                                                }}>Update</button>
                                                <div id="mobBtnLoader" className='animBtn d-none'><div className='gSignIn-Anim'></div></div>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                            : <div className="loader"></div>}
                    </div>
                </div>
                <ToastPage />
            </div>
        </>
    )
}