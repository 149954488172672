import { DataGrid, GridToolbar } from '@material-ui/data-grid';
import React, { useEffect, useState } from 'react';
import { configs, formatCurrency } from '../../../assets/Config';
import { useLogin } from '../../../services/contexts/AdminLoginContext';
import LeftBarPage from '../../includes/dashboard/LeftBarPage';
import TopBarPage from '../../includes/dashboard/TopBarPage';
import '../../../assets/styles/NM.css'
import { useNMContext } from '../../../services/contexts/NMContext';
import ToastPage from '../../includes/ToastPage';
import { Link } from 'react-router-dom';
import { useHistory } from 'react-router';
import { Modal } from '@material-ui/core';
import { useKYCContext } from '../../../services/contexts/KYCContext';
import { useBuyOrderContext } from '../../../services/contexts/BuyOrdersContext';
import { Autocomplete } from '@material-ui/lab';
import { TextField } from "@material-ui/core";
import { getDashboardData, postDashboardData } from '../../../services/apis/DistributorDashboardManagementApi';
import { getPromotionData, postPromotionData } from '../../../services/apis/PromotionalManagementApi';


export default function PromotionalMessagePage() {

    const { setCurrModule, allowedFunc, token, setShowSuccess, logout } = useLogin()

    const [message, setMessage] = useState("")
    const [textColor, setTextColor] = useState("000000")
    const [bgColor, setBgColor] = useState("ffffff")
    const [websiteLink, setWebsiteLink] = useState("")
    const [messageTrade, setMessageTrade] = useState("")
    const [textColorTrade, setTextColorTrade] = useState("000000")
    const [bgColorTrade, setBgColorTrade] = useState("ffffff")
    const [websiteLinkTrade, setWebsiteLinkTrade] = useState("")
    const [error, setError] = useState("")

    const history = useHistory()


    useEffect(() => {
        try {
            if (allowedFunc.includes(configs.functionList['dashboard_management:manage_dashboard'])) {
                (async () => {
                    setCurrModule(configs.modules.promoMgmt)
                    let response = await getPromotionData(token)
                    if (response && response.status === 200) {
                        setMessage(response.data.website_text)
                        setTextColor(response.data.website_text_color)
                        setBgColor(response.data.website_background_color)
                        setWebsiteLink(response.data.website_link)
                        setMessageTrade(response.data.trade_dashboard_text)
                        setTextColorTrade(response.data.trade_text_color)
                        setBgColorTrade(response.data.trade_background_Color)
                        setWebsiteLinkTrade(response.data.trade_link)
                    } else if (response && response.status === 401) {
                        logout();
                    } else {
                    }
                })()
            } else {
                return history.goBack()
            }
        } catch (error) {
            console.error(error);
        }
    }, [])

    async function saveData(btnId) {
        try {

            document.getElementById(btnId).classList.toggle("d-none")
            document.getElementById("saveDataBtnLoader").classList.toggle("d-none")

            let response = await postPromotionData(token, message, textColor, bgColor, messageTrade, textColorTrade, bgColorTrade, websiteLink, websiteLinkTrade)
            if (response && response.status === 200) {
                setError("");
                setShowSuccess(true)
            } else if (response && response.status === 401) {
                logout();
            } else {
                setError(response.data.error);
            }

            document.getElementById(btnId).classList.toggle("d-none")
            document.getElementById("saveDataBtnLoader").classList.toggle("d-none")
        } catch (error) {
            console.error(error)
        }
    }


    // customer dashboard management main ui code
    return (
        <>
            <TopBarPage />
            <div style={{ display: "flex" }}>
                <LeftBarPage />
                <div id='nmContainer' className="smPO-container">
                    <div style={{ width: "100%" }}>
                        <div className="row d-flex align-items-center mt-2 ml-2 mb-4 mr-3">
                            <div className="col-lg-6 text-left">
                                <p className="h4 text-custom-grey">Promotional Message Management</p>
                            </div>
                        </div>
                        <div className="smPOI-container">
                            <div className='row'>
                                <div className='col-12 mb-4'>
                                    <form>
                                        <p className='m-0 p-0 font-weight-bold'>Altius main website</p>
                                        <div className="row">

                                            <div className='col-lg-3 col-md-6 col-12'>
                                                <label htmlFor="title">Message</label>
                                                <input type="text" className="form-control mb-1 f-10" name="title" value={message} placeholder="Message" onChange={(e) => {
                                                    setMessage(e.target.value)
                                                }} />
                                            </div>
                                            <div className='col-lg-3 col-md-6 col-12'>
                                                <label htmlFor="title">Link</label>
                                                <input type="text" className="form-control mb-1 f-10" name="title" value={websiteLink} placeholder="Link" onChange={(e) => {
                                                    setWebsiteLink(e.target.value)
                                                }} />
                                            </div>
                                            <div className='col-lg-3 col-md-6 col-12'>
                                                <label htmlFor="title">Text Color</label>
                                                <input type="color" className="form-control mb-1 f-10" value={`#${textColor}`} name="title" onChange={(e) => {
                                                    setTextColor(e.target.value.replace('#', ''))
                                                }} />
                                            </div>
                                            <div className='col-lg-3 col-md-6 col-12'>
                                                <label htmlFor="title">Background Color</label>
                                                <input type="color" className="form-control mb-1 f-10" value={`#${bgColor}`} name="title" onChange={(e) => {
                                                    setBgColor(e.target.value.replace("#", ''))
                                                }} />
                                            </div>
                                        </div>

                                        <p className='m-0 mt-4 p-0 font-weight-bold'>Altius Trade website</p>
                                        <div className="row ">

                                            <div className='col-lg-3 col-md-6 col-12'>
                                                <label htmlFor="title">Message</label>
                                                <input type="text" className="form-control mb-1 f-10" name="title" value={messageTrade} placeholder="Message" onChange={(e) => {
                                                    setMessageTrade(e.target.value)
                                                }} />
                                            </div>
                                            <div className='col-lg-3 col-md-6 col-12'>
                                                <label htmlFor="title">Link</label>
                                                <input type="text" className="form-control mb-1 f-10" name="title" value={websiteLinkTrade} placeholder="Link" onChange={(e) => {
                                                    setWebsiteLinkTrade(e.target.value)
                                                }} />
                                            </div>
                                            <div className='col-lg-3 col-md-6 col-12'>
                                                <label htmlFor="title">Text Color</label>
                                                <input type="color" className="form-control mb-1 f-10" value={`#${textColorTrade}`} name="title" onChange={(e) => {
                                                    setTextColorTrade(e.target.value.replace('#', ''))
                                                }} />
                                            </div>
                                            <div className='col-lg-3 col-md-6 col-12'>
                                                <label htmlFor="title">Background Color</label>
                                                <input type="color" className="form-control mb-1 f-10" value={`#${bgColorTrade}`} name="title" onChange={(e) => {
                                                    setBgColorTrade(e.target.value.replace("#", ''))
                                                }} />
                                            </div>
                                        </div>
                                        <div className="form-check p-0">
                                            <p className="text-danger m-0" id="generateSocialMediaImageError">{error}</p>
                                        </div>

                                        <div className=" mt-1">
                                            <button id="latest_updateBtn" className="btn btn-primary" onClick={(e) => {
                                                e.preventDefault()
                                                saveData("latest_updateBtn")
                                            }}>Save</button>
                                            <div id="saveDataBtnLoader" className='animBtn d-none'><div className='gSignIn-Anim'></div></div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <ToastPage />
            </div>
        </>
    )
}