import axios from "axios";
import { configs } from "../../../assets/Config";

// get all roles and users under role
export function getAllRolesList(token) {
    return new Promise(async (resolve, reject) => {
        try {
            var config = {
                method: 'get',
                url: `${process.env.REACT_APP_BASEURL}/admin/rolemanagement/rolesWithUsers`,
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            };

            let res = await axios(config)
            resolve(res)
        } catch (err) {
            resolve(err?.response)
        }
    })
}

// create role
export function createNewRole(token, name, description, functionId) {
    return new Promise(async (resolve, reject) => {
        try {
            var data = {
                "name": name,
                "description": description,
                "function_ids": functionId
            }
            var config = {
                method: 'post',
                url: `${process.env.REACT_APP_BASEURL}/admin/rolemanagement/createRole`,
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json'
                },
                data: data
            };

            let res = await axios(config)
            resolve(res)
        } catch (err) {
            resolve(err?.response)
        }
    })
}

// update role
export function updateExistingRole(token, name, description, functionId, roleId) {
    return new Promise(async (resolve, reject) => {
        try {
            var data = {
                "name": name,
                "description": description,
                "function_ids": functionId,
                "role_id": roleId
            }
            var config = {
                method: 'patch',
                url: `${process.env.REACT_APP_BASEURL}/admin/rolemanagement/editRole`,
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json'
                },
                data: data
            };

            let res = await axios(config)
            resolve(res)
        } catch (err) {
            resolve(err?.response)
        }
    })
}


// delete a role
export function deleteRoleById(token, roleId) {
    return new Promise(async (resolve, reject) => {
        try {
            var config = {
                method: 'delete',
                url: `${process.env.REACT_APP_BASEURL}/admin/rolemanagement/deleteRole/${roleId}`,
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            };

            let res = await axios(config)
            resolve(res)
        } catch (err) {
            resolve(err?.response)
        }
    })
}

// get all deleted roles
export function getAllDeletedRoles(token) {
    return new Promise(async (resolve, reject) => {
        try {
            var config = {
                method: 'get',
                url: `${process.env.REACT_APP_BASEURL}/admin/rolemanagement/deletedRoles`,
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            };

            let res = await axios(config)
            resolve(res)
        } catch (err) {
            resolve(err?.response)
        }
    })
}

// restore role by id
export function restoreRoleById(token, roleId) {
    return new Promise(async (resolve, reject) => {
        try {
            var config = {
                method: 'patch',
                url: `${process.env.REACT_APP_BASEURL}/admin/rolemanagement/restoreRole/${roleId}`,
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            };

            let res = await axios(config)
            resolve(res)
        } catch (err) {
            resolve(err?.response)
        }
    })
}


// post career page data
export function postCareerPage(token, pageData) {
    return new Promise(async (resolve, reject) => {
        try {
            var config = {
                method: 'post',
                url: `${process.env.REACT_APP_BASEURL}/admin/career`,
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json'
                },
                data: {
                    data: pageData
                }
            };

            let res = await axios(config)
            resolve(res)
        } catch (err) {
            resolve(err?.response)
        }
    })
}

// get career page data
export function getCareerPage(token) {
    return new Promise(async (resolve, reject) => {
        try {
            var config = {
                method: 'get',
                url: `${process.env.REACT_APP_BASEURL}/admin/career`,
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            };

            let res = await axios(config)
            resolve(res)
        } catch (err) {
            resolve(err?.response)
        }
    })
}
