import Modal from "@material-ui/core/Modal";
import { useState } from "react";
import { configs } from "../../assets/Config";
import '../../assets/styles/TopBar.css'
import { getAllGSecs } from "../../services/apis/assetManagement/GSecManagementApi";
import { useLogin } from "../../services/contexts/AdminLoginContext";
import { useBondContext } from "../../services/contexts/assetManagement/BondContext";
import { useBucketContext } from "../../services/contexts/assetManagement/BucketContext";
import { useGSecContext } from "../../services/contexts/assetManagement/GSecContext";
import { useMixBucketContext } from "../../services/contexts/assetManagement/MixBucketContext";
import { useMLDContext } from "../../services/contexts/assetManagement/MLDContext";
import { useStartupContext } from "../../services/contexts/assetManagement/StartupContext";
import { useBuyOrderContext } from "../../services/contexts/BuyOrdersContext";
import { useCMContext } from "../../services/contexts/companyManagement/CMContext";
import { useNewsContext } from "../../services/contexts/companyManagement/NewsContext";
import { useCouponContext } from "../../services/contexts/couponManagement/CouponContext";
import { useRMContext } from "../../services/contexts/RMContext";
import { useSellOrderContext } from "../../services/contexts/SellOrdersContext";


export default function DeleteModalPage(deleteData) {
    const { deleteRole, deleteAdmin } = useRMContext()
    const { deleteCompanyById, getAllCompaniesList } = useCMContext()
    const { deleteMLDById, getAllMLDList } = useMLDContext()
    const { deleteStartupById, getAllStartupList } = useStartupContext()
    const { deleteBondById, getAllBondList } = useBondContext()
    const { deleteGSecById, getAllGSecList } = useGSecContext()
    const { deleteNewsById, getAllNewsList } = useNewsContext()
    const { deleteOrderById, getOrdersByType, activeStatus } = useBuyOrderContext()
    const { deleteSellOrderById, getSellOrder, activeStatusSell } = useSellOrderContext()
    const { deletecouponById, getAllcouponList } = useCouponContext()
    const { deleteBucketById, getAllBucketList } = useBucketContext()
    const { deleteMixBucketById, getAllMixBucketList } = useMixBucketContext()

    const { showDelete, setShowDelete, setShowSuccess } = useLogin()

    const [error, setError] = useState("")

    const handleClose = () => {
        setShowDelete(false);
    };

    async function deleteDataById() {
        try {
            document.getElementById("deleteBtn").classList.toggle("d-none")
            document.getElementById("deleteBtnLoader").classList.toggle("d-none")

            if (deleteData.category === configs.modules.rm) {
                deleteRole(deleteData.id)
                handleClose()

            } else if (deleteData.category === configs.modules.am) {
                deleteAdmin(deleteData.id)
                handleClose()

            } else if (deleteData.category === configs.modules.cm) {
                let response = await deleteCompanyById(deleteData.id)
                if (response === "success") {
                    setError("")
                    handleClose()
                    setShowSuccess(true)
                    getAllCompaniesList()
                } else {
                    setError(response)
                }
            } else if (deleteData.category === configs.modules.mldMgmt) {
                let response = await deleteMLDById(deleteData.id)
                if (response === "success") {
                    setError("")
                    handleClose()
                    setShowSuccess(true)
                    getAllMLDList()
                } else {
                    setError(response)
                }
            } else if (deleteData.category === configs.modules.startupMgmt) {
                let response = await deleteStartupById(deleteData.id)
                if (response === "success") {
                    setError("")
                    handleClose()
                    setShowSuccess(true)
                    getAllStartupList()
                } else {
                    setError(response)
                }
            } else if (deleteData.category === configs.modules.gsecMgmt) {
                let response = await deleteGSecById(deleteData.id)
                if (response === "success") {
                    setError("")
                    handleClose()
                    setShowSuccess(true)
                    getAllGSecList()
                } else {
                    setError(response)
                }
            } else if (deleteData.category === configs.modules.bondMgmt) {
                let response = await deleteBondById(deleteData.id)
                if (response === "success") {
                    setError("")
                    handleClose()
                    setShowSuccess(true)
                    getAllBondList()
                } else {
                    setError(response)
                }
            } else if (deleteData.category === configs.modules.newsManage) {
                let response = await deleteNewsById(deleteData.id)
                if (response === "success") {
                    setError("")
                    handleClose()
                    setShowSuccess(true)
                    getAllNewsList()
                } else {
                    setError(response)
                }
            } else if (deleteData.category === configs.modules.smBuyOrder) {
                let response = await deleteOrderById(deleteData.id)
                if (response === "success") {
                    setError("")
                    handleClose()
                    setShowSuccess(true)
                    getOrdersByType(configs.shareStatusTypes[activeStatus].api)

                } else {
                    setError(response)
                }
            } else if (deleteData.category === configs.modules.smSellOrder) {
                let response = await deleteSellOrderById(deleteData.id)
                if (response === "success") {
                    setError("")
                    handleClose()
                    setShowSuccess(true)
                    getSellOrder(configs.sellOrderStatusTypes[activeStatusSell].api)

                } else {
                    setError(response)
                }
            } else if (deleteData.category === configs.modules.couponMgmt) {
                let response = await deletecouponById(deleteData.id)
                if (response === "success") {
                    setError("")
                    handleClose()
                    setShowSuccess(true)
                    getAllcouponList()
                } else {
                    setError(response)
                }
            } else if (deleteData.category === configs.modules.bucketMgmt) {
                let response = await deleteBucketById(deleteData.id)
                if (response === "success") {
                    setError("")
                    handleClose()
                    setShowSuccess(true)
                    getAllBucketList()
                } else {
                    setError(response)
                }
            } else if (deleteData.category === configs.modules.mixBucketMgmt) {
                let response = await deleteMixBucketById(deleteData.id)
                if (response === "success") {
                    setError("")
                    handleClose()
                    setShowSuccess(true)
                    getAllMixBucketList()
                } else {
                    setError(response)
                }
            }

            document.getElementById("deleteBtn").classList.toggle("d-none")
            document.getElementById("deleteBtnLoader").classList.toggle("d-none")
        } catch (error) {

        }
    }

    return (<Modal open={showDelete} onClose={handleClose}>
        <div className="modal-fade" id="KYCSubmitModal" tabIndex="-1" role="dialog" aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered" role="document">
                <div className="modal-content">
                    <div className="modal-header border-0">
                        <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={handleClose}>
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div className="modal-body text-center py-3">
                        <p className="text-center h1 font-weight-normal text-danger">
                            <i className="far fa-times-circle mr-1"></i>
                            Delete
                        </p>
                        <p className="mt-3 px-3">
                            Are you sure that you want to delete <b>{deleteData.type}: {deleteData.name}</b>?
                        </p>
                        {error ? <p className="mt-3 px-3 text-danger">
                            {error}
                        </p> : null}
                        <button id="deleteBtn" type="button" data-dismiss="modal" className="btn btn-primary text-center mt-3" onClick={(e) => {
                            e.preventDefault()
                            deleteDataById()
                        }}>Delete</button>
                        <div id="deleteBtnLoader" className='animBtn d-none w-100'><div className='gSignIn-Anim'></div></div>
                    </div>
                </div>
            </div>
        </div>
    </Modal>)
}