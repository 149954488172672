import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { configs } from '../../../assets/Config';
import '../../../assets/styles/RM.css'
import { useLogin } from '../../../services/contexts/AdminLoginContext';
import { useRMContext } from '../../../services/contexts/RMContext';
import TreeSelection from '../../includes/TreeSelection';

export default function CreateRolePage() {

    const { functionList, arFunction, setarFunction, functionDetail, setFunctionDetail, updateRoleDetail, editRole, setEditRole, setEditFunction, removeFunction, setRemoveFunction } = useRMContext();
    const { setShowSuccess } = useLogin()
    const [roleName, setRoleName] = useState()
    const [roleDesc, setRoleDesc] = useState("")
    const [error, setError] = useState("")
    const [reset, setReset] = useState(false)


    // create/update role
    async function roleDetails(e) {
        try {
            e.preventDefault()
            document.getElementById("createRoleBtn").classList.toggle("d-none")
            document.getElementById("createRoleBtnLoader").classList.toggle("d-none")

            if (roleName && !roleName.trim()) {
                setError(configs.createRoleError.name)
            } else if (roleDesc && !roleDesc.trim()) {
                setError(configs.createRoleError.desc)
            } else {
                let response = await updateRoleDetail(roleName, roleDesc);
                if (response === "success") {
                    setShowSuccess(true);
                    resetCreateRole();
                } else {
                    setError(response)
                }
            }

            document.getElementById("createRoleBtn").classList.toggle("d-none")
            document.getElementById("createRoleBtnLoader").classList.toggle("d-none")
        } catch (error) {

        }

    }

    // reset create role form
    async function resetCreateRole() {
        try {
            setEditRole({
                edit: false,
                data: {}
            })
            setarFunction([])
            setRemoveFunction([])
            setError("")
            setRoleName("")
            setRoleDesc("")
            setReset(true)
            Object.keys(functionList).sort().map((element, key) => {
                functionList[element].anySelected = false;
            })
            document.getElementById("createRoleForm").reset();
        } catch (error) {

        }
    }

    useEffect(() => {
        try {
            if (editRole.edit) {
                setRoleName(editRole.data.name)
                setRoleDesc(editRole.data.description)
                let list = [];

                Object.keys(functionList).map((element, key) => {
                    let allCheck = false, anySelected = false;
                    functionList[element].subFunctions.forEach(element2 => {
                        allCheck = editRole.data.function_ids.includes(element2.id);
                        anySelected = editRole.data.function_ids.includes(element2.id) ? editRole.data.function_ids.includes(element2.id) : anySelected;
                    });
                    if (allCheck) {
                        list.push(functionList[element].id)
                    }
                    functionList[element]["anySelected"] = anySelected;
                })
                setarFunction([...list, ...editRole.data.function_ids])
                setRemoveFunction([...list, ...removeFunction])
            }
        } catch (error) {

        }
    }, [editRole.data])



    // create role ui code
    return (
        <div id="rmCreateRole" className="col-lg-3 col-md-4 d-md-block d-none pt-3 main-rmPanel">
            <p className="h6 text-custom-grey">{editRole.edit ? "Edit Role" : "Create a New Role"}</p>
            <form id="createRoleForm" action="">
                <input type="text" id="roleName" className="form-control f-10" placeholder="Role Name" value={roleName ? roleName : ""} required onChange={(e) => {
                    setRoleName(e.target.value)
                }} />
                <textarea id="roleDesc" className="form-control w-100 mt-3 f-10" rows="5" value={roleDesc ? roleDesc : ""} required placeholder="Role Description" onChange={(e) => {
                    setRoleDesc(e.target.value)
                }} />
                <div className="function-container mt-3">
                    {functionList && Object.keys(functionList) && Object.keys(functionList).length > 0 ? Object.keys(functionList).sort().map((element, key) => {
                        return (
                            <TreeSelection key={`createRole` + key} title={{ title: element.toLowerCase(), id: functionList[element].id }} content={functionList[element].subFunctions} anySelected={functionList[element].anySelected} />
                        )
                    }) : <div className="loader"></div>}
                </div>
                <div className="form-check p-0">
                    <p className="text-danger" id="createRoleError">{error}</p>
                </div>
                <button id="createRoleBtn" className="btn btn-primary mt-3" disabled={roleName && roleDesc ? false : true} onClick={(e) => {
                    roleDetails(e)
                }}>{editRole.edit ? "Update Role" : "Create Role"}</button>
                <div id="createRoleBtnLoader" className='animBtn d-none'><div className='gSignIn-Anim'></div></div>
                {editRole.edit ?
                    <button className="btn btn-danger mt-3 ml-3" disabled={roleName && roleDesc ? false : true} onClick={(e) => {
                        e.preventDefault()
                        resetCreateRole()
                    }}>Reset</button>
                    : null}
                <div className='row m-0 p-0'>
                    <button className="btn btn-primary mt-3 d-md-none" onClick={(e) => {
                        e.preventDefault()
                        document.getElementById("rmCreateRole").classList.toggle('d-none')
                        document.getElementById("roleListPage").classList.toggle('d-none')
                    }}>View All Roles</button>
                </div>
            </form >
        </div >
    )
}