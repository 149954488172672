import { useState } from "react";
import { useLogin } from "../../../../services/contexts/AdminLoginContext";
import { generateBase64 } from "../../../../assets/Config";

export default function AddStatusData(props) {

    const { dashboardHighlight, setDashboardHighlight } = useLogin();

    const [error, setError] = useState("");

    // get file contents through file chooser
    async function uploadFile(file, parentIndex, index) {
        try {
            if (file.type === "image/jpg" || file.type === "image/jpeg" || file.type === "image/png") {
                let highlight = dashboardHighlight;
                highlight[parentIndex].highlights[index].file = await generateBase64(file)
                highlight[parentIndex].highlights[index].fileName = file.name
                setDashboardHighlight([...dashboardHighlight], highlight);
            } else {
                alert("Only jpg or png files are allowed.")
            }
        } catch (error) {
            console.error(error)
        }
    }

    // update button text and link
    function uploadText(text, link, parentIndex, index) {
        try {
            let highlight = dashboardHighlight;
            if (text) {
                highlight[parentIndex].highlights[index].btnText = text
            } else if (link) {
                highlight[parentIndex].highlights[index].btnLink = link
            }
            setDashboardHighlight([...dashboardHighlight], highlight);

        } catch (error) {
            console.error(error)
        }
    }



    // add highlight function
    function modifyHighlight(parentIndex, index, setDelete) {
        try {
            if (setDelete && dashboardHighlight[parentIndex].highlights.length > 1) {
                let highlight = dashboardHighlight
                highlight[parentIndex].highlights.splice(index, 1);
                setDashboardHighlight([...dashboardHighlight], highlight)
            } else if (dashboardHighlight[parentIndex]?.highlights[dashboardHighlight[parentIndex].highlights.length - 1]?.file) {
                let highlight = dashboardHighlight
                highlight[parentIndex].highlights.push({
                    file: '',
                    btnText: "",
                    btnLink: ""
                })
                setDashboardHighlight([...dashboardHighlight], highlight)
                setError("");
            } else if (!setDelete) {
                setError("New highlight component could not be created as above highlight component has empty highlight")
            }
        } catch (error) {
            console.error(error);
        }
    }

    return (
        <>
            <div className='row m-2 p-2 bg-white border border-dark' key={`storyHigh${props.parentIndex}${props.index}`}>
                <div className='col-4'>
                    <div className="form-group">
                        <div className='f-12'>
                            <label htmlFor="title" className="font-weight-bold">Image File</label>
                        </div>
                        <div className="file-border rounded py-4" onDragOver={(e) => {
                            e.preventDefault();
                        }} onDrop={(e) => {
                            uploadFile(e.dataTransfer.files[0], props.parentIndex, props.index)
                            e.preventDefault();
                        }}>
                            <i className="fas fa-file-upload f-22"></i>
                            <p className="pb-0 mb-0 f-12">
                                Drag your file or &nbsp;
                                <input type="file" id={`highlightInternal${props.parentIndex}${props.index}`} required hidden accept="image/jpg, image/jpeg, image/png" onChange={(e) => {
                                    uploadFile(e.target.files[0], props.parentIndex, props.index)
                                }} />
                                <label htmlFor={`highlightInternal${props.parentIndex}${props.index}`} className="mb-0 pb-0">Click here</label>
                                &nbsp; to  <br /> Upload: <span className="text-theme">{props.data.fileName}</span>
                            </p>
                        </div>
                    </div>
                </div>
                <div className='col-4'>
                    <div className="form-group">
                        <div className='f-12'>
                            <label htmlFor="title" className="font-weight-bold">Button Text</label>
                        </div>
                        <input type="text" className="form-control" placeholder="Button Text" value={props.data.btnText} onChange={(e) => {
                            uploadText(e.target.value, null, props.parentIndex, props.index)
                        }} />
                    </div>
                    <div className="form-group">
                        <div className='f-12'>
                            <label htmlFor="title" className="font-weight-bold">Button Link</label>
                        </div>
                        <input type="text" className="form-control" placeholder="Button Link" value={props.data.btnLink} onChange={(e) => {
                            uploadText(null, e.target.value, props.parentIndex, props.index)
                        }} />

                    </div>
                </div>
                <div className='col-4'>
                    <div className="form-group">
                        <div className="justify-content-end d-flex">
                            <button href="#" data-toggle="modal" data-target="#deleteRole" className="btn btn-danger f-10" onClick={(e) => {
                                e.preventDefault()
                                modifyHighlight(props.parentIndex, props.index, true)
                            }}><i className="fas fa-trash mr-2 f-10"></i>Delete Highlight</button>
                        </div>
                        <p className="font-weight-bold">Current Image File</p>
                        <img style={{ width: "10rem", "objectFit": "contain" }} src={props.data.file} />
                    </div>
                </div>
            </div>

            {props.lastEle ?
                <div className='row m-0 p-0 mt-3'>
                    <p className="text-danger">{error}</p>
                    <div className='col-12 m-0 p-0 d-flex justify-content-end'>
                        <div className="form-group d-flex">
                            <button className="text-primary custom-button" onClick={(e) => {
                                e.preventDefault()
                                modifyHighlight(props.parentIndex)
                            }}><i className="fas fa-plus-circle mr-2"></i>Add New</button>
                        </div>
                    </div>
                </div>
                : null}
        </>
    )
}