import React, { useEffect, useState } from 'react';
import '../../../assets/styles/KYC.css';
import {
    DataGrid, GridToolbar, GridToolbarContainer,
    GridToolbarExport,
} from '@material-ui/data-grid';
import { useDistributorContext } from '../../../services/contexts/DistributorContext';
import { configs } from '../../../assets/Config';
import { Link } from 'react-router-dom';
import { useLogin } from '../../../services/contexts/AdminLoginContext';
import LeftBarPage from '../../includes/dashboard/LeftBarPage';
import TopBarPage from '../../includes/dashboard/TopBarPage';
import { useKYCContext } from '../../../services/contexts/KYCContext';
import { useHistory } from 'react-router-dom';


export default function CustomerActionPage() {

    const { error, getAllUserActions, actions, setKycRequesterUserDetail, setKycRequesterUserComplete, setpanDoc,
        setaddressDoc,
        setbankDoc,
        setdematDoc } = useKYCContext()

    const { allowedFunc, setCurrModule } = useLogin()

    const history = useHistory()

    useEffect(() => {
        if (allowedFunc.includes(configs.functionList['user_management:load_user_actions'])) {
            setCurrModule(configs.modules.custActions)
        } else {
            return history.goBack()
        }
    }, [])

    // declare table rows
    const [columns, setColumns] = useState([
        { field: "id", headerName: "ID", minWidth: 100, flex: 0.5, hide: true, filterable: false },
        { field: "asset_id", headerName: "Asset Id", minWidth: 100, flex: 0.5 },
        { field: "asset_name", headerName: "Asset Name", minWidth: 150, flex: 0.5 },
        { field: "asset_type", headerName: "Asset Type", minWidth: 100, flex: 0.5 },
        {
            field: "name", headerName: "Name", minWidth: 150, flex: 0.4, cellClassName: "break-text f-12", valueGetter: (params) => {
                return params.row.user.name
            }
        },
        {
            field: "email", headerName: "E-mail", minWidth: 150, flex: 0.4, cellClassName: "break-text f-12", renderCell: (params) => {
                if (allowedFunc.includes(configs.functionList['user_management:load_user_details'])) {
                    return <Link to={`/customerProfile/${params.row.user.id}`} onClick={() => {
                        setKycRequesterUserDetail(params.row.user)
                    }}>{params.row.user.email}</Link>
                } else {
                    return params.row.user.email
                }
            }, valueGetter: (value) => value.row.user.email
        },
        {
            field: "mobile", headerName: "Phone No.", minWidth: 100, flex: 0.3, cellClassName: "break-text f-12", renderCell: (params) => {
                return <a href={"https://wa.me/" + params.row.user.mobile} target="_blank">{params.row.user.mobile ? `+${params.row.user.mobile}` : params.row.user.mobile}</a>
            }, valueGetter: (value) => value.row.user.mobile ? `+${value.row.user.mobile}` : value.row.user.mobile
        },
        { field: "date", headerName: "Date", minWidth: 50, flex: 0.5, cellClassName: "break-text" },
        { field: "time", headerName: "Time", minWidth: 100, flex: 0.5, cellClassName: "break-text" },
    ])

    // get list of kyc requesters
    useEffect(() => {
        (async () => {
            try {
                await getAllUserActions();
            } catch (error) {

            }
        })();
    }, [])

    // react data table export option
    function CustomToolbar() {
        return (
            <GridToolbarContainer>
                <GridToolbarExport />
            </GridToolbarContainer>
        );
    }

    // admin kyc requesters basic details ui code
    return (
        <>
            <TopBarPage />
            <div style={{ display: "flex" }}>
                <LeftBarPage />
                <div id='custStatContainer' className="smbo-container">
                    <div style={{ width: "100%" }}>
                        <div className="row mx-md-4 mx-2 my-2 p-0">
                            <p className="text-custom-grey h3 mt-2 ml-3">Customer Actions</p>
                        </div>
                        <div className="kyc-table">
                            {actions ? <DataGrid
                                rows={actions}
                                columns={columns}
                                resizable={true}
                                autoPageSize
                                rowHeight={40}
                                headerHeight={30}
                                pagination
                                components={{
                                    Toolbar: GridToolbar,
                                }}
                            />
                                : <div className="loader"></div>}
                        </div>


                    </div>
                </div>
            </div>
        </>
    )
}