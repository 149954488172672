import { Modal } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { configs } from '../../../assets/Config';
import '../../../assets/styles/RM.css'
import { uploadCompanyDataDoc } from '../../../services/apis/companyManagement/CMApi';
import { useLogin } from '../../../services/contexts/AdminLoginContext';
import { useCMContext } from '../../../services/contexts/companyManagement/CMContext';
import RichTextEditorPage from '../../includes/RichTextEditorPage';
import { uploadCSVDoc, downloadCSVDoc } from '../../../services/apis/shareManagement/InventoryAPI';
import ToastPage from '../../includes/ToastPage';

export default function UpdateInventoryPage() {

    const { setShowSuccess, token, logout, setLoginError, setCurrModule, allowedFunc } = useLogin()
    const { getInventoryAnalyticsData, companyDetail, setCompanyDetail } = useCMContext();

    const [file, setFile] = useState("")
    const [depFile, setDepFile] = useState("")
    const [error, setError] = useState("")
    const [depError, setDepError] = useState("")

    const history = useHistory()

    useEffect(() => {
        if (!allowedFunc.includes(configs.functionList['buy_module:export_inventory']) && !allowedFunc.includes(configs.functionList['buy_module:update_inventory'])) {
            return history.goBack()
        } else {
            setCurrModule(configs.modules.smInventory)
        }
    })

    // get file contents through file chooser
    function uploadFile(file, dep) {
        try {
            if ((file.type === ".csv" || file.type === "text/csv" || file.type === "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" || file.type === "application/vnd.ms-excel")) {
                if (dep) {
                    setDepFile(file)
                } else {
                    setFile(file)
                }
            } else {
                alert("Only csv files are allowed.")
            }
        } catch (error) {

        }
    }

    // upload csv to server
    async function uploadFileToServer(dep) {
        try {
            if (file || (dep && depFile)) {
                document.getElementById(dep ? "uploaddepFileBtn" : "uploadFileBtn").classList.toggle("d-none")
                document.getElementById(dep ? "uploaddepFileBtnLoader" : "uploadFileBtnLoader").classList.toggle("d-none")

                let response = await uploadCSVDoc(token, dep ? depFile : file, dep);
                if (response && response.status === 200) {
                    setShowSuccess(true)
                    setCompanyDetail("");
                    getInventoryAnalyticsData();
                    if (dep) {
                        setDepFile(null)
                        setDepError("")
                    }
                    else {
                        setError("")
                        setFile(null)
                    }
                } else if (response && response.status === 401) {
                    logout();
                    setLoginError(configs.unauthorizedErrorMessage);
                }
                else {
                    if (dep) {
                        setDepError(response.data.error)
                        setDepFile(null)
                    } else {
                        setError(response.data.error)
                        setFile(null)
                    }
                }

                document.getElementById(dep ? "uploaddepFileBtn" : "uploadFileBtn").classList.toggle("d-none")
                document.getElementById(dep ? "uploaddepFileBtnLoader" : "uploadFileBtnLoader").classList.toggle("d-none")
            } else {
                if (dep) {
                    setDepError("Please select a file to upload.")
                } else {
                    setError("Please select a file to upload.")
                }
            }
        } catch (error) {

        }
    }

    // download csv from server
    async function downloadFile(dep) {
        try {
            document.getElementById(dep ? "downloaddepFileBtn" : "downloadFileBtn").classList.toggle("d-none")
            document.getElementById(dep ? "downloaddepFileBtnLoader" : "downloadFileBtnLoader").classList.toggle("d-none")

            let response = await downloadCSVDoc(token, dep);
            if (response && response.status === 200) {
                let a = document.createElement("a"); //Create <a>
                a.href = response.data; //Image Base64 Goes here
                a.download = response.data ? dep ? "Depository InventoryData.csv" : "InventoryData.csv" : null; //File name Here
                a.click();
            } else if (response && response.status === 401) {
                logout();
                setLoginError(configs.unauthorizedErrorMessage);
            }
            else { }

            document.getElementById(dep ? "downloaddepFileBtn" : "downloadFileBtn").classList.toggle("d-none")
            document.getElementById(dep ? "downloaddepFileBtnLoader" : "downloadFileBtnLoader").classList.toggle("d-none")
        } catch (error) {

        }
    }



    // create role ui code
    return (
        <div id="updateExportInventory" className="col-lg-3 col-md-4 d-md-block d-none pt-3 main-rmPanel">
            <form action="">
                <div className="form-group">
                    <label htmlFor="upload_userPAN"><b>Import Inventory CSV</b></label>
                    <div className="file-border rounded py-4" onDragOver={(e) => {
                        e.preventDefault();
                    }} onDrop={(e) => {
                        uploadFile(e.dataTransfer.files[0])
                        e.preventDefault();
                    }}>
                        <i className="fas fa-file-upload"></i>
                        <p className="pb-0 mb-0">
                            Drag your file or &nbsp;
                            <input type="file" id="upload_userPAN" required hidden accept=".csv, text/csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel" onChange={(e) => {
                                uploadFile(e.target.files[0])
                                e.target.value = '';
                            }} />
                            <label htmlFor="upload_userPAN" className="mb-0 pb-0">Click here</label>
                            &nbsp; to choose <br /> Upload: <span className="text-theme">{file ? file.name : ""}</span>
                        </p>
                    </div>
                </div>
                <div className="form-check p-0">
                    <p className="text-danger" id="inventoryError">{error}</p>
                </div>
                <div className="text-center row d-flex">
                    <div className='col-5 p-0'>
                        <a id="uploadFileBtn" href="#" className="btn btn-primary" onClick={(e) => {
                            e.preventDefault()
                            uploadFileToServer()
                        }}><i className="fas fa-upload mr-2"></i>Upload CSV</a>
                        <div className='d-flex justify-content-center'>
                            <div id="uploadFileBtnLoader" className='animBtn d-none'><div className='gSignIn-Anim'></div></div>
                        </div>
                    </div>
                    <div className='col-7 p-0'>
                        <a id="downloadFileBtn" href="#" className="btn btn-primary" onClick={(e) => {
                            e.preventDefault()
                            downloadFile()
                        }}><i className="fas fa-download mr-2"></i>Download Inventory CSV</a>
                        <div className='d-flex justify-content-center'>
                            <div id="downloadFileBtnLoader" className='animBtn d-none'><div className='gSignIn-Anim'></div></div>
                        </div>
                    </div>
                </div>
            </form>

            <form action="" className='mt-5'>
                <div className="form-group">
                    <label htmlFor="upload_depInventory"><b>Import Depository Inventory CSV</b></label>
                    <div className="file-border rounded py-4" onDragOver={(e) => {
                        e.preventDefault();
                    }} onDrop={(e) => {
                        uploadFile(e.dataTransfer.files[0], true)
                        e.preventDefault();
                    }}>
                        <i className="fas fa-file-upload"></i>
                        <p className="pb-0 mb-0">
                            Drag your file or &nbsp;
                            <input type="file" id="upload_depInventory" required hidden accept=".csv, text/csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel" onChange={(e) => {
                                uploadFile(e.target.files[0], true)
                                e.target.value = '';
                            }} />
                            <label htmlFor="upload_depInventory" className="mb-0 pb-0">Click here</label>
                            &nbsp; to choose <br /> Upload: <span className="text-theme">{depFile ? depFile.name : ""}</span>
                        </p>
                    </div>
                </div>
                <div className="form-check p-0">
                    <p className="text-danger" id="inventoryError">{depError}</p>
                </div>
                <div className="text-center row d-flex">
                    <div className='col-5 p-0'>
                        <a id="uploaddepFileBtn" href="#" className="btn btn-primary" onClick={(e) => {
                            e.preventDefault()
                            uploadFileToServer(true)
                        }}><i className="fas fa-upload mr-2"></i>Upload CSV</a>
                        <div className='d-flex justify-content-center'>
                            <div id="uploaddepFileBtnLoader" className='animBtn d-none'><div className='gSignIn-Anim'></div></div>
                        </div>
                    </div>
                    <div className='col-7 p-0'>
                        <a id="downloaddepFileBtn" href="#" className="btn btn-primary" onClick={(e) => {
                            e.preventDefault()
                            downloadFile(true)
                        }}><i className="fas fa-download mr-2"></i>Download Depository<br /> Inventory CSV</a>
                        <div className='d-flex justify-content-center'>
                            <div id="downloaddepFileBtnLoader" className='animBtn d-none'><div className='gSignIn-Anim'></div></div>
                        </div>
                    </div>
                </div>
            </form>

            <div className='row m-0 p-0 mt-5'>
                <button className="btn btn-primary d-md-none" onClick={(e) => {
                    e.preventDefault()
                    document.getElementById("updateExportInventory").classList.toggle('d-none')
                    document.getElementById("inventoryAnalyticsListPage").classList.toggle('d-none')
                    history.push('/shareManagement/inventory')
                }}>View Analytics</button>
            </div>
            <ToastPage />
        </div>
    )
}