import { DataGrid, GridToolbar } from '@material-ui/data-grid';
import React, { useEffect, useState } from 'react';
import { configs, formatCurrency } from '../../../assets/Config';
import { useLogin } from '../../../services/contexts/AdminLoginContext';
import LeftBarPage from '../../includes/dashboard/LeftBarPage';
import TopBarPage from '../../includes/dashboard/TopBarPage';
import '../../../assets/styles/NM.css'
import { useNMContext } from '../../../services/contexts/NMContext';
import ToastPage from '../../includes/ToastPage';
import { Link } from 'react-router-dom';
import { useHistory } from 'react-router';
import { Modal } from '@material-ui/core';
import { useKYCContext } from '../../../services/contexts/KYCContext';
import { useBuyOrderContext } from '../../../services/contexts/BuyOrdersContext';
import { Autocomplete } from '@material-ui/lab';
import { TextField } from "@material-ui/core";
import { getDashboardData, postDashboardData } from '../../../services/apis/DistributorDashboardManagementApi';


export default function DistributorDashboardPage() {

    const { setCurrModule, allowedFunc, token, setShowSuccess, logout } = useLogin()

    const [Youtube, setYoutube] = useState([])
    const [YoutubeError, setYoutubeError] = useState("")
    const [Updates, setUpdates] = useState([])
    const [UpdatesError, setUpdatesError] = useState("")
    const [ClientLink, setClientLink] = useState([])
    const [ClientLinkError, setClientLinkError] = useState("")

    const history = useHistory()


    useEffect(() => {
        (async () => {
            try {
                setCurrModule(configs.modules.distDashMng)
                if (allowedFunc.includes(configs.functionList['dashboard_management:manage_dashboard'])) {
                    (async () => {
                        let response = await getDashboardData(token)
                        if (response && response.status === 200) {
                            if (response.data && response.data.latest_update && response.data.latest_update.length > 0) {
                                setUpdates(response.data.latest_update)
                            }
                            if (response.data && response.data.youtube && response.data.youtube.length > 0) {
                                setYoutube(response.data.youtube)
                            }
                            if (response.data && response.data.clients && response.data.clients.length > 0) {
                                setClientLink(response.data.clients)
                            }

                        } else if (response && response.status === 401) {
                            logout();
                        } else {
                        }
                    })()
                } else {
                    return history.goBack()
                }
            } catch (error) {

            }
        })()
    }, [])

    async function saveData(type, btnId) {
        try {

            document.getElementById(btnId).classList.toggle("d-none")
            document.getElementById("saveDataBtnLoader").classList.toggle("d-none")

            let response;
            if (type == "latest_update") {
                let allOk = true;
                Updates.forEach(element => {
                    if (!element.value || !element.description) {
                        allOk = false;
                    }
                })
                if ((Updates.length === 5) && allOk) {
                    response = await postDashboardData(token, type, Updates)
                    if (response && response.status === 200) {
                        setUpdatesError("");
                        setShowSuccess(true)
                    } else if (response && response.status === 401) {
                        logout();
                    } else {
                        setUpdatesError(response.data.error);
                    }

                } else {
                    setUpdatesError("Please provide 5 links and their label for Latest Update.")
                }

            } else if (type == "clients") {
                let allOk = true;
                ClientLink.forEach(element => {
                    if (!element.value || !element.description) {
                        allOk = false;
                    }
                })
                if ((ClientLink.length === 4) && allOk) {
                    response = await postDashboardData(token, type, ClientLink)
                    if (response && response.status === 200) {
                        setClientLinkError("");
                        setShowSuccess(true)
                    } else if (response && response.status === 401) {
                        logout();
                    } else {
                        setClientLinkError(response.data.error);
                    }

                } else {
                    setClientLinkError("Please provide 3 links and their label for For Your Clients Data.")
                }

            }
            else if (type == "youtube") {
                let allOk = true;
                Youtube.forEach(element => {
                    if (!element.value) {
                        allOk = false;
                    }
                })
                if ((Youtube.length === 3) && allOk) {
                    response = await postDashboardData(token, type, Youtube)
                    if (response && response.status === 200) {
                        setYoutubeError("");
                        setShowSuccess(true)
                    } else if (response && response.status === 401) {
                        logout();
                    } else {
                        setYoutubeError(response.data.error);
                    }

                } else {
                    setYoutubeError("Please enter 3 youtube links.")
                }
            }

            document.getElementById(btnId).classList.toggle("d-none")
            document.getElementById("saveDataBtnLoader").classList.toggle("d-none")
        } catch (error) {
            console.error(error)
        }
    }


    // customer dashboard management main ui code
    return (
        <>
            <TopBarPage />
            <div style={{ display: "flex" }}>
                <LeftBarPage />
                <div id='nmContainer' className="smPO-container">
                    <div style={{ width: "100%" }}>
                        <div className="row d-flex align-items-center mt-2 ml-2 mb-4 mr-3">
                            <div className="col-lg-6 text-left">
                                <p className="h4 text-custom-grey">Distributor Dashboard Management</p>
                            </div>
                        </div>
                        <div className="smPOI-container">
                            <div className='row'>
                                <div className='col-12 mb-4'>
                                    <form>
                                        <div>
                                            <label htmlFor="title" className='font-weight-bold'>Latest Updates</label>
                                        </div>
                                        <div className="row ">
                                            <div className='col-12 f-12'>
                                                <label htmlFor="title">Update Link 1</label>
                                            </div>
                                            <div className='col-md-6 col-12'>
                                                <input type="text" className="form-control mb-1 f-10" name="title" value={Updates && Updates[0] ? Updates[0].description : ""} placeholder="Display Text" onChange={(e) => {
                                                    let temp = [...Updates];
                                                    temp[0] = temp[0] ? temp[0] : {}
                                                    temp[0].description = e.target.value;
                                                    setUpdates(temp)
                                                }} />
                                            </div>
                                            <div className='col-md-6 col-12'>
                                                <input type="text" className="form-control mb-1 f-10" name="title" value={Updates && Updates[0] ? Updates[0].value : ""} placeholder="Link" onChange={(e) => {
                                                    let temp = [...Updates];
                                                    temp[0] = temp[0] ? temp[0] : {}
                                                    temp[0].value = e.target.value;
                                                    setUpdates(temp)
                                                }} />
                                            </div>
                                        </div>
                                        <div className="row ">
                                            <div className='col-12 f-12'>
                                                <label htmlFor="title">Update Link 2</label>
                                            </div>
                                            <div className='col-md-6 col-12'>
                                                <input type="text" className="form-control mb-1 f-10" name="title" value={Updates && Updates[1] ? Updates[1].description : ""} placeholder="Display Text" onChange={(e) => {
                                                    let temp = [...Updates];
                                                    temp[1] = temp[1] ? temp[1] : {}
                                                    temp[1].description = e.target.value;
                                                    setUpdates(temp)
                                                }} />
                                            </div>
                                            <div className='col-md-6 col-12'>
                                                <input type="text" className="form-control mb-1 f-10" name="title" value={Updates && Updates[1] ? Updates[1].value : ""} placeholder="Link" onChange={(e) => {
                                                    let temp = [...Updates];
                                                    temp[1] = temp[1] ? temp[1] : {}
                                                    temp[1].value = e.target.value;
                                                    setUpdates(temp)
                                                }} />
                                            </div>
                                        </div>
                                        <div className="row ">
                                            <div className='col-12 f-12'>
                                                <label htmlFor="title">Update Link 3</label>
                                            </div>
                                            <div className='col-md-6 col-12'>
                                                <input type="text" className="form-control mb-1 f-10" name="title" value={Updates && Updates[2] ? Updates[2].description : ""} placeholder="Display Text" onChange={(e) => {
                                                    let temp = [...Updates];
                                                    temp[2] = temp[2] ? temp[2] : {}
                                                    temp[2].description = e.target.value;
                                                    setUpdates(temp)
                                                }} />
                                            </div>
                                            <div className='col-md-6 col-12'>
                                                <input type="text" className="form-control mb-1 f-10" name="title" value={Updates && Updates[2] ? Updates[2].value : ""} placeholder="Link" onChange={(e) => {
                                                    let temp = [...Updates];
                                                    temp[2] = temp[2] ? temp[2] : {}
                                                    temp[2].value = e.target.value;
                                                    setUpdates(temp)
                                                }} />
                                            </div>
                                        </div>
                                        <div className="row ">
                                            <div className='col-12 f-12'>
                                                <label htmlFor="title">Update Link 4</label>
                                            </div>
                                            <div className='col-md-6 col-12'>
                                                <input type="text" className="form-control mb-1 f-10" name="title" value={Updates && Updates[3] ? Updates[3].description : ""} placeholder="Display Text" onChange={(e) => {
                                                    let temp = [...Updates];
                                                    temp[3] = temp[3] ? temp[3] : {}
                                                    temp[3].description = e.target.value;
                                                    setUpdates(temp)
                                                }} />
                                            </div>
                                            <div className='col-md-6 col-12'>
                                                <input type="text" className="form-control mb-1 f-10" name="title" value={Updates && Updates[3] ? Updates[3].value : ""} placeholder="Link" onChange={(e) => {
                                                    let temp = [...Updates];
                                                    temp[3] = temp[3] ? temp[3] : {}
                                                    temp[3].value = e.target.value;
                                                    setUpdates(temp)
                                                }} />
                                            </div>
                                        </div>
                                        <div className="row ">
                                            <div className='col-12 f-12'>
                                                <label htmlFor="title">Update Link 5</label>
                                            </div>
                                            <div className='col-md-6 col-12'>
                                                <input type="text" className="form-control mb-1 f-10" name="title" value={Updates && Updates[4] ? Updates[4].description : ""} placeholder="Display Text" onChange={(e) => {
                                                    let temp = [...Updates];
                                                    temp[4] = temp[4] ? temp[4] : {}
                                                    temp[4].description = e.target.value;
                                                    setUpdates(temp)
                                                }} />
                                            </div>
                                            <div className='col-md-6 col-12'>
                                                <input type="text" className="form-control mb-1 f-10" name="title" value={Updates && Updates[4] ? Updates[4].value : ""} placeholder="Link" onChange={(e) => {
                                                    let temp = [...Updates];
                                                    temp[4] = temp[4] ? temp[4] : {}
                                                    temp[4].value = e.target.value;
                                                    setUpdates(temp)
                                                }} />
                                            </div>
                                        </div>
                                        <div className="form-check p-0">
                                            <p className="text-danger m-0" id="generateSocialMediaImageError">{UpdatesError}</p>
                                        </div>

                                        <div className=" mt-1">
                                            <button id="latest_updateBtn" className="btn btn-primary" onClick={(e) => {
                                                e.preventDefault()
                                                saveData("latest_update", "latest_updateBtn")
                                            }}>Save</button>
                                            <div id="saveDataBtnLoader" className='animBtn d-none'><div className='gSignIn-Anim'></div></div>
                                        </div>
                                    </form>
                                </div>
                                <div className='col-12 my-4'>
                                    <form>
                                        <div>
                                            <label htmlFor="title" className='font-weight-bold'>For Your Clients</label>
                                        </div>
                                        <div className="row ">
                                            <div className='col-12 f-12'>
                                                <label htmlFor="title">Link 1</label>
                                            </div>
                                            <div className='col-md-6 col-12'>
                                                <input type="text" className="form-control mb-1 f-10" name="title" value={ClientLink && ClientLink[0] ? ClientLink[0].description : ""} placeholder="Display Text" onChange={(e) => {
                                                    let temp = [...ClientLink];
                                                    temp[0] = temp[0] ? temp[0] : {}
                                                    temp[0].description = e.target.value;
                                                    setClientLink(temp)
                                                }} />
                                            </div>
                                            <div className='col-md-6 col-12'>
                                                <input type="text" className="form-control mb-1 f-10" name="title" value={ClientLink && ClientLink[0] ? ClientLink[0].value : ""} placeholder="Link" onChange={(e) => {
                                                    let temp = [...ClientLink];
                                                    temp[0] = temp[0] ? temp[0] : {}
                                                    temp[0].value = e.target.value;
                                                    setClientLink(temp)
                                                }} />
                                            </div>
                                        </div>
                                        <div className="row ">
                                            <div className='col-12 f-12'>
                                                <label htmlFor="title">Link 2</label>
                                            </div>
                                            <div className='col-md-6 col-12'>
                                                <input type="text" className="form-control mb-1 f-10" name="title" value={ClientLink && ClientLink[1] ? ClientLink[1].description : ""} placeholder="Display Text" onChange={(e) => {
                                                    let temp = [...ClientLink];
                                                    temp[1] = temp[1] ? temp[1] : {}
                                                    temp[1].description = e.target.value;
                                                    setClientLink(temp)
                                                }} />
                                            </div>
                                            <div className='col-md-6 col-12'>
                                                <input type="text" className="form-control mb-1 f-10" name="title" value={ClientLink && ClientLink[1] ? ClientLink[1].value : ""} placeholder="Link" onChange={(e) => {
                                                    let temp = [...ClientLink];
                                                    temp[1] = temp[1] ? temp[1] : {}
                                                    temp[1].value = e.target.value;
                                                    setClientLink(temp)
                                                }} />
                                            </div>
                                        </div>
                                        <div className="row ">
                                            <div className='col-12 f-12'>
                                                <label htmlFor="title">Link 3</label>
                                            </div>
                                            <div className='col-md-6 col-12'>
                                                <input type="text" className="form-control mb-1 f-10" name="title" value={ClientLink && ClientLink[2] ? ClientLink[2].description : ""} placeholder="Display Text" onChange={(e) => {
                                                    let temp = [...ClientLink];
                                                    temp[2] = temp[2] ? temp[2] : {}
                                                    temp[2].description = e.target.value;
                                                    setClientLink(temp)
                                                }} />
                                            </div>
                                            <div className='col-md-6 col-12'>
                                                <input type="text" className="form-control mb-1 f-10" name="title" value={ClientLink && ClientLink[2] ? ClientLink[2].value : ""} placeholder="Link" onChange={(e) => {
                                                    let temp = [...ClientLink];
                                                    temp[2] = temp[2] ? temp[2] : {}
                                                    temp[2].value = e.target.value;
                                                    setClientLink(temp)
                                                }} />
                                            </div>
                                        </div>
                                        <div className="row ">
                                            <div className='col-12 f-12'>
                                                <label htmlFor="title">Link 4</label>
                                            </div>
                                            <div className='col-md-6 col-12'>
                                                <input type="text" className="form-control mb-1 f-10" name="title" value={ClientLink && ClientLink[3] ? ClientLink[3].description : ""} placeholder="Display Text" onChange={(e) => {
                                                    let temp = [...ClientLink];
                                                    temp[3] = temp[3] ? temp[3] : {}
                                                    temp[3].description = e.target.value;
                                                    setClientLink(temp)
                                                }} />
                                            </div>
                                            <div className='col-md-6 col-12'>
                                                <input type="text" className="form-control mb-1 f-10" name="title" value={ClientLink && ClientLink[3] ? ClientLink[3].value : ""} placeholder="Link" onChange={(e) => {
                                                    let temp = [...ClientLink];
                                                    temp[3] = temp[3] ? temp[3] : {}
                                                    temp[3].value = e.target.value;
                                                    setClientLink(temp)
                                                }} />
                                            </div>
                                        </div>
                                        <div className="form-check p-0">
                                            <p className="text-danger m-0" id="generateSocialMediaImageError">{ClientLinkError}</p>
                                        </div>

                                        <div className=" mt-1">
                                            <button id="clientsBtn" className="btn btn-primary" onClick={(e) => {
                                                e.preventDefault()
                                                saveData("clients", "clientsBtn")
                                            }}>Save</button>
                                            <div id="saveDataBtnLoader" className='animBtn d-none'><div className='gSignIn-Anim'></div></div>
                                        </div>
                                    </form>
                                </div>
                                <div className='col-12 my-4'>
                                    <form>
                                        <div>
                                            <label htmlFor="title" className='font-weight-bold'>For You (YouTube Embed Links)</label>
                                        </div>
                                        <div className="row ">
                                            <div className='col-12 f-12'>
                                                <label htmlFor="title">Link 1</label>
                                            </div>
                                            <div className='col-md-6 col-12'>
                                                <input type="text" className="form-control mb-1 f-10" name="title" value={Youtube && Youtube[0] ? Youtube[0].value : ""} placeholder="Link" onChange={(e) => {
                                                    let temp = [...Youtube];
                                                    temp[0] = temp[0] ? temp[0] : {}
                                                    temp[0].value = e.target.value;
                                                    temp[0].description = null;
                                                    setYoutube(temp)
                                                }} />
                                            </div>
                                        </div>
                                        <div className="row ">
                                            <div className='col-12 f-12'>
                                                <label htmlFor="title">Link 2</label>
                                            </div>
                                            <div className='col-md-6 col-12'>
                                                <input type="text" className="form-control mb-1 f-10" name="title" value={Youtube && Youtube[1] ? Youtube[1].value : ""} placeholder="Link" onChange={(e) => {
                                                    let temp = [...Youtube];
                                                    temp[1] = temp[1] ? temp[1] : {}
                                                    temp[1].value = e.target.value;
                                                    temp[1].description = null;
                                                    setYoutube(temp)
                                                }} />
                                            </div>
                                        </div>
                                        <div className="row ">
                                            <div className='col-12 f-12'>
                                                <label htmlFor="title">Link 3</label>
                                            </div>
                                            <div className='col-md-6 col-12'>
                                                <input type="text" className="form-control mb-1 f-10" name="title" value={Youtube && Youtube[2] ? Youtube[2].value : ""} placeholder="Link" onChange={(e) => {
                                                    let temp = [...Youtube];
                                                    temp[2] = temp[2] ? temp[2] : {}
                                                    temp[2].value = e.target.value;
                                                    temp[2].description = null;
                                                    setYoutube(temp)
                                                }} />
                                            </div>
                                        </div>
                                        <div className="form-check p-0">
                                            <p className="text-danger m-0" id="generateSocialMediaImageError">{YoutubeError}</p>
                                        </div>

                                        <div className=" mt-1">
                                            <button id="youtubeBtn" className="btn btn-primary" onClick={(e) => {
                                                e.preventDefault()
                                                saveData("youtube", "youtubeBtn")
                                            }}>Save</button>
                                            <div id="saveDataBtnLoader" className='animBtn d-none'><div className='gSignIn-Anim'></div></div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <ToastPage />
            </div>
        </>
    )
}